import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SuccessModal from "./SuccessModal";
import Confetti from "react-confetti";
import LanguageModal from "./LanguageModal";
import { MerchantListings } from "./Listings";
import { getHome, getListing } from "../../api/merchant";
import { getEventStatus } from "../../utils/common";
import { Reservations } from "./Reservations";
import { useEffect, useState } from "react";
import BeatLoader from "react-spinners/BeatLoader";
import { updateSelectedListing } from "../../store/slicers/listingSlice";
import ChevronRight from "../../assets/svg/ChevronRight";
import { HowDoesItWorks } from "../common/HowDoesItWorksModal";
import { Soon } from "./Soon";
const MerchantHome = () => {
  const buttons = ["Event Inquiries"];
  const [state, setState] = useState(0);
  const merchant = useSelector(
    (state) => state.auth.account_info.merchant_info
  );
  const firstName = useSelector((state) => state.auth.account_info.firstname);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showListing, setShowlisting] = useState(false);
  const [showReservations, setShowReservations] = useState(false);
  const showWelcome = useSelector((state) => state.auth.showWelcome);
  const showLanguage = useSelector((state) => state.auth.showLanguage);
  const selectedListing = useSelector((state) => state.listing.selectedListing);
  const [isModalShow, setModalShow] = useState(false);
  const sRowPerPage = 30;
  const [currentEvent, setCurrentEvent] = useState();
  const [pendingEvent, setPendingEvent] = useState();
  const [upcomingEvent, setUpcomingEvent] = useState();
  const [cancelledEvent, setCancelledEvent] = useState();
  const [eventInquiries, setEventInquiries] = useState()
  const [bLoading, setLoading] = useState(false);
  const isSoonMerchant = useSelector((state) => state.listing.isSoonMerchant);

  const filterEvent = async () => {
    if (bLoading) {
      return;
    }

    setLoading(true);
    const requestData = {
      service_code: selectedListing?.service_code,
    };

    try {
      const response = await getHome(requestData);
      if (response.error === 0) {
        setEventInquiries(response.data.event_inquiries);
        setCurrentEvent(response.data.current_event);
        setPendingEvent(response.data.pending_event);
        setUpcomingEvent(response.data.upcoming_event);
        setCancelledEvent(response.data.cancelled_event);
        setLoading(false);
      } else {
        setEventInquiries([])
        setCurrentEvent([]);
        setPendingEvent([]);
        setUpcomingEvent([]);
        setCancelledEvent([]);
        setLoading(false);
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  useEffect(() => {
    handleListings();
  }, []);

  const handleListings = async () => {
    const requestData = {
      page_no: 1,
      row_per_page: 1,
    };

    try {
      const response = await getListing(requestData);
      if (response.error === 0) {
        if (response.data.pagination.current_page === 1) {
          if (response.data.services_list.length !== 0 && !selectedListing) {
            dispatch(updateSelectedListing(response.data.services_list[0]));
          }
        }
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  useEffect(() => {
    filterEvent();
  }, [selectedListing]);

  const handleClick = (event, status) => {
    if (state === 2) {
      if (status !== "Expired") {
      }
    }
  };
  const handleRemove = async (bookingCode) =>{
    if (bLoading) {
      return;
    }

    setLoading(true);
    const requestData = {
      booking_code: bookingCode,
      flag: 'remove'
    };

    try {
      const response = await getHome(requestData);
      if (response.error === 0) {
        setEventInquiries(response.data.event_inquiries);
        setCurrentEvent(response.data.current_event);
        setPendingEvent(response.data.pending_event);
        setUpcomingEvent(response.data.upcoming_event);
        setCancelledEvent(response.data.cancelled_event);
        setLoading(false);
      }
    } catch (error) {
      console.log("Error:", error);
    }
  }
  const renderComponent = (event, key) => {
    return (
      <div key={key} className=" flex flex-col bg-primary-50  rounded-lg ">
        <div
          className="p-5"
          onClick={() => handleClick(event, event.note)}
        >
          {/* <div className="flex flex-col gap-2">
            <p
              className={`py-1 px-2 w-max  text-xs rounded-full text-white ${
                event.note === "Completed"
                  ? "bg-green-500"
                  : "bg-orange-500 "
              }`}
            >
              {event.note}
            </p>
            {event?.payment_status_note && (
              <p className="py-1 bg-primary-600 text-white px-2 w-max  text-xs rounded-full">
                {event?.payment_status_note}
              </p>
            )}
          </div> */}
          <div className="flex flex-row  mt-5">
            {event?.profile_img !== "" ? (
              <img
                draggable="false"
                className="mr-3 text-center text-white rounded-full bg-sunrise-500 w-12 h-12 bg-no-repeat bg-cover"
                src={event?.profile_img}
                alt={event?.client_name}
              />
            ) : (
              <div className="rounded-full bg-sunrise-500 w-12 h-12 bg-no-repeat bg-cover"></div>
            )}
            <div className="flex flex-col self-center ">
              <p className="text-primary-600 font-semibold">
                {event.client_name}
              </p>
            </div>
          </div>
          <div className="flex flex-col text-primary-600 text-xs mt-3">
            <div className="flex gap-x-2 items-center flex-row">
              <div className="w-5 h-5">
                <div
                  className="icon-calendar h-full w-full "
                  style={{ backgroundPositionY: "center" }}
                />
              </div>
              <p>{event.start_date}</p>
            </div>
            <div className="flex gap-x-2 items-center flex-row">
              <div className="w-5 h-5">
                <div
                  className="icon-location h-full w-full "
                  style={{ backgroundPositionY: "center" }}
                />
              </div>
              <p>{event.location}</p>
            </div>
          </div>
        </div>
        <div
          onClick={() => {
            navigate("/merchant/inbox/" + event.booking_code);
          }}
          className={`cursor-pointer rounded-full p-2 bg-primary-500 mb-5 mx-5`}
        >
          <p className="text-white text-sm text-center">Message</p>
        </div>
        <div onClick={()=> handleRemove(event.booking_code)} className={`cursor-pointer rounded-b-lg p-2 bg-red-500 mt-1`}>
          <p className="text-white text-center">Remove</p>
        </div>
      </div>
    );
  };

  return (
    <div className="align-center items-center">
      {!showWelcome && !showLanguage && (
        <div>
          <div className="border flex flex-wrap text-center lg:text-left lg:justify-between rounded-xl p-4 w-full justify-center">
            <div>
              <div className="text-primary-600 font-semibold text-xl">
                <h3>Hello {firstName}!</h3>
              </div>
              <div className="flex flex-col lg:flex-row mt-2 lg:mt-0 gap-y-2 lg:gap-y-0">
                <p className="text-xs text-primary-600 pr-0 lg:pr-4">
                  {merchant?.merchant_name}
                </p>
                {merchant?.status === "2" && (
                  <p className="text-xs text-primary-300 ">
                    {selectedListing?.title +
                      ` 
                  ${selectedListing?.status === "1" ? "(Inactive)" : ""} 
                  ${selectedListing?.status === "9" ? "(For Approval)" : ""}` ||
                      "-"}
                  </p>
                )}
              </div>
            </div>
            <div className="flex flex-wrap justify-center text-center gap-x-4 mt-4 lg:mt-0">
              <div className="flex flex-wrap gap-2 justify-center">
                {merchant?.status === "2" && (
                  <div
                    onClick={() => setShowlisting(true)}
                    className="text-sm bg-sunrise-500 hover:opacity-90 cursor-pointer border rounded-full text-center align-center border-sunrise-500 px-6 py-2"
                  >
                    <p className="text-center text-white self-center text-center flex h-full items-center justify-center">
                     Switch Event Listing
                    </p>
                  </div>
                )}
                {/* {merchant?.status === "2" && (
                  <div
                    onClick={() => setShowReservations(true)}
                    className="text-sm  bg-sunrise-500 hover:opacity-90 cursor-pointer border rounded-full text-center align-center border-sunrise-500 px-6 py-2"
                  >
                    <p className="text-center text-white self-center text-center flex h-full items-center justify-center">
                      Reservations
                    </p>
                  </div>
                )} */}
                <div
                  onClick={() => setModalShow(true)}
                  className="flex justify-between w-full lg:w-40 py-2 cursor-pointer text-xs select-none rounded-full bg-white ring-1 ring-primary-500"
                >
                  <div className="items-center flex-row  flex w-full">
                    <div className="text-left px-2 text-primary-300 w-full ">
                      How does it work?
                    </div>
                    <div className="rounded-full bg-primary-500 mr-1">
                      <ChevronRight />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="border h-full my-5 rounded-xl  p-4 w-full">
            <div className="w-max  flex flex-row rounded-full border border-primary-500">
              {buttons.map((btn, idx) => {
                return (
                  <div
                    onClick={() => setState(idx)}
                    key={idx}
                    className={`${
                      idx === state && "bg-primary-500"
                    } text-sm hover:opacity-90 cursor-pointer m-1 rounded-full py-2 align-center px-5`}
                  >
                    <p
                      className={`${
                        idx === state && "text-white"
                      }  text-center text-primary-500`}
                    >
                      {btn}
                    </p>
                  </div>
                );
              })}
            </div>

            {merchant?.status === "1" && (
              <div className="flex flex-col m-5">
                <p className="text-sm text-primary-600">
                  Your application is now subject for verification. Please wait
                  for confirmation
                </p>
                <div className="mt-10" style={{ textAlignLast: "center" }}>
                  <BeatLoader color="#423767" />
                </div>
              </div>
            )}

            <div className="w-full  gap-5 mt-5 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4">
              {state === 0 ? (
                merchant?.status === "1" ? null : eventInquiries === undefined ||
                  eventInquiries?.length === 0 ? (
                  <div className="py-40 flex col-span-1 md:col-span-2 lg:col-span-3 2xl:col-span-4 justify-center">
                    <div className="justify-center text-center-webkit">
                      <div className="  items-center  w-20 h-20 mr-3">
                        <div className="no-item h-full w-full" />
                      </div>
                      <p className="mt-2 text-primary-600 text-sm">
                        There's no inquiry
                      </p>
                    </div>
                  </div>
                ) : (
                  eventInquiries?.map((event, key) => {
                    return renderComponent(event, key);
                  })
                )
              ) : null}
              {state === 1 ? (
                merchant?.status === "1" ? null : upcomingEvent === undefined ||
                  upcomingEvent?.length === 0 ? (
                  <div className="py-40 flex col-span-1 md:col-span-2 lg:col-span-3 2xl:col-span-4 justify-center">
                    <div className="justify-center text-center-webkit">
                      <div className="  items-center  w-20 h-20 mr-3">
                        <div className="no-item h-full w-full" />
                      </div>
                      <p className="mt-2 text-primary-600 text-sm">
                        There's no upcoming event
                      </p>
                    </div>
                  </div>
                ) : (
                  upcomingEvent?.map((event, key) => {
                    return renderComponent(event, key);
                  })
                )
              ) : null}
            </div>
          </div>
        </div>
      )}
      {showListing && (
        <MerchantListings
          isOpen={showListing}
          selectedListing={selectedListing}
          setSelectedListing={(val) => dispatch(updateSelectedListing(val))}
          onClose={() => setShowlisting(false)}
        />
      )}

      {showReservations && (
        <Reservations
          selectedListing={selectedListing}
          isOpen={showReservations}
          onClose={() => setShowReservations(false)}
        />
      )}

      {showWelcome && <SuccessModal />}
      {showWelcome && (
        <Confetti
          width={window.innerWidth - 20}
          height={window.innerHeight - 20}
          tweenDuration={1}
        />
      )}
      {showWelcome
        ? null
        : (showLanguage || merchant?.language === null) && <LanguageModal />}

      {isModalShow && (
        <HowDoesItWorks
          isMerchant={true}
          handleClose={() => setModalShow(false)}
        />
      )}

     {/* {isSoonMerchant &&  <Soon />}  */}
    </div>
  );
};

export default MerchantHome;
