import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import {
  updateLoginModal,
  updateLogoutModal,
  updateMenuModal,
  updateUnreads,
} from "../../store/slicers/authSlice";
import { useEffect } from "react";
import { toast } from "react-hot-toast";
import { unreadData } from "../../api/client";
export const Menu = ({ isScrolled, merchantInfo, isMerchantRegister }) => {
  const isLoggedIn = useSelector((state) => state.auth.token);
  const unreadNotif = useSelector((state) => state.auth.unreadNotif);
  const merchantMsg = useSelector((state) => state.auth.merchantMsg);
  const unreadMsg = useSelector((state) => state.auth.unreadMsg);
  const [isInMerchant, setInMerchant] = useState(false);
  const [isInInbox, setIsInInbox] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const getUnreads = async () => {
    try {
      const response = await unreadData();
      if (response.error === 0) {
        dispatch(
          updateUnreads({
            notif: response.data?.unread_notif,
            msg: response.data?.unread_msg,
            merchantMsg: response?.data?.unread_msg_merchant || 0,
          })
        );
      }
    } catch (error) {}
  };

  useEffect(() => {
    getUnreads();
  }, []);

  useEffect(() => {
    if (location.pathname.includes("merchant")) {
      setInMerchant(true);
    } else {
      setInMerchant(false);
    }

    if (location.pathname.includes("inbox")) {
      setIsInInbox(true);
    } else {
      setIsInInbox(false);
    }
  }, [location]);

  return (
    <>
      <div
        onClick={() => {
          dispatch(updateMenuModal(false));
        }}
        className="fixed inset-0  opacity-50 z-10"
      />
      <div
        className={`fixed z-10 ${isScrolled ? "top-16" : "top-40"} ${
          isInInbox ? "right-10" : "right-10"
        } lg:right-40 py-4 w-56 bg-white rounded-xl shadow-2xl`}
      >
        <ul className="flex flex-col">
        {!isInMerchant && !isMerchantRegister && (
          <li
            className={`cursor-pointer hover:bg-gray-100 flex lg:hidden`}
            onClick={() => {
              window.open(
                "https://heyzine.com/flip-book/fe3b4e9a2e.html",
                "_blank"
              );
            }}
          >
            <p className="text-xs text-primary-500 font-semibold py-3 px-4">
              Make it DIY!
            </p>
          </li>)}

        {!isInMerchant && !merchantInfo && (
          <li
            className={`cursor-pointer hover:bg-gray-100 flex lg:hidden`}
            onClick={() => {
              navigate("/merchant");
              dispatch(updateMenuModal(false));
            }}
          >
            <p className="text-xs text-primary-500 font-semibold py-3 px-4">
              Be an Event Merchant
            </p>
          </li>)}

          {/* {isInMerchant && merchantInfo && (
            <li
              className={`cursor-pointer hover:bg-gray-100 flex lg:hidden`}
              onClick={() => {
                navigate("/category");
                dispatch(updateMenuModal(false));
              }}
            >
              <p className="text-xs text-primary-500 font-semibold py-3 px-4">
                Switch as Guest
              </p>
            </li>
          )} */}

          {!isInMerchant && merchantInfo && (
            <li
              className={`cursor-pointer hover:bg-gray-100 flex lg:hidden`}
              onClick={() => {
                navigate("/merchant/home");
              }}
            >
              <p className="text-xs text-primary-500 font-semibold py-3 px-4">
                Switch as Merchant
              </p>
            </li>
          )}

          {isLoggedIn ? (
            <>
              {isMerchantRegister && (
                <li
                  className={`cursor-pointer hover:bg-gray-100 flex lg:hidden`}
                  onClick={() => {
                    navigate("/category");
                    dispatch(updateMenuModal(false));
                  }}
                >
                  <p className="text-xs text-primary-500 font-semibold py-3 px-4">
                    Switch as Guest
                  </p>
                </li>
              )}

              {isInMerchant && merchantInfo && (
                <>
                  <li
                    className={`cursor-pointer hover:bg-gray-100 flex lg:hidden`}
                    onClick={() => {
                      navigate("/category");
                      dispatch(updateMenuModal(false));
                    }}
                  >
                    <p className="text-xs text-primary-500 font-semibold py-3 px-4">
                      Switch as Guest
                    </p>
                  </li>

                  <li
                    className={`cursor-pointer hover:bg-gray-100 flex lg:hidden`}
                    onClick={() => {
                      navigate("/merchant/calendar");
                      dispatch(updateMenuModal(false));
                    }}
                  >
                    <p className="text-xs text-gray-400  font-semibold py-3 px-4">
                      Calendar
                    </p>
                  </li>

                  <li
                    className={`cursor-pointer hover:bg-gray-100 flex lg:hidden`}
                    onClick={() => {
                      navigate("/merchant/insights");
                      dispatch(updateMenuModal(false));
                    }}
                  >
                    <p className="text-xs text-gray-400  font-semibold py-3 px-4">
                      Insights
                    </p>
                  </li>

                  <li
                    className={`cursor-pointer hover:bg-gray-100 flex lg:hidden`}
                    onClick={() => {
                      navigate("/merchant/menu");
                      dispatch(updateMenuModal(false));
                    }}
                  >
                    <p className="text-xs text-gray-400  font-semibold py-3 px-4">
                      Menu
                    </p>
                  </li>
                </>
              )}

              {/* {!isInMerchant && merchantInfo && (
                <li
                  className={`cursor-pointer hover:bg-gray-100 flex lg:hidden`}
                  onClick={() => {
                    navigate("/merchant/home");
                    dispatch(updateMenuModal(false));
                  }}
                >
                  <p className="text-xs text-primary-500 font-semibold py-3 px-4">
                    Switch as Merchant
                  </p>
                </li>
              )} */}
            </>
          ) : null}

          {!isLoggedIn && (
            <>
              <li
                className="cursor-pointer hover:bg-gray-100"
                onClick={() => {
                  dispatch(updateLoginModal({ status: true }));
                  dispatch(updateMenuModal(false));
                }}
              >
                <p className="text-xs text-black py-3 px-4">Log In</p>
              </li>
              <li
                className="cursor-pointer hover:bg-gray-100"
                onClick={() => {
                  dispatch(updateLoginModal({ status: true }));
                  dispatch(updateMenuModal(false));
                }}
              >
                <p className="text-xs text-gray-400 py-3 px-4">Sign Up</p>
              </li>
            </>
          )}
          {isLoggedIn && (
            <>
              <li
                className="cursor-pointer hover:bg-gray-100"
                onClick={() => {
                  if (isInMerchant) {
                    console.log("navigate")
                    navigate("/merchant/inbox/all");
                  } else {
                    navigate("/client/inbox/all");
                  }
                  dispatch(updateMenuModal(false));
                }}
              >
                <p className="text-xs font-semibold text-gray-400 py-3 px-4 relative">
                  Messages{" "}
                  {isInMerchant
                    ? merchantMsg === 1 && (
                        <span className="bg-red-500 rounded-full p-0.5 absolute mx-1"></span>
                      )
                    : unreadMsg === 1 && (
                        <span className="bg-red-500 rounded-full p-0.5 absolute mx-1"></span>
                      )}
                </p>
              </li>
              <li
                className="cursor-pointer font-semibold hover:bg-gray-100"
                onClick={() => {
                  navigate("/notifications");
                  dispatch(updateMenuModal(false));
                }}
              >
                <p className="text-xs font-semibold text-gray-400 py-3 px-4 relative">
                  Notifications
                  {unreadNotif === 1 && (
                    <span className="bg-red-500 rounded-full p-0.5 absolute mx-1"></span>
                  )}
                </p>
              </li>
              {!isInMerchant && (
                <li
                  className="cursor-pointer font-semibold hover:bg-gray-100"
                  onClick={() => {
                    navigate("/events");
                    dispatch(updateMenuModal(false));
                  }}
                >
                  <p className="text-xs text-gray-400 py-3 px-4">My Events</p>
                </li>
              )}
              {!isInMerchant && (
                <li
                  className="cursor-pointer font-semibold hover:bg-gray-100"
                  onClick={() => {
                    navigate("/wishlists");
                    dispatch(updateMenuModal(false));
                  }}
                >
                  <p className="text-xs text-gray-400 py-3 px-4">Wishlists</p>
                </li>
              )}
              <li
                className="cursor-pointer hover:bg-gray-100 border-t"
                onClick={() => {
                  navigate("/account");
                  dispatch(updateMenuModal(false));
                }}
              >
                <p className="text-xs text-gray-400 py-3 px-4">Account</p>
              </li>

              <li
                className="cursor-pointer hover:bg-gray-100"
                onClick={() => {
                  dispatch(updateLogoutModal(true));
                  dispatch(updateMenuModal(false));
                }}
              >
                <p className="text-xs text-gray-400 py-3 px-4">Logout</p>
              </li>
            </>
          )}

          <li
            className={`cursor-pointer hover:bg-gray-100 ${
              !isLoggedIn && "border-t"
            }`}
            onClick={() => {
              navigate("/support");
              dispatch(updateMenuModal(false));
            }}
          >
            <p className="text-xs text-gray-400 py-3 px-4">Help Center</p>
          </li>
        </ul>
      </div>
    </>
  );
};
