import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import Close from "../../../assets/svg/Close";
import Loader from "../../common/Loader";
import { reportListing } from "../../../api/client/listing";
import Error from "../../../assets/svg/Error";
import dayjs from "dayjs";
import {
  paymentStatusHandler,
  paymentStatusHandlerColor,
  statusHandler,
  statusHandlerColor,
} from "../../../utils/common";
export const BookingDetails = ({ serviceOffered, handleOnClose, id }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 text-gray-500">
      <div
        className="fixed inset-0 bg-black opacity-50"
        onClick={() => handleOnClose()}
      ></div>
      <div className="bg-white p-6 mx-4 rounded-3xl relative w-500">
        <button
          className="absolute top-6 left-6 text-gray-400 hover:text-gray-600"
          onClick={handleOnClose}
        >
          <Close className="w-4 h-4" />
        </button>

        <div className="mt-10">
          <div>
            <div className="text-lg font-semibold">Booking Details</div>
            <div className="text-xs text-gray-400">
              {serviceOffered?.title} by {serviceOffered?.merchant_name}
            </div>
          </div>
          <div className="text-sm mt-5">
            <div className="flex flex-row justify-between">
              <p className="font-semibold">Booking Code</p>
              <p className=" cursor-pointer select-all text-primary-500">
                {id}
              </p>
            </div>

            <div className="mt-2 flex flex-row justify-between">
              <p className="font-semibold">Status</p>
              <p
                className={`${statusHandlerColor(
                  serviceOffered?.booking_status
                )}`}
              >
                {statusHandler(serviceOffered?.booking_status)}
              </p>
            </div>

            {/* <div className="mt-2 flex flex-row justify-between">
              <p className="font-semibold">Payment Status</p>
              <p className={`${paymentStatusHandlerColor(
                  serviceOffered?.payment_status
                )}`}>
                {paymentStatusHandler(serviceOffered?.payment_status)}
              </p>
            </div> */}
            <div className="mt-2 flex flex-row justify-between">
              <p className="font-semibold">Type of Event</p>
              <p className="text-gray-400">{serviceOffered?.remarks}</p>
            </div>
            <div className="mt-2 flex flex-row justify-between">
              <p className="font-semibold">Date</p>
              <p className="text-gray-400">
                {" "}
                {dayjs(serviceOffered?.start_date).format(
                  "MMM DD, YYYY h:MM A"
                )}{" "}
                {serviceOffered?.start_date !== serviceOffered?.end_date &&
                  "- " +
                    dayjs(serviceOffered?.end_date).format(
                      "MMM DD, YYYY h:MM A"
                    )}
              </p>
            </div>
            <div className="mt-2 flex flex-row justify-between">
              <p className="font-semibold">Venue</p>
              <p className="text-gray-400">{serviceOffered?.location}</p>
            </div>
            <div className="mt-2 flex flex-row justify-between">
              <p className="font-semibold">Number of guest/s</p>
              <p className="text-gray-400">{serviceOffered?.pax} pax</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
