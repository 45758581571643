import React, { useState } from "react";
import { ModalHeader } from "../../common/ModalHeader";

export const ORImages = ({
  setShowReceipts,
  receipts
  
}) => {
  const handleClose = () => {
    setShowReceipts();
  };
  
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 text-gray-500">
      <div className="fixed inset-0 bg-black opacity-50"></div>
      <div className="bg-white rounded-3xl relative py-6 mx-4 w-500">
        <ModalHeader
          title={"Receipts"}
          onClick={() => {
            handleClose();
          }}
          type={1}
        />
        <div className="overflow-auto h-96 px-5 gap-2 flex flex-col">
        {receipts?.map((data, key) => {
          return (
            <div key={key}>
              <a href={data?.receipt} target="_blank" rel="noopener noreferrer">
                <img
                  src={data?.receipt}
                  alt="Receipt"
                  className="w-full rounded-lg border"
                />
              </a>
            </div>
          );
        })}
        </div>
      </div>
    </div>
  );
};
