import React from "react";
const Loader = ({ borderColor = "border-white", size = "w-5 h-5" }) => {
  return (
    <div className="w-full flex items-center justify-center">
      <div
        className={`${size} rounded-full animate-spin  border border-solid ${borderColor} border-t-transparent`}
      ></div>
    </div>
  );
};

export default Loader;
