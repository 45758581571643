import axiosInstance from "../../utils/axiosInstance";
import axios from "axios";

export async function merchantApplication(_requestData) {
  const requestData = {
    ctl: "account",
    act: "merchant_application",
    ..._requestData,
  };
  try {
    const response = await axiosInstance.post("/", requestData);
    return response.data;
  } catch (error) {
    return error;
  }
}
export async function getListing(_requestData) {
  const requestData = {
    ctl: "services",
    act: "list",
    ..._requestData,
  };
  try {
    const response = await axiosInstance.post("/", requestData);
    return response.data;
  } catch (error) {
    return error;
  }
}

export async function getHome(_requestData) {
  const requestData = {
    ctl: "merchant",
    act: "home",
    ..._requestData,
  };
  try {
    const response = await axiosInstance.post("/", requestData);
    return response.data;
  } catch (error) {
    return error;
  }
}

export async function bookingConfirm(_requestData) {
  const requestData = {
    ctl: "merchant",
    act: "confirm_booking",
    ..._requestData,
  };
  try {
    const response = await axiosInstance.post("/", requestData);
    return response.data;
  } catch (error) {
    return error;
  }
}


export async function bookingCancel(_requestData) {
  const requestData = {
    ctl: "merchant",
    act: "cancel_booking",
    ..._requestData,
  };
  try {
    const response = await axiosInstance.post("/", requestData);
    return response.data;
  } catch (error) {
    return error;
  }
}

export async function availability(_requestData) {
  const requestData = {
    ctl: "services",
    act: "availability",
    ..._requestData,
  };

  try {
    const response = await axiosInstance.post("/", requestData);
    return response.data;
  } catch (error) {
    return error;
  }
}


export async function surcharge(_requestData) {
  const requestData = {
    ctl: "services",
    act: "surcharge",
    ..._requestData,
  };

  try {
    const response = await axiosInstance.post("/", requestData);
    return response.data;
  } catch (error) {
    return error;
  }
}




export async function services(_requestData) {
  const requestData = {
    ctl: "services",
    ..._requestData,
  };

  try {
    const response = await axiosInstance.post("/", requestData);
    return response.data;
  } catch (error) {
    return error;
  }
}

export async function report(_requestData) {
  const requestData = {
    ctl: "merchant",
    act: "report",
    ..._requestData,
  };

  try {
    const response = await axiosInstance.post("/", requestData);
    return response.data;
  } catch (error) {
    return error;
  }
}


export async function checkAccount(_requestData) {
  const requestData = {
    ctl: "merchant",
    act: "check_account",
    ..._requestData,
  };

  try {
    const response = await axiosInstance.post("/", requestData);
    return response.data;
  } catch (error) {
    return error;
  }
}



export async function merchantAccountUpdate(_requestData) {
  const requestData = {
    ctl: "merchant",
    act: "update_account",
    ..._requestData,
  };

  try {
    const response = await axiosInstance.post("/", requestData);
    return response.data;
  } catch (error) {
    return error;
  }
}

export async function bankInfo(_requestData) {
  const requestData = {
    ctl: "merchant",
    act: "bank_info",
    ..._requestData,
  };
  try {
    const response = await axiosInstance.post("/", requestData);
    return response.data;
  } catch (error) {
    return error;
  }
}


export async function payouts(_requestData) {
  const requestData = {
    ctl: "merchant",
    act: "payout",
    ..._requestData,
  };
  try {
    const response = await axiosInstance.post("/", requestData);
    return response.data;
  } catch (error) {
    return error;
  }
}


export async function uploadReceipt(_requestData) {
  const requestData = {
    ctl: "merchant",
    act: "upload_receipt",
    ..._requestData,
  };
  try {
    const response = await axiosInstance.post("/", requestData);
    return response.data;
  } catch (error) {
    return error;
  }
}