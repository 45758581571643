import Close from "../../../assets/svg/Close";
import { formatDateToMonthYear } from "../../../utils/common";

export const Review = ({ onClose, reviews }) => {
  const handleOnClose = () => {
    onClose();
  };
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 text-gray-500">
      <div className="fixed inset-0 bg-black opacity-50"></div>
      <div className="bg-white py-6 rounded-3xl relative mx-4 w-600">
        <button
          className="px-6 text-gray-400 hover:text-gray-600"
          onClick={handleOnClose}
        >
          <Close className="w-4 h-4" />
        </button>

        <div
          id="reviews"
          className="px-6 overflow-y-auto"
          style={{ maxHeight: "500px" }}
        >
          <div className="mt-10 mb-5">
            <div className="text-lg font-semibold">
              {reviews.length} reviews
            </div>
          </div>
          <div className="flex flex-col">
            {reviews.map((data, key) => {
              return (
                <div
                  key={key}
                  id={`review_${key}`}
                  className={`${
                    reviews.length - 1 === key ? "" : "border-b"
                  } p-5 flex flex-col`}
                >
                  <div className="flex flex-row align-center">
                    {data.profile_img !== "" ? (
                      <img
                        draggable="false"
                        className="rounded-full bg-primary-500 w-10 h-10 bg-no-repeat bg-cover"
                        src={data.profile_img}
                        alt={data.firstname}
                      />
                    ) : (
                      <div className="rounded-full defaulticon w-10 h-10 bg-no-repeat bg-cover"></div>
                    )}

                    <div className="flex-col mt-1 pl-3">
                      <div className="text-black">{data.firstname ?? "-"}</div>
                      <div className="text-xs" style={{ lineHeight: 0.5 }}>
                        {formatDateToMonthYear(data.date_created)}
                      </div>
                    </div>
                  </div>

                  <div className="text-sm mt-5">{data.review}</div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
