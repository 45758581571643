import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import ContentLoader from "react-content-loader";
import { useNavigate } from "react-router-dom";
import Close from "../../../assets/svg/Close";
import Check from "../../../assets/svg/Check";
import {
  addToEventList,
  cancelReservation,
  paymentReservation,
  reservation,
  updateReservation,
} from "../../../api/client/listing";
import { toast } from "react-hot-toast";
import Loader from "../../common/Loader";
import { Breadcrumbs } from "../../common/Breadcrumbs";
import { PrimaryButton } from "../../common/PrimaryButton";
import dayjs from "dayjs";
import { config } from "../../../utils/config";
import { PaymentSchedule } from "../Payment/PaymentSchedule";
import { CancelBooking } from "../../common/CancelBooking";
import { statusHandler, statusHandlerColor } from "../../../utils/common";
const Reservations = () => {
  const sRowPerPage = 50;
  const navigate = useNavigate();
  const [aListings, setListings] = useState([]);
  const [bLoading, setLoading] = useState(false);
  const [bLastPage, setLastPage] = useState(false);
  const [iPage, setPage] = useState(1);
  const [bookingCode, setBookingCode] = useState("");
  const [paymentCode, setPaymentCode] = useState("");

  const [isCancelLoading, setIsCancelLoading] = useState(false);
  const [showCancel, setShowCancel] = useState(false);
  const [selectedCancel, setSelectedCancel] = useState("");

  const [showPaymentDetails, setShowPaymentDetails] = useState(false);

  useEffect(() => {
    handleListings();
  }, []);

  const refreshListing = () => {
    setListings([]);
    setLoading(false);
    setLastPage(false);
    setPage(1);

    if (iPage === 1 && !bLoading) {
      handleListings();
    }
  };

  const handleListings = async () => {
    if (bLoading || bLastPage) {
      return;
    }

    setLoading(true);

    const _requestData = {
      page_no: iPage,
      row_per_page: sRowPerPage,
      flag: "init",
    };

    try {
      const response = await reservation(_requestData);
      if (response.error === 0) {
        if (
          response.data.pagination.current_page === 1 &&
          response.data.reservation_list.length === 0
        ) {
          setLastPage(true);
          setLoading(false);
          return;
        }

        setLoading(false);
        if (response.data.pagination.current_page === 1) {
          setListings(response.data.reservation_list);
          setPage(2);
        } else {
          const listings = aListings?.concat(response.data.reservation_list);
          setListings(listings);
          setPage(response.data.pagination.current_page + 1);
        }

        if (
          response.data.pagination.current_page ===
          response.data.pagination.total_page
        ) {
          setLastPage(true);
        }
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const handleCancelRequest = async (reason) => {
    setIsCancelLoading(true);
    const _requestData = {
      booking_code: selectedCancel,
      reason,
    };
    try {
      const response = await cancelReservation(_requestData);
      if (response.error === 0) {
        setIsCancelLoading(false);
        setShowCancel(false);
        setSelectedCancel("");
        toast(response.msg);
        setListings([]);
        setLoading(false);
        setLastPage(false);
        setPage(1);

        if (!bLastPage) {
          handleListings();
        }
      } else {
        // show error
        toast(response.error_msg);
        setIsCancelLoading(false);
      }
    } catch (error) {
      setIsCancelLoading(false);
      console.log("Error:", error);
    }
  };

  // const handleCategory = async (data) => {
  //   window.open(
  //     config.ANYAYA_URL +
  //       `/category/${data.service_type_code}/${data.service_name}/${data.service_code}`,
  //     "_blank"
  //   );
  // };

  const handlePay = async (data) => {
    if (data.booking_status === "1" && data.payment_status === "0") {
      navigate(`/client/inbox/${data.booking_code}`);
      // for reservation payment
      // go to Pay to Reserve Date
    } else if (data.booking_status === "2" && data.payment_status === "1") {
      // for payment
      // show payment details
      // setPaymentCode(data?.payment_code)
      setBookingCode(data.booking_code);
      setShowPaymentDetails(true);
    } else if (data.booking_status === "2" && data.payment_status === "2") {
      // for payment
      // show payment details
    }
  };

  return (
    <div className="px-5 align-center items-center ">
      <Breadcrumbs location="events/reservations" />
      <div className="text-2xl font-semibold text-black">Reservations</div>
      <div id="events" className="mt-5">
        <InfiniteScroll
          dataLength={aListings?.length || 0}
          next={() => handleListings()}
          hasMore={!bLastPage}
          loader={
            bLoading ? (
              <div className="mt-10">
                <Loader borderColor="border-primary-500" />
              </div>
            ) : null
          }
          style={{ overflow: bLoading ? "hidden" : "auto" }}
          scrollThreshold={0.5}
          className="gap-8"
        >
          {!bLoading ? (
            aListings?.length === 0 ? (
              bLastPage && (
                <div className="w-full h-full flex flex-col justify-center items-center">
                  <div className="text-lg font-semibold mt-10">
                    Nothing is in your Reservations
                  </div>
                  <div className="text-sm">
                    Your Reservation is currently empty
                  </div>
                  <div className="w-60 mt-5">
                    <PrimaryButton
                      title={"Explore Listings"}
                      onClick={() => {
                        navigate("/category");
                      }}
                    />
                  </div>
                </div>
              )
            ) : (
              <table className="text-gray-500 table-auto w-full">
                <thead>
                  <tr className="whitespace-nowrap  border-y text-sm">
                    <th className="font-semibold px-4 py-2 text-start">Code</th>
                    <th className="font-semibold px-4 py-2 text-start">
                      Merchant
                    </th>
                    <th className="font-semibold px-4 py-2 text-center">
                      Listing
                    </th>
                    <th className="font-semibold px-4 py-2 text-center">
                      Date
                    </th>
                    <th className="font-semibold px-4 py-2 text-center">
                      Total
                    </th>
                    {/* <th className="font-semibold px-4 py-2 text-center">
                      To be Paid
                    </th> */}
                    {/* <th className="font-semibold px-4 py-2 text-start">
                      Status
                    </th> */}
                  </tr>
                </thead>
                <tbody>
                  {aListings?.map((data, i) => (
                    <tr key={i} className="whitespace-nowrap border-y text-sm">
                      <td className="font-normal cursor-pointer select-all text-center px-4 py-2">
                        {data.booking_code}
                      </td>
                      <td className="font-normal text-start px-4 py-2">
                        {data.merchant_name}
                      </td>
                      <td
                        // onClick={() => {
                        //   handleCategory(data);
                        // }}
                        className="font-normal text-center px-4 py-2"
                      >
                        {data.title}
                      </td>
                      <td className="font-normal text-center px-4 py-2">
                        {dayjs(data.start_date).format("MMM D, YYYY h:mm a")}
                      </td>
                      <td className="font-normal text-center px-4 py-2">
                        PHP {data.total_amount}
                      </td>
                      {/* <td className="font-normal text-center px-4 py-2">
                        PHP {data.total_amount}
                      </td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
            )
          ) : null}
        </InfiniteScroll>

        {showPaymentDetails && (
          <PaymentSchedule
            bookingCode={bookingCode}
            refreshListing={() => refreshListing()}
            onClose={() => setShowPaymentDetails(false)}
          />
        )}

        {showCancel && (
          <CancelBooking
            onClose={() => {
              setShowCancel(false);
              setSelectedCancel("");
            }}
            handleCancel={(reason) => handleCancelRequest(reason)}
            isLoading={isCancelLoading}
          />
        )}
      </div>
    </div>
  );
};
export default Reservations;
