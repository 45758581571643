import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Close from "../../assets/svg/Close";
import { PrimaryButton } from "./PrimaryButton";

export const HowDoesItWorks = ({ handleClose, isMerchant }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 text-gray-500">
      <div
        className="fixed inset-0 bg-black opacity-50"
        onClick={() => handleClose()}
      ></div>
      <div className="bg-white rounded-3xl relative py-6 mx-4 w-96">
        <div className="flex justify-between flex-row px-6 border-b pb-4">
          <div className="text-primary-600 text-lg font-semibold">
            How does it work?
          </div>

          <button
            className="text-gray-400 hover:text-gray-600"
            onClick={() => {
              handleClose();
            }}
          >
            <Close className="w-4 h-4" />
          </button>
        </div>

        {isMerchant ? (
          <div
            id="how-to"
            className="gap-y-2 flex flex-col mt-5 px-6 mb-2 text-center"
          >
            <div className="flex flex-col">
              <div className="text-base font-semibold text-sunrise-500">
                Step 1
              </div>
              <div className="text-sm text-primary-500">
                Verify Business Account.
              </div>
            </div>
            <div className="flex flex-col">
              <div className="text-base font-semibold text-sunrise-500">
                Step 2
              </div>
              <div className="text-sm text-primary-500">
                Create Event Listing.
              </div>
            </div>
            <div className="flex flex-col">
              <div className="text-base font-semibold text-sunrise-500">
                Step 3
              </div>
              <div className="text-sm text-primary-500">
                Provide payout information.
              </div>
            </div>

            <div className="flex flex-col">
              <div className="text-base font-semibold text-sunrise-500">
                Step 4
              </div>
              <div className="text-sm text-primary-500">
                Chat with customers.
              </div>
            </div>

            <div className="flex flex-col">
              <div className="text-base font-semibold text-sunrise-500">
                Step 5
              </div>
              <div className="text-sm text-primary-500">
                Get payment directly from customers.
              </div>
            </div>

            <div className="flex flex-col">
              <div className="text-base font-semibold text-sunrise-500">
                Step 6
              </div>
              <div className="text-sm text-primary-500">
                Deliver service to your customer.
              </div>
            </div>
          </div>
        ) : (
          <div
            id="how-to"
            className="gap-y-2 flex flex-col mt-5 px-6 mb-2 text-center"
          >
            <div className="flex flex-col">
              <div className="text-base font-semibold text-sunrise-500">
                Step 1
              </div>
              <div className="text-sm text-primary-500">
                Choose an event listing.
              </div>
            </div>
            <div className="flex flex-col">
              <div className="text-base font-semibold text-sunrise-500">
                Step 2
              </div>
              <div className="text-sm text-primary-500">
                Chat with the merchant.
              </div>
            </div>
            <div className="flex flex-col">
              <div className="text-base font-semibold text-sunrise-500">
                Step 3
              </div>
              <div className="text-sm text-primary-500">
                Pay for your reservation directly to merchant.
              </div>
            </div>

            <div className="flex flex-col">
              <div className="text-base font-semibold text-sunrise-500">
                Step 4
              </div>
              <div className="text-sm text-primary-500">Celebrate!</div>
            </div>
          </div>
        )}
        {/* <div className="px-6">
          <PrimaryButton
            title={"Yes"}
            isLoading={false}
            onClick={() => {
              handleClose();
            }}
          />
        </div> */}
      </div>
    </div>
  );
};
