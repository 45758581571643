import React, { useEffect, useRef, useState } from "react";
import { Breadcrumbs } from "../../../common/Breadcrumbs";
import { useDispatch, useSelector } from "react-redux";
import { accountSettings } from "../../../../api/client";
import { updateAccountSettings } from "../../../../store/slicers/authSlice";
const PrivacyAndSharing = () => {
  const [search, setSearch] = useState(false);
  const [seen, setSeen] = useState(false);
  const accountSet = useSelector((state) => state.auth.account_settings);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getAccountSettings();
  }, []);

  const getAccountSettings = async () => {
    try {
      const _requestData = {
        flag: "init",
      };
      const response = await accountSettings(_requestData);
      if (response.error === 0) {
        dispatch(updateAccountSettings(response.data.account_settings));
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const updateSettings = async (key, value) => {
    try {
      const _requestData = {
        flag: "save",
        setting_key: key,
        setting_value: value,
      };
      const response = await accountSettings(_requestData);
      if (response.error === 0) {
        const updatedSettings = { ...accountSet, [key]: value };
        dispatch(updateAccountSettings(updatedSettings));
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <div className="px-10 mt-5 align-center items-center">
      <Breadcrumbs location="account/privacy-&-sharing" />
      <div className="text-2xl font-semibold text-black">
        Privacy And Sharing
      </div>

      <div>
        <div className="mt-10">
          {/* <div>
            <div className="text-base font-semibold text-black">
              Decide how your profile and activity are shown to others
            </div>
            <div className="text-sm text-gray-400">
              Include my profile & listing in search engines
            </div>

            <div className="w-20 mt-1 text-xs flex flex-row justify-between">
            <div
                  onClick={
                    accountSet?.read_receipts === "0"
                      ? () => updateSettings("read_receipts", "1")
                      : () => {}
                  }
                  className={`${
                    accountSet?.read_receipts === "1"
                      ? "cursor-pointer text-black font-semibold"
                      : "cursor-pointer text-gray-400 "
                  }`}
                >
                  ON
                </div>
                <div
                  onClick={
                    accountSet?.read_receipts === "1"
                      ? () => updateSettings("read_receipts", "0")
                      : () => {}
                  }
                  className={`${
                    accountSet?.read_receipts === "0"
                      ? "cursor-pointer text-black font-semibold"
                      : "cursor-pointer text-gray-400 "
                  }`}
                >
                  OFF
                </div>
            </div>
          </div> */}
          <div>
            <div className="text-base font-semibold text-black">
              Read Receipts
            </div>
            <div className="text-sm text-gray-400">
              Show people that you've read their messages
            </div>

            <div className="w-20 mt-1 text-xs flex flex-row justify-between">
              <div className="w-20 mt-1 text-xs flex flex-row justify-between">
                <div
                  onClick={
                    accountSet?.read_receipts === "0"
                      ? () => updateSettings("read_receipts", "1")
                      : () => {}
                  }
                  className={`${
                    accountSet?.read_receipts === "1"
                      ? "cursor-pointer text-black font-semibold"
                      : "cursor-pointer text-gray-400 "
                  }`}
                >
                  ON
                </div>
                <div
                  onClick={
                    accountSet?.read_receipts === "1"
                      ? () => updateSettings("read_receipts", "0")
                      : () => {}
                  }
                  className={`${
                    accountSet?.read_receipts === "0"
                      ? "cursor-pointer text-black font-semibold"
                      : "cursor-pointer text-gray-400 "
                  }`}
                >
                  OFF
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyAndSharing;
