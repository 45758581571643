import React from "react";
import { useNavigate } from "react-router-dom";
import { capitalizeFirstLetter } from "../../utils/common";

export const Breadcrumbs = ({ location, listing = false }) => {
  const navigate = useNavigate();
  const segments = location.split("/").filter((segment) => segment !== "");

  const handleBreadcrumbClick = (index) => {
    const path = `/${segments.slice(0, index + 1).join("/")}`;
    if (listing) {
      window.close()
    } else {
      if (path === "/account/payments") {
        navigate("/account/payments");
      } else if (path === "/account/login-&-security") {
        navigate("/account/login-and-security");
      } else {
        navigate(path);
      }
    }
  };

  return (
    <nav className="flex py-3 text-gray-700 text-xs" aria-label="Breadcrumb">
      <ol className="inline-flex items-center">
        {segments.map((segment, index) => (
          <li key={index} aria-current="page">
            <div className="flex items-center">
              <span
                className={`${index !== 0 && "ml-1 md:ml-2 "} mr-2 text-sm  ${
                  index === segments.length - 1
                    ? "text-black font-semibold"
                    : "text-gray-400 hover:underline"
                } cursor-pointer`}
                onClick={() => {
                  if (index !== segments.length - 1) {
                    handleBreadcrumbClick(index);
                  }
                }}
              >
                {capitalizeFirstLetter(segment.replace(/-/g, " "))}
              </span>
              <svg
                className={`${
                  index === segments.length - 1 && "hidden"
                } w-3 h-3 mx-1 text-gray-400`}
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 6 10"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 9 4-4-4-4"
                />
              </svg>
            </div>
          </li>
        ))}
      </ol>
    </nav>
  );
};
