import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import ContentLoader from "react-content-loader";
import { wishlist } from "../../../api/client/listing";
import { convertToKebabCase } from "../../../utils/common";
import Loader from "../../common/Loader";
import { PrimaryButton } from "../../common/PrimaryButton";

const Wishlists = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [wishlists, setWishlists] = useState([]);
  const navigate = useNavigate();
  const handleWishlists = async () => {
    setIsLoading(true);
    try {
      const _requestData = {
        act: "wishlist",
      };
      const response = await wishlist(_requestData);
      if (response.error === 0) {
        setWishlists(response.data.wishlist);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    handleWishlists();
  }, []);

  const wishlistClick = (data) => {
    navigate(
      `/category/${data.service_code}/${convertToKebabCase(
        data.service_name
      )}/${data.service_code}`
    );
  };

  const contentLoad = () => {
    return (
      <ContentLoader
        speed={1}
        height="12rem"
        viewBox="0 0 195 205"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
      >
        <rect rx="20" ry="20" width="100%" height="100%" />
      </ContentLoader>
    );
  };

  return (
    <div className="px-5 align-center items-center ">
      <div className="text-2xl font-semibold text-black">Wishlists</div>
      {isLoading && (
        <div className="w-full h-full mt-10 flex flex-col justify-center items-center">
          <Loader borderColor="border-primary-500" size="h-10 w-10" />
        </div>
      )}

      <div className="mt-5 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-x-10 gap-y-10">
        {!isLoading && wishlists !== undefined
          ? wishlists.length !== 0 &&
            wishlists.map((data, key) => {
              return (
                <div
                  className="pt-4"
                  key={key}
                  onClick={() => {
                    wishlistClick(data);
                  }}
                >
                  <div className="mb-2">{data.title}</div>
                  <div className="mx-auto flex flex-row justify-center">
                    {data.service_img && data.service_img.length !== 0 ? (
                      <div className="relative w-1/2 cursor-pointer mr-1 group">
                        <img
                          alt="gallery"
                          draggable="false"
                          style={{ height: "228px" }}
                          className="rounded-tl-xl rounded-bl-xl block w-full object-cover object-center"
                          src={data.service_img[0]}
                        />
                      </div>
                    ) : (
                      contentLoad()
                    )}
                    <div className="w-1/2">
                      {data.service_img && data.service_img.length !== 0
                        ? data.service_img.slice(1, 3).map((image, index) => (
                            <div key={index} className="cursor-pointer group">
                              <div className="w-full">
                                <img
                                  alt="gallery"
                                  draggable="false"
                                  className={`block h-28 w-full object-cover object-center ${
                                    index === 0
                                      ? "rounded-tr-xl mb-1"
                                      : "rounded-br-xl"
                                  } `}
                                  src={image}
                                />
                              </div>
                            </div>
                          ))
                        : null}
                    </div>
                  </div>
                  <div className="text-center mt-2 font-semibold">
                    {data.list_name}
                  </div>
                </div>
              );
            })
          : null}
      </div>

      {!isLoading && wishlists !== undefined && wishlists.length === 0 && (
        <div className="w-full h-full flex flex-col justify-center items-center">
          {/* <div className="text-lg font-semibold">Icon Here</div> */}
          <div className="text-lg font-semibold mt-10">
            Nothing is in your Wishlist
          </div>
          <div className="text-sm">Your Wishlist is currently empty</div>
          <div className="w-60 mt-5">
            <PrimaryButton
              title={"Explore Listings"}
              onClick={() => {
                navigate("/category");
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Wishlists;
