import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  services: [],
  searchNameInHome: "",
  listings: [],
  listingPage: "1",
  listingLoading: false,
  listingLastPage: false,
  filterModalSub: "",
  filterModalDate: "",
  filterModalLocation: "",
  filterModalMerchant: "",
  filterModalIsland: "",
  filterModalType: "",
  isSearchModalOpen: false,
  serviceCalendar: [],
  serviceOffered: {},
  servicePricing: {},
  servicePhotos: [],
  serviceReviews: [],
  eventHighlights: [],
  selectedDate: {
    startDate: "",
    endDate: "",
  },
  wishlistModal: false,
  selectedListingId: "",
  requestToBookModal: false,
  selectedListing: null,
  isSoon: false,
  isSoonMerchant: true,
  isPaid: false,
  isSoonLanding: false
};

const listingSlice = createSlice({
  name: "listing",
  initialState,
  reducers: {
    updateServices(state, action) {
      state.services = action.payload;
    },
    updateSearchNameInHome(state, action) {
      state.searchNameInHome = action.payload;
    },
    updateListings(state, action) {
      state.listings = action.payload;
    },
    updateListingPage(state, action) {
      state.listingPage = action.payload;
    },
    updateListingLoading(state, action) {
      state.listingLoading = action.payload;
    },
    updateListingLastPage(state, action) {
      state.listingLastPage = action.payload;
    },
    updateFilterModalSub(state, action) {
      state.filterModalSub = action.payload;
    },
    updateFilterModalDate(state, action) {
      state.filterModalDate = action.payload;
    },
    updateFilterModalLocation(state, action) {
      state.filterModalLocation = action.payload;
    },
    updateFilterModalMerchant(state, action) {
      state.filterModalMerchant = action.payload;
    },
    updateFilterModalIsland(state, action) {
      state.filterModalIsland = action.payload;
    },
    updateSearchModal(state, action) {
      state.isSearchModalOpen = action.payload.status;
      state.filterModalType = action.payload.type;
    },
    updateWishlistModal(state, action) {
      state.wishlistModal = action.payload.status;
      state.selectedListingId = action.payload.id;
    },
    updateSoonModal(state, action) {
      state.isSoon = action.payload;
    },
    updateSoonMerchantModal(state, action) {
      state.isSoonMerchant = action.payload;
    },
    updateRequestToBookModal(state, action) {
      state.requestToBookModal = action.payload;
    },
    updateMultipleFields(state, action) {
      Object.assign(state, action.payload);
    },
    updateSelectedListing(state, action) {
      state.selectedListing = action.payload;
    },
    updateIsSoonLanding(state, action) {
      state.isSoonLanding = action.payload;
    },
    updateIsPaid(state, action) {
      state.isPaid = action.payload;
    },
  },
});

export const {
  updateServices,
  updateSearchNameInHome,
  updateListings,
  updateListingPage,
  updateListingLoading,
  updateListingLastPage,
  updateFilterModalSub,
  updateFilterModalDate,
  updateFilterModalLocation,
  updateFilterModalMerchant,
  updateFilterModalIsland,
  updateSearchModal,
  updateMultipleFields,
  updateWishlistModal,
  updateRequestToBookModal,
  updateSelectedListing,
  updateSoonModal,
  updateSoonMerchantModal,
  updateIsPaid,
  updateIsSoonLanding
} = listingSlice.actions;

export default listingSlice.reducer;
