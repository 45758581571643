import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { accountSettings, notificationList } from "../../../api/client";
import { updateAccountSettings } from "../../../store/slicers/authSlice";

const Notification = () => {
  const dispatch = useDispatch();
  const [notifications, setNotifications] = useState([]);
  const [activeTab, setTab] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const accountSet = useSelector((state) => state.auth.account_settings);
  const phone = useSelector((state) => state.auth.phone);
  const navigate = useNavigate();

  const getNotification = async () => {
    try {
      const response = await notificationList();
      if (response.error === 0) {
        setNotifications(response?.data?.notifications);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleClick = async (data) => {
    if (data?.read === "0") {
      const _requestData = { flag: "set_read", notif_no: data.notif_no };
      try {
        const response = await notificationList(_requestData);
        if (response.error === 0) {
          const notificationIndex = notifications.findIndex(
            (notification) => notification.notif_no === data.notif_no
          );
          if (notificationIndex !== -1) {
            notifications[notificationIndex].read = "1";
            setNotifications(notifications);
          }
        }

        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    }

    //   open new page where it belongs
    if (data?.location === "booking") {
      if (data?.is_merchant === "1") {
        navigate("/merchant/inbox/" + data?.id);
      } else {
        navigate("/client/inbox/" + data?.id);
      }
    } else if (data?.location === "account_verification") {
      navigate("/account/personal");
    } else if (data?.location === "service_listing") {
      navigate("/category/" + data?.id);
    } else if (data?.location === "merchant_verification") {
      navigate("/merchant/menu");
    }
  };

  useEffect(() => {
    getNotification();
  }, []);

  return (
    <div className="px-10  mt-5  align-center items-center">
      <div className="text-2xl font-semibold text-black">Notifications</div>

      <div className="mt-10">
        <ul className="flex flex-col text-sm  gap-y-3">
          {notifications.map((notification, index) => {
            return (
              <li className="mr-2" key={index}>
                <div
                  onClick={() => {
                    handleClick(notification);
                  }}
                  className={`${
                    notification.read === "0" && "font-semibold"
                  } inline-flex items-center justify-center cursor-pointer`}
                >
                  {notification?.profile_img !== "" &&
                  notification?.profile_img !== undefined ? (
                    notification?.profile_img === "anyaya" ? (
                      <div className="hidden lg:flex items-center justify-center w-12 lg:w-10 h-12 lg:h-10 mr-3">
                        <div className="icon-anyaya h-full w-full" />
                      </div>
                    ) : (
                      <img
                        draggable="false"
                        className="hidden lg:flex mr-3 rounded-full bg-primary-500 w-12 lg:w-10 h-12 lg:h-10 bg-no-repeat bg-cover"
                        src={notification?.profile_img}
                        alt={"Profile_Image" + notification?.profile_img}
                      />
                    )
                  ) : (
                    <div className="mr-3 rounded-full defaulticon w-12 lg:w-10 h-12 lg:h-10 bg-no-repeat bg-cover"></div>
                  )}
                  <div className="flex flex-col">
                    <p> {notification.notif_msg}</p>
                    <p className="text-gray-400 text-xs">
                      {" "}
                      {notification.date_created}
                    </p>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default Notification;
