import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateAccountInfo } from "../../../store/slicers/authSlice";
import { ModalHeader } from "../ModalHeader";
import Loader from "../Loader";
import { updateProfile } from "../../../api/client";
import Error from "../../../assets/svg/Error";

export const UpdatePicture = ({ setProfilePicture }) => {
  const profileImage = useSelector(
    (state) => state.auth.account_info.profile_img
  );
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const [error, setError] = useState("");

  const [selectedFile, setSelectedFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(profileImage);

  const handleFileChange = (e) => {
    const allowedFileTypes = ["image/jpeg", "image/jpg", "image/png"];

    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      if (allowedFileTypes.includes(file.type)) {
        setSelectedFile(file);

        // Display the selected image preview
        const reader = new FileReader();
        reader.onload = (e) => {
          if (e.target && typeof e.target.result === "string") {
            setImagePreview(e.target.result);
          }
        };
        reader.readAsDataURL(file);
      }
    }
  };

  const handleUpload = async () => {
    if (selectedFile) {
      setIsLoading(true);
      try {
        const _requestData = {
          act: "update_profile",
          flag: "change_photo",
          profile_img: selectedFile,
        };
        const response = await updateProfile(_requestData);
        if (response.error === 0) {
          const accountInfo = response.data.account_info;
          dispatch(updateAccountInfo(accountInfo));
          setIsLoading(false);
          handleClose();
        } else {
          setError(response.error_msg);
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
        setError("Failed. Please try again.");
      }
    }
  };

  const handleClose = () => {
    setProfilePicture();
  };
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 text-gray-500">
      <div className="fixed inset-0 bg-black opacity-50"></div>
      <div className="bg-white rounded-3xl relative py-6 mx-4 w-500">
        <ModalHeader
          title={"Update Profile Photo"}
          onClick={() => {
            handleClose();
          }}
          type={1}
        />

        <div id="profile_picture" className="px-6 w-full">
          <div className="pb-6 text-center-webkit align-center flex flex-col">
            {selectedFile ? (
              <>
                <div className="text-xs">You look great!</div>
                <div className="text-xs mt-2">
                  This photo will be added to your profile it will also be seen
                  by merchants and other users, so make sure it doesn't contain
                  any sensitive info.
                </div>
              </>
            ) : (
              <>
                <div className="text-xs">Update a profile photo</div>
                <div className="text-xs mt-2">
                  Choose an image that shows your face
                </div>
              </>
            )}

            <div className="w-full">
              {imagePreview ? (
                <img
                  draggable="false"
                  src={imagePreview}
                  alt="Selected"
                  className="mt-6 rounded-full w-28 h-28"
                />
              ) : (
                <div className="mt-6 rounded-full w-28 h-28 defaulticon bg-no-repeat bg-cover"></div>
              )}
            </div>
          </div>
          {error && (
            <span className="text-xs text-red-500 flex flex-row item-center mb-5">
              <Error className="w-3 mr-1 self-center" />
              {error}
            </span>
          )}
          {selectedFile && (
            <div className="mb-2">
              <button
                className="bg-primary-500 px-10 w-full text-white font-semibold py-4 rounded-xl hover:opacity-90"
                onClick={
                  isLoading
                    ? () => {}
                    : () => {
                        handleUpload();
                      }
                }
              >
                {isLoading ? <Loader /> : <p className="text-sm">Done</p>}
              </button>
            </div>
          )}
          <label
            className={`${
              selectedFile
                ? "bg-white text-primary-500 border border-primary-500"
                : "font-semibold bg-primary-500 text-white hover:opacity-90"
            } px-10 flex justify-center w-full text-sm py-4 rounded-xl cursor-pointer`}
          >
            <input type="file" className="hidden" onChange={handleFileChange} />
            {selectedFile ? "Change Photo" : "Upload"}
          </label>
        </div>
      </div>
    </div>
  );
};
