import React from "react";
import { updateLanguage, updateWelcome } from "../../store/slicers/authSlice";
import { useDispatch, useSelector } from "react-redux";
const SuccessModal = () => {
  const dispatch = useDispatch();

  const openLanguage = () => {
    dispatch(updateWelcome(false));
    dispatch(updateLanguage(true));
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 text-gray-500">
      <div className=" rounded-3xl relative py-6 mx-4 w-500">
        <div className="modal-content">
          <h2 className="text-base text-primary-600 lg:text-2xl font-bold mb-4">
            Welcome to Team Anyaya!
          </h2>
          <p className="mb-4 text-sm lg:text-base">
            We’re so pleased that you’ve decided to join our growing family of
            event merchants!
          </p>
          <p className="mb-4 text-sm lg:text-base">
            Your application is now subject for verification. Please give us 24
            hours to confirm the validity of your documents.
          </p>
          <button
            onClick={() => {
              openLanguage();
            }}
            className="text-sm mt-6 p-4 bg-primary-500 text-white rounded-full hover:bg-primary-600 focus:outline-none"
          >
            Set-up your merchant account
          </button>
        </div>
      </div>
    </div>
  );
};

export default SuccessModal;
