import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import Close from "../../../assets/svg/Close";
import Loader from "../../common/Loader";
import Error from "../../../assets/svg/Error";
import { paymentReservation } from "../../../api/client/listing";
import dayjs from "dayjs";
import { config } from "../../../utils/config";
import DatePicker from "react-multi-date-picker";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
export const PaymentSchedule = ({
  onClose,
  bookingCode,
  refreshListing,
  location,
  paymentCode,
  expressPayment = "0",
  setSuccess,
  setErrorx,
}) => {
  const [error, setError] = useState("");
  const [selectedOption, setSelectedOption] = useState(
    location === "inbox" ? paymentCode : ""
  );
  const navigate = useNavigate();
  const [sender, setSender] = useState("");
  const [invoceNo, setInvoiceNo] = useState("");
  const [reference, setReference] = useState("");
  const [dop, setDop] = useState("");
  const [bname, setBName] = useState("");
  const [mop, setMop] = useState("");
  const [data, setData] = useState();

  const [merchantBank, setMerchantBank] = useState({});

  const [step, setStep] = useState(location === "inbox" ? 2 : 1);
  const [isLoading, setIsLoading] = useState(false);
  const [s3Loading, setS3Loading] = useState(false);

  const [initLoading, setInitLoading] = useState(true);

  useEffect(() => {
    handleInit();
  }, []);

  const handleInit = async () => {
    const _requestData = {
      booking_code: bookingCode,
      flag: "init",
    };
    try {
      const response = await paymentReservation(_requestData);
      if (response.error === 0) {
        setData(response.data);
        setInitLoading(false);
      } else {
        setInitLoading(false);
        handleOnClose();
      }
    } catch (error) {
      console.log(error);
      handleOnClose();
      setInitLoading(false);
    }
  };

  const handleOptionChange = (key) => {
    setSelectedOption(key);
  };
  const handleOnClose = () => {
    setSelectedOption("");
    setStep(1);
    setSender("");
    setReference("");
    setInvoiceNo("");
    setDop("");
    setBName("");
    setMop("");
    setError("");
    setS3Loading(false);
    onClose();
  };
  const handleNext = () => {
    setStep(2);
  };

  const handleMerchantBank = async () => {
    setS3Loading(true);
    try {
      const _requestData = {
        flag: "merchant_bank",
        booking_code: data?.reservation.booking_code,
      };

      const response = await paymentReservation(_requestData);
      if (response.error === 0) {
        setStep(3);
        setMerchantBank(response.data.merchant_bank);
        setS3Loading(false);
      } else {
        setStep(step - 1);
        setS3Loading(false);
      }
    } catch (error) {
      setStep(step - 1);
      setS3Loading(false);
    }
  };
  const handleSubmit = async () => {
    setError("");
    setIsLoading(true);
    try {
      const _requestData = {
        flag: "manual_payment",
        payment_code: selectedOption,
        sender_name: sender,
        // invoce_no: invoceNo,
        payment_mode: mop,
        bank_name: bname,
        payment_date: dop,
        reference_no: reference,
      };

      const response = await paymentReservation(_requestData);
      if (response.error === 0) {
        setIsLoading(false);
        setSuccess(response.msg);
        refreshListing();
        handleOnClose();
        navigate(`/client/inbox/${response.data?.booking_code}`);
      } else {
        setError(response.error_msg);
        setIsLoading(false);
      }
    } catch (error) {
      handleOnClose();
      setIsLoading(false);
    }
  };
  const amount =
    parseFloat(data?.reservation.total_amount.replace(/,/g, "")) / 2;
  const formattedAmount = amount.toLocaleString("en-US", {
    style: "currency",
    currency: "PHP",
    minimumFractionDigits: 2,
  });

  const handlePayment = async () => {
    setIsLoading(true);
    const _requestData = {
      payment_code: paymentCode || selectedOption,
      flag: "express_payment",
    };
    try {
      const response = await paymentReservation(_requestData);
      if (response.error === 0) {
        setIsLoading(false);
        window.location.href = response.checkout.redirectUrl;
      } else {
        toast(response.error_msg);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      toast(error);
    }
  };
  const isPaymentSubjectForVerification =
    (data?.reservation?.booking_status_code === "1" &&
      data?.payment_schedule[0]?.status === "1") ||
    (data?.reservation?.booking_status_code === "2" &&
      data?.payment_schedule[1]?.status === "1") ||
    (data?.reservation?.booking_status_code === "2" &&
      data?.payment_schedule[2]?.status === "1");
  const isPaymentDeclined =
    (data?.reservation?.booking_status_code === "1" &&
      data?.payment_schedule[0]?.status === "3") ||
    (data?.reservation?.booking_status_code === "2" &&
      data?.payment_schedule[1]?.status === "3") ||
    (data?.reservation?.booking_status_code === "2" &&
      data?.payment_schedule[2]?.status === "3");

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 text-gray-500">
      <div className="fixed inset-0 bg-black opacity-50"></div>
      <div className="bg-white p-6 mx-4 rounded-3xl relative w-600">
        <button
          className="absolute top-6 left-6 text-gray-400 hover:text-gray-600"
          onClick={
            step !== 1 && step !== 2
              ? () => {
                  setStep(step - 1);
                  setError("");
                }
              : handleOnClose
          }
        >
          <Close className="w-4 h-4" />
        </button>

        {initLoading ? (
          <div className="h-620">
            <Loader borderColor="border-primary-500" />
          </div>
        ) : (
          <>
            {step === 1 && (
              <div id="payment_1">
                <div className="mt-10 mb-5">
                  <div className="text-lg font-semibold">Payment Details</div>
                </div>
                <div className="flex flex-col gap-2">
                  {isPaymentDeclined && (
                    <div className="mt-4">
                      <p className="text-sm text-red-500">
                        We're sorry, but your payment was declined. Please
                        double-check your payment information and resubmit the
                        payment to complete your transaction. If the issue
                        persists, contact customer support for further
                        assistance.
                      </p>
                    </div>
                  )}
                  {isPaymentSubjectForVerification && (
                    <div className="mt-4">
                      <p className="text-sm text-gray-500">
                        Your payment is now subject for verification. Please
                        wait for confirmation
                      </p>
                    </div>
                  )}
                  {!isPaymentSubjectForVerification &&
                    data?.payment_schedule.map((data, key) => {
                      return (
                        <>
                          {["1"].includes(data?.reservation?.booking_status) &&
                            data?.is_reservation === "1" && (
                              <div
                                key={key}
                                onClick={() => {
                                  handleOptionChange(data.payment_code);
                                }}
                                className="cursor-pointer border border-gray-400 p-3 rounded-md mt-4"
                              >
                                <p className="text-sm text-gray-500">
                                  Pay to reserve date{" "}
                                </p>
                                {isLoading ? (
                                  <Loader borderColor="border-primary-500" />
                                ) : (
                                  <p className="text-gray-400 text-xs">
                                    Pay the reservation fee (PHP{" "}
                                    {data?.reservation?.reservation_fee})
                                    {data?.reservation?.service_fee &&
                                      ` and Service Fee (PHP ${data?.reservation?.service_fee})`}
                                  </p>
                                )}
                              </div>
                            )}
                          {data?.reservation?.booking_status === "2" &&
                            data?.reservation?.payment_status_code === "1" &&
                            data?.is_reservation === "2" && (
                              <div
                                key={key}
                                onClick={() => {
                                  handleOptionChange(data.payment_code);
                                }}
                                className="cursor-pointer border border-gray-400 p-3 rounded-md mt-4"
                              >
                                <p className="text-sm text-gray-500">
                                  Pay in full
                                </p>
                                <p className="text-gray-400 text-xs">
                                  (PHP {data?.amount_paid}) before{" "}
                                  {dayjs(data?.payment_date).format(
                                    "MM/DD/YYYY"
                                  )}
                                </p>
                              </div>
                            )}

                          {["1"].includes(data?.reservation?.booking_status) &&
                            data?.is_reservation === "3" && (
                              <div
                                key={key}
                                onClick={() => {
                                  handleOptionChange(data.payment_code);
                                }}
                                className="cursor-pointer border border-gray-400 p-3 rounded-md mt-4"
                              >
                                <p className="text-sm text-gray-500">
                                  Pay in full (PHP {data?.amount_paid})
                                </p>
                                {isLoading ? (
                                  <Loader borderColor="border-primary-500" />
                                ) : (
                                  <p className="text-gray-400 text-xs">
                                    Pay the reservation fee
                                    {data?.reservation?.service_fee &&
                                      `, Service Fee (PHP ${data?.reservation?.service_fee})`}
                                    {data?.reservation?.service_fee &&
                                      ` and Contract Price`}
                                  </p>
                                )}
                              </div>
                            )}
                        </>
                      );
                    })}

                  {/* {data?.payment_schedule.slice(0, 1).map((data, key) => {
                    return (
                      <div
                        key={key}
                        className="flex flex-row justify-between border rounded-lg p-2 cursor-pointer"
                        onClick={() => handleOptionChange(data.payment_code)}
                      >
                        <div className="flex flex-col justify-center">
                          <span className="font-semibold">Pay 50%</span>
                          <span className="text-sm">
                            PHP {data["amount_paid"]} before{" "}
                            {dayjs(data["payment_date"]).format("MMM DD, YYYY")}
                          </span>
                        </div>
                        <label
                          htmlFor={data.payment_code}
                          className="self-center cursor-pointer"
                        >
                          <span
                            className={`${
                              selectedOption === data.payment_code
                                ? "opacity-100"
                                : "opacity-20"
                            } h-5 w-5 bg-primary-500  rounded-full inline-block`}
                          ></span>
                        </label>
                      </div>
                    );
                  })} */}

                  {/* <div
                    className="flex flex-row justify-between border rounded-lg p-2 cursor-pointer"
                    onClick={() =>
                      handleOptionChange(
                        data?.payment_schedule[1]?.payment_code
                      )
                    }
                  >
                    <div className="flex flex-col justify-center">
                      <span className="font-semibold">Full Payment</span>
                      <span className="text-sm">
                        Pay all before{" "}
                        {dayjs(data?.payment_schedule[1]?.payment_date).format(
                          "MMM DD, YYYY"
                        )}{" "}
                        or Pay Now
                      </span>
                    </div>
                    <label
                      htmlFor="pay-full"
                      className="self-center cursor-pointer"
                    >
                      <span
                        className={`${
                          selectedOption ===
                          data?.payment_schedule[1]?.payment_code
                            ? "opacity-100"
                            : "opacity-20"
                        } h-5 w-5 bg-primary-500  rounded-full inline-block`}
                      ></span>
                    </label>
                  </div> */}
                </div>
              </div>
            )}

            {step === 2 && (
              <div id="payment_2" className="flex flex-col">
                <div className="mt-10 ">
                  <div className="text-lg font-semibold">Payment Details</div>
                </div>
                <div className="py-2 text-sm">
                  <div>
                    <span className="font-semibold">
                      {data?.reservation.title}
                    </span>{" "}
                    by{" "}
                    <span className="font-semibold">
                      {data?.reservation.firstname}
                    </span>{" "}
                    of{" "}
                    <span className="font-semibold">
                      {data?.reservation.merchant_name}
                    </span>
                    ({data?.reservation.service_name})
                  </div>
                  <div className="py-2">
                    <div className="flex flex-row justify-between">
                      <span id="event-date">Event Date</span>
                      <span id="event-date-value" className="text-gray-400">
                        {dayjs(data?.reservation.start_date).format(
                          "MMM DD, YYYY h:MM A"
                        )}{" "}
                        {data?.reservation.start_date !==
                          data?.reservation.end_date &&
                          " - " +
                            dayjs(data?.reservation.end_date).format(
                              "MMM DD, YYYY h:MM A"
                            )}
                      </span>
                    </div>
                    <div className="flex flex-row justify-between">
                      <span id="event-location">Event Location</span>
                      <span id="event-location-value" className="text-gray-400">
                        {data?.reservation.location}
                      </span>
                    </div>
                    <div className="flex flex-row justify-between">
                      <span id="event-price">Total Amount</span>
                      <span id="event-price-value" className="text-gray-400">
                        ₱{data?.reservation.total_amount}
                      </span>
                    </div>
                    {data?.reservation.remaining_balance !==
                      data?.reservation.total_amount && (
                      <div className="flex flex-row justify-between">
                        <span id="event-balance">Remaining Balance</span>
                        <span
                          id="event-balance-value"
                          className="text-gray-400"
                        >
                          ₱{data?.reservation.remaining_balance}
                        </span>
                      </div>
                    )}
                    {data?.payment_schedule.map((sched, key) => {
                      return sched.payment_code === selectedOption ? (
                        <div className="flex flex-row justify-between">
                          <span id="event-atbp">Amount to be paid</span>
                          <span id="event-atbp-value" className="text-gray-400">
                            ₱{sched.amount_paid}
                          </span>
                        </div>
                      ) : null;
                    })}

                    <div className="flex flex-row justify-between">
                      <span id="event-dop">Date of Payment</span>
                      <span id="event-dop-value" className="text-gray-400">
                        {dayjs(new Date()).format("MMM DD, YYYY")}
                      </span>
                    </div>
                  </div>
                </div>
                <div id="payment-type" className="gap-y-4 flex flex-col">
                  <div
                    id="express"
                    className="text-xs text-gray-400 flex flex-col"
                  >
                    <span className="font-semibold text-primary-500">
                      Express Payment Process
                    </span>
                    <span>Express payment is powered by PayMaya Business</span>
                    <span>
                      Pay using any of the following: Credit Card / Debit Card /
                      PayMaya / GCash
                    </span>
                    <span>
                      Payment will be automatically processed and instantly
                      secured.
                    </span>
                    <span>
                      3% of the total transaction payment will be added as
                      PayMaya fees.
                    </span>
                    <span>
                      Express Payment is unavailable within 10 days before the
                      date of event.
                    </span>
                  </div>
                  <div
                    id="express"
                    className="text-xs text-gray-400 flex flex-col"
                  >
                    <span className="font-semibold text-primary-500">
                      Basic Payment Process
                    </span>
                    <span>
                      Send your payment directly to our corporate bank via
                      online banking or over the counter payment center.
                    </span>
                    <span>
                      Please keep your receipts for validation procedure.
                    </span>
                    {/* <span>
                      Reservation will be confirmed by the Merchant after
                      validation.
                    </span> */}
                    {/* <span>Email Notifications only.</span> */}
                  </div>
                </div>
              </div>
            )}

            {step === 3 && (
              <div id="payment_3" className="flex flex-col">
                <div className="mt-10 ">
                  <div className="text-lg font-semibold">Basic Payment</div>
                  <span className="text-sm">
                    Pay via online banking or over the counter payments.
                  </span>
                </div>
                <div className="py-2 text-sm">
                  <span>Transfer Payment to</span>

                  <div className="py-2">
                    <div className="flex flex-row justify-between">
                      <span id="event-date">Bank</span>
                      <span id="event-date-value" className="text-gray-400">
                        {merchantBank.bank_name}
                      </span>
                    </div>
                    <div className="flex flex-row justify-between">
                      <span id="event-location">Account Name</span>
                      <span id="event-location-value" className="text-gray-400">
                        {merchantBank.account_name}
                      </span>
                    </div>
                    <div className="flex flex-row justify-between">
                      <span id="event-price">Account Number</span>
                      <span id="event-price-value" className="text-gray-400">
                        {merchantBank.account_no}
                      </span>
                    </div>
                    {data?.payment_schedule.map((sched, key) => {
                      return sched.payment_code === selectedOption ? (
                        <div className="mt-4 text-primary-500 font-semibold flex flex-row justify-between">
                          <span id="event-total">Total Payment</span>
                          <span id="event-total-value">
                            {"₱" + sched.amount_paid}
                          </span>
                        </div>
                      ) : null;
                    })}
                  </div>
                </div>
              </div>
            )}

            {step === 4 && (
              <div id="payment_4" className="flex flex-col">
                <div className="mt-10 ">
                  <div className="text-lg font-semibold">
                    Transaction Information
                  </div>
                </div>
                <div className="py-2 text-sm">
                  <div>
                    <div className="w-full  items-center p-2 border mr-2 rounded-xl text-sm">
                      <label
                        htmlFor="sender"
                        className="px-2 text-xs text-gray-400"
                      >
                        Sender/Name
                      </label>
                      <div className="flex">
                        <input
                          autoFocus={true}
                          className="resize-none border-none px-2 outline-none w-full bg-transparent"
                          id="sender"
                          type="text"
                          value={sender}
                          onChange={(e) => setSender(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="mt-2">
                    <Dropdown
                      array={["Over the Counter", "Bank Transfer"]}
                      func={(data) => setMop(data)}
                      keyName=""
                      selected={mop}
                      title="Mode of Payment"
                      subtitle="-"
                    />
                    {/* <select
                          id="modeOfPayment"
                          className="w-full px-2 border-none outline-none bg-transparent"
                          value={mop}
                          onChange={(e) => setMop(e.target.value)}
                        >
                          <option value="Over the Counter">
                            Over the Counter
                          </option>
                          <option value="Bank Transfer">Bank Transfer</option>
                        </select> */}
                  </div>

                  <div className="mt-2">
                    <div className="w-full  items-center p-2 border mr-2 rounded-xl text-sm">
                      <label
                        htmlFor="bankName"
                        className="px-2 text-xs text-gray-400"
                      >
                        Bank name or Payment Center
                      </label>
                      <div className="flex">
                        <input
                          className="resize-none border-none px-2 outline-none w-full bg-transparent"
                          id="bankName"
                          type="text"
                          value={bname}
                          onChange={(e) => setBName(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="mt-2">
                    <div className="w-full  items-center   rounded-xl text-sm">
                      <div className="flex">
                        <DatePicker
                          className="purple"
                          value={dop}
                          format="MM/DD/YYYY"
                          onChange={(date) => {
                            setDop(date.toString());
                          }}
                          render={(value, openCalendar) => {
                            return (
                              <div
                                id="dob"
                                onClick={openCalendar}
                                className="mt-2 p-2 border rounded-xl text-sm"
                              >
                                <label
                                  htmlFor="bankName"
                                  className="px-2 text-xs text-gray-400"
                                >
                                  Date of Payment
                                </label>
                                {value === "" ? (
                                  <p className="px-2 w-full text-gray-400">
                                    Date of Payment
                                  </p>
                                ) : (
                                  <p className="px-2 w-full">
                                    {dayjs(value).format("MMMM D, YYYY")}
                                  </p>
                                )}
                              </div>
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="mt-2">
                    <div className="w-full  items-center p-2 border mr-2 rounded-xl text-sm">
                      <label
                        htmlFor="reference"
                        className="px-2 text-xs text-gray-400"
                      >
                        Invoice and Ref. Number
                      </label>
                      <div className="flex">
                        <input
                          className="resize-none border-none px-2 outline-none w-full bg-transparent"
                          id="reference"
                          type="text"
                          value={reference}
                          onChange={(e) => setReference(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  {/* <div className="mt-2">
                    <div className="w-full  items-center p-2 border mr-2 rounded-xl text-sm">
                      <label
                        htmlFor="reference"
                        className="px-2 text-xs text-gray-400"
                      >
                        Invoice
                      </label>
                      <div className="flex">
                        <input
                          className="resize-none border-none px-2 outline-none w-full bg-transparent"
                          id="invoceNo"
                          type="text"
                          value={invoceNo}
                          onChange={(e) => setInvoiceNo(e.target.value)}
                        />
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            )}

            <div className="absolute">
              {error && (
                <span className="text-xs text-red-500 flex flex-row item-center">
                  <Error className="w-3 mr-1 self-center" />
                  {error}
                </span>
              )}
            </div>

            {step === 2 && (
              <div className="mt-4 text-xs text-gray-400">
                By selecting any of the payment process, I agree to{" "}
                <span
                  onClick={() => {
                    window.open(
                      config.ANYAYA_URL + "/terms-and-conditions",
                      "_blank"
                    );
                  }}
                  className="underline cursor-pointer"
                >
                  Anyaya’s Terms & Conditions
                </span>{" "}
                and{" "}
                <span
                  className="underline cursor-pointer"
                  onClick={() => {
                    window.open(
                      config.ANYAYA_URL + "/privacy-policy",
                      "_blank"
                    );
                  }}
                >
                  Privacy Policy.
                </span>
              </div>
            )}

            {step === 3 && (
              <div className="mt-4 text-xs text-gray-400 italic">
                Please keep your receipt for validation then tap proceed when
                payment is made.
              </div>
            )}

            {step === 4 && (
              <div className="mt-6 text-xs text-gray-400 italic">
                Please make sure all details are correct. Inaccuracy may cause
                delay and inconvenience.
              </div>
            )}

            <div
              className={`mt-4 flex flex-row ${
                step === 2 || step === 3 || step === 4
                  ? "justify-between"
                  : "justify-end"
              }`}
            >
              <div className="flex flex-row justify-start gap-2">
                {step === 2 &&
                  (expressPayment === "1" ? (
                    <button
                      className="mt-4 bg-sunrise-500 px-10 text-white font-semibold py-3 rounded-full hover:opacity-90"
                      onClick={() => {
                        if (!isLoading) {
                          handlePayment();
                        }
                      }}
                    >
                      {isLoading ? (
                        <div className="w-full">
                          <Loader />
                        </div>
                      ) : (
                        <p className="text-sm">Express Payment</p>
                      )}
                    </button>
                  ) : null)}

                {step !== 1 && (
                  <button
                    className="mt-4 bg-primary-500  text-white font-semibold py-3 w-44 rounded-full hover:opacity-90"
                    onClick={() => {
                      if (step === 2) {
                        if (!s3Loading) {
                          handleMerchantBank();
                        }
                      } else if (step === 3) {
                        setStep(4);
                      } else if (step === 4) {
                        if (!isLoading) {
                          handleSubmit();
                        }
                      }
                    }}
                  >
                    {step === 2 ? (
                      s3Loading ? (
                        <Loader />
                      ) : (
                        <p className="text-sm">Basic Payment</p>
                      )
                    ) : null}
                    {step === 3 && <p className="text-sm">Proceed</p>}
                    {step === 4 ? (
                      isLoading ? (
                        <Loader />
                      ) : (
                        <p className="text-sm">Confirm Details</p>
                      )
                    ) : null}
                  </button>
                )}
              </div>

              {step === 2 && (
                <div className="items-center flex flex-col self-center">
                  <div className="w-24 h-12 mt-3">
                    <div
                      className="logo h-full w-full "
                      style={{ backgroundPositionY: "center" }}
                    />
                  </div>
                </div>
              )}

              {(step === 3 || step === 4) && (
                <div className="items-center flex flex-col self-center">
                  <div className="w-36 h-12 mt-3">
                    <div
                      className="logo h-full w-full "
                      style={{ backgroundPositionY: "center" }}
                    />
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const Dropdown = ({ array, func, selected, title, subtitle }) => {
  const handleSelect = (data) => {
    func(data);
    setShowModal(false);
  };
  const [showModal, setShowModal] = useState(false);
  return (
    <div className={`w-full items-center p-2 border rounded-xl`}>
      <button
        onClick={() => {
          setShowModal(true);
        }}
        type="button"
        className="border-none outline-none items-center bg-transparent flex flex-row px-2 justify-between text-start text-xs w-full "
      >
        <div>
          <div classNamme="text-xs text-gray-400">{title}</div>

          {selected === "" ? (
            <div className="text-sm mt-1 text-gray-400">{subtitle}</div>
          ) : (
            <div className="text-sm mt-1">{selected}</div>
          )}
        </div>

        <svg
          className="-mr-1 ml-2 h-5 w-5"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fillRule="evenodd"
            d="M10 12.707l-5.146-5.147a1 1 0 011.414-1.414L10 10.586l4.146-4.146a1 1 0 111.414 1.414L10 12.707z"
            clipRule="evenodd"
          />
        </svg>
      </button>

      {showModal &&
        (array.length === 0 ? (
          <></>
        ) : (
          <>
            <div
              onClick={() => setShowModal(false)}
              className="fixed inset-0"
            ></div>

            <div
              className="absolute max-h-96 overflow-scroll mt-3 w-80 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="options-menu"
            >
              <div className="py-1" role="none">
                {array?.map((data, key) => {
                  return (
                    <div
                      onClick={() => {
                        handleSelect(data);
                      }}
                      className="block p-4 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                      role="menuitem"
                      key={key}
                    >
                      {data}
                    </div>
                  );
                })}
              </div>
            </div>
          </>
        ))}
    </div>
  );
};
