import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Breadcrumbs } from "../../../common/Breadcrumbs";
import Checkbox from "../../../common/Checkbox";
import { accountSettings } from "../../../../api/client";
import { updateAccountSettings } from "../../../../store/slicers/authSlice";

const Notifications = () => {
  const dispatch = useDispatch();
  const [activeTab, setTab] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const accountSet = useSelector((state) => state.auth.account_settings);

  const phone = useSelector((state) => state.auth.account_info?.mobile_no);
  const navigate = useNavigate();

  const updateMarketing = (val) => {
    updateSettings("unsubscribe_email", val ? "1" : "0");
  };

  const getAccountSettings = async () => {
    try {
      const _requestData = {
        flag: "init",
      };
      const response = await accountSettings(_requestData);
      if (response.error === 0) {
        dispatch(updateAccountSettings(response.data.account_settings));
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const updateSettings = async (key, value) => {
    try {
      const _requestData = {
        flag: "save",
        setting_key: key,
        setting_value: value,
      };
      const response = await accountSettings(_requestData);
      if (response.error === 0) {
        const updatedSettings = { ...accountSet, [key]: value };
        dispatch(updateAccountSettings(updatedSettings));
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAccountSettings();
  }, []);

  return (
    <div className="px-10  mt-5  align-center items-center">
      <Breadcrumbs location="account/notifications" />
      <div className="text-2xl font-semibold text-black">Notifications</div>

      <div className="border-b border-gray-200 mt-10">
        <ul className="flex flex-wrap -mb-px text-sm  text-center">
          <li className="mr-2">
            <div
              onClick={() => {
                setTab(0);
              }}
              className={`inline-flex items-center justify-center py-4 pr-4 rounded-t-lg cursor-pointer ${
                activeTab === 0 && "font-semibold border-b-2 border-black"
              }`}
            >
              Account
            </div>
          </li>
          <li className="mr-2">
            <div
              onClick={() => {
                setTab(1);
              }}
              className={`inline-flex items-center justify-center p-4 rounded-t-lg cursor-pointer ${
                activeTab === 1 && "font-semibold border-b-2 border-black"
              }`}
            >
              Offers and updates
            </div>
          </li>
        </ul>
      </div>

      {activeTab === 0 && (
        <div>
          <div className="mt-10">
            <div className="text-lg font-semibold text-black">
              Account activity and reminders
            </div>
            <div className="text-sm text-gray-400 mt-2">
              Confirm your booking and account activity and get important
              reminders about your reservations, event listing and event
              details.
            </div>

            <div className="mt-5">
              <div className="text-sm font-semibold">Account activity</div>
              <div className="w-20 mt-1 text-xs flex flex-row justify-between">
                <div
                  onClick={
                    accountSet?.account_activity === "0"
                      ? () => updateSettings("account_activity", "1")
                      : () => {}
                  }
                  className={`${
                    accountSet?.account_activity === "1"
                      ? "cursor-pointer text-black font-semibold"
                      : "cursor-pointer text-gray-400 "
                  }`}
                >
                  ON
                </div>
                <div
                  onClick={
                    accountSet?.account_activity === "1"
                      ? () => updateSettings("account_activity", "0")
                      : () => {}
                  }
                  className={`${
                    accountSet?.account_activity === "0"
                      ? "cursor-pointer text-black font-semibold"
                      : "cursor-pointer text-gray-400 "
                  }`}
                >
                  OFF
                </div>
              </div>
            </div>

            <div className="mt-5">
              <div className="text-sm font-semibold">
                Event listing activity
              </div>
              <div className="w-20 mt-1 text-xs flex flex-row justify-between">
                <div
                  onClick={
                    accountSet?.event_listing_activity === "0"
                      ? () => updateSettings("event_listing_activity", "1")
                      : () => {}
                  }
                  className={`${
                    accountSet?.event_listing_activity === "1"
                      ? "cursor-pointer text-black font-semibold"
                      : "cursor-pointer text-gray-400 "
                  }`}
                >
                  ON
                </div>
                <div
                  onClick={
                    accountSet?.event_listing_activity === "1"
                      ? () => updateSettings("event_listing_activity", "0")
                      : () => {}
                  }
                  className={`${
                    accountSet?.event_listing_activity === "0"
                      ? "cursor-pointer text-black font-semibold"
                      : "cursor-pointer text-gray-400 "
                  }`}
                >
                  OFF
                </div>
              </div>
            </div>

            <div className="mt-5">
              <div className="text-sm font-semibold">Reminders activity</div>
              <div className="w-20 mt-1 text-xs flex flex-row justify-between">
                <div
                  onClick={
                    accountSet?.reminders_activity === "0"
                      ? () => updateSettings("reminders_activity", "1")
                      : () => {}
                  }
                  className={`${
                    accountSet?.reminders_activity === "1"
                      ? "cursor-pointer text-black font-semibold"
                      : "cursor-pointer text-gray-400 "
                  }`}
                >
                  ON
                </div>
                <div
                  onClick={
                    accountSet?.reminders_activity === "1"
                      ? () => updateSettings("reminders_activity", "0")
                      : () => {}
                  }
                  className={`${
                    accountSet?.reminders_activity === "0"
                      ? "cursor-pointer text-black font-semibold"
                      : "cursor-pointer text-gray-400 "
                  }`}
                >
                  OFF
                </div>
              </div>
            </div>
          </div>
          <div className="mt-5 border-y py-5">
            <div className="text-lg font-semibold text-black">
              Guest and Merchant Messages
            </div>
            <div className="text-sm text-gray-400 mt-2">
              Keep in touch with your merchant or guests before and during an
              event.
            </div>

            <div className="mt-5">
              <div className="text-sm font-semibold">Messages</div>
              <div className="w-20 mt-1 text-xs flex flex-row justify-between">
                <div
                  onClick={
                    accountSet?.messages === "0"
                      ? () => updateSettings("messages", "1")
                      : () => {}
                  }
                  className={`${
                    accountSet?.messages === "1"
                      ? "cursor-pointer text-black font-semibold"
                      : "cursor-pointer text-gray-400 "
                  }`}
                >
                  ON
                </div>
                <div
                  onClick={
                    accountSet?.messages === "1"
                      ? () => updateSettings("messages", "0")
                      : () => {}
                  }
                  className={`${
                    accountSet?.messages === "0"
                      ? "cursor-pointer text-black font-semibold"
                      : "cursor-pointer text-gray-400 "
                  }`}
                >
                  OFF
                </div>
              </div>
            </div>
          </div>
          <div className="text-xs font-semibold flex flex-wrap py-5">
            By opting in to messages, you agree to receive automated marketing
            messages from Anyaya at {`${phone}`}. To receive messages at
            different number,{" "}
            <p
              className="underline mx-1 cursor-pointer"
              onClick={() => {
                navigate("/account/personal");
              }}
            >
              update your contact number
            </p>{" "}
            on your personal info page.
          </div>
        </div>
      )}

      {activeTab === 1 && (
        <div>
          <div className="py-10 border-b">
            <div className="text-lg font-semibold text-black">
              Anyaya updates, tips and offers
            </div>
            <div className="text-sm text-gray-400 mt-2">
              Stay up to date on the latest news and updates from Anyaya and get
              personalized recommendations and special offers
            </div>

            <div className="mt-5">
              <div className="text-sm font-semibold">News and updates</div>
              <div className="w-20 mt-1 text-xs flex flex-row justify-between">
                <div
                  onClick={
                    accountSet?.news_updates === "0"
                      ? () => updateSettings("news_updates", "1")
                      : () => {}
                  }
                  className={`${
                    accountSet?.news_updates === "1"
                      ? "cursor-pointer text-black font-semibold"
                      : "cursor-pointer text-gray-400 "
                  }`}
                >
                  ON
                </div>
                <div
                  onClick={
                    accountSet?.news_updates === "1"
                      ? () => updateSettings("news_updates", "0")
                      : () => {}
                  }
                  className={`${
                    accountSet?.news_updates === "0"
                      ? "cursor-pointer text-black font-semibold"
                      : "cursor-pointer text-gray-400 "
                  }`}
                >
                  OFF
                </div>
              </div>
            </div>

            <div className="mt-5">
              <div className="text-sm font-semibold">
                Recommendations and offers
              </div>
              <div className="w-20 mt-1 text-xs flex flex-row justify-between">
                <div
                  onClick={
                    accountSet?.offers === "0"
                      ? () => updateSettings("offers", "1")
                      : () => {}
                  }
                  className={`${
                    accountSet?.offers === "1"
                      ? "cursor-pointer text-black font-semibold"
                      : "cursor-pointer text-gray-400 "
                  }`}
                >
                  ON
                </div>
                <div
                  onClick={
                    accountSet?.offers === "1"
                      ? () => updateSettings("offers", "0")
                      : () => {}
                  }
                  className={`${
                    accountSet?.offers === "0"
                      ? "cursor-pointer text-black font-semibold"
                      : "cursor-pointer text-gray-400 "
                  }`}
                >
                  OFF
                </div>
              </div>
            </div>

            <div className="mt-5">
              <div className="text-sm font-semibold">Event planning</div>
              <div className="w-20 mt-1 text-xs flex flex-row justify-between">
                <div
                  onClick={
                    accountSet?.event_planning === "0"
                      ? () => updateSettings("event_planning", "1")
                      : () => {}
                  }
                  className={`${
                    accountSet?.event_planning === "1"
                      ? "cursor-pointer text-black font-semibold"
                      : "cursor-pointer text-gray-400 "
                  }`}
                >
                  ON
                </div>
                <div
                  onClick={
                    accountSet?.event_planning === "1"
                      ? () => updateSettings("event_planning", "0")
                      : () => {}
                  }
                  className={`${
                    accountSet?.event_planning === "0"
                      ? "cursor-pointer text-black font-semibold"
                      : "cursor-pointer text-gray-400 "
                  }`}
                >
                  OFF
                </div>
              </div>
            </div>
            <div className="mt-5">
              <div className="text-sm font-semibold">Event regulations</div>
              <div className="w-20 mt-1 text-xs flex flex-row justify-between">
                <div
                  onClick={
                    accountSet?.event_regulations === "0"
                      ? () => updateSettings("event_regulations", "1")
                      : () => {}
                  }
                  className={`${
                    accountSet?.event_regulations === "1"
                      ? "cursor-pointer text-black font-semibold"
                      : "cursor-pointer text-gray-400 "
                  }`}
                >
                  ON
                </div>
                <div
                  onClick={
                    accountSet?.event_regulations === "1"
                      ? () => updateSettings("event_regulations", "0")
                      : () => {}
                  }
                  className={`${
                    accountSet?.event_regulations === "0"
                      ? "cursor-pointer text-black font-semibold"
                      : "cursor-pointer text-gray-400 "
                  }`}
                >
                  OFF
                </div>
              </div>
            </div>
          </div>

          <div className="mt-5">
            <Checkbox
              label={"Unsubscribe from all marketing emails"}
              checked={accountSet?.unsubscribe_email === "1"}
              onChange={(val) => {
                updateMarketing(val);
              }}
            />
          </div>
          <div className="text-xs font-semibold py-5 flex flex-wrap">
            <p>
              By opting in to messages, you agree to receive automated marketing
              messages from Anyaya at {`${phone}`}. To receive messages at
              different number,
              <span
                className="underline mx-1 cursor-pointer"
                onClick={() => {
                  navigate("/account/personal");
                }}
              >
                update your contact number
              </span>{" "}
              <span>on your personal info page.</span>
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Notifications;
