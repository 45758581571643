import React, { useEffect, useState } from "react";
import DatePicker, { Calendar } from "react-multi-date-picker";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { findEarliestAvailableDate } from "../../../utils/common";
import { updateMultipleFields } from "../../../store/slicers/listingSlice";
import { viewListing } from "../../../api/client/listing";
import { useLocation } from "react-router-dom";
const CalendarService = ({ dateBlocked = [], type = "" }) => {
  const [numberOfMonths, setNumberOfMonths] = useState(
    window.innerWidth < 768 ? 1 : 2
  );
  const dispatch = useDispatch();
  const location = useLocation();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedDate, setDate] = useState("");
  const serviceOffered = useSelector((state) => state.listing.serviceOffered);
  const initialDate = useSelector((state) => state.listing.selectedDate);
  const pathnameParts = location.pathname.split("/");
  const listingId = pathnameParts[pathnameParts.length - 1];

  useEffect(() => {
    if (initialDate.startDate === "") {
      const earliestAvail = findEarliestAvailableDate(dateBlocked);
      setDate(earliestAvail + "," + earliestAvail);
      dispatch(
        updateMultipleFields({
          selectedDate: {
            startDate: earliestAvail,
            endDate: earliestAvail,
          },
        })
      );
      setStartDate(dayjs(earliestAvail));
      setEndDate(dayjs(earliestAvail));
    } else {
      setStartDate(dayjs(initialDate.startDate));
      setEndDate(dayjs(initialDate.endDate));
      setDate(initialDate.startDate + "," + initialDate.endDate);
    }
  }, []);

  useEffect(() => {
    function handleResize() {
      setNumberOfMonths(window.innerWidth < 768 ? 1 : 2);
    }
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleServiceCharge = async () => {
    dispatch(
      updateMultipleFields({
        selectedDate: {
          startDate: "",
          endDate: "",
        },
      })
    );
    const dates = selectedDate.split(",");
    let start = "";
    let end = "";
    if (dates.length === 2) {
      start = dayjs(dates[0]).format("YYYY-MM-DD");
      end = dayjs(dates[1]).format("YYYY-MM-DD");
      if (end !== "Invalid Date") {
        try {
          const _requestData = {
            service_code: listingId,
            start_date: start,
            end_date: end,
          };
          const response = await viewListing(_requestData);
          if (response.error === 0) {
            const data = response.data;
            dispatch(
              updateMultipleFields({
                selectedDate: {
                  startDate: start,
                  endDate: end,
                },
                serviceCalendar: data.service_calendar,
                serviceOffered: data.service_offered,
                servicePricing: data.service_pricing,
                servicePhotos: data.service_photos,
                serviceReviews: data.service_reviews,
                eventHighlights: data.event_highlight,
              })
            );
          } else {
            dispatch(
              updateMultipleFields({
                selectedDate: {
                  startDate: "",
                  endDate: "",
                },
              })
            );
          }
        } catch (error) {
          dispatch(
            updateMultipleFields({
              selectedDate: {
                startDate: "",
                endDate: "",
              },
            })
          );
        }
      }
    }
  };

  useEffect(() => {
    handleServiceCharge();
  }, [selectedDate]);

  return (
    <div>
      {type === "picker" ? (
        <DatePicker
          value={selectedDate.split(",")}
          className="mr-0 md:mr-14 purple"
          range
          minDate={dayjs().toDate()}
          onChange={(date) => {
            const dates = date?.toString().split(",").map(dayjs);

            if (dates) {
              setStartDate(dates[0]);
              setEndDate(dates[1]);
            }
            if (date?.toString().includes(",")) {
              setDate(date?.toString());
            } else {
              setDate(date?.toString() + ",");
            }
          }}
          mapDays={({ date }) => {
            let formattedDay = dayjs(date.toDate()).format("YYYY-MM-DD");

            if (dateBlocked.includes(formattedDay)) {
              return {
                className: "highlight blocked-date line-through",
                disabled: true,
              };
            }

            if (startDate) {
              if (dayjs(formattedDay).diff(startDate, "day") < 0 && !endDate) {
                return {
                  className: "highlight blocked-date line-through",
                  disabled: true,
                };
              }
              if (dayjs(formattedDay).diff(startDate, "day") > 0) {
                const blocked = [];
                dateBlocked.map((data) => {
                  if (dayjs(data).diff(startDate, "day") > 0) {
                    blocked.push(data);
                  }
                });
                if (
                  dayjs(blocked[0]).isAfter(startDate, "day") &&
                  dayjs(blocked[0]).isBefore(formattedDay, "day") &&
                  !endDate
                ) {
                  return {
                    className: "highlight blocked-date line-through",
                    disabled: true,
                  };
                }
              }
            }

            if (endDate) {
              return { className: "", disabled: false };
            }
          }}
          numberOfMonths={numberOfMonths}
          headerOrder={["MONTH_YEAR", "LEFT_BUTTON", "RIGHT_BUTTON"]}
        />
      ) : (
        <Calendar
          value={selectedDate.split(",")}
          className="mr-0 md:mr-14 purple"
          range
          minDate={dayjs().toDate()}
          onChange={(date) => {
            const dates = date?.toString().split(",").map(dayjs);

            if (dates) {
              setStartDate(dates[0]);
              setEndDate(dates[1]);
            }
            if (date?.toString().includes(",")) {
              setDate(date?.toString());
            } else {
              setDate(date?.toString() + ",");
            }
          }}
          mapDays={({ date }) => {
            let formattedDay = dayjs(date.toDate()).format("YYYY-MM-DD");

            if (dateBlocked.includes(formattedDay)) {
              return {
                className: "highlight blocked-date line-through",
                disabled: true,
              };
            }

            if (startDate) {
              if (dayjs(formattedDay).diff(startDate, "day") < 0 && !endDate) {
                return {
                  className: "highlight blocked-date line-through",
                  disabled: true,
                };
              }
              if (dayjs(formattedDay).diff(startDate, "day") > 0) {
                const blocked = [];
                dateBlocked.map((data) => {
                  if (dayjs(data).diff(startDate, "day") > 0) {
                    blocked.push(data);
                  }
                });
                if (
                  dayjs(blocked[0]).isAfter(startDate, "day") &&
                  dayjs(blocked[0]).isBefore(formattedDay, "day") &&
                  !endDate
                ) {
                  return {
                    className: "highlight blocked-date line-through",
                    disabled: true,
                  };
                }
              }
            }

            if (endDate) {
              return { className: "", disabled: false };
            }
          }}
          numberOfMonths={numberOfMonths}
          headerOrder={["MONTH_YEAR", "LEFT_BUTTON", "RIGHT_BUTTON"]}
        />
      )}

      <div
        className="cursor-pointer text-right text-xs underline"
        onClick={() => {

          const earliestAvail = findEarliestAvailableDate(dateBlocked);
          setDate(earliestAvail + "," + earliestAvail);
          dispatch(
            updateMultipleFields({
              selectedDate: {
                startDate: earliestAvail,
                endDate: earliestAvail,
              },
            })
          );
          setStartDate(dayjs(earliestAvail));
          setEndDate(dayjs(earliestAvail));

        }}
      >
        Clear Dates
      </div>
    </div>
  );
};

export default CalendarService;
