import React, { useState } from "react";
import { useDispatch } from "react-redux";
import ContentLoader from "react-content-loader";
import { useNavigate } from "react-router-dom";
import {
  updateListingLastPage,
  updateListingLoading,
  updateListingPage,
  updateListings,
} from "../../../store/slicers/listingSlice";
import { convertToKebabCase } from "../../../utils/common";

export const List = ({ service_img, service_name, service_type_code }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);

  const handleClick = () => {
    dispatch(updateListingLoading(false));
    dispatch(updateListingLastPage(false));
    dispatch(updateListingPage(1));
    dispatch(updateListings([]));
    navigate(
      `/category/${service_type_code}/${convertToKebabCase(service_name)}`
    );
  };

  const contentLoad = () => {
    return (
      <ContentLoader
        speed={1}
        width="15rem"
        height="15rem"
        className="absolute"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
      >
        <rect rx="10" ry="10" width="15rem" height="15rem" />
      </ContentLoader>
    );
  };
  return (
    <div className="h-full w-full rounded-2xl p-4 bg-primary-50 hover:opacity-70">
      <div
        key={service_type_code}
        className="w-full  cursor-pointer"
        onClick={handleClick}
        style={{ height: "15rem" }}
      >
        {isLoading && contentLoad()}

        <img
          draggable="false"
          onLoad={() => {
            setIsLoading(false);
          }}
          alt={service_name}
          src={service_img}
          className={`w-full h-full bg-no-repeat object-cover bg-center`}
        />
      </div>
      <h2 className="px-2 pt-3 text-lg text-primary-600 text-center  ">{service_name}</h2>
    </div>
  );
};
