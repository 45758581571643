import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  token: null,
  account_info: {},
  account_settings: {},
  phone: "",
  loginModal: false,
  loginNavigate: undefined,
  OTPModal: false,
  isNewAccount: false,
  menuModal: false,
  logoutModal: false,
  unreadNotif: 0,
  unreadMsg: 0,
  merchantMsg: 0,
  showWelcome: false,
  showLanguage: false,
  showPayment: false,
  showMerchantPayouts: false,
  isRemember:false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    updateNewAccount(state, action) {
      state.isNewAccount = action.payload;
    },
    updateLoginModal(state, action) {
      state.loginModal = action.payload.status;
      if (action.payload.navigate !== undefined) {
        state.loginNavigate = action.payload.navigate;
      }
    },
    updateMenuModal(state, action) {
      state.menuModal = action.payload;
    },
    updateOTPModal(state, action) {
      state.OTPModal = action.payload;
    },
    updatePhone(state, action) {
      state.phone = action.payload;
    },
    updateAccountInfo(state, action) {
      state.account_info = action.payload;
    },
    updateToken(state, action) {
      state.token = action.payload;
    },
    updateLogoutModal(state, action) {
      state.logoutModal = action.payload;
    },
    updateAccountSettings(state, action) {
      state.account_settings = action.payload;
    },
    updateUnreads(state, action) {
      state.unreadNotif = action.payload.notif;
      state.unreadMsg = action.payload.msg;
      state.merchantMsg = action.payload.merchantMsg;
    },
    updateWelcome(state, action) {
      state.showWelcome = action.payload;
    },
    updateLanguage(state, action) {
      state.showLanguage = action.payload;
    },
    updatePaymentModal(state, action) {
      state.showPayment = action.payload;
    },
    updateMerchantPaymentModal(state, action) {
      state.showMerchantPayouts = action.payload;
    },
    updateRemember(state, action) {
      state.isRemember = action.payload;
    },
    
    logout(state) {
      state.token = null;
      state.account_info = {};
      state.account_settings = {};
      state.phone = "";
      state.loginModal = false;
      state.loginNavigate = undefined;
      state.OTPModal = false;
      state.isNewAccount = false;
      state.menuModal = false;
      state.logoutModal = false;
      state.unreadNotif = 0;
      state.unreadMsg = 0;
      state.merchantMsg = 0;
      state.showWelcome = false;
      state.showLanguage = false;
      state.showPayment = false;
    },
  },
});

export const {
  updateNewAccount,
  updateLoginModal,
  updateOTPModal,
  updatePhone,
  updateMenuModal,
  updateLogoutModal,
  updateAccountInfo,
  updateToken,
  updateAccountSettings,
  updateUnreads,
  logout,
  updateWelcome,
  updateLanguage,
  updatePaymentModal,
  updateMerchantPaymentModal,
  updateRemember,
} = authSlice.actions;

export default authSlice.reducer;
