import React from "react";
import { ModalHeader } from "../../common/ModalHeader";

export const ChatImages = ({ setShowImages, images, header }) => {
  const handleClose = () => {
    setShowImages();
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 text-gray-500">
      <div className="fixed inset-0 bg-black opacity-50"></div>
      <div className="bg-white rounded-3xl relative py-6 mx-4 w-500">
        <ModalHeader
          title={header}
          onClick={() => {
            handleClose();
          }}
          type={1}
        />
        <div className="overflow-auto h-96 px-5 gap-2 flex flex-col">
          {Boolean(images) && images.length !== 0 ? (
            images?.map((data, key) => {
              return (
                <div key={key}>
                  <a
                    href={data?.file_url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={data?.file_url}
                      alt="Receipt"
                      className="w-full rounded-lg border"
                    />
                  </a>
                </div>
              );
            })
          ) : (
            <div className="text-gray-400 text-xs flex justify-center h-full text-center items-center">
              No Photos Uploaded
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
