import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Close from "../../../assets/svg/Close";
import { PrimaryButton } from "../PrimaryButton";
import {
  logout,
  updateLogoutModal,
  updatePhone,
} from "../../../store/slicers/authSlice";
import { logoutUser } from "../../../api/client";

export const Logout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => {
    dispatch(updateLogoutModal(false));
  };

  const handleLogout = async () => {
    setIsLoading(true);
    try {
      const response = await logoutUser();
      if (response.error === 0) {
        setIsLoading(false);
        dispatch(logout());
        // reset details!!
        handleClose();
        navigate("/category");
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 text-gray-500">
      <div className="fixed inset-0 bg-black opacity-50"></div>
      <div className="bg-white rounded-3xl relative py-6 mx-4 w-500">
        <button
          className="absolute top-6 left-6 text-gray-400 hover:text-gray-600"
          onClick={() => {
            handleClose();
          }}
        >
          <Close className="w-4 h-4" />
        </button>

        <div id="logout" className="mt-10 px-6 mb-2">
          <div className="my-5">
            <div className="text-lg font-semibold">Logout your account</div>
          </div>
          <div className="text-sm">Are you sure to logout?</div>
        </div>
        <div className="px-6">
          <PrimaryButton
            title={"Yes"}
            isLoading={isLoading}
            onClick={() => {
              handleLogout();
            }}
          />
        </div>
      </div>
    </div>
  );
};
