import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout, updateToken } from "../../../../store/slicers/authSlice";
import { ModalHeader } from "../../../common/ModalHeader";
import { PrimaryButton } from "../../../common/PrimaryButton";
import { deactivate } from "../../../../api/client";

export const Deactivate = ({
  isDeactivateOpen,
  handleDeactivateToggle,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const handleClose = () => {
    handleDeactivateToggle(false);
  };

  const handleDeactivate = async () => {
    setIsLoading(true);
    try {
      await deactivate()
      setIsLoading(false);
      handleClose();
      dispatch(logout());
      dispatch(updateToken(""));
      navigate("/category");
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 text-gray-500">
      {/* Backdrop */}
      <div className="fixed inset-0 bg-black opacity-50"></div>
      {/* Modal content */}
      <div
        className="bg-white rounded-3xl relative py-6 mx-4"
        style={{ width: "500px" }}
      >
        <ModalHeader
          title={"Deactivate"}
          onClick={() => {
            handleClose();
          }}
          type={1}
        />
        <div id="deactivate" className="px-6 mb-2">
          <div className="text-sm text-justify">
            Are you considering deactivating your account? This action will
            temporarily suspend your access to our platform. Your profile and
            activities will be hidden from others during this period. To
            reactivate your account, please contact our support. They will
            assist you in the reactivation process to ensure a smooth return to
            our platform. Click <span className="text-red-500">Deactivate my Account</span> to proceed
            with deactivation
          </div>
        </div>
        <div className="px-6">
          <PrimaryButton
            title={"Deactivate My Account"}
            isLoading={isLoading}
            onClick={() => {
              handleDeactivate();
            }}
          />
        </div>
      </div>
    </div>
  );
};
