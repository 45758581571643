import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MetaTags from "../common/MetaTags";
import Helmet from "react-helmet";
import { BetterBrowsing } from "../common/BetterBrowsing";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Merchant from "../../assets/images/merchant-web-landing.png";
import MerchantMobile from "../../assets/images/merchant-landing-mobile-new.png";
import MerchantTab from "../../assets/images/merchant-tab-landing.png";
import { updateIsSoonLanding } from "../../store/slicers/listingSlice";
import { useDispatch, useSelector } from "react-redux";
import { updateLoginModal } from "../../store/slicers/authSlice";
const MerchantLanding = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.auth.token);
  const isMerchant = useSelector(
    (state) => state.auth.account_info.merchant_info
  );
  const [stateChanger, setState] = useState("");

  useEffect(() => {
    setState("s");
  }, [isLoggedIn]);

  useEffect(() => {
    setState("x");
  }, [isMerchant]);

  return (
    <div className="hidden-scroll h-screen overflow-hidden relative">
      <Helmet>
        <MetaTags
          title="An Events Marketplace"
          description="Undoubtedly personal. Create your own memories."
          keywords="genuine event merchants"
        />
      </Helmet>
      <div className="w-full absolute inset-0 flex justify-center flex-col ">
        <div className="flex p-10 md:p-24 flex-col justify-between h-full">
          <div className="flex flex-col h-full justify-start mt-5 md:justify-center">
            <div className="h-12">
              <div className="anyaya-new h-full w-full " />
            </div>
            <div
              className="my-8 md:my-12 flex flex-col gap-y-8 md:gap-y-14 text-4xl md:text-6xl"
              style={{ color: "#423767" }}
            >
              <div>
                <p>Become an</p>
                <p>Event Merchant</p>
              </div>
              <div className="text-lg md:text-2xl">
                <p>Showcase your expertise.</p>
                <p>Celebrate with people.</p>
              </div>
            </div>
            <button
               onClick={() => {
                if (isLoggedIn) {
                  if (!isMerchant) {
                    navigate("/merchant/signup");
                  } else {
                    navigate("/merchant/home");
                  }
                } else {
                  dispatch(
                    updateLoginModal({
                      status: true,
                      navigate: "merchant",
                    })
                  );
                }
              }}
              className="w-40 md:w-56 bg-sunrise-500 text-white py-3 md:py-4 rounded-full hover:opacity-80"
            >
              <p className="text-base md:text-xl">Sign up now</p>
            </button>
            <div
              className="block md:hidden mt-10   w-full self-center"
              style={{ color: "#423767" }}
            >
              <div className="text-xs md:text-sm">Powered by</div>
              <div className="h-5 w-18 mt-2">
                <div className="maya h-full w-full " />
              </div>
            </div>
          </div>

          <div
            className="hidden md:flex flex-row w-full self-center"
            style={{ color: "#423767" }}
          >
            <div className="text-sm mr-2">Powered by</div>
            <div className="h-6 w-20">
              <div className="maya h-full w-full " />
            </div>
          </div>
        </div>
      </div>

      <LazyLoadImage
        className="hidden lg:block h-screen w-full object-right object-contain bg-no-repeat bg-white"
        src={Merchant}
        alt={"Merchant"}
      />
      <LazyLoadImage
        className="block md:block h-screen w-full object-bottom object-contain bg-no-repeat bg-white"
        src={MerchantTab}
        alt={"MerchantTab"}
      />
      <LazyLoadImage
        className="block md:hidden h-screen w-full object-bottom object-contain bg-no-repeat bg-white"
        src={MerchantMobile}
        alt={"MerchantMobile"}
      />
    </div>
  );
};

export default MerchantLanding;
