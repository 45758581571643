import React, { useState } from "react";
import { uploadReceipt } from "../../api/merchant";
import { toast } from "react-hot-toast";
import Loader from "../common/Loader";
import { ModalHeader } from "../common/ModalHeader";
import Error from "../../assets/svg/Error";
export const UploadOR = ({ setOR, paymentCode }) => {
  const [isLoading, setIsLoading] = useState(false);

  const [error, setError] = useState("");

  const [selectedFile, setSelectedFile] = useState(null);
  const [imagePreview, setImagePreview] = useState("");

  const handleFileChange = (e) => {
    const allowedFileTypes = ["image/jpeg", "image/jpg", "image/png"];

    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      if (allowedFileTypes.includes(file.type)) {
        setSelectedFile(file);

        // Display the selected image preview
        const reader = new FileReader();
        reader.onload = (e) => {
          if (e.target && typeof e.target.result === "string") {
            setImagePreview(e.target.result);
          }
        };
        reader.readAsDataURL(file);
      }
    }
  };

  const handleUpload = async () => {
    if (selectedFile) {
      setIsLoading(true);
      try {
        const _requestData = {
          payment_code: paymentCode,
          profile_img: selectedFile,
        };
        const response = await uploadReceipt(_requestData);
        if (response.error === 0) {
          toast.success(response.messsage);
          setIsLoading(false);
          handleClose();
        } else {
          setError(response.error_msg);
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
        setError("Failed. Please try again.");
      }
    }
  };

  const handleClose = () => {
    setOR();
  };
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 text-gray-500">
      <div className="fixed inset-0 bg-black opacity-50"></div>
      <div className="bg-white rounded-3xl relative py-6 mx-4 w-500">
        <ModalHeader
          title={"Upload Invoice or Receipt"}
          onClick={() => {
            handleClose();
          }}
          type={1}
        />

        <div id="profile_picture" className="px-6 w-full">
          <div className="pb-6 text-center-webkit align-center flex flex-col">
            <div className="text-xs">
              Upload invoice or receipt to each booking to secure your payout.
            </div>

            <div className="w-full">
              {imagePreview ? (
                <a
                  href={imagePreview}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={imagePreview}
                    alt="OR"
                    className="mt-3 h-60 w-60 border rounded-lg bg-no-repeat bg-center bg-contain"
                  />
                </a>
              ) : (
                <div className="mt-3 h-60 w-60 border rounded-lg bg-no-repeat bg-center bg-contain"></div>
              )}
            </div>
          </div>
          {error && (
            <span className="text-xs text-red-500 flex flex-row item-center mb-5">
              <Error className="w-3 mr-1 self-center" />
              {error}
            </span>
          )}
          {selectedFile && (
            <div className="mb-2">
              <button
                className="bg-primary-500 px-10 w-full text-white font-semibold py-4 rounded-xl hover:opacity-90"
                onClick={
                  isLoading
                    ? () => {}
                    : () => {
                        handleUpload();
                      }
                }
              >
                {isLoading ? <Loader /> : <p className="text-sm">Done</p>}
              </button>
            </div>
          )}
          <label
            className={`${
              selectedFile
                ? "bg-white text-primary-500 border border-primary-500"
                : "font-semibold bg-primary-500 text-white hover:opacity-90"
            } px-10 flex justify-center w-full text-sm py-4 rounded-xl cursor-pointer`}
          >
            <input type="file" className="hidden" onChange={handleFileChange} />
            {selectedFile ? "Update File" : "Upload"}
          </label>
        </div>
      </div>
    </div>
  );
};
