import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateSoonModal } from "../../../store/slicers/listingSlice";
export const Soon = () => {
  const dispatch = useDispatch();

  const handleOnClose = () => {
    dispatch(
      updateSoonModal(false)
    );
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 text-gray-500">
      <div
        onClick={handleOnClose}
        className="fixed inset-0 bg-black opacity-50"
      ></div>
      <div className="bg-white p-6 mx-4 rounded-3xl relative w-500">
        <div id="report_3" className="h-auto flex items-center">
          <div className=" w-full my-5 text-center">
            <div className="w-40 h-12 mx-auto">
              <div
                className="logo h-full w-full "
                style={{ backgroundPositionY: "center" }}
              />
            </div>
            <div className={`text-gray-500 text-base mt-10`}>
              Our services will be available soon. We will send email and sms
              notifications once we’re open. Thank you!
            </div>
          </div>
        </div>

        <div className="flex flex-row justify-end">
          <button
            className="mt-4 bg-primary-500 px-10 text-white font-semibold py-3 rounded-full hover:opacity-90"
            onClick={() => {
              handleOnClose();
            }}
          >
            <p className="text-sm">Ok</p>
          </button>
        </div>
      </div>
    </div>
  );
};
