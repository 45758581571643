import axiosInstance from "../../utils/axiosInstance";

export async function chatMerchant(_requestData) {
  const requestData = {
    ctl: "merchant",
    act: "chat",
    ..._requestData,
  };
  try {
    const response = await axiosInstance.post("/", requestData);
    return response.data;
  } catch (error) {
    return error;
  }
}
