import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import Close from "../../../assets/svg/Close";
import Loader from "../../common/Loader";
import { reportListing } from "../../../api/client/listing";
import Error from "../../../assets/svg/Error";
import dayjs from "dayjs";
import { checkAccount } from "../../../api/merchant";
export const ProfileDetails = ({ accountCode, handleOnClose }) => {
  const [clientAccount, setClientAccount] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    initialProfile();
  }, []);

  const initialProfile = async () => {
    const _requestData = {
      account_code: accountCode,
    };
    try {
      const response = await checkAccount(_requestData);
      if (response.error === 0) {
        setIsLoading(false);
        setClientAccount(response?.data?.client_account);
      } else {
        handleOnClose();
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      handleOnClose();
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 text-gray-500">
      <div
        className="fixed inset-0 bg-black opacity-50"
        onClick={() => handleOnClose()}
      ></div>
      <div
        className="bg-white p-6 mx-4 rounded-3xl relative w-500"
        style={{ height: "500px" }}
      >
        <button
          className="absolute top-6 left-6 text-gray-400 hover:text-gray-600"
          onClick={handleOnClose}
        >
          <Close className="w-4 h-4" />
        </button>

        {!isLoading && (
          <div className="mt-10">
            <div className="text-sm mt-5">
              <div className="flex flex-col">
                <div className="align-center self-center justify-self-center">
                  {clientAccount["profile_img"] !== "" ? (
                    <img
                      draggable="false"
                      className="mr-3 text-center text-white rounded-full bg-primary-500 w-36 h-36 bg-no-repeat bg-cover"
                      src={clientAccount["profile_img"]}
                      alt={"Profile Chat"}
                    />
                  ) : (
                    <div className="mr-3 rounded-full defaulticon w-36 h-36 bg-no-repeat bg-cover"></div>
                  )}
                </div>

                <div className="self-center my-4">
                  <p className="text-lg">{clientAccount.full_name}</p>
                </div>
                <div className="text-primary-500 mb-2">Identity Confirmed</div>
                {clientAccount.personal_info === 1 && (
                  <div className="flex flex-row gap-x-4">
                    <div className="w-6 h-6">
                      <div className="check w-full h-full bg-no-repeat bg-contain"></div>
                    </div>
                    <p className="self-center">Personal Info</p>
                  </div>
                )}

                {clientAccount.email_address === 1 && (
                  <div className="flex flex-row gap-x-4">
                    <div className="w-6 h-6">
                      <div className="check w-full h-full bg-no-repeat bg-contain"></div>
                    </div>
                    <p className="self-center">Email Address</p>
                  </div>
                )}

                {clientAccount.contact_number === 1 && (
                  <div className="flex flex-row gap-x-4">
                    <div className="w-6 h-6">
                      <div className="check w-full h-full bg-no-repeat bg-contain"></div>
                    </div>
                    <p className="self-center">Contact Number</p>
                  </div>
                )}

                {clientAccount.valid_id === 1 && (
                  <div className="flex flex-row gap-x-4">
                    <div className="w-6 h-6">
                      <div className="check w-full h-full bg-no-repeat bg-contain"></div>
                    </div>
                    <p className="self-center">Valid ID</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
