import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useGoogleLogin } from "@react-oauth/google";
import { Breadcrumbs } from "../../../common/Breadcrumbs";
import Error from "../../../../assets/svg/Error";
import Loader from "../../../common/Loader";
import { validatePhoneNumber } from "../../../../utils/common";
import {
  getUserDetails,
  updateGoogleLogin,
  updateMobileLogin,
} from "../../../../api/client";
import { OTP } from "./OTP";
import { Deactivate } from "./Deactivate";
import { toast } from "react-hot-toast";
import { updateAccountInfo } from "../../../../store/slicers/authSlice";
const LoginSecurity = () => {
  const mobile = useSelector((state) => state.auth?.account_info?.M);
  const fb = useSelector((state) => state.auth?.account_info?.F);
  const gmail = useSelector((state) => state.auth?.account_info?.G);
  const dispatch = useDispatch();
  const [isDeactivateOpen, setDeactivateToggle] = useState(false);
  const [updateMobile, setUpdateMobile] = useState(false);
  const [error, setError] = useState("");
  const [tempPhone, setTempPhone] = useState(mobile);
  const [isLoadingMobile, setIsLoadingMobile] = useState(false);
  const [isOtpOpen, setOTPModal] = useState(false);

  const [isLoadingGoogle, setIsLoadingGoogle] = useState(false);

  const handleSavePhone = async () => {
    setIsLoadingMobile(true);
    setError("");
    const _requestData = {
      flag: "init",
      mobile_no: tempPhone,
    };

    const response = await updateMobileLogin(_requestData);

    if (response.error === 0) {
      setIsLoadingMobile(false);
      setOTPModal(true);
    } else {
      setIsLoadingMobile(false);
      setError(response.error_msg);
    }
  };

  const mobileUpdate = () => {
    setTempPhone(mobile);
    setUpdateMobile(true);
  };

  const closeMobile = () => {
    setTempPhone(mobile);
    setUpdateMobile(false);
    setOTPModal(false);
  };
  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const userDetails = await getUserDetails(tokenResponse.access_token);
      if (typeof userDetails === "string") {
        toast(userDetails);
      } else {
        handleProceedGoogle(userDetails.email, userDetails.id);
      }
    },
  });

  const handleProceedGoogle = async (email, id) => {
    setIsLoadingGoogle(true);
    try {
    const _requestData = {
      googleid: id,
      email: email,
    };
    const response = await updateGoogleLogin(_requestData)
    if (response.error === 0) {
      const accountInfo = response.data.account_info;
      setIsLoadingGoogle(false);
      dispatch(updateAccountInfo(accountInfo));
    } else {
      setIsLoadingGoogle(false);
      toast(response.error_msg);
    }
     } catch (error) {
       setIsLoadingGoogle(false);
       toast("Failed. Please try again");
     }
  };

  return (
    <div className="mt-5 px-10 align-center items-center">
      <Breadcrumbs location="account/login-&-security" />
      <div className="text-2xl font-semibold text-black">Login & Security</div>

      <div className="mt-5 ">
        <div id="mobilenumber" className=" py-4">
          <div className="flex flex-row text-sm items-center justify-between">
            <div>
              <div className="flex flex-col">
                <span className="font-semibold"> Mobile Number</span>
                {updateMobile && (
                  <span>For notifications, reminders, and help logging in</span>
                )}
              </div>
              <div className="text-gray-400 mt-2">
                {mobile !== "" ? (
                  updateMobile ? (
                    <>
                      <div className="flex flex-row">
                        <div
                          className={`w-60 lg:w-96 flex items-center p-2 border ${
                            error && "border-red-500"
                          } rounded-xl text-sm`}
                        >
                          <span>+63</span>
                          <input
                            autoFocus={true}
                            value={tempPhone}
                            onChange={(e) => {
                              setTempPhone(validatePhoneNumber(e.target.value));
                            }}
                            className="resize-none border-none  outline-none w-full p-2 bg-transparent"
                            placeholder="Enter your mobile number"
                          />
                        </div>
                      </div>
                      {error && (
                        <span className="text-xs text-red-500 flex flex-row item-center">
                          <Error className="w-3 mr-1 self-center" />
                          {error}
                        </span>
                      )}
                    </>
                  ) : (
                    `${mobile}`
                  )
                ) : (
                  "Not connected"
                )}
              </div>
            </div>
            <div
              onClick={() => {
                if (mobile === "") {
                  mobileUpdate();
                } else {
                  if (updateMobile) {
                    if (mobile === tempPhone) {
                      closeMobile();
                    } else {
                      handleSavePhone();
                    }
                  } else {
                    mobileUpdate();
                  }
                }
              }}
              className="text-primary-500 cursor-pointer"
            >
              {isLoadingMobile ? (
                <Loader borderColor="border-primary-500" />
              ) : updateMobile ? (
                mobile === tempPhone ? (
                  `Close`
                ) : (
                  "Save"
                )
              ) : mobile === "" ? (
                "Connect"
              ) : (
                `Update`
              )}
            </div>
          </div>
        </div>

        {/* <div id="facebook" className="border-y py-4">
          <div className="flex flex-row text-sm items-center justify-between">
            <div>
              <div className="font-semibold">Facebook</div>
              <div className="text-gray-400">
                {fb !== "" ? fb : "Not connected"}
              </div>
            </div>
            <div className="text-primary-500 cursor-pointer">
              {fb === "" ? "Connect" : `Update`}
            </div>
          </div>
        </div> */}

        <div id="google" className="border-y py-4">
          <div className="flex flex-row text-sm items-center justify-between">
            <div>
              <div className="font-semibold">Google</div>
              <div className="text-gray-400">
                {gmail !== "" ? gmail : "Not connected"}
              </div>
            </div>
            <div
              onClick={() => {
                googleLogin();
              }}
              className="text-primary-500 cursor-pointer"
            >
              {isLoadingGoogle ? (
                <Loader borderColor="border-primary-500" />
              ) : gmail === "" ? (
                "Connect"
              ) : (
                `Update`
              )}
            </div>
          </div>
        </div>

        <div id="deactivate" className="border-b py-4">
          <div className="font-semibold">Account</div>
          <div className="flex my-5 flex-row text-sm items-center justify-between">
            <div className="font-medium">Deactivate your account</div>
            <div
              className="text-red-500 cursor-pointer"
              onClick={() => setDeactivateToggle(true)}
            >
              Deactivate
            </div>
          </div>
        </div>
      </div>

      {isOtpOpen && <OTP phone={tempPhone} closeMobile={() => closeMobile()} />}

      {isDeactivateOpen && (
        <Deactivate
          isDeactivateOpen={isDeactivateOpen}
          handleDeactivateToggle={() => {
            setDeactivateToggle(false);
          }}
        />
      )}

      {/* {isOtpOpen && (
        <AccountUpdateOTP
          setOTPModal={(val) => setOTPModal(val)}
          tempPhone={tempPhone}
          setTempPhone={(val) => setTempPhone(val)}
          closeMobile={() => closeMobile()}
        />
      )} */}
    </div>
  );
};

export default LoginSecurity;
