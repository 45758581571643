import Close from "../../../assets/svg/Close";

export const SuccessModal = ({ onClose }) => {
  const handleOnClose = () => {
    onClose();
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 text-gray-500">
      <div className="fixed inset-0 bg-black opacity-50"></div>
      <div className="bg-white p-6 mx-4 rounded-3xl relative w-500">
        <button
          className="absolute top-6 left-6 text-gray-400 hover:text-gray-600"
          onClick={handleOnClose}
        >
          <Close className="w-4 h-4" />
        </button>

        <div id="review" className="h-96 flex items-center">
          <div className="mt-10 w-full mb-5 text-center">
            <div className="w-44 h-20 mx-auto mb-5">
              <div
                className="logo h-full w-full "
                style={{ backgroundPositionY: "center" }}
              />
            </div>
            <div className="text-xl text-primary-500 font-semibold">
              Thank you for submitting a review.
            </div>
            <div className="text-base mt-10">
              It helps us regulate and maintain the quality of our merchants.
            </div>
          </div>
        </div>

        <div className="flex justify-end">
          <button
            className="mt-4 bg-primary-500 px-10 text-white font-semibold py-3 rounded-full hover:opacity-90"
            onClick={() => {
              handleOnClose();
            }}
          >
            <p className="text-sm">Ok</p>
          </button>
        </div>
      </div>
    </div>
  );
};
