import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../Loader";
import { ModalHeader } from "../ModalHeader";
import { numberOnly } from "../../../utils/common";
import { useNavigate } from "react-router-dom";
import Error from "../../../assets/svg/Error";
import { mobileLogin } from "../../../api/client";
import {
  updateLoginModal,
  updateOTPModal,
  updateNewAccount,
  updateAccountInfo,
  updateToken,
} from "../../../store/slicers/authSlice";

export const OTP = () => {
  const dispatch = useDispatch();
  const phone = useSelector((state) => state.auth.phone);
  const loginNavigate = useSelector((state) => state.auth.loginNavigate);
  const isRemember = useSelector((state) => state.auth.isRemember);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSendingOTP, setIsSendingOTP] = useState(false);
  const [otpStr, setOtp] = useState("");
  const navigate = useNavigate();
  const intervalRef = useRef();

  const [seconds, setSeconds] = useState(60);

  const handleOTPChange = (event) => {
    const inputOTP = event.target.value;
    setOtp(numberOnly(inputOTP));
    if (otpStr.length === 6) {
      handleProceed();
    }
  };

  const handleProceed = async () => {
    setError("");
    if (otpStr === "") {
      setError("OTP is required.");
      return;
    }
    setIsLoading(true);
    try {
      const _requestData = {
        flag: "save",
        mobile_no: phone,
        code: otpStr,
        keep_login: isRemember ? "1" : "0",
      };
      const response = await mobileLogin(_requestData);
      if (response.error === 0) {
        const accountInfo = response.data.account_info;
        setIsLoading(false);
        if (accountInfo.new_account) {
          dispatch(updateNewAccount(true));
        } else {
          if (loginNavigate !== undefined) {
            if (loginNavigate === "merchant") {
              if (Boolean(accountInfo.merchant_info) === false){
                navigate("/merchant/signup");
              } else {
                navigate("/merchant/home");
              }
            }
          }
        }
        dispatch(updateToken(response.data.token));
        dispatch(updateAccountInfo(accountInfo));
        // // if (location !== "otp") {
        // //   navigate(+1);
        // // }
        handleClose();
      } else {
        setIsLoading(false);
        setError(response.error_msg);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      setError("OTP failed. Please try again.");
    }
  };

  const handleClose = () => {
    setError("");
    setOtp("");
    setSeconds(60);
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
    dispatch(updateOTPModal(false));
  };

  useEffect(() => {
    setSeconds(60);
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    };
  }, []);

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (intervalRef.current) {
          clearInterval(intervalRef.current);
          intervalRef.current = null;
        }
      }
    }, 1000);
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    };
  }, [seconds]);

  const handleResend = async () => {
    if (!isSendingOTP) {
      setError("");
      if (phone === "") {
        navigate(-1);
        return;
      }
      setIsSendingOTP(true);

      try {
        const _requestData = { flag: "init", mobile_no: phone };
        const response = await mobileLogin(_requestData);
        if (response.error === 0) {
          setIsSendingOTP(false);
          setSeconds(60);
        } else {
          setIsSendingOTP(false);
          setError(response.error_msg);
        }
      } catch (error) {
        setIsSendingOTP(false);
        setError("Login failed. Please try again.");
      }
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 text-gray-500">
      <div className="fixed inset-0 bg-black opacity-50"></div>
      <div className="bg-white rounded-3xl relative py-6 mx-4 w-500">
        <ModalHeader
          title={"Confirm Number"}
          onClick={() => {
            handleClose();
            dispatch(updateLoginModal({ status: true }));
          }}
          type={2}
        />

        <div id="OTP" className="px-6">
          <div className="text-sm mb-5">
            Enter the code we sent over SMS to +63{phone ?? ""}
          </div>
          <div
            className={`flex items-center p-2 border ${
              error && "border-red-500"
            } rounded-xl text-sm`}
          >
            <input
              autoFocus={true}
              value={otpStr}
              maxLength={6}
              onChange={handleOTPChange}
              className="resize-none border-none  outline-none w-full p-2 bg-transparent"
            />
          </div>
          <div className="h-3 mt-2">
            {error && (
              <span className="text-xs text-red-500 flex flex-row item-center">
                <Error className="w-3 mr-1 self-center" />
                {error}
              </span>
            )}
          </div>
        </div>
        <div className="px-6 pt-4">
          <div
            onClick={
              seconds === 0
                ? () => {
                    handleResend();
                  }
                : () => {}
            }
            className={`${
              seconds === 0 ? "text-primary-500" : "text-gray-400"
            } text-xs cursor-pointer`}
          >
            Resend OTP {seconds === 0 ? "" : `(${seconds}s)`}
          </div>

          <button
            className="mt-4 bg-primary-500 px-10 w-full text-white font-semibold py-4 rounded-xl hover:opacity-90"
            onClick={isLoading ? () => {} : handleProceed}
          >
            {isLoading ? <Loader /> : <p className="text-sm">Continue</p>}
          </button>
        </div>
      </div>
    </div>
  );
};
