import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import ContentLoader from "react-content-loader";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { services } from "../../../api/merchant";
import { PrimaryButton } from "../../common/PrimaryButton";
import { convertToKebabCase } from "../../../utils/common";
const Services = () => {
  const sRowPerPage = 20;
  const [aListings, setListings] = useState([]);
  const [bLoading, setLoading] = useState(false);
  const [bLastPage, setLastPage] = useState(false);
  const [iPage, setPage] = useState(1);
  const [isSaving, setSaving] = useState(false);

  const [isUpdated, setIsUpdated] = useState("a");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(false);
    setPage(1);
    setLastPage(false);
  }, [isUpdated]);

  useEffect(() => {
    handleListings();
  }, [isUpdated, iPage, bLoading]);

  const handleListings = async () => {
    if (bLoading || bLastPage) {
      return;
    }

    setLoading(true);

    const _requestData = {
      page_no: iPage,
      row_per_page: sRowPerPage,
      act: "list",
    };

    try {
      const response = await services(_requestData);
      if (response.error === 0) {
        if (
          response.data.pagination.current_page === 1 &&
          response.data.services_list.length === 0
        ) {
          setLastPage(true);
          setLoading(false);
          return;
        }

        setLoading(false);
        if (response.data.pagination.current_page === 1) {
          setListings(response.data.services_list);
          setPage(2);
        } else {
          const listings = aListings?.concat(response.data.services_list);
          setListings(listings);
          setPage(response.data.pagination.current_page + 1);
        }

        if (
          response.data.pagination.current_page ===
          response.data.pagination.total_page
        ) {
          setLastPage(true);
        }
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const handleStatus = async (service_code, flag) => {
    setLoading(true);
    try {
      const _requestData = {
        act: flag,
        service_code,
      };
      const response = await services(_requestData);
      if (response.error === 0) {
        toast(response.msg);
        setIsUpdated((prev) => (prev === "a" ? "b" : "a")); // Toggle isUpdated between 'a' and 'b'
      } else {
        setLoading(false);
        toast(response.error_msg);
      }
    } catch (error) {
      setLoading(false);
      toast(error);
    }
  };

  const handleOnClose = () => {
    setListings([]);
    setLoading(false);
    setLastPage(false);
    setPage(1);
    setSaving(false);
  };
  const loaders = Array.from({ length: 10 }, (_, index) => (
    <ContentLoader
      key={index}
      speed={1}
      width="15rem"
      height="10rem"
      viewBox="0 0 195 205"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <rect rx="20" ry="20" width="100%" height="100%" />
    </ContentLoader>
  ));

  return (
    <div>
      <div id="events" className="px-8">
        <div className="mb-5 flex flex-row justify-between">
          <div className="text-lg font-semibold">Event listings</div>
        </div>
        <div id="events" className="">
          <InfiniteScroll
            dataLength={aListings?.length || 0}
            next={() => handleListings()}
            hasMore={!bLastPage}
            loader={bLoading ? loaders : null}
            scrollThreshold={0.5}
            className={`${
              !bLoading && aListings?.length === 0
                ? ""
                : "grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-8"
            }`}
          >
            {!bLoading && aListings?.length === 0 ? (
              <div className="w-full h-full flex flex-col justify-center items-center">
                <div className="text-lg font-semibold mt-10">
                  Nothing is in your listing
                </div>
                <div className="text-sm">Your Listing is currently empty</div>
                <div className="w-60 mt-5">
                  <PrimaryButton
                    title={"Create Listing"}
                    onClick={() => {
                      navigate("/merchant/listing/new");
                    }}
                  />
                </div>
              </div>
            ) : (
              aListings?.map((data, i) => (
                <div key={i} className="relative">
                  <img
                    draggable="false"
                    alt={data.title}
                    src={data.cover_img}
                    onClick={() => {
                      navigate(
                        `/category/${data?.service_code}/${convertToKebabCase(
                          data?.service_name
                        )}/${data?.service_code}`
                      );
                    }}
                    className="cursor-pointer h-40 w-60 border  rounded-3xl bg-no-repeat bg-center bg-cover"
                  />
                  <div className="flex flex-row justify-between">
                    <div className="mt-2 text-sm">
                      <div className="font-primary-500 font-semibold">
                        {data.title}
                      </div>
                      <div className="text-xs">{data.service_name}</div>
                      <div className="text-xs">{data.location}</div>
                      {data?.status === "1" ? (
                        <div className="text-xs">Inactive</div>
                      ) : null}
                      {data?.status === "9" ? (
                        <div className="text-xs">For Approval</div>
                      ) : null}
                      <div className="flex flex-row justify-between">
                        <div
                          onClick={() => {
                            navigate(`/merchant/listing/${data.service_code}`);
                          }}
                          className={`cursor-pointer rounded-b-lg p-2 bg-sunrise-500`}
                        >
                          <p className="text-xs text-white text-center">Update</p>
                        </div>
                        {data?.status === "0" && (
                           <div
                           onClick={() => {
                            handleStatus(data?.service_code, "deactivate")
                           }}
                           className={`cursor-pointer rounded-b-lg p-2 bg-red-500`}
                         >
                           <p className="text-xs text-white text-center">Disable</p>
                         </div>
                        )}
                        {data?.status === "1" && (
                          <div
                            className="cursor-pointer underline text-green-500 text-xs "
                            onClick={() =>
                              handleStatus(data?.service_code, "activate")
                            }
                          >
                            Enable
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))
            )}
          </InfiniteScroll>
        </div>
      </div>
    </div>
  );
};

export default Services;
