import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SuccessModal from "./SuccessModal";
import Confetti from "react-confetti";
import LanguageModal from "./LanguageModal";
import { Maintenance } from "./Maintenance";
import { MerchantListings } from "./Listings";
import { bankInfo, getHome, getListing } from "../../api/merchant";
import { getEventStatus } from "../../utils/common";
import { Reservations } from "./Reservations";
import { Payouts } from "./Payouts";

import { useEffect, useState } from "react";
import BeatLoader from "react-spinners/BeatLoader";
import { updateSelectedListing } from "../../store/slicers/listingSlice";
import { toast } from "react-hot-toast";
import { PaymentDetails } from "./PaymentDetails";
import {
  updateMerchantPaymentModal,
  updatePaymentModal,
} from "../../store/slicers/authSlice";

const MerchantMenu = () => {
  const buttons = ["Current event", "Upcoming"];
  const [state, setState] = useState(0);
  const navigate = useNavigate();
  const merchant = useSelector(
    (state) => state.auth.account_info.merchant_info
  );
  const firstName = useSelector((state) => state.auth.account_info.firstname);
  const dispatch = useDispatch();
  const [showListing, setShowlisting] = useState(false);
  const [showReservations, setShowReservations] = useState(false);
  const [showMerchantPayout, setShowMerchantPayout] = useState(false);
  const showWelcome = useSelector((state) => state.auth.showWelcome);
  const showLanguage = useSelector((state) => state.auth.showLanguage);
  const selectedListing = useSelector((state) => state.listing.selectedListing);
  const showPayment = useSelector((state) => state.auth.showPayment);
  const sRowPerPage = 30;
  const [currentEvent, setCurrentEvent] = useState();
  const [pendingEvent, setPendingEvent] = useState();
  const [upcomingEvent, setUpcomingEvent] = useState();
  const [cancelledEvent, setCancelledEvent] = useState();
  const [bLoading, setLoading] = useState(false);
  const [bankDetails, setBankDetails] = useState({});
  const [showMaintenance, setShowMaintenance] = useState(false);

  const filterEvent = async () => {
    if (bLoading) {
      return;
    }

    setLoading(true);
    const requestData = {
      service_code: selectedListing?.service_code,
    };

    try {
      const response = await getHome(requestData);
      if (response.error === 0) {
        setCurrentEvent(response.data.current_event);
        setPendingEvent(response.data.pending_event);
        setUpcomingEvent(response.data.upcoming_event);
        setCancelledEvent(response.data.cancelled_event);
        setLoading(false);
      } else {
        setCurrentEvent([]);
        setPendingEvent([]);
        setUpcomingEvent([]);
        setCancelledEvent([]);
        setLoading(false);
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  useEffect(() => {
    handleListings();
    handleInit();
  }, []);

  const handleInit = async () => {
    const _requestData = { flag: "init" };
    const response = await bankInfo(_requestData);
    if (response.error === 0) {
      setBankDetails(response.data.bank_info);
    }
  };

  const handleListings = async () => {
    const requestData = {
      page_no: 1,
      row_per_page: 1,
    };

    try {
      const response = await getListing(requestData);
      if (response.error === 0) {
        if (response.data.pagination.current_page === 1) {
          if (response.data.services_list.length !== 0 && !selectedListing) {
            dispatch(updateSelectedListing(response.data.services_list[0]));
          }
        }
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  useEffect(() => {
    filterEvent();
  }, [selectedListing]);

  const handleClick = (event, status) => {
    if (state === 2) {
      if (status !== "Expired") {
      }
    }
  };

  const renderComponent = (event, key) => {
    return (
      <div key={key} className=" w-80 border rounded-lg shadow-xl">
        <div onClick={() => handleClick(event, event.note)}>
          <p
            className={`p-5 ${
              event.note === "Expired" ? "text-red-500" : "text-sunrise-500"
            }`}
          >
            {event.note}
          </p>
          <div className="p-5 flex flex-row justify-between">
            <div className="flex flex-col">
              <p>{event.client_name}</p>
              <p>{event.start_date}</p>
              <p>{event.location}</p>
            </div>
            <div className="w-12 h-12 rounded-full bg-sunrise-500"></div>
          </div>
        </div>
        <div
          className={`${
            event.note === "Expired" && "hidden"
          } cursor-pointer rounded-b-lg p-2 bg-sunrise-500`}
        >
          <p className="text-white text-center">Message</p>
        </div>
      </div>
    );
  };

  return (
    <div className="h-screen align-center items-center">
      {!showWelcome && !showLanguage && (
        <div className="grid grid-cols-12 h-full">
          <div className="flex rounded-xl flex-col p-10 h-full menubg w-full h-full bg-no-repeat bg-cover bg-primary-500 col-span-12 lg:col-span-5">
            <div className="h-full flex flex-col gap-10 mt-20">
              <div className="font-semibold text-6xl text-white">
                <h1>Hello,</h1>
                <h1>{firstName}</h1>
                <p className="text-xl mt-10 text-white">
                  {merchant?.merchant_name}
                </p>
                {merchant?.status === "2" && (
                  <p className="text-xl mt-1 text-white ">
                    {selectedListing?.title +
                      ` 
                  ${selectedListing?.status === "1" ? "(Inactive)" : ""} 
                  ${selectedListing?.status === "9" ? "(For Approval)" : ""}` ||
                      "-"}
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-4 col-span-12 lg:col-span-7 py-10 px-2 lg:px-10 text-sunrise-500">
            <div
              onClick={() => {
                if (merchant?.status !== "1") {
                  navigate("/merchant/listing");
                } else {
                  toast.dismiss();
                  toast(
                    "Your application is now subject for verification. Please wait for confirmation"
                  );
                }
              }}
              className="cursor-pointer hover:bg-sunrise-500 hover:text-white w-full lg:w-96  border border-sunrise-500 p-5 rounded-lg"
            >
              Update Event listings
            </div>
            {/* <div
              onClick={() => {
                if (merchant?.status !== "1") {
                  setShowReservations(true);
                } else {
                  toast.dismiss();
                  toast(
                    "Your application is now subject for verification. Please wait for confirmation"
                  );
                }
              }}
              className="cursor-pointer hover:bg-sunrise-500 hover:text-white w-full lg:w-96  border border-sunrise-500 p-5 rounded-lg"
            >
              Reservations
            </div> */}
            <div
              onClick={() => {
                if (merchant?.status !== "1") {
                  navigate("/merchant/listing/new");
                } else {
                  toast.dismiss();
                  toast(
                    "Your application is now subject for verification. Please wait for confirmation"
                  );
                }
              }}
              className="cursor-pointer hover:bg-sunrise-500 hover:text-white w-full lg:w-96  border border-sunrise-500 p-5 rounded-lg"
            >
              Create a new listing
            </div>
            <div
              onClick={() => {
                dispatch(updatePaymentModal(true));
              }}
              className="cursor-pointer hover:bg-sunrise-500 hover:text-white w-full lg:w-96  border border-sunrise-500 p-5 rounded-lg"
            >
              Payout Details
            </div>
            {/* <div
              onClick={() => {
                setShowMerchantPayout(true);
              }}
              className="cursor-pointer hover:bg-sunrise-500 hover:text-white w-full lg:w-96  border border-sunrise-500 p-5 rounded-lg"
            >
              Merchant Payouts
            </div> */}
            {/* <div
              onClick={() => {
                if (merchant?.status !== "1") {
                  navigate("/merchant/listing/new");
                } else {
                  toast.dismiss();
                  toast(
                    "Your application is now subject for verification. Please wait for confirmation"
                  );
                }
              }}
              className="cursor-pointer hover:bg-sunrise-500 hover:text-white w-full lg:w-96  border border-sunrise-500 p-5 rounded-lg"
            >
              Transaction history
            </div> */}
          </div>
        </div>
      )}
      {showMerchantPayout && (
        <Payouts
          selectedListing={selectedListing}
          isOpen={showMerchantPayout}
          onClose={() => {
            handleInit();
            setShowMerchantPayout(false);
          }}
        />
      )}
      {showMaintenance && (
        <Maintenance handleClose={() => setShowMaintenance(false)} />
      )}
      {showReservations && (
        <Reservations
          selectedListing={selectedListing}
          isOpen={showReservations}
          onClose={() => {
            handleInit();
            setShowReservations(false);
          }}
        />
      )}

      {showPayment && <PaymentDetails />}
    </div>
  );
};

export default MerchantMenu;
