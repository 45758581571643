import React, { useState } from "react";
import { BookingDetails } from "./BookingDetails";
import { ProfileDetails } from "./ProfileDetails";
import ContentLoader from "react-content-loader";
import { toast } from "react-hot-toast";
import dayjs from "dayjs";
import { bookingCancel, bookingConfirm } from "../../../api/merchant";
import Loader from "../../common/Loader";
import { CancelBooking } from "../../common/CancelBooking";
import { Modal } from "../../common/Modal";
import { ORImages } from "../../client/Inbox/ORImages";
import { ChatImages } from "../../client/Inbox/ChatImages";
import { useDispatch, useSelector } from "react-redux";
import { updateIsPaid } from "../../../store/slicers/listingSlice";
import InboxPhotos from "./Photos";

const Details = ({
  serviceOffered,
  bookingCode,
  resetMessages,
  chatDetails,
  bookingReceipt,
  chatImages,
  merchantChatImages,
}) => {
  const dispatch = useDispatch();
  const isPaid = useSelector((state) => state.listing.isPaid);

  const [showDetails, setShowDetails] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const [isAcceptLoading, setIsAcceptLoading] = useState(false);
  const [isDeclinedLoading, setIsDeclinedLoading] = useState(false);
  const [showCancel, setShowCancel] = useState(false);
  const [successCancel, setSuccessCancel] = useState(false);
  const [showReceipts, setShowReceipts] = useState(false);
  const [showImages, setShowImages] = useState(false);
  const [showUploadedImage, setShowUploadedImage] = useState(false);
  const [msg, setMsg] = useState("");
  const handleProfile = () => {
    setShowProfile(true);
  };
  const handleAcceptRequest = async () => {
    setIsAcceptLoading(true);
    const _requestData = {
      booking_code: bookingCode,
    };
    try {
      const response = await bookingConfirm(_requestData);
      if (response.error === 0) {
        setIsAcceptLoading(false);
        setMsg(response.msg);
        resetMessages();
      } else {
        setMsg(response.error_msg);
        setIsAcceptLoading(false);
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const handleCancelRequest = async (reason) => {
    setIsDeclinedLoading(true);
    const _requestData = {
      booking_code: bookingCode,
      reason,
    };
    try {
      const response = await bookingCancel(_requestData);
      if (response.error === 0) {
        setIsDeclinedLoading(false);
        setShowCancel(false);
        setSuccessCancel(true);
        resetMessages();
      } else {
        setSuccessCancel(false);
        toast(response.error_msg);
        setIsDeclinedLoading(false);
      }
    } catch (error) {
      setSuccessCancel(false);
      console.log("Error:", error);
    }
  };

  return (
    <div className="flex flex-col justify-between h-full p-5">
      {serviceOffered ? (
        <div className="flex flex-row lg:flex-col">
          <div className="hidden lg:block">
            <div className="border-b pb-3">
              <p className="font-semibold text-base">
                {serviceOffered?.full_name}
              </p>
              <p className="text-xs text-primary-500 cursor-pointer select-all">
                Booking Code: {bookingCode}
              </p>

              <p className=" text-xs text-gray-400">{serviceOffered?.title}</p>
              <p className=" text-xs text-gray-400">
                {serviceOffered?.remarks}
              </p>
              <p className=" text-xs text-gray-400">
                {serviceOffered?.location}
              </p>
              <p className="text-xs text-gray-400">
                {" "}
                {dayjs(serviceOffered?.start_date).format(
                  "MMM DD, YYYY h:MM A"
                )}{" "}
                {serviceOffered?.start_date !== serviceOffered?.end_date &&
                  "- " +
                    dayjs(serviceOffered?.end_date).format(
                      "MMM DD, YYYY h:MM A"
                    )}
              </p>
              <p className="text-xs text-gray-400">
                {serviceOffered?.pax} Guests - PHP{" "}
                {serviceOffered?.service_price}
              </p>

              {/* <p className="text-xs text-gray-400">
                Booking Status:
                <span
                  className={`mx-1 text-xs ${statusHandlerColor(
                    serviceOffered?.booking_status
                  )}`}
                >
                  {statusHandler(serviceOffered?.booking_status)}
                </span>
              </p>

              <p className="text-xs text-gray-400">
                Payment Status:
                <span
                  className={`mx-1 text-xs ${paymentStatusHandlerColor(
                    serviceOffered?.payment_status
                  )}`}
                >
                  {paymentStatusHandler(serviceOffered?.payment_status)}
                </span>
              </p> */}
            </div>

            {["0", "7"].includes(serviceOffered?.booking_status) && (
              <div
                onClick={
                  isDeclinedLoading || isAcceptLoading
                    ? () => {}
                    : () => handleAcceptRequest()
                }
                className="cursor-pointer border border-primary-500 p-3 rounded-md mt-4"
              >
                {isAcceptLoading ? (
                  <Loader borderColor="border-primary-500" />
                ) : (
                  <p className="text-xs text-primary-500">Accept Request</p>
                )}
              </div>
            )}
            <div
              onClick={() => setShowUploadedImage(true)}
              className="cursor-pointer border border-primary-500 p-3 rounded-md mt-4"
            >
              <p className="text-xs text-primary-500">Upload Photo</p>
            </div>
            <div
              onClick={() => setShowImages(true)}
              className="cursor-pointer border border-sunrise-500 p-3 rounded-md mt-4"
            >
              <p className="text-xs text-sunrise-500">See Client Upload</p>
            </div>
            {/* {serviceOffered?.happening_today === "1"
              ? null
              : ["0", "1", "2", "7"].includes(
                  serviceOffered?.booking_status
                ) && (
                  <div
                    onClick={() => {
                      if (serviceOffered?.payment_status === "0") {
                        setShowCancel(true);
                      } else {
                        dispatch(updateIsPaid(true));
                      }
                    }}
                    className="cursor-pointer border border-red-500 p-3 rounded-md mt-4"
                  >
                    {isDeclinedLoading ? (
                      <Loader borderColor="border-sunrise-500" />
                    ) : (
                      <p className="text-xs text-red-500">Cancel</p>
                    )}
                  </div>
                )} */}

            {/* {bookingReceipt?.length > 0 && (
              <div
                onClick={() => setShowReceipts(true)}
                className="cursor-pointer border border-orange-500 p-3 rounded-md mt-4"
              >
                <p className="text-xs text-orange-500">View Receipts</p>
              </div>
            )} */}

            <p
              onClick={() => handleProfile()}
              className="cursor-pointer py-5 border-b text-sm  text-gray-400 underline"
            >
              Show profile of {serviceOffered?.full_name}
            </p>

            <div className="mt-5 text-sm font-semibold">
              <p>Potential Income</p>
            </div>

            <div>
              <div className="text-sm font-gray-400 mt-2 flex flex-row justify-between">
                <p>Contract Price</p>
                <p>{chatDetails?.potential_income?.contract_price}</p>
              </div>
              {/* {chatDetails?.potential_income?.merchant_service_fee && (
                <div className="text-sm font-gray-400 mt-2 flex flex-row justify-between">
                  <p>Merchant Service Fee</p>
                  <p>-{chatDetails?.potential_income?.merchant_service_fee}</p>
                </div>
              )} */}
              <div className="text-sm font-gray-400 mt-2 flex flex-row justify-between">
                <p>Surcharge</p>
                <p>{chatDetails?.potential_income?.surcharge}</p>
              </div>
              <div className="my-5 border-t"></div>
              <div className="text-sm font-semibold border-b pb-5 flex flex-row justify-between">
                <p>Total Amount</p>
                <p>{chatDetails?.potential_income?.total_amount}</p>
              </div>
            </div>

            {["44", "33"].includes(serviceOffered?.booking_status) && (
              <div className="mt-4">
                <p className="text-sm text-gray-500">
                  Cancellation request is now subject for verification. Please
                  wait for confirmation.
                </p>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="hidden lg:block">
          <ContentLoader
            speed={2}
            width={4000}
            height={460}
            viewBox="0 0 4000 460"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
          >
            <rect x="3" y="211" rx="2" ry="2" width="237" height="17" />
            <rect x="3" y="29" rx="2" ry="2" width="314" height="164" />
            <rect x="3" y="344" rx="0" ry="0" width="0" height="6" />
            <rect x="3" y="319" rx="0" ry="0" width="301" height="105" />
            <rect x="3" y="236" rx="2" ry="2" width="237" height="17" />
            <rect x="3" y="267" rx="2" ry="2" width="237" height="33" />
          </ContentLoader>
        </div>
      )}

      <div className="pb-28 hidden lg:block">
        <div className="py-5 items-center">
          <div className="flex flex-row mt-2 justify-between">
            <div className="w-12 h-12 mr-5">
              <div
                className="icon-anyaya h-full w-full "
                style={{ backgroundPositionY: "center" }}
              />
            </div>
            <div className="text-xs w-60 italic">
              To avoid a scam, never communicate outside of the Anyaya Website.
            </div>
          </div>
        </div>
      </div>

      {showDetails && (
        <BookingDetails
          serviceOffered={serviceOffered}
          handleOnClose={() => setShowDetails(false)}
          id={bookingCode}
        />
      )}

      {showProfile && (
        <ProfileDetails
          accountCode={serviceOffered?.account_code}
          handleOnClose={() => setShowProfile(false)}
        />
      )}
      {showCancel && (
        <CancelBooking
          onClose={() => setShowCancel(false)}
          handleCancel={(reason) => handleCancelRequest(reason)}
          isLoading={isDeclinedLoading}
        />
      )}
      {showReceipts && (
        <ORImages
          setShowReceipts={() => setShowReceipts(false)}
          receipts={bookingReceipt}
        />
      )}
      {showUploadedImage && (
        <InboxPhotos
          handleOnClose={() => setShowUploadedImage(false)}
          uploadedPhotos={merchantChatImages}
          id={bookingCode}
          location="merchant"
          resetMessages={() => resetMessages()}
        />
      )}
      {showImages && (
        <ChatImages
          setShowImages={() => setShowImages(false)}
          header="Client Upload"
          images={chatImages}
        />
      )}
      {msg && (
        <Modal onClose={() => setMsg("")} title={"Success"} description={msg} />
      )}

      {successCancel && (
        <Modal
          onClose={() => setSuccessCancel(false)}
          title={"Cancelled"}
          description={
            "You cancelled client's requested/reserved/confirmed booking. This may have an effect on your upcoming payouts. Please give our team 24 hours to review this cancellation."
          }
        />
      )}
      {isPaid && (
        <Modal
          onClose={() => {
            dispatch(updateIsPaid(false));
          }}
          title={""}
          description="Service cancellation after full payment is not permitted. Please reach out to us if you still need to cancel. Thank you.
          "
        />
      )}
    </div>
  );
};

export default Details;
