import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { validateEmail, validatePhoneNumber } from "../../../../utils/common";
import MetaTags from "../../../common/MetaTags";
import { Breadcrumbs } from "../../../common/Breadcrumbs";
import Error from "../../../../assets/svg/Error";
import Loader from "../../../common/Loader";
import {
  accountVerification,
  updateMobileLogin,
  updateProfile,
} from "../../../../api/client";
import { OTP } from "../LoginSecurity/OTP";
import { merchantAccountUpdate } from "../../../../api/merchant";
import Close from "../../../../assets/svg/Close";
import { merchantInfo } from "../../../../api/client/listing";
import { toast } from "react-hot-toast";
import Helmet from "react-helmet";
import { UpdatePicture } from "../../../common/Auth/UpdatePicture";
const PersonalInfo = () => {
  const dispatch = useDispatch();
  const {
    firstname: firstName,
    lastname: lastName,
    M: mobileNumber,
    birthdate,
    email_address: emailAddress,
  } = useSelector((state) => state.auth.account_info);
  const isMerchant = useSelector(
    (state) => state.auth.account_info.merchant_info
  );
  const [isOtpOpen, setOTPModal] = useState(false);
  const [editInput, setEditInput] = useState(null); // Use string to represent the field being edited

  const [firstname, setFirstName] = useState(firstName);
  const [lastname, setLastName] = useState(lastName);
  const [email, setEmail] = useState(emailAddress === "" ? "" : emailAddress);
  const [id, setId] = useState("");
  const [idStatus, setIdStatus] = useState("0");
  const [phone, setPhoneNumber] = useState(mobileNumber);
  const [merchantName, setMerchantName] = useState(isMerchant?.merchant_name);

  const [error, setError] = useState("");

  // Temporary state for storing changes
  const [tempFirstname, setTempFirstname] = useState(firstname);
  const [tempLastname, setTempLastname] = useState(lastname);
  const [tempEmail, setTempEmail] = useState(email);
  const [tempPhone, setTempPhone] = useState(phone);
  const [tempMerchantName, setTempMerchantName] = useState(merchantName);
  const [tempId, setTempId] = useState(
    "Not provided (upload a valid ID to book events)"
  );

  const [isLoadingName, setIsLoadingName] = useState(false);
  const [isLoadingEmail, setIsLoadingEmail] = useState(false);
  const [isLoadingPhone, setIsLoadingPhone] = useState(false);
  const [isLoadingId, setIsLoadingId] = useState(false);
  const [isLoadingBusiness, setIsLoadingBusiness] = useState(false);

  const [permitFiles, setPermitFiles] = useState([]);
  const [permitPreviews, setPermitPreviews] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);

  const [merchantUpdateStatus, setMerchantUpdateStatus] = useState("0");
  const [updateProfilePicture, setProfilePicture] = useState(false);
  const [permits, setPermits] = useState([]);

  const profileImage = useSelector(
    (state) => state.auth.account_info.profile_img
  );
  const initMerchantProfile = async () => {
    const _requestData = {
      merchant_code: isMerchant?.merchant_code,
    };
    try {
      const response = await merchantInfo(_requestData);
      if (response.error === 0) {
        setPermitPreviews(response?.data?.merchant_application);
        setPermits(response?.data?.merchant_application);
        setMerchantUpdateStatus(response?.data?.merchant?.status);
      }
    } catch (error) {}
  };
  const handlePermitFileChange = (e) => {
    const files = e.target.files;

    if (files) {
      const newFiles = Array.from(files);
      setPermitFiles([...permitFiles, ...newFiles]);

      const newPreviews = Array.from(files).map((file) =>
        URL.createObjectURL(file)
      );
      setPermitPreviews([...permitPreviews, ...newPreviews]);
    }
  };

  const removePermitPreview = (index) => {
    const updatedPreviews = permitPreviews.filter((_, i) => i !== index);

    const updatedFiles = permitFiles.filter((_, i) => i !== index);
    setPermitFiles(updatedFiles);

    setPermitPreviews(updatedPreviews);
  };

  const renderPermitPreviews = () => {
    return permitPreviews.map((preview, index) => {
      const foundPermit = permits.find((permit) => permit === preview);
      return (
        <div key={index} className="relative">
          <img
            src={preview}
            alt={`Permit ${index + 1}`}
            onClick={() => setSelectedImage(permitPreviews[index])}
            className="mt-3 h-40 w-40 border rounded-lg bg-no-repeat bg-center bg-contain"
          />
          {!foundPermit && (
            <button
              onClick={() => removePermitPreview(index)}
              className="absolute top-0 right-0 mt-4 mr-1  bg-red-500 text-white rounded-full text-xs"
            >
              <Close className="w-4 h-4" />
            </button>
          )}
        </div>
      );
    });
  };

  useEffect(() => {
    handleInitId();
    initMerchantProfile();
  }, []);

  useEffect(() => {
    setFirstName(firstName);
    setLastName(lastName);
    setEmail(emailAddress === "" ? "" : emailAddress);
    setPhoneNumber(mobileNumber);
  }, [firstName, lastName, mobileNumber, birthdate, emailAddress]);

  const handleSaveName = async () => {
    if (tempFirstname === "" || tempLastname === "") {
      setError("Full legal name is required.");
    } else {
      setFirstName(tempFirstname);
      setLastName(tempLastname);
      setIsLoadingName(true);
      const _requestData = {
        act: "profile",
        flag: "legal_name",
        firstname: tempFirstname,
        lastname: tempLastname,
      };
      const response = await updateProfile(_requestData);

      if (response.error === 0) {
        setIsLoadingName(false);
        setTempFirstname("");
        setTempLastname("");
        setEditInput(null);
      } else {
        setIsLoadingName(false);
        setError(response.error_msg);
      }
    }
  };

  const handleSaveEmail = async () => {
    if (!validateEmail(tempEmail)) {
      setError("Invalid email address.");
    } else {
      setEmail(tempEmail);
      setIsLoadingEmail(true);
      const _requestData = {
        act: "profile",
        flag: "email",
        email: tempEmail,
      };

      const response = await updateProfile(_requestData);

      if (response.error === 0) {
        setIsLoadingEmail(false);
        setTempEmail("");
        setEditInput(null);
      } else {
        setIsLoadingEmail(false);
        setError(response.error_msg);
      }
    }
  };

  const handleSaveMerchantName = async () => {
    if (tempMerchantName === "") {
      setError("Invalid business name.");
    } else {
      if (permitFiles.length === 0) {
        toast("Please upload business permits.");
        return;
      }
      setMerchantName(tempMerchantName);
      setIsLoadingBusiness(true);
      const _requestData = {
        merchant_name: tempMerchantName,
        doc_file: permitFiles,
      };

      const response = await merchantAccountUpdate(_requestData);

      if (response.error === 0) {
        setIsLoadingBusiness(false);
        setTempMerchantName("");
        setEditInput(null);
        toast(response.msg);
      } else {
        setIsLoadingBusiness(false);
        setError(response.error_msg);
      }
    }
  };

  const closeMobile = () => {
    setTempPhone(phone);
    setIsLoadingPhone(false);
    setTempEmail("");
    setEditInput(null);
    setOTPModal(false);
  };
  const handleSavePhone = async () => {
    setIsLoadingPhone(true);
    setError("");
    const _requestData = {
      flag: "init",
      mobile_no: tempPhone,
    };

    const response = await updateMobileLogin(_requestData);

    if (response.error === 0) {
      setIsLoadingPhone(false);
      setOTPModal(true);
    } else {
      setIsLoadingPhone(false);
      setError(response.error_msg);
    }
  };
  const handleInitId = async () => {
    const _requestData = {
      flag: "init",
    };

    const response = await accountVerification(_requestData);
    if (response.error === 0) {
      setId(response.msg || "Submit your ID for verification");
      setIdStatus(response.data.status);
    }
  };

  const handleSaveId = async () => {
    if (typeof tempId === "string" || tempId === undefined) {
      setError("Invalid Identification.");
    } else {
      setId(
        "Your account is now subject for verification. Please wait for confirmation."
      );
      const _requestData = {
        flag: "save",
        id_photo: tempId,
      };
      setIsLoadingId(true);
      const response = await accountVerification(_requestData);
      if (response.error === 0) {
        setIsLoadingId(false);
        setTempId("");
        setEditInput(null);
      } else {
        setIsLoadingId(false);
        setError(response.error_msg);
      }
    }
  };

  const handleEdit = (field) => {
    // Set temporary state variables with current values from the main state
    setError("");
    if (field === "name") {
      setTempFirstname(firstname);
      setTempLastname(lastname);
    } else if (field === "email") {
      setTempEmail(email);
    } else if (field === "phone") {
      setTempPhone(phone);
    } else if (field === "id") {
      setTempId(tempId);
    } else if (field === "merchant_name") {
      setTempMerchantName(merchantName);
    }

    // Enter edit mode for the specified field
    setEditInput(field);
  };

  const handleIdFileChange = (e) => {
    const file = e.target.files?.[0];
    if (file) {
      setTempId(file);
    }
  };

  return (
    <div className="px-10  align-center items-center">
      <Helmet>
        <MetaTags
          title="Account Settings - Personal Information"
          description=""
          keywords=""
        />
      </Helmet>
      <Breadcrumbs location="account/personal-info" />
      <div className="text-2xl font-semibold text-black">
        Personal Information
      </div>

      <div id="user" className="mt-10 border-b pb-3">
        <div className="flex flex-row justify-between">
          <div className="text-xs font-black font-semibold mb-2">
            Profile Photo
          </div>
          <div
            onClick={() => {
              setProfilePicture(true);
            }}
            className="font-black text-xs underline cursor-pointer"
          >
            Edit
          </div>
        </div>
        <div className="w-20 h-20">
          {profileImage ? (
            <div
              className="rounded-full bg-primary-500 w-full h-full bg-no-repeat bg-cover"
              style={{ backgroundImage: `url(${profileImage})` }}
            ></div>
          ) : (
            <div className="rounded-full defaulticon w-full h-full bg-no-repeat bg-cover"></div>
          )}
        </div>
      </div>

      <div id="name" className="mt-5">
        <div className="flex flex-row justify-between border-b pb-3">
          <div className="flex flex-col">
            <div className="flex flex-col text-xs font-black font-semibold mb-2">
              <span>Legal name</span>
              {editInput === "name" && (
                <span className="text-gray-400 font-normal mt-1">
                  Make sure it matches the name on your government ID.
                </span>
              )}
            </div>
            <div className="text-xs">
              {editInput === "name" ? (
                <>
                  <div className="flex w-60  lg:w-96 flex-row">
                    <div
                      className={`w-full  items-center p-2 border mr-2 ${
                        error && "border-red-500"
                      } rounded-xl text-sm`}
                    >
                      <label
                        htmlFor="firstname"
                        className="px-2 text-xs text-gray-400"
                      >
                        First Name
                      </label>
                      <div className="flex">
                        <input
                          autoFocus={true}
                          className="resize-none border-none px-2 outline-none w-full bg-transparent"
                          id="firstname"
                          type="text"
                          placeholder="First Name"
                          value={tempFirstname}
                          onChange={(e) => setTempFirstname(e.target.value)}
                        />
                      </div>
                    </div>
                    <div
                      className={`w-full  items-center p-2 border mr-2 ${
                        error && "border-red-500"
                      } rounded-xl text-sm`}
                    >
                      <label
                        htmlFor="lastname"
                        className="px-2 text-xs text-gray-400"
                      >
                        Last Name
                      </label>
                      <div className="flex">
                        <input
                          autoFocus={true}
                          className="resize-none border-none px-2 outline-none w-full bg-transparent"
                          id="lastname"
                          type="text"
                          placeholder="Last Name"
                          value={tempLastname}
                          onChange={(e) => setTempLastname(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  {error && (
                    <span className="text-xs text-red-500 flex flex-row item-center">
                      <Error className="w-3 mr-1 self-center" />
                      {error}
                    </span>
                  )}
                </>
              ) : typeof firstname === "string" &&
                typeof lastname === "string" ? (
                `${firstname} ${lastname}`
              ) : (
                "-"
              )}
            </div>
          </div>
          <div
            className="font-black text-xs underline cursor-pointer"
            onClick={() =>
              isLoadingName
                ? {}
                : editInput === "name"
                ? handleSaveName()
                : handleEdit("name")
            }
          >
            {isLoadingName ? (
              <Loader borderColor="border-primary-500" />
            ) : editInput === "name" ? (
              "Save"
            ) : (
              "Edit"
            )}
          </div>
        </div>
      </div>

      <div id="email" className="mt-5">
        <div className="flex flex-row justify-between border-b pb-3">
          <div className="flex flex-col">
            <div className="flex flex-col text-xs font-black font-semibold mb-2">
              <span>Email Address</span>
              {editInput === "email" && (
                <span className="text-gray-400 font-normal mt-1">
                  Use an address you’ll always have access to.
                </span>
              )}
            </div>
            <div className="text-xs">
              {editInput === "email" ? (
                <>
                  <div className="flex flex-row">
                    <div
                      className={` w-60  lg:w-96 flex items-center p-2 border mr-2 ${
                        error && "border-red-500"
                      } rounded-xl text-sm`}
                    >
                      <input
                        autoFocus={true}
                        placeholder="Email Address"
                        className="resize-none border-none outline-none w-full  p-2 bg-transparent"
                        value={tempEmail}
                        onChange={(e) => setTempEmail(e.target.value)}
                      />
                    </div>
                  </div>
                  {error && (
                    <span className="text-xs text-red-500 flex flex-row item-center">
                      <Error className="w-3 mr-1 self-center" />
                      {error}
                    </span>
                  )}
                </>
              ) : typeof email === "string" ? (
                email
              ) : (
                "-"
              )}
            </div>
          </div>
          <div
            className="font-black text-xs underline cursor-pointer"
            onClick={() =>
              isLoadingEmail
                ? {}
                : editInput === "email"
                ? handleSaveEmail()
                : handleEdit("email")
            }
          >
            {isLoadingEmail ? (
              <Loader borderColor="border-primary-500" />
            ) : editInput === "email" ? (
              "Save"
            ) : (
              "Edit"
            )}
          </div>
        </div>
      </div>

      <div className="mt-5">
        <div className="flex flex-row justify-between border-b pb-3">
          <div className="flex flex-col">
            <div className="flex flex-col text-xs font-black font-semibold mb-2">
              <span>Contact Number</span>
              {editInput === "phone" && (
                <span className="font-normal">
                  For notifications, reminders, and help logging in
                </span>
              )}
            </div>
            <div className="text-xs">
              {editInput === "phone" ? (
                <>
                  <div className="flex flex-row">
                    <div
                      className={`w-60 lg:w-96 flex items-center p-2 border ${
                        error && "border-red-500"
                      } rounded-xl text-sm`}
                    >
                      <span>+63</span>
                      <input
                        autoFocus={true}
                        value={tempPhone}
                        onChange={(e) => {
                          setTempPhone(validatePhoneNumber(e.target.value));
                        }}
                        className="resize-none border-none  outline-none  w-96 p-2 bg-transparent"
                        placeholder="Enter your contact number"
                      />
                    </div>
                  </div>
                  {error && (
                    <span className="text-xs text-red-500 flex flex-row item-center">
                      <Error className="w-3 mr-1 self-center" />
                      {error}
                    </span>
                  )}
                </>
              ) : phone === "" ? (
                "Not provided"
              ) : (
                <div className="text-xs">
                  <span>+63</span>{" "}
                  <span>
                    {typeof phone === "string" ? phone : "-"}
                  </span>
                </div>
              )}
            </div>
          </div>
          <div
            className="font-black text-xs underline cursor-pointer"
            onClick={() =>
              isLoadingPhone
                ? {}
                : editInput === "phone"
                ? tempPhone === phone
                  ? handleSavePhone()
                  : handleSavePhone()
                : handleEdit("phone")
            }
          >
            {isLoadingPhone ? (
              <Loader borderColor="border-primary-500" />
            ) : editInput === "phone" ? (
              tempPhone === phone ? (
                "Close"
              ) : (
                "Verify"
              )
            ) : (
              "Edit"
            )}
          </div>
        </div>
      </div>
      <div className="mt-5">
        <div className="flex flex-row justify-between border-b pb-3">
          <div className="flex flex-col">
            <div className="text-xs font-black font-semibold mb-2">
              Government ID
            </div>
            <div className="text-xs">
              {editInput === "id" ? (
                <>
                  <div className="flex flex-row">
                    <div
                      className={` w-60  lg:w-96 flex items-center p-2 border mr-2 ${
                        error && "border-red-500"
                      } rounded-xl text-sm`}
                    >
                      <input
                        type="file"
                        accept="image/*"
                        className="mt-2"
                        onChange={handleIdFileChange}
                      />
                    </div>
                  </div>
                  {error && (
                    <span className="text-xs text-red-500 flex flex-row item-center">
                      <Error className="w-3 mr-1 self-center" />
                      {error}
                    </span>
                  )}
                </>
              ) : (
                `${id}`
              )}
            </div>
          </div>
          {idStatus !== "1" && idStatus !== "3" && (
            <div
              className="font-black text-xs underline cursor-pointer"
              onClick={() =>
                editInput === "id" ? handleSaveId() : handleEdit("id")
              }
            >
              {editInput === "id" ? "Save" : "Edit"}
            </div>
          )}
        </div>
      </div>

      {/* MERCHANT */}
      {isMerchant && (
        <>
          <div id="merchant_name" className="mt-5">
            <div className="flex flex-row justify-between  pb-3">
              <div className="flex flex-col">
                <div className="flex flex-col text-xs font-black font-semibold mb-2">
                  <span>Business Name</span>
                  {editInput !== "merchant_name" &&
                  merchantUpdateStatus === "9" ? (
                    <span className="text-gray-400 font-normal mt-1">
                      Pending Approval
                    </span>
                  ) : null}

                  {editInput === "merchant_name" && (
                    <span className="text-gray-400 font-normal mt-1">
                      Subject for approval (update your business permits)
                    </span>
                  )}
                </div>
                <div className="text-xs">
                  {editInput === "merchant_name" ? (
                    <div>
                      <div className="flex flex-row">
                        <div
                          className={` w-60  lg:w-96 flex items-center p-2 border mr-2 ${
                            error && "border-red-500"
                          } rounded-xl text-sm`}
                        >
                          <input
                            autoFocus={true}
                            placeholder="Business Name"
                            className="resize-none border-none outline-none w-full  p-2 bg-transparent"
                            value={tempMerchantName}
                            onChange={(e) =>
                              setTempMerchantName(e.target.value)
                            }
                          />
                        </div>
                      </div>
                      {error && (
                        <span className="text-xs text-red-500 flex flex-row item-center">
                          <Error className="w-3 mr-1 self-center" />
                          {error}
                        </span>
                      )}
                    </div>
                  ) : typeof merchantName === "string" ? (
                    merchantName
                  ) : (
                    "-"
                  )}
                </div>
              </div>
              {merchantUpdateStatus !== "9" && (
                <div
                  className="font-black text-xs underline cursor-pointer"
                  onClick={() => {
                    if (!isLoadingBusiness) {
                      if (editInput === "merchant_name") {
                        if (merchantName === tempMerchantName) {
                          setIsLoadingBusiness(false);
                          setTempMerchantName("");
                          setEditInput(null);
                        } else {
                          handleSaveMerchantName();
                        }
                      } else {
                        handleEdit("merchant_name");
                      }
                    }
                  }}
                >
                  {isLoadingBusiness ? (
                    <Loader borderColor="border-primary-500" />
                  ) : editInput === "merchant_name" ? (
                    merchantName === tempMerchantName ? (
                      "Close"
                    ) : (
                      "Save"
                    )
                  ) : (
                    "Edit"
                  )}
                </div>
              )}
            </div>
          </div>
          <div className={`w-full items-center text-sm`}>
            <div className="flex flex-col text-xs font-black font-semibold mb-2">
              <span>Business Permit</span>
            </div>
            <div className="flex flex-row">
              {editInput && (
                <label
                  htmlFor="permit"
                  className="mr-2 cursor-pointer border border-primary-500 rounded-lg mr-2 p-2"
                >
                  <p className="px-2 cursor-pointer text-xs text-primary-500">
                    Upload
                  </p>
                </label>
              )}
              <label className="hidden lg:flex  self-center text-xs text-gray-400">
                eg. DTI, Barangay, Mayor's, BIR
              </label>
            </div>
            <div className="flex">
              <input
                id="permit"
                type="file"
                accept="image/*"
                className="mt-2 hidden"
                onChange={handlePermitFileChange}
                multiple
              />
            </div>

            <div className="flex justify-start flex-wrap gap-4">
              {renderPermitPreviews()}
            </div>
          </div>
        </>
      )}

      {isOtpOpen && <OTP phone={tempPhone} closeMobile={() => closeMobile()} />}
      {selectedImage && (
        <div className="fixed inset-0 flex items-center justify-center z-50 text-gray-500">
          <div
            onClick={() => setSelectedImage(null)}
            className="fixed inset-0 bg-black opacity-50"
          ></div>
          <div className="bg-white rounded-3xl relative py-6 mx-4 w-500">
            <div className="modal-content">
              <img src={selectedImage} alt="Selected ID" />
            </div>
          </div>
        </div>
      )}
      {updateProfilePicture && (
        <UpdatePicture setProfilePicture={() => setProfilePicture(false)} />
      )}
    </div>
  );
};

export default PersonalInfo;
