import Close from "../../assets/svg/Close";
import { useNavigate } from "react-router-dom";
export const Modal = ({ onClose, title, description }) => {
  const navigate = useNavigate();

  const handleOnClose = () => {
    onClose();
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 text-gray-500">
      <div
        onClick={handleOnClose}
        className="fixed inset-0 bg-black opacity-50"
      ></div>
      <div className="bg-white p-6 mx-4 rounded-3xl relative w-500">
        {/* <button
          className="absolute top-6 left-6 text-gray-400 hover:text-gray-600"
          onClick={handleOnClose}
        >
          <Close className="w-4 h-4" />
        </button> */}

        <div id="report_3" className="h-auto flex items-center">
          <div className=" w-full my-5 text-center">
            <div className="w-40 h-12 mx-auto">
              <div
                className="logo h-full w-full "
                style={{ backgroundPositionY: "center" }}
              />
            </div>
            {/* <div className={`text-xl ${title === "Error" ? "text-red-500": "text-primary-500"} font-semibold`}>
              {title}
            </div> */}
            <div
              className={`${
                title === "Error"
                  ? "text-red-500"
                  : title === "Cancelled"
                  ? "text-gray-500"
                  : "text-primary-500"
              } text-base mt-10`}
            >
              {description}
            </div>
          </div>
        </div>

        <div className="flex flex-row justify-end">
          <button
            className="mt-4 bg-primary-500 px-10 text-white font-semibold py-3 rounded-full hover:opacity-90"
            onClick={() => {
              handleOnClose();
              if (title !== "Error" && title !== "Cancelled") {
                navigate("/merchant/home");
              }
            }}
          >
            <p className="text-sm">Ok</p>
          </button>
        </div>
      </div>
    </div>
  );
};
