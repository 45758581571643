import { availability, getListing, surcharge } from "../../api/merchant";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PrimaryButton } from "../common/PrimaryButton";
import { ModalHeader } from "../common/ModalHeader";
import dayjs from "dayjs";
import { toast } from "react-hot-toast";
import { updateSelectedListing } from "../../store/slicers/listingSlice";
import { MerchantListings } from "./Listings";
import Close from "../../assets/svg/Close";
import { numberOnly, validateMoney } from "../../utils/common";
import Loader from "../common/Loader";
import { Calendar } from "react-multi-date-picker";

export function MerchantCalendar() {
  const [unavailable, setUnavailable] = useState([]);
  const [serviceInfo, setServiceInfo] = useState({});
  const [incomingSurcharge, setSurcharge] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [showListing, setShowlisting] = useState(false);
  const [selectedInfo, setSelectInfo] = useState(null);
  const [activeMonth, setActiveMonth] = useState(dayjs(new Date()).format("M"));
  const [activeYear, setActiveYear] = useState(
    dayjs(new Date()).format("YYYY")
  );
  const [activeSurcharge, setActiveSurcharge] = useState("");
  const [surchargePrice, setSurchargePrice] = useState("");
  const [surchargeInput, handleSurchargeInput] = useState(activeSurcharge);
  const [editSurcharge, setEditSurcharge] = useState(false);
  const [isSurchargeLoading, setSurchargeLoading] = useState(false);
  const merchant = useSelector(
    (state) => state.auth.account_info.merchant_info
  );

  const dispatch = useDispatch();

  const selectedListing = useSelector((state) => state.listing.selectedListing);

  useEffect(() => {
    handleListings();
  }, []);

  useEffect(() => {
    handleInitAvailability();
    handleInitSurcharge();
  }, [selectedListing]);

  const handleListings = async () => {
    const requestData = {
      page_no: 1,
      row_per_page: 1,
    };

    try {
      const response = await getListing(requestData);
      if (response.error === 0) {
        if (response.data.pagination.current_page === 1) {
          if (response.data.services_list.length !== 0 && !selectedListing) {
            dispatch(updateSelectedListing(response.data.services_list[0]));
          }
        }
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const handleInitSurcharge = async () => {
    const _requestData = {
      flag: "init",
      service_code: selectedListing?.service_code,
    };

    const response = await surcharge(_requestData);
    if (response.error === 0) {
      setServiceInfo(response.data.service_info);
      setSurcharge(response.data.incoming_surcharge);
    }
  };

  const handleInitAvailability = async () => {
    const _requestData = {
      flag: "init",
      service_code: selectedListing?.service_code,
    };

    const response = await availability(_requestData);
    if (response.error === 0) {
      setUnavailable(response.data.unavailable_dates);
    }
  };

  const handleDateSelect = (selectInfo) => {
    if (merchant?.status !== "1") {
      setModalOpen(true);
      setSelectInfo(selectInfo);
    } else {
    }
  };

  const handleSurcharge = async () => {
    setSurchargeLoading(true);
    const _requestData = {
      flag: "save",
      service_code: selectedListing?.service_code,
      month: activeMonth === 0 ? "12" : activeMonth,
      year: activeYear,
      surcharge: surchargeInput,
    };

    const response = await surcharge(_requestData);
    if (response.error === 0) {
      toast(response.msg);
      setSurchargeLoading(false);
      handleInitSurcharge();
      setEditSurcharge(false);
    } else {
      toast(response.error_msg);
      setSurchargeLoading(false);
    }
  };

  useEffect(() => {
    let iSurge = "";
    let surchargePricex = "";
    for (let i = 0; i < incomingSurcharge.length; i++) {
      if (
        activeMonth + " " + activeYear ===
        incomingSurcharge[i]["month"] + " " + incomingSurcharge[i]["year"]
      ) {
        iSurge = incomingSurcharge[i]["percent_charge"];
        surchargePricex = incomingSurcharge[i]["surcharge_price"];
      }
    }

    setActiveSurcharge(iSurge);
    setSurchargePrice(surchargePricex);
  }, [activeMonth, activeYear, incomingSurcharge]);

  return (
    <>
      <div
        className={`${
          merchant?.status !== "1" && "border-t"
        }  flex flex-col lg:flex-row h-screen`}
      >
        {merchant?.status !== "1" && (
          <div className="w-96 flex-2 lg:border-r  flex flex-col gap-x-2  px-4">
            <div className="self-center w-full mt-5">
              <div className="w-full cursor-pointer items-center p-4 border rounded-lg text-sm">
                <div className="flex">
                  <div
                    className="w-full px-1 border-none outline-none bg-transparent"
                    onClick={() => setShowlisting(true)}
                  >
                    <p>
                      {selectedListing
                        ? selectedListing?.title +
                            ` 
                  ${selectedListing?.status === "1" ? "(Inactive)" : ""} 
                  ${selectedListing?.status === "9" ? "(For Approval)" : ""}` ||
                          "-"
                        : "-"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="my-5 cursor-pointer items-center justify-center text-sm">
              <div className="flex">
                <div className="w-full border-none outline-none bg-transparent">
                  <p className="text-xs text-gray-400">Contract Price</p>
                </div>
              </div>

              <div id="contractPrice" className="">
                <div className="flex flex-row gap-x-10">
                  <div className="text-md lg:text-xl">
                    ₱ {formatNumber(serviceInfo?.service_price || 0)}
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-5 cursor-pointer items-center justify-center text-sm">
              <div className="flex">
                <div className="w-full border-none outline-none bg-transparent">
                  <p className="text-xs text-gray-400">
                    Peak Season Surcharge{" "}
                    {editSurcharge ? "(in %)" : `(₱${surchargePrice})`}
                  </p>
                </div>
              </div>

              <div id="peakseason" className="">
                <div className="flex flex-row justify-between gap-x-10">
                  {editSurcharge ? (
                    <div
                      className={`flex w-20 flex-row items-center text-md lg:text-xl  rounded-xl `}
                    >
                      <div className="flex">
                        <input
                          autoFocus={true}
                          className="w-40 resize-none border-none outline-none  bg-transparent"
                          id="surcharge"
                          type="text"
                          placeholder="%"
                          value={surchargeInput}
                          onChange={(e) =>
                            handleSurchargeInput(validateMoney(e.target.value))
                          }
                        />
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="whitespace-nowrap text-md lg:text-xl w-full">
                        {activeSurcharge}%
                      </div>
                    </>
                  )}

                  <div
                    className="text-sm text-primary-500 text-center self-center align-center cursor-pointer"
                    onClick={
                      editSurcharge
                        ? surchargeInput === "" ||
                          surchargeInput === activeSurcharge
                          ? () => {
                              setEditSurcharge(false);
                              handleSurchargeInput(activeSurcharge);
                            }
                          : () => handleSurcharge()
                        : () => setEditSurcharge(true)
                    }
                  >
                    {selectedListing === null ? (
                      <></>
                    ) : editSurcharge ? (
                      isSurchargeLoading ? (
                        <Loader borderColor="border-primary-500" />
                      ) : surchargeInput === "" ? (
                        "Close"
                      ) : (
                        "Save"
                      )
                    ) : (
                      "Edit"
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <Calendar
          className="purple fullwidth w-full"
          minDate={dayjs().toDate()}
          onChange={(e) => {
            if (selectedListing === null) {
              toast.dismiss();
              toast.error("You currently don't have any active listings");
            } else {
              handleDateSelect(e.toDate());
            }
          }}
          onMonthChange={(e) => {
            const month = dayjs(e.toDate()).format("M");
            const year = dayjs(e.toDate()).format("YYYY");
            setActiveMonth(month);
            setActiveYear(year);
          }}
          mapDays={({ date }) => {
            let formattedDay = dayjs(date.toDate()).format("YYYY-MM-DD");

            if (unavailable.includes(formattedDay)) {
              return {
                className: "highlight blocked-date line-through",
              };
            }
          }}
          numberOfMonths={1}
          headerOrder={["MONTH_YEAR", "LEFT_BUTTON", "RIGHT_BUTTON"]}
        />
      </div>
      {modalOpen && (
        <BlockedDates
          blockDates={unavailable}
          selectedInfo={selectedInfo}
          handleClose={() => {
            setModalOpen(false);
            setSelectInfo(null);
          }}
          rerendInit={() => handleInitAvailability()}
        />
      )}

      {showListing && (
        <MerchantListings
          isOpen={showListing}
          selectedListing={selectedListing}
          setSelectedListing={(val) => dispatch(updateSelectedListing(val))}
          onClose={() => setShowlisting(false)}
        />
      )}
    </>
  );
}

function formatNumber(number) {
  const formatted = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(number);

  return formatted;
}

// a custom render function
function BlockedDates({ selectedInfo, handleClose, blockDates, rerendInit }) {
  const selectedListing = useSelector((state) => state.listing.selectedListing);
  const [isLoading, setIsLoading] = useState(false);
  const [isBlocked, setIsBlocked] = useState(
    blockDates.includes(dayjs(selectedInfo).format("YYYY-MM-DD"))
  );

  const handleToggle = () => {
    setIsBlocked((prevState) => !prevState);
  };

  const closeModal = () => {
    handleClose();
    setIsBlocked(false);
    setIsLoading(false);
  };

  const handleBlock = async () => {
    setIsLoading(true);
    let selectedDate = dayjs(selectedInfo).format("YYYY-MM-DD");
    let available_dates = [];
    if (isBlocked) {
      blockDates.push(selectedDate);
    } else {
      available_dates.push(selectedDate);
      const updatedBlockDates = blockDates.filter((date) => {
        if (dayjs(date.start).format("YYYY-MM-DD") === selectedDate) {
          return false; // Remove the entry that matches the criteria
        }
        return true; // Keep all other entries
      });
      blockDates = updatedBlockDates;
    }

    const _requestData = {
      flag: "save",
      service_code: selectedListing?.service_code,
    };

    if (available_dates.length > 0) {
      _requestData["available_dates"] = available_dates;
    } else {
      _requestData["unavailable_dates"] = blockDates;
    }

    const response = await availability(_requestData);
    if (response.error === 0) {
      setIsLoading(false);
      toast(response.msg);
      rerendInit();
      closeModal();
    } else {
      setIsLoading(false);
      toast(response.error_msg);
    }
  };

  return (
    <div
      className="fixed inset-0 flex items-center justify-center  text-gray-500"
      style={{ zIndex: "999" }}
    >
      <div className="fixed inset-0 bg-black opacity-50"></div>
      <div className="bg-white rounded-3xl relative py-6 mx-4 w-500">
        <ModalHeader
          title={dayjs(selectedInfo).format("MMMM DD, YYYY")}
          onClick={() => {
            closeModal();
          }}
          type={1}
        />
        <div className="px-6 mb-2">
          <div className="flex w-full justify-center">
            <div
              className={`mb-2 cursor-pointer w-40 justify-center items-center flex flex-row border rounded-full p-0.5 text-center text-sm justify-end`}
              onClick={handleToggle}
            >
              <div
                className={`text-center ${
                  !isBlocked
                    ? "text-white border rounded-full py-2 px-4 bg-primary-500"
                    : "py-2 px-4"
                }`}
                style={{ marginRight: isBlocked ? "-1px" : "0" }}
              >
                Open
              </div>
              <div
                className={`text-center ${
                  isBlocked
                    ? "text-white border rounded-full py-2 px-4 bg-primary-500"
                    : "py-2 px-4"
                }`}
                style={{ marginLeft: isBlocked ? "-1px" : "0" }}
              >
                Blocked
              </div>
            </div>
          </div>
        </div>
        <div className="px-6">
          <PrimaryButton
            title={"Save"}
            isLoading={isLoading}
            onClick={() => {
              handleBlock();
            }}
          />
        </div>
      </div>
    </div>
  );
}
