import Close from "../../../assets/svg/Close";

export const Highlights = ({ onClose, highlights }) => {
  const handleOnClose = () => {
    onClose();
  };
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 text-gray-500">
      <div className="fixed inset-0 bg-black opacity-50"></div>
      <div className="bg-white w-600 py-6 rounded-3xl relative mx-4">
        <button
          className="px-6 text-gray-400 hover:text-gray-600"
          onClick={handleOnClose}
        >
          <Close className="w-4 h-4" />
        </button>

        <div
          id="highlights"
          className="px-6 overflow-y-auto"
          style={{ maxHeight: "500px" }}
        >
          <div className="my-5">
            <div className="text-lg font-semibold">The Highlights</div>
          </div>
          <div id="merchant-highlights" className="">
            <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-8">
              {highlights.map((highlight, key) => {
                return (
                  <div key={key}>
                    <img
                      draggable={false}
                      className={"h-36 xl:h-44 bg-sunrise-500 rounded-3xl"}
                      src={highlight.img_name}
                      alt={highlight.title}
                    />
                    <div className="text-xs underline text-center mt-2 text-black">
                      {highlight.title}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
