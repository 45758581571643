import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../common/Loader";
import Error from "../../assets/svg/Error";
import { ModalHeader } from "../common/ModalHeader";
import { updatePaymentModal } from "../../store/slicers/authSlice";
import { bankInfo } from "../../api/merchant";
import { toast } from "react-hot-toast";
export const PaymentDetails = () => {
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const [bankDetails, setBankDetails] = useState({});

  const [bankName, setBankName] = useState("");
  const [accountName, setAccountName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const showPayment = useSelector((state) => state.auth.showPayment);

  useEffect(() => {
    if (showPayment) {
      handleInit();
    }
  }, [showPayment]);

  const handleInit = async () => {
    setIsLoading(true);
    try {
      const _requestData = { flag: "init" };
      const response = await bankInfo(_requestData);
      if (response.error === 0) {
        setIsLoading(false);
        setBankDetails(response.data.bank_info);
        setBankName(response?.data?.bank_info?.bank_name || "");
        setAccountName(response?.data?.bank_info?.account_name || "");
        setAccountNumber(response?.data?.bank_info?.account_no || "");
      } else {
        handleClose();
        toast(response.error_msg);
      }
    } catch (error) {
      handleClose();
      toast("Failed. Please try again.");
    }
  };

  const handleProceed = async () => {
    setError("");
    if (bankName === "" && accountName === "" && accountNumber === "") {
      setError("Please ensure that all fields are filled in.");
      return;
    }

    setIsLoading(true);
    try {
      const _requestData = {
        flag: "save",
        bank_name: bankName,
        account_name: accountName,
        account_no: accountNumber,
      };
      const response = await bankInfo(_requestData);
      if (response.error === 0) {
        setIsLoading(false);
        toast(response.msg);
        handleClose();
      } else {
        setIsLoading(false);
        setError(response.error_msg);
      }
    } catch (error) {
      setIsLoading(false);
      setError("Failed. Please try again.");
    }
  };

  const handleClose = () => {
    setError("");
    setBankName("");
    setAccountName("");
    setAccountNumber("");
    setBankDetails("");
    dispatch(updatePaymentModal(false));
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 text-gray-500">
      <div className="fixed inset-0 bg-black opacity-50"></div>
      <div className="bg-white rounded-3xl relative py-6 mx-4 w-500">
        <ModalHeader
          title={"Payout details"}
          onClick={() => {
            handleClose();
          }}
          type={1}
        />

        <div id="bank" className="px-6 flex flex-col gap-y-2">
          <div className="text-xs text-gray-400 mb-2">
          Please make sure your bank details are accurate. This information will be shown to customers directing them where to place payments.  This is not mandatory. You may or may not provide payout details.
          </div>

          <div id="bankName">
            <div className="w-full  items-center p-2 border mr-2 rounded-xl text-sm">
              <label htmlFor="bankName" className="px-2 text-xs text-gray-400">
                Bank Name
              </label>
              <div className="flex">
                <input
                  autoFocus={true}
                  className="resize-none border-none px-2 outline-none w-full bg-transparent"
                  id="bankName"
                  type="text"
                  value={bankName}
                  onChange={(e) => setBankName(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div id="accountName">
            <div className="w-full  items-center p-2 border mr-2 rounded-xl text-sm">
              <label
                htmlFor="accountName"
                className="px-2 text-xs text-gray-400"
              >
                Account Name
              </label>
              <div className="flex">
                <input
                  
                  className="resize-none border-none px-2 outline-none w-full bg-transparent"
                  id="accountName"
                  type="text"
                  value={accountName}
                  onChange={(e) => setAccountName(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div id="accountNumber">
            <div className="w-full  items-center p-2 border mr-2 rounded-xl text-sm">
              <label
                htmlFor="accountNumber"
                className="px-2 text-xs text-gray-400"
              >
                Account Number
              </label>
              <div className="flex">
                <input
                  
                  className="resize-none border-none px-2 outline-none w-full bg-transparent"
                  id="accountNumber"
                  type="text"
                  value={accountNumber}
                  onChange={(e) => setAccountNumber(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="h-3 mt-2">
            {error && (
              <span className="text-xs text-red-500 flex flex-row item-center">
                <Error className="w-3 mr-1 self-center" />
                {error}
              </span>
            )}
          </div>
        </div>
        <div className="px-6">
          <button
            className="mt-4 bg-primary-500 px-10 w-full text-white font-semibold py-4 rounded-xl hover:opacity-90"
            onClick={isLoading ? () => {} : handleProceed}
          >
            {isLoading ? <Loader /> : <p className="text-sm">Proceed</p>}
          </button>
        </div>
      </div>
    </div>
  );
};
