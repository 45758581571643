export const ClientTerms = () => {
  return (
    <div>
      <div className="mt-5 text-xs lg:text-sm text-black">
        <div className="mb-2 text-sm lg:text-base font-semibold text-black ">
          1. DEFINITIONS
        </div>
        <div>
          The parties referred to in this Agreement shall be defined as follows:
        </div>
        <div className="pl-5 my-5">
          a. Company, Us, We: The Company, as the creator, operator, and
          publisher of the Website, makes the Website and certain Services on
          it, available to users. Anyaya Booking Services, Company, We, Us, Our,
          Ours and other first person pronouns will refer to the Company, as
          well as all employees and affiliates of the Company.
        </div>
        <div className="pl-5 my-5">
          b. You, the User, the Client: You, as the user of the website will be
          referred to throughout this Agreement with second-person pronouns such
          as You, Your, Yours, or as User or Client.
        </div>
        <div className="pl-5 my-5">
          c. Parties: Collectively, the parties to this Agreement (the Company
          and You) will be referred to as Parties.
        </div>
      </div>

      <div className="mt-5 text-xs lg:text-sm text-black">
        <div className="mb-2 text-sm lg:text-base font-semibold text-black ">
          2. INTELLECTUAL PROPERTY
        </div>
        <div className="mb-5">
          The Website, including all content features in the Website including
          all text, graphics, images, logos, trademarks, and the link (the
          "Content"), and Services provided by the Company are the property of
          the Company. You agree that that the Company owns all right, title,
          interest in and to the Content and that You will not use the Content
          for any unlawful or infringing purpose.
        </div>

        <div className="mb-5">
          Subject to this Agreement, the Company grants You a non-exclusive,
          limited, non-transferable, and revocable license to use the Content
          solely in connection with Your use of the Website and Services. The
          Content may not be used for any other purpose, and this license
          terminates upon Your cessation of the use of the Website or Services
          or at the termination of this Agreement.
        </div>

        <div>
          You agree not to reproduce or distribute the Content in any way,
          including electronically or via registration of any new trademarks,
          trade names, service marks, or Uniform Resource Locators (URLs),
          without express written permission from the Company.
        </div>
      </div>

      <div className="mt-5 text-xs lg:text-sm text-black">
        <div className="mb-2 text-sm lg:text-base font-semibold text-black ">
          3. USER ACCOUNTS AND OBLIGATIONS
        </div>
        <div className="mb-5">
          Some content on the Website may only be accessed by the User by
          registering with Us. When You do so, You will choose a user
          identifier, which may be Your email address or another term, as well
          as a password. You may also be required to provide government
          identification, billing information, and personal information,
          including, but not limited to, Your name. You are responsible for
          ensuring the accuracy of this information.
        </div>

        <div className="mb-5">
          You agree to keep Your user identifier and account log in details
          confidential and that You will not share such identifying information
          with any third party. If You discover that Your identifying
          information has been compromised, You agree to notify Us immediately
          in writing. Email notification will suffice.
        </div>

        <div className="mb-5">
          You are responsible for maintaining the safety and security of Your
          identifying information as well as keeping Us informed of any changes
          to Your identifying information. Providing false or inaccurate
          information, or using the Website or Services to further fraud or
          unlawful activity is grounds for immediate termination of this
          Agreement.
        </div>
        <div>
          You agree that You are solely responsible for all acts or omissions
          that occur under Your identifying information, including the content
          of any transmissions using the Website or Service.
        </div>
      </div>

      <div className="mt-5 text-xs lg:text-sm text-black">
        <div className="mb-2 text-sm lg:text-base font-semibold text-black ">
          4. ACCEPTABLE USE
        </div>
        <div>
          As a condition of Your use of the Website or Services, You agree not
          to use the Website or Service for any unlawful purpose or any purpose
          prohibited under this clause. You agree not to use the Website or
          Services in any way that could damage the Website, Services, or
          general business of the Company.
        </div>
        <div className="mt-3">
          You further agree not to use the Website or Services to:
        </div>
        <div className="pl-5 my-5">
          a. Harass, abuse, or threaten others or otherwise violate any person's
          legal rights;
        </div>
        <div className="pl-5 my-5">
          b. Violate any intellectual property rights of the Company or any
          third party;
        </div>
        <div className="pl-5 my-5">c. Perpetrate any fraud;</div>
        <div className="pl-5 my-5">
          d. Engage in or create any unlawful gambling, sweepstakes, or pyramid
          scheme;
        </div>
        <div className="pl-5 my-5">
          e. Attempt to gain unauthorized access to the Website or Service,
          other accounts, computer systems or networks connected with the
          Website or Service;
        </div>
        <div className="pl-5 my-5">
          f. Transmit or upload any content or material that contains viruses,
          trojan horses, ransomware, or other harmful or deleterious programs or
          software;
        </div>
        <div className="pl-5 my-5">
          g. Publish or distribute any obscene or defamatory material;
        </div>
        <div className="pl-5 my-5">
          h. Publish or distribute any material that incites violence, date, or
          discrimination towards any group;
        </div>
        <div className="pl-5 my-5">
          i. Unlawfully gather information about others, including email
          addresses;
        </div>
        <div className="pl-5 my-5">
          j. Interfere with another user's use and enjoyment of the Website or
          Service or any similar Website or Service.
        </div>
      </div>

      <div className="mt-5 text-xs lg:text-sm text-black">
        <div className="mb-2 text-sm lg:text-base font-semibold text-black ">
          5. SUBMISSION POLICY
        </div>
        <div className="mb-5">
          User Submissions are text, photos, images, graphics, videos, or any
          other content that the User and Merchant has added, uploaded, or
          posted to the Website or Service
        </div>

        <div className="mb-5">
          All User Submissions shall remain the property of the User, unless
          otherwise stated, however, the User grants the Company a royalty-free,
          non-exclusive, perpetual, irrevocable, worldwide license to copy,
          display, use, broadcast, transmit, translate, distribute, modify, and
          make derivative works of any content You publish, upload, or otherwise
          make available to the Website, including your name and/or username,
          voice, and/or likeness, in whole or in part, in any media or
          technology.
        </div>

        <div className="mb-5">
          User Submissions are deemed non-confidential and the Company has no
          obligation to maintain its confidentiality.
        </div>
        <div>
          If You feel that any of Your intellectual property rights have been
          infringed or otherwise violated by the posting of information or media
          by another of Our users, please contact Us and let Us know
        </div>
      </div>

      <div className="mt-5 text-xs lg:text-sm text-black">
        <div className="mb-2 text-sm lg:text-base font-semibold text-black ">
          6. AFFILIATE MARKETING AND ADVERTISING
        </div>
        <div>
          The Company, through the Website and Services, may engage in affiliate
          marketing whereby the Company receives a commission on or percentage
          of the sale of goods or services on or through the Website. The
          Company may also accept advertising and sponsorships from commercial
          businesses or receive other forms of advertising compensation.
        </div>
      </div>

      <div className="mt-5 text-xs lg:text-sm text-black">
        <div className="mb-2 text-sm lg:text-base font-semibold text-black ">
          7. THIRD PARTY LINKS
        </div>
        <div className="mb-5">
          You acknowledge that We may, from time to time, include links or
          references to other websites, other content, or other materials
          ("Third Party Links"), none of which are controlled by Us.
        </div>
        <div className="mb-5">
          Third Party Links are provided for Your information only and We do not
          make any representations, warranties, or guarantees as to the
          accuracy, completeness, performance, reliability, timeliness, quality,
          or suitability for a particular purpose of these Third Party Links. We
          do not endorse, approve, or support these Third Party Links.
        </div>
        <div className="mb-5">
          You use the Third Party Links at Your own risk.
        </div>
      </div>

      <div className="mt-5 text-xs lg:text-sm text-black">
        <div className="mb-2 text-sm lg:text-base font-semibold text-black ">
          8. SALE OF GOODS/SERVICES
        </div>
        <div className="mb-5">
          We sell Goods or Services, or allow third parties to sell goods or
          services on the Website. Some specific exclusions of liability will
          apply, as described in the "Exclusion of Liability" Clause.
        </div>
        <div className="mb-5">
          Please refer to Our additional terms and conditions for sale of goods
          and/or terms and conditions for sale of services as applicable.
        </div>
        <div className="mb-5">
          From time to time, the Company may post promotional offers for Goods
          and Services on the website. The frequency of such offers, as well as
          their terms and conditions are determined by the Company.
        </div>
        <div className="mb-5">
          The user is aware that the number of promotional offers for Goods and
          Services is limited.
        </div>
        <div className="mb-5">
          The Company does not guarantee or promise to Users that:
        </div>
        <div className="pl-5 my-5">
          a. the purchase of any promotional Goods and Services on the website
          is any way beneficial for the User and/or third parties; and
        </div>
        <div className="pl-5 my-5">
          b. the cost of promotional Goods and Services is necessarily lower
          than their usual cost on the website or on other third-party websites.
        </div>
      </div>

      <div className="mt-5 text-xs lg:text-sm text-black">
        <div className="mb-2 text-sm lg:text-base font-semibold text-black ">
          9. EXCLUSION OF LIABILITY
        </div>
        <div className="pl-5 my-5">
          a. The Website and Service, and its Content, are provided for general
          information only and may change at any time without prior notice.
        </div>
        <div className="pl-5 my-5">
          b. You accept and acknowledge that the Website, Goods, or Services may
          contain mistakes, errors, and inaccuracies.
        </div>
        <div className="pl-5 my-5">
          c. Your use of the Website, Content, and information or documentation
          that We may provide to You in connection with Your use of the Goods,
          Services, or Products including documentation, data, and information
          developed by Us or owned by Us, and other materials which may assist
          in Your use of Goods or Services or Website (collectively, the
          "Materials"), is entirely at Your risk. It is Your responsibility to
          make sure that any Goods, Services, Materials, Content, or other
          information available through the Website or Service suits Your
          particular purpose.
        </div>
        <div className="pl-5 my-5">
          d. Neither We, nor any third parties provide any guarantees or
          warranties regarding the accuracy, completeness, performance,
          reliability, timeliness, quality, merchantability, safety, legality,
          or suitability for a particular purpose of the Website, Goods, or
          Services.
        </div>
        <div className="pl-5 my-5">
          e. To the maximum extent permitted by law, We hereby expressly exclude
          all warranties, guarantees, representations, or terms (whether express
          or implied) except for those expressly set out in these Agreements.
        </div>
        <div className="pl-5 my-5">
          f. To the maximum extent permitted by law, We hereby expressly exclude
          any liability in relation to the accuracy, completeness, performance,
          reliability, timeliness, quality, merchantability, safety, legality,
          or suitability for a particular purpose of the Website, Goods, or
          Services.
        </div>
        <div className="pl-5 my-5">
          g. To the maximum extent permitted by law, We hereby expressly exclude
          any liability in relation to loss of data, interruption to Your
          business or any damages which are incidental to or arise from such
          loss of data or interruption to business.
        </div>
        <div className="pl-5 my-5">
          h. To the maximum extent permitted by law, We will not be liable for
          any damage, loss, cost, or expense including legal costs and expenses,
          whether direct or indirect, incurred by You in connection with Your
          use of the Website, Goods, or Services.
        </div>
        <div className="pl-5 my-5">
          i. For Goods and/or Services sold by third parties via the Website or
          via Third Party Links ("Third Party Goods and Services"):
        </div>
        <div className="pl-10 my-5">
          1. You acknowledge and agree that We have no control over those Third
          Party Goods and Services and that You purchase such Third Party Goods
          and Services at Your own risk.
        </div>
        <div className="pl-10 my-5">
          2. You acknowledge and agree that any Third Party Goods and Services
          will be governed by agreements entered into directly and only between
          You and the Third Party for which We shall have no liability.
        </div>
        <div className="pl-10 my-5">
          3. You acknowledge and agree that We assume no liability and provide
          no warranties or guarantees regarding the accuracy, completeness,
          performance, reliability, timeliness, quality, merchantability,
          safety, legality, or suitability for a particular purpose of Third
          Party Goods and Services.
        </div>
        <div className="pl-10 my-5">
          4. To the maximum extent permitted by law, You hereby release Us from
          any claim related to Third Party Goods and Services including any and
          all warranty and product liability claims.
        </div>
      </div>

      <div className="mt-5 text-xs lg:text-sm text-black">
        <div className="mb-2 text-sm lg:text-base font-semibold text-black ">
          10. PAYMENT/BOOKING RESERVATION
        </div>
        <div className="mb-5">
          You agree to ensure payment for any services You may purchase from Us
          and from third-party merchants, and You acknowledge and affirm that
          prices are subject to change. You agree to provide Us with a valid
          email and a valid billing information. When purchasing a Good or
          Service, We reserve the right to reject or cancel an order for any
          reason, including errors or omissions in the information that You
          provide to us. You agree to ensure payment for any services You may
          purchase from Us and from our merchants, and You acknowledge and
          affirm that prices are subject to change. You agree to monitor Your
          method of payment.
        </div>
        <div className="mb-5">
          The Company accepts the following methods of payment through the
          website:
        </div>
        <div className="mb-5 pl-5">
          Credit Card; Debit Card; Paypal; PayMaya; Gcash.
        </div>
        <div className="mb-5">
          You must be fully entitled to use the card or account.
        </div>
        <div className="mb-5">
          You acknowledge and accept that there are inherent risks with
          downloading any digital Goods. Please contact Us using the details at
          the end of this Agreement if you experience technical problems
          regarding delivery of digital Goods.
        </div>

        <div className="mb-5">
          We take no responsibility for any flawed and incomplete event service
          You obtain from our third-party merchants.
        </div>
        <div className="mb-5">
          We take no responsibility and exclude any liability in relation with
          any outside transaction that You may have with our third-party
          merchants.
        </div>
      </div>

      <div className="mt-5 text-xs lg:text-sm text-black">
        <div className="mb-2 text-sm lg:text-base font-semibold text-black ">
          11. SERVICE RESERVATION, CANCELLATION, MODIFICATION, AND ACCURACY
        </div>
        <div className="mb-5">
          Our website is a subscription-based marketplace wherein Merchants and Customers can negotiate and freely transact outside the platform while absolving Us from any liability and damages. If in any case You would like to transact with an agreeing Merchant using our website and payment solutions, such action should follow corresponding booking policies below: 
        </div>
        <div className="mb-5">
          Booking Reservation: Merchants can accept or decline any reservation
          request from You. Merchants are expected to supply and provide
          services to your event once the merchant accepts and confirms your
          booking. Any event that has been reported by you to be false and
          unauthentic would result in an investigation of Merchant’s account
          termination.
        </div>
        <div className="mb-5 pl-5">
          Booking Cancellation: You and the merchant can cancel a booking
          reservation any time, but such actions could result in non-refundable
          payments and payouts. The client and merchant must notify Us promptly
          and provide a valid reason for cancellation in which we would use to
          determine whether to grant a refund or a payout. Any damages or burden
          caused by the cancellation would result in an investigation of account
          termination with corresponding fines.
        </div>
        <div className="mb-5">
          Booking Modifications: You may request changes to a booking, subject
          to Merchant’s approval and availability. Any additional costs
          resulting from the client's request of modifications are the Client’s
          responsibility.
        </div>
        <div className="mb-5">
          Booking Accuracy: Merchants are expected to create and customize any
          event listing with proficient accuracy. Any reports of hidden charges
          would result in an investigation of account termination.
        </div>
      </div>

      <div className="mt-5 text-xs lg:text-sm text-black">
        <div className="mb-2 text-sm lg:text-base font-semibold text-black ">
          12. ASSUMPTION OF RISK
        </div>
        <div className="mb-5">
          The Website and Services are provided for communication purposes only.
          You acknowledge and agree that any information posted on Our Website
          is not intended to be legal advice, medical advice, or financial
          advice, and no fiduciary relationship has been created between You and
          the Company.
        </div>
        <div className="mb-5">
          You further agree that Your purchase of any products and services on
          the Website is at Your own risk. The Company does not assume
          responsibility or liability for any advice or other information given
          on the Website or by the merchants.
        </div>
        <div className="mb-5">
          We shall not be liable for any damage to any computer, equipment,
          software, data, or other information caused by Your access or use of
          the Website or Service. We shall likewise not be liable for any action
          of third parties.
        </div>
      </div>

      <div className="mt-5 text-xs lg:text-sm text-black">
        <div className="mb-2 text-sm lg:text-base font-semibold text-black ">
          13. PRIVACY
        </div>
        <div className="mb-5">
          Through Your Use of the Website and Services, You may provide Us with
          certain information. By using the Website or the Services, You
          authorize the Company to use Your information in the Philippines and
          any other country where We may operate.
        </div>
        <div className="mb-5">
          We take Our privacy obligations very seriously
        </div>
        <div className="mb-5">
          Please refer to Our privacy policy for further information about what
          information We collect, how We use it and store it, and Your rights in
          relation to it.
        </div>
      </div>
      <div className="mt-5 text-xs lg:text-sm text-black">
        <div className="mb-2 text-sm lg:text-base font-semibold text-black ">
          14. INDEMNITY
        </div>
        <div className="mb-5">
          You agree to defend and indemnify the Company and any of its
          affiliated (if applicable) and hold Us harmless against and legal
          claims and demands, including reasonable attorney's fees, which may
          arise from or relate to Your use or misuse of the Website or Services,
          Your breach of this Agreement, or Your conduct or actions.
        </div>
        <div className="mb-5">
          You agree that the Company shall be able to select its own legal
          counsel and may participate in its own defense, if the Company wishes.
        </div>
      </div>

      <div className="mt-5 text-xs lg:text-sm text-black">
        <div className="mb-2 text-sm lg:text-base font-semibold text-black ">
          15. OTHER ACTION
        </div>
        <div className="mb-5">
          We reserve the right to take any of the following actions in Our sole
          discretion:
        </div>
        <div className="pl-5 my-5">
          a. Monitor, review, edit or delete any User Submissions
        </div>
        <div className="pl-5 my-5">
          b. Determine whether or not You have breached this Agreement;
        </div>
        <div className="pl-5 my-5">
          c. Record any correspondence that occurs in public sections of the
          Website or Service;
        </div>
        <div className="pl-5 my-5">
          d. Review any allegations about breaches of these Website or Service,
          and determine in Our sole discretion whether to take any action in
          response to those alleged breaches, including removal of any Content
          in relation to those alleged breaches;
        </div>
        <div className="pl-5 my-5">
          e. Determine in our sole discretion whether to terminate Your or
          another user's access to any particular section or sections of the
          Website or Service.
        </div>
      </div>

      <div className="mt-5 text-xs lg:text-sm text-black">
        <div className="mb-2 text-sm lg:text-base font-semibold text-black">
          16. SPAM POLICY
        </div>
        <div>
          You are strictly prohibited from using the Website or any of the
          Company's Services for spam activities, including gathering email
          addresses and personal information from others or sending any mass
          commercial emails.
        </div>
      </div>

      <div className="mt-5 text-xs lg:text-sm text-black">
        <div className="mb-2 text-sm lg:text-base font-semibold text-black">
          17. REVERSE ENGINEERING & SECURITY
        </div>
        <div>You agree not to undertake any of the following actions:</div>
        <div className="pl-5 my-5">
          a. Reverse engineer, or attempt to reverse engineer or disassemble any
          code or software from or on the Website or Services;
        </div>
        <div className="pl-5 my-5">
          b. Violate the security of the Website or Services through any
          unauthorized access, circumvention of encryption or other security
          tools, data mining, or interference to any host, user, or network.
        </div>
      </div>

      <div className="mt-5 text-xs lg:text-sm text-black">
        <div className="mb-2 text-sm lg:text-base font-semibold text-black">
          18. MODIFICATION & SERVICE INTERRUPTIONS
        </div>
        <div>
          The Company may, in Our sole discretion, vary, alter, amend, change,
          or update the Website or Service and/or its Content at any time. The
          Company may need to interrupt Your access to the Website to implement
          any change or to perform maintenance or emergency services on a
          scheduled or unscheduled basis. You agree that Your access to the
          Website may be affected by unanticipated or unscheduled downtime, for
          any reason, but that the Company shall have no liability for any
          damage or loss caused as a result of such downtime.
        </div>
      </div>

      <div className="mt-5 text-xs lg:text-sm text-black">
        <div className="mb-2 text-sm lg:text-base font-semibold text-black">
          19. VARIATION OF TERMS
        </div>
        <div className="mb-5">
          The Company may, from time to time and at any time without notice to
          You, modify this Agreement. You agree that the Company has the right
          to modify this Agreement or revise anything contained herein. You
          further agree that all modifications to this Agreement are in full
          force and effect immediately upon posting on the Website and that the
          modifications or variations will replace any prior version of this
          Agreement, unless prior versions are specifically referred to or
          incorporated into the latest modification or variation of this
          Agreement.
        </div>
        <div className="mb-5">
          You agree to routinely monitor this Agreement and refer to the
          Effective date at the bottom part of this Agreement to note
          modifications or variations. You further agree to clear Your cache
          when doing so to avoid accessing an older version of this Agreement.
        </div>
        <div className="mb-5">
          Your continued use of the Website after any modifications to this
          Agreement is a manifestation of Your continued assent to this
          Agreement.
        </div>
        <div>
          In the event that You fail to monitor any modifications to or
          variations of this Agreement, You agree that such failure shall be
          considered an affirmative waiver of Your right to review the modified
          Agreement.
        </div>
      </div>

      <div className="mt-5 text-xs lg:text-sm text-black">
        <div className="mb-2 text-sm lg:text-base font-semibold text-black">
          20. ENTIRE AGREEMENT
        </div>
        <div>
          This Agreement constitutes the entire understanding between the
          Parties with respect to any and all use of this Website. The Agreement
          supersedes and replaces all prior or contemporaneous agreements or
          understandings, written or oral, regarding the use of this Website.
        </div>
      </div>

      {/* Applicable Law */}
      <div className="mt-5 text-xs lg:text-sm text-black">
        <div className="mb-2 text-sm lg:text-base font-semibold text-black">
          21. APPLICABLE LAW
        </div>
        <div>
          This Agreement and Your use of the Website and all non-contractual
          relationships arising out of Your use shall be governed and construed
          in accordance with the laws of the Philippines.
        </div>
        <div>
          In case of any dispute or litigation, the Parties agree to submit to
          the jurisdiction of the Philippines courts.
        </div>
      </div>

      {/* Assignment */}
      <div className="mt-5 text-xs lg:text-sm text-black">
        <div className="mb-2 text-sm lg:text-base font-semibold text-black">
          22. ASSIGNMENT
        </div>
        <div>
          This Agreement, or the rights granted hereunder, may not be assigned,
          sold, leased, or otherwise transferred in whole or in part by You.
          Should this Agreement, or the rights granted hereunder, be assigned,
          sold, leased, or otherwise transferred by the Company, the rights and
          liabilities of the Company will bind and inure to any assignees,
          administrators, successors, and executors.
        </div>
      </div>

      {/* Severability */}
      <div className="mt-5 text-xs lg:text-sm text-black">
        <div className="mb-2 text-sm lg:text-base font-semibold text-black">
          23. SEVERABILITY
        </div>
        <div>
          If any part or sub-part of this Agreement is held invalid or
          unenforceable by a court of law or competent arbitrator, the remaining
          parts and sub-parts will be enforced to the maximum extent possible.
          In such conditions, the remainder of this Agreement shall continue in
          full force.
        </div>
      </div>

      {/* No Waiver */}
      <div className="mt-5 text-xs lg:text-sm text-black">
        <div className="mb-2 text-sm lg:text-base font-semibold text-black">
          24. NO WAIVER
        </div>
        <div>
          Our failure to enforce any provision of this Agreement shall not
          constitute a waiver of any future enforcement of that provision or of
          any other provision
        </div>
        <div>
          No waiver shall be deemed to have been made unless expressed in
          writing and signed by Us. Waiver of any part or sub-part of this
          Agreement will not constitute a waiver of any other part or sub-part
          or of the same part or sub-part on a future date.
        </div>
      </div>

      {/* Headings */}
      <div className="mt-5 text-xs lg:text-sm text-black">
        <div className="mb-2 text-sm lg:text-base font-semibold text-black">
          25. HEADINGS
        </div>
        <div>
          Headings of parts and subparts under this Agreement are for
          convenience and organization only. Headings shall not affect the
          meaning of any provisions of this Agreement.
        </div>
      </div>
      <div className="mt-5 text-xs lg:text-sm text-black">
        <div className="mb-2 text-sm lg:text-base font-semibold text-black">
          26. CONTACT US
        </div>
        <div>
          You can contact us about this Agreement using the following details:
        </div>
        <div>Anyaya Booking Services</div>
        <div>Quezon City, Metro Manila, 1124.</div>
        <div>+639173002089</div>
        <div>anyaya.events@gmail.com</div>
      </div>
      <div className="mt-5 text-xs lg:text-sm text-black">
        <div className="mb-2 text-sm lg:text-base font-semibold text-black">
          27. EFFECTIVE DATE
        </div>
        <div>This Agreement will become effective on 10 January 2024.</div>
      </div>
    </div>
  );
};
