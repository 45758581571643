import React, { useState } from "react";
import {
  updateAccountInfo,
  updateLanguage,
} from "../../store/slicers/authSlice";
import Checkbox from "../common/Checkbox";
import { useDispatch, useSelector } from "react-redux";
import { updateProfile } from "../../api/client";
import { toast } from "react-hot-toast";
import Loader from "../common/Loader";
const LanguageModal = () => {
  const dispatch = useDispatch();
  const languages = [
    "Afrikaans",
    "Albanian",
    "Arabic",
    "Armenian",
    "Azeri",
    "Basque",
    "Belarusian",
    "Bosnian",
    "Bulgarian",
    "Catalan",
    "Chinese",
    "Croatian",
    "Czech",
    "Danish",
    "Divehi",
    "Dutch",
    "English",
    "Esperanto",
    "Estonian",
    "Faroese",
    "Farsi",
    "Finnish",
    "French",
    "FYRO Macedonian",
    "Galician",
    "Georgian",
    "German",
    "Greek",
    "Gujarati",
    "Hebrew",
    "Hindi",
    "Hungarian",
    "Icelandic",
    "Indonesian",
    "Italian",
    "Japanese",
    "Latvian",
    "Lithuanian",
    "Kannada",
    "Kazakh",
    "Konkani",
    "Korean",
    "Kyrgyz",
    "Malay",
    "Maltese",
    "Maori",
    "Marathi",
    "Mongolian",
    "Northern Sotho",
    "Norwegian",
    "Pashto",
    "Polish",
    "Portuguese",
    "Punjabi",
    "Quechua",
    "Romanian",
    "Russian",
    "Sami",
    "Sanskrit",
    "Serbian",
    "Slovak",
    "Slovenian",
    "Spanish",
    "Swahili",
    "Swedish",
    "Syriac",
    "Tagalog",
    "Tatar",
    "Tamil",
    "Telugu",
    "Thai",
    "Tsonga",
    "Tswana",
    "Turkish",
    "Ukrainian",
    "Urdu",
    "Uzbek",
    "Vietnamese",
    "Welsh",
    "Xhosa",
    "Zulu",
  ];
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedLanguage, setSelectedLanguage] = useState([]);
  const [bLoading, setLoading] = useState(false);

  const accountInfo = useSelector((state) => state.auth.account_info);

  const handleSelectLanguage = (province) => {
    const index = selectedLanguage.indexOf(province);
    if (index > -1) {
      selectedLanguage.splice(index, 1);
    } else {
      selectedLanguage.push(province);
    }
    setSelectedLanguage([...selectedLanguage]);
  };
  const filteredLanguages = languages.filter((language) =>
    language.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleSave = async () => {
    setLoading(true);
    const _requestData = {
      act: "profile",
      flag: "language",
      language: selectedLanguage.join(", "),
    };
    const response = await updateProfile(_requestData);

    if (response.error === 0) {
      toast(response.msg);
      setLoading(false);

      const updatedMerchant = { ...accountInfo.merchant_info };
      updatedMerchant.language = selectedLanguage.join(", ");

      // Create a new updated accountInfo object by keeping the original data
      const updatedAccountInfo = {
        ...accountInfo,
        merchant_info: updatedMerchant,
      };

      // Dispatch an action to update the entire account_info
      dispatch(updateAccountInfo(updatedAccountInfo));

      dispatch(updateLanguage(false));
    } else {
      setLoading(false);
      toast(response.error_msg);
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 text-gray-500">
      {/* Backdrop */}
      <div className="fixed inset-0 bg-black opacity-50"></div>
      {/* Modal content */}
      <div className="bg-white rounded-3xl relative py-6 mx-4 w-500">
        <h2 className="px-8 text-primary-600 text-base lg:text-2xl font-bold mb-4">
          Languages you speak
        </h2>
        <div className="px-8 w-full">
          <input
            autoFocus={true}
            className="resize-none outline-none border py-2 px-4 rounded-full w-full"
            id="search"
            placeholder="Search for a language"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>

        <div className="px-8 overflow-y-auto" style={{ height: "500px" }}>
          {filteredLanguages.map((language, key) => {
            return (
              <Checkbox
                checkSize="h-8 w-8"
                label={language}
                labelClass="text-base"
                checked={selectedLanguage.includes(language)}
                onChange={() => handleSelectLanguage(language)}
              />
            );
          })}
        </div>
        <div className="flex justify-end">
          <button
            onClick={() => {
              if (!bLoading) {
                handleSave();
              }
            }}
            className="text-sm mx-6 mt-6 px-12 py-2 bg-primary-500 text-white rounded-full hover:bg-primary-600 focus:outline-none"
          >
            {bLoading ? <Loader /> : "Save"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default LanguageModal;
