import React from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./store";
import { GoogleOAuthProvider } from "@react-oauth/google";
import AppRouter from "./AppRouter";
import { config } from "./utils/config";
import MessengerCustomerChat from "react-messenger-customer-chat";
import MessengerIcon from "./assets/svg/MessengerIcon";

const App = () => {
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <GoogleOAuthProvider clientId={config.GOOGLE_AUTH_CLIENT_ID}>
          <AppRouter />
          <MessengerIcon />
          {/* {isMobile && <MessengerIcon />} */}
          {/* {!isMobile && <MessengerCustomerChat pageId="198680789992617" />} */}
          {/* <FacebookChatPlugin pageId={"198680789992617"} apiVersion={"v3.1"} /> */}
        </GoogleOAuthProvider>
      </PersistGate>
    </Provider>
  );
};

export default App;
