import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import Heart from "../../assets/svg/Heart";
import {
  updateLoginModal,
  updateMenuModal,
} from "../../store/slicers/authSlice";

export const FooterMobile = () => {
  const isLoggedIn = useSelector((state) => state.auth.token);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [hideFooter, setHideFooter] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [isInMerchant, setInMerchant] = useState(false);
  const unreadMsg = useSelector((state) => state.auth.unreadMsg);
  const merchantMsg = useSelector((state) => state.auth.merchantMsg);

  useEffect(() => {
    if (location.pathname.includes("/inbox")) {
      setHideFooter(true);
    } else {
      setHideFooter(false);
    }
  }, [location]);

  useEffect(() => {
    if (location.pathname === "/" || location.pathname === "/merchant") {
      setHideFooter(true);
    }

    if (location.pathname.includes("/merchant/")) {
      setInMerchant(true);
    } else {
      setInMerchant(false);
    }
  }, [location]);
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos =
        window.pageYOffset || document.documentElement.scrollTop;
      const shouldHide = currentScrollPos > prevScrollPos;
      setPrevScrollPos(currentScrollPos);
      if (
        location.pathname === "/" ||
        location.pathname.includes("/merchant")
      ) {
      } else {
        setHideFooter(shouldHide);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollPos]);

  return (
    <footer
      className={`footer ${
        hideFooter ? "hidden lg:hidden" : "lg:hidden"
      } border-t bg-white px-4 py-4 fixed bottom-0 left-0 w-full flex justify-center items-center`}
    >
      {!isInMerchant && (
        <div
          className="footer-section text-xs mr-6 flex-col text-center-webkit"
          onClick={() => {
            navigate("/events");
          }}
        >
          <div className="h-8 w-8 mb-1.5">
            <div className="event w-full h-full bg-no-repeat bg-cover"></div>
          </div>
          <div>My Events</div>
        </div>
      )}
      {isInMerchant && (
        <div
          className="footer-section text-xs mr-6 flex-col text-center-webkit"
          onClick={() => {
            navigate("/merchant/calendar");
          }}
        >
          <div className="h-8 w-8 mb-1.5">
            <div className="event w-full h-full bg-no-repeat bg-cover"></div>
          </div>
          <div>Calendar</div>
        </div>
      )}
      {isInMerchant && (
        <div
          className="footer-section text-xs mr-6 flex-col text-center-webkit"
          onClick={() => {
            navigate("/merchant/insights");
          }}
        >
          <div className="h-8 w-8 mb-1.5">
            <div className="privacysharing w-full h-full bg-no-repeat bg-cover"></div>
          </div>
          <div>Insights</div>
        </div>
      )}
      {!isInMerchant && (
        <div
          className="footer-section text-xs mr-6 flex-col text-center-webkit"
          onClick={() => {
            navigate("/wishlists");
          }}
        >
          <Heart className="h-8 w-8 mb-1.5 text-white" stroke="#604EA0" />
          Wishlists
        </div>
      )}
      <div
        id="login"
        className={`footer-section flex-col text-center-webkit text-xs ${
          isLoggedIn && "hidden"
        }`}
        onClick={() => {
          dispatch(updateLoginModal({ status: true }));
        }}
      >
        <div className="h-8 w-8 mb-1.5">
          <div className="defaulticon w-full h-full bg-no-repeat bg-cover"></div>
        </div>
        Log In
      </div>
      {isLoggedIn && (
        <div
          id="messages"
          className="footer-section mr-6 text-xs flex-col text-center-webkit"
          onClick={() => {
            if (isInMerchant) {
              navigate("/merchant/inbox/all");
            } else {
              navigate("/client/inbox/all");
            }
          }}
        >
          <div className="h-8 w-8 mb-1.5">
            <div className="response w-full h-full bg-no-repeat bg-cover"></div>
          </div>
          <div className="relative">
            Messages
            {isInMerchant && merchantMsg === 1 && (
              <span className="bg-red-500 rounded-full p-0.5 absolute mx-1"></span>
            )}
            {unreadMsg === 1 && (
              <span className="bg-red-500 rounded-full p-0.5 absolute mx-1"></span>
            )}
          </div>
        </div>
      )}
      {isLoggedIn && (
        <div
          id="account"
          className="footer-section text-xs flex-col text-center-webkit"
          onClick={() => {
            navigate("/account");
          }}
        >
          <div className="h-8 w-8 mb-1.5">
            <div className="defaulticon w-full h-full bg-no-repeat bg-cover"></div>
          </div>
          <div>Account</div>
        </div>
      )}
    </footer>
  );
};

export const Footer = () => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <footer
      id="footer"
      className={`${
        (location.pathname.includes("/client/inbox") && "hidden") ||
        (location.pathname === "/" && "hidden") ||
        (location.pathname === "/merchant" && "hidden")
      } text-primary-600 footer border-t bg-white px-6 pt-6 pb-4  bottom-0 left-0 w-full flex flex-col justify-center items-center`}
    >
      <div className="flex flex-row">
        <div className="footer-section text-xs">© 2023 Anyaya</div>
        <div className="footer-section text-xs mx-2 hidden lg:flex">•</div>
        <div
          className="footer-section text-xs cursor-pointer hidden lg:flex"
          onClick={() => {
            navigate("/support");
          }}
        >
          Support
        </div>
        <div className="footer-section text-xs mx-2">•</div>
        <div
          className="footer-section text-xs cursor-pointer"
          onClick={() => {
            navigate("/terms-and-conditions");
          }}
        >
          Terms & Conditions
        </div>
        <div className="footer-section text-xs mx-2">•</div>
        <div
          className="footer-section text-xs cursor-pointer"
          onClick={() => {
            navigate("/privacy-policy");
          }}
        >
          Privacy Policy
        </div>
      </div>
      <div className="flex flex-row mt-2 justify-center footer-section">
        <span className="mr-2 text-xs  text-primary-600 self-center">
          Powered by
        </span>
        <div className="w-20 h-8 ">
          <div
            className="maya h-full w-full "
            style={{ backgroundPositionY: "center" }}
          />
        </div>
      </div>
    </footer>
  );
};
