import React, { useEffect, useRef, useState } from "react";
import { Breadcrumbs } from "../../../common/Breadcrumbs";
import { paymentHistory } from "../../../../api/client/listing";
import Loader from "../../../common/Loader";
import dayjs from "dayjs";

const PaymentHistory = () => {
  const paymentStatus = ["Unpaid", "For Confirmation", "Paid"];
  const [history, setHistory] = useState([]);
  const [isLoading, setInitLoading] = useState(true);

  useEffect(() => {
    initialHistory();
  }, []);


  const initialHistory = async () => {
    try {
      const response = await paymentHistory();
      if (response.error === 0) {
        setHistory(response.data.payment_history);
        setInitLoading(false);
      } else {
        setInitLoading(false);
      }
    } catch (error) {
      setInitLoading(false);
    }
  };

  return (
    <div className="px-4 mt-5  align-center items-center">
      <Breadcrumbs location="account/payments" />
      <div className="text-2xl font-semibold text-black">
        {history.length === 0 ? "Payment" : "Payments"}
      </div>
    
      {/* <div className="text-xl mt-4  text-black">
        {history.length === 0 ? "" : "Completed"}
      </div> */}

      {isLoading && <Loader borderColor="border-primary-500" />}
      {isLoading ? (
        <></>
      ) : history.length === 0 ? (
        <div className="text-sm text-gray-400 mt-2">
          Once you have a reservation, this is where you can come to track your
          payments.
        </div>
      ) : (
        history.map((data, key) => {
          return (
            <div key={key} className="flex w-3/5 flex-row mt-5">
              <img
                draggable="false"
                alt={data.title}
                src={data.cover_img}
                className="w-20 h-20 border rounded-2xl bg-no-repeat bg-center mr-4 bg-cover"
              />

              {/* <div className="rounded-2xl w-20 h-20 bg-sunrise-500 p-4 text-white mr-4"></div> */}
              <div className="w-full self-center">
                <div className="font-semibold text-black">
                  {paymentStatus[data.status]}
                  {data.status === 3 && ` · ` + data.date_posted}
                </div>
                <div className="text-sm text-gray-400 cursor-pointer select-all">{data.booking_code}</div>
                <div className="text-sm text-gray-400">{data.title}</div>
                <div className="text-sm text-gray-400">{data.bank_name}</div>
                <div className="text-sm text-gray-400">
                  {data?.booking_details?.location} ·{" "}
                  {dayjs(data?.booking_details?.start_date).format(
                    "MMM DD, YY h:MM A"
                  )}{" "}
                  {data?.booking_details?.start_date !==
                    data?.booking_details?.end_date &&
                    "- " +
                      dayjs(data?.booking_details?.end_date).format(
                        "MMM DD, YY h:MM A"
                      )}
                </div>
              </div>
              <div className="self-center text-sm text-gray-400">
                <div className="font-semibold">₱{data?.amount_paid}</div>
                <div className="text-right">PHP</div>
              </div>
            </div>
          );
        })
      )}
    </div>
  );
};

export default PaymentHistory;
