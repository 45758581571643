import * as React from "react";

const Star = (props) => {
  const { height = 16, width = 16, fill = "#F6A31E", className } = props;

  return (
    <svg
    
      height={height}
      width={width}
      viewBox="0 0 53.867 53.867"
      className={className}
    >
      <path
        fill={fill}
        d="M26.934 1.318L35.256 18.182 53.867 20.887 40.4 34.013 43.579 52.549 26.934 43.798 10.288 52.549 13.467 34.013 0 20.887 18.611 18.182z"
      />
    </svg>
  );
};

export default Star;
