import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateLoginModal } from "../../../store/slicers/authSlice";
import { useLocation } from "react-router-dom";
const MerchantProtected = ({ children }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const isMerchant = useSelector(
    (state) => state.auth.account_info.merchant_info
  );
  const isLoggedIn = useSelector((state) => state.auth.token);

  
  const navigate = useNavigate();
  useEffect(() => {
    if (isLoggedIn === "") {
      dispatch(updateLoginModal({ status: true }));
      navigate("/category");
    }
  }, [isLoggedIn, navigate, dispatch]);
  
  // useEffect(() => {
  //   if (!isMerchant) {
  //     dispatch(updateLoginModal({ status: true }));
  //     navigate("/merchant");
  //   }
  // }, [isMerchant, navigate, dispatch]);
  const isInbox = location.pathname.includes("inbox");
  return (
    <div className={`${isInbox ? "" : "px-10"} lg:px-40 xl:px-56 pb-10 pt-4`}>
      {children}
    </div>
  );
};

export default MerchantProtected;
