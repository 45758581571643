import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { toast } from "react-hot-toast";
import { bookingCancel, bookingConfirm, getHome } from "../../api/merchant";
import Loader from "../common/Loader";
import Close from "../../assets/svg/Close";
import { ProfileDetails } from "./Inbox/ProfileDetails";
import { CancelBooking } from "../common/CancelBooking";

export const Reservations = ({ isOpen, onClose, selectedListing }) => {
  const tabs = ["Current event", "Upcoming", "For Approval", "Cancelled"];
  const [selectedTab, selectTab] = useState(0);
  const dispatch = useDispatch();
  const [currentEvent, setCurrentEvent] = useState();
  const [pendingEvent, setPendingEvent] = useState();
  const [upcomingEvent, setUpcomingEvent] = useState();
  const [cancelledEvent, setCancelledEvent] = useState();
  const [bLoading, setLoading] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const [accountCode, setAccountCode] = useState("");
  const [acceptLoading, setAcceptLoading] = useState(false);

  const [isDeclinedLoading, setIsDeclinedLoading] = useState(false);
  const [showCancel, setShowCancel] = useState(false);
  const [selectedCancel, selectCancel] = useState("");

  const filterEvent = async () => {
    if (bLoading) {
      return;
    }

    setLoading(true);
    const _requestData = {
      service_code: selectedListing?.service_code,
    };

    try {
      const response = await getHome(_requestData);
      if (response.error === 0) {
        setCurrentEvent(response.data.current_event);
        setPendingEvent(response.data.pending_event);
        setUpcomingEvent(response.data.upcoming_event);
        setCancelledEvent(response.data.cancelled_event);
        setLoading(false);
      } else {
        setCurrentEvent([]);
        setPendingEvent([]);
        setUpcomingEvent([]);
        setCancelledEvent([]);
        setLoading(false);
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };
  useEffect(() => {
    if (isOpen) {
      filterEvent();
    }
  }, [isOpen]);

  const handleOnClose = () => {
    onClose();
  };

  const approve = async (bookingCode) => {
    const _requestData = {
      booking_code: bookingCode,
    };
    try {
      const response = await bookingConfirm(_requestData);
      if (response.error === 0) {
        toast(response.msg);
        filterEvent();
      } else {
        toast(response.error_msg);
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };
  const decline = async (reason) => {
    setIsDeclinedLoading(true);
    const _requestData = {
      booking_code: selectedCancel,
      reason,
    };
    try {
      const response = await bookingCancel(_requestData);
      if (response.error === 0) {
        selectCancel("");
        toast(response.msg);
        setIsDeclinedLoading(false);
        setShowCancel(false);
        filterEvent();
      } else {
        toast(response.error_msg);
        setIsDeclinedLoading(false);
      }
    } catch (error) {
      setIsDeclinedLoading(false);
      console.log("Error:", error);
    }
  };

  const returnTable = (data, i) => {
    return (
      <div key={i} className="py-2 border-b cursor-pointer relative">
        <div className="gap-2  text-sm  mt-2 flex flex-wrap">
          <div className="flex-1">
            <div
              className="whitespace-nowrap text-primary-500 cursor-pointer"
              onClick={() => {
                setAccountCode(data.account_code);
                setShowProfile(true);
              }}
            >
              {data.client_name}
            </div>
            <div className="text-xs text-gray-400">{data.location}</div>
          </div>
          <div className="flex flex-col text-right px-2">
            <div className="text-xs text-gray-400">Total Income</div>
            <div>PHP {data.total_amount}</div>
          </div>
          <div className="flex-1 text-center">{data.title}</div>
          {selectedTab !== 2 && selectedTab !== 3 && (
            <div className="text-primary-500">{data.note}</div>
          )}
          {selectedTab === 2 && (
            <div className="flex-1 flex flex-col gap-2">
              <div
                onClick={() => approve(data.booking_code)}
                className="bg-primary-500 text-white p-2 text-xs cursor-pointer rounded-lg"
              >
                Accept
              </div>
              <div
                onClick={() => {
                  setShowCancel(true);
                  selectCancel(data.booking_code);
                }}
                className="border-primary-500 border text-primary-500 p-2 text-xs cursor-pointer rounded-lg"
              >
                Decline
              </div>
            </div>
          )}
        </div>
        <div className="text-xs text-gray-400">{data.start_date}</div>
        <div className="text-xs text-gray-400">{data.pax} pax</div>
      </div>
    );
  };

  return (
    <div
      style={{ zIndex: "999" }}
      className="fixed inset-0 flex items-center justify-center text-gray-500"
    >
      <div className="fixed inset-0 bg-black opacity-50"></div>
      <div className="bg-white py-6 overflow-x-auto rounded-3xl relative mx-4 w-600">
        <button
          className="px-6 text-gray-400 hover:text-gray-600"
          onClick={handleOnClose}
        >
          <Close className="w-4 h-4" />
        </button>

        <div id="highlights" className="px-8 overflow-y-auto h-600">
          <div className="my-5">
            <div className="text-lg font-semibold">Reservations</div>
          </div>

          <div className="flex flex-row text-sm text-gray-500  border-b">
            {tabs.map((tab, idx) => {
              return (
                <div
                  onClick={() => selectTab(idx)}
                  className={`${
                    selectedTab === idx && "border-primary-500 border-b  "
                  } font-semibold px-4 whitespace-nowrap   pb-2 cursor-pointer`}
                  key={idx}
                >
                  {tab}
                </div>
              );
            })}
          </div>
          <div>
            {bLoading && (
              <div className="mt-10">
                <Loader borderColor="border-primary-500" size="h-10 w-10" />
              </div>
            )}
            {selectedTab === 0 &&
              currentEvent?.map((data, i) => returnTable(data, i))}
            {selectedTab === 1 &&
              upcomingEvent?.map((data, i) => returnTable(data, i))}
            {selectedTab === 2 &&
              pendingEvent?.map((data, i) => returnTable(data, i))}
            {selectedTab === 3 &&
              cancelledEvent?.map((data, i) => returnTable(data, i))}
          </div>
        </div>
      </div>
      {showProfile && (
        <ProfileDetails
          accountCode={accountCode}
          handleOnClose={() => setShowProfile(false)}
        />
      )}
     {showCancel && (
        <CancelBooking
          onClose={() => setShowCancel(false)}
          handleCancel={(reason) => decline(reason)}
          isLoading={isDeclinedLoading}
        />
      )}
    </div>
  );
};
