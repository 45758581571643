import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateLogoutModal } from "../../../store/slicers/authSlice";

const Account = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const firstName = useSelector(
    (state) => state.auth.account_info.firstname || ""
  );
  const lastName = useSelector(
    (state) => state.auth.account_info.lastname || ""
  );
  const email = useSelector(
    (state) => state.auth.account_info.email_address || ""
  );
  const merchantInfo = useSelector(
    (state) => state.auth.account_info.merchant_info
  );
  return (
    <div className="px-5 align-center items-center ">
      <div className="flex flex-row gap-x-4">
        <div className="text-2xl font-semibold text-black">Account</div>
   
      </div>
      <div className="flex flex-wrap text-lg mt-2">
        <div className="font-semibold text-black mr-1">
          {firstName + " " + lastName} {email === "" ? "" : ","}
        </div>
        <div className="mr-1">{email}</div>
      </div>

      <div className="mt-5 flex flex-wrap gap-6 lg:gap-12">
        <div
          className="w-80 border border-gray-150 cursor-pointer shadow-xl p-5 rounded-3xl"
          onClick={() => {
            navigate("/account/personal");
          }}
        >
          <div className="w-10 h-10">
            <div className="personalinfo w-full h-full bg-no-repeat bg-cover"></div>
          </div>

          <div className="text-black text-sm font-semibold mt-5">
            Personal Info
          </div>
          <div className="text-gray-400 text-xs">
            Provide personal and contact details.
          </div>
        </div>
        {/* <div
          className="w-80 border border-gray-150 cursor-pointer shadow-xl p-5 rounded-3xl"
          onClick={() => {
            navigate("/account/payments");
          }}
        >
          <div className="w-10 h-10">
            <div className="payments w-full h-full bg-no-repeat bg-cover"></div>
          </div>
          <div className="text-black text-sm font-semibold mt-5">Payments</div>
          <div className="text-gray-400 text-xs">
            Review payment details for payments.
          </div>
        </div> */}
        <div
          className="w-80 border border-gray-150 cursor-pointer shadow-xl p-5 rounded-3xl"
          onClick={() => {
            navigate("/account/login-and-security");
          }}
        >
          <div className="w-10 h-10">
            <div className="loginsecurity w-full h-full bg-no-repeat bg-cover"></div>
          </div>
          <div className="text-black text-sm font-semibold mt-5">
            Login and Security
          </div>
          <div className="text-gray-400 text-xs">
            Secure and update account.
          </div>
        </div>
        <div
          className="w-80 border border-gray-150 cursor-pointer shadow-xl p-5 rounded-3xl"
          onClick={() => {
            navigate("/account/notifications");
          }}
        >
          <div className="w-10 h-10">
            <div className="notification w-full h-full bg-no-repeat bg-cover"></div>
          </div>
          <div className="text-black text-sm font-semibold mt-5">
            Notifications
          </div>
          <div className="text-gray-400 text-xs">
            Update notification reference.
          </div>
        </div>
        <div
          className="w-80 border border-gray-150 cursor-pointer shadow-xl p-5 rounded-3xl"
          onClick={() => {
            navigate("/account/privacy-sharing");
          }}
        >
          <div className="w-10 h-10">
            <div className="privacysharing w-full h-full bg-no-repeat bg-cover"></div>
          </div>
          <div className="text-black text-sm font-semibold mt-5">
            Privacy and Sharing
          </div>
          <div className="text-gray-400 text-xs">
            Manage personal data and data sharing settings.
          </div>
        </div>
        <div
          className="w-80 border border-gray-150 cursor-pointer shadow-xl p-5 rounded-3xl block lg:hidden"
          onClick={() => {
            dispatch(updateLogoutModal(true));
          }}
        >
          <div className="w-10 h-10">
            <div className="personalinfo w-full h-full bg-no-repeat bg-cover"></div>
          </div>
          <div className="text-black text-sm font-semibold mt-5">Logout</div>
          <div className="text-gray-400 text-xs">Logout your account</div>
        </div>
      </div>
    </div>
  );
};

export default Account;
