import React from "react";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import { useSelector } from "react-redux";

const buttonStyle = {
  width: "30px",
  background: "none",
  border: "0px",
  margin: "10px",
};

const properties = {
  prevArrow: (
    <button style={{ ...buttonStyle }}>
      <svg
        className="w-4 h-4 ml-4 text-primary-500"
        width="298"
        height="512"
        shapeRendering="geometricPrecision"
        textRendering="geometricPrecision"
        imageRendering="optimizeQuality"
        fillRule="evenodd"
        clipRule="evenodd"
        viewBox="0 0 298 511.93"
        fill="currentColor"
      >
        <path
          fillRule="nonzero"
          d="M285.77 441c16.24 16.17 16.32 42.46.15 58.7-16.16 16.24-42.45 16.32-58.69.16l-215-214.47c-16.24-16.16-16.32-42.45-.15-58.69L227.23 12.08c16.24-16.17 42.53-16.09 58.69.15 16.17 16.24 16.09 42.54-.15 58.7l-185.5 185.04L285.77 441z"
        />
      </svg>
    </button>
  ),
  nextArrow: (
    <button style={{ ...buttonStyle }}>
      <svg
        className="w-4 h-4 mr-5 transform rotate-180 text-primary-500"
        width="298"
        height="512"
        shapeRendering="geometricPrecision"
        textRendering="geometricPrecision"
        imageRendering="optimizeQuality"
        fillRule="evenodd"
        clipRule="evenodd"
        viewBox="0 0 298 511.93"
        fill="currentColor"
      >
        <path
          fillRule="nonzero"
          d="M285.77 441c16.24 16.17 16.32 42.46.15 58.7-16.16 16.24-42.45 16.32-58.69.16l-215-214.47c-16.24-16.16-16.32-42.45-.15-58.69L227.23 12.08c16.24-16.17 42.53-16.09 58.69.15 16.17 16.24 16.09 42.54-.15 58.7l-185.5 185.04L285.77 441z"
        />
      </svg>
    </button>
  ),
};

export const Slideshow = ({ openAlbumModal }) => {
  const coverImage = useSelector(
    (state) => state.listing.serviceOffered.cover_img
  );
  const servicePhotos = useSelector((state) => state.listing.servicePhotos);

  let images = [];

  images.push(coverImage);
  images.push(...servicePhotos);

  const openA=()=>{
    openAlbumModal();
  }

  return (
    <div className="text-center-webkit w-full block md:hidden">
      <div className="slide-container mt-2 ">
        <Slide duration={500} indicators={true} {...properties}>
          {images.map((image, index) => (
            <div
              className="w-full h-72"
              key={index}
              onClick={() => openA()}
            >
              <img
                draggable={false}
                className={` rounded-3xl w-full h-full bg-no-repeat object-cover sm:object-scale-down bg-center`}
                src={image}
                alt={"Service Gallery " + index}
              />
            </div>
          ))}
        </Slide>
      </div>
    </div>
  );
};
