import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import {
  capitalizeFirstLetter,
  dateFormatter,
  locationFilter,
  provinceFilter,
} from "../../utils/common";
import {
  updateFilterModalDate,
  updateFilterModalIsland,
  updateFilterModalLocation,
  updateFilterModalMerchant,
  updateListingLastPage,
  updateListingLoading,
  updateListingPage,
  updateListings,
  updateSearchModal,
} from "../../store/slicers/listingSlice";
import Checkbox from "./Checkbox";
import Back from "../../assets/svg/Back";
import { Calendar } from "react-multi-date-picker";
function SearchComponent() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isSearchModalOpen = useSelector(
    (state) => state.listing.isSearchModalOpen
  );
  const filterModalType = useSelector((state) => state.listing.filterModalType);

  const filterModalDate = useSelector((state) => state.listing.filterModalDate);
  const filterModalLocation = useSelector(
    (state) => state.listing.filterModalLocation
  );
  const filterModalMerchant = useSelector(
    (state) => state.listing.filterModalMerchant
  );
  const filterModalIsland = useSelector(
    (state) => state.listing.filterModalIsland
  );

  const [selectedIsland, setIsland] = useState(filterModalIsland);
  const [searchPlace, setPlace] = useState("");
  const [searchMerchant, setMerchant] = useState(filterModalMerchant);
  const [searchDate, setDate] = useState(filterModalDate);

  const [regions, setRegion] = useState([]);
  const [selectedProvince, selectProvince] = useState([]);
  const [selectAllProvince, selectAll] = useState(true);

  const [wherePosition, setWherePosition] = useState({ top: 0, left: 0 });
  const [datePosition, setDatePosition] = useState({ top: 0, left: 0 });

  const whereContainerRef = useRef(null);
  const dateContainerRef = useRef(null);

  const islands = [
    { key: "flexible", name: "I'm Flexible" },
    { key: "luzon", name: "Luzon" },
    { key: "visayas", name: "Visayas" },
    { key: "mindanao", name: "Mindanao" },
  ];
  const updatePosition = () => {
    if (whereContainerRef.current) {
      const { top, left } = whereContainerRef.current.getBoundingClientRect();
      setWherePosition({
        top: top + whereContainerRef.current.offsetHeight,
        left,
      });
    }
    if (dateContainerRef.current) {
      const { top, left } = dateContainerRef.current.getBoundingClientRect();
      setDatePosition({
        top: top + dateContainerRef.current.offsetHeight,
        left,
      });
    }
  };
  useEffect(() => {
    updatePosition();
    window.addEventListener("resize", updatePosition);
    return () => {
      window.removeEventListener("resize", updatePosition);
    };
  }, [filterModalType]);
  const handleSelectProvince = (province) => {
    const index = selectedProvince.indexOf(province);
    if (index > -1) {
      selectAll(false);
      selectedProvince.splice(index, 1);
    } else {
      selectedProvince.push(province);
    }
    selectProvince([...selectedProvince]);
    setPlace(selectedProvince.toString());
  };
  const handleSelectAll = () => {
    if (selectAllProvince) {
      // Deselect all provinces
      selectAll(false);
      selectProvince([]);
    } else {
      // Select all provinces
      let allProvinces = [];
      regions.forEach((data) => {
        // Collect all provinces from all regions
        allProvinces = allProvinces.concat(
          data.provinces.map((prv) => prv.province_name)
        );
      });
      selectProvince(allProvinces);
      selectAll(true);
    }
    const sIsland = capitalizeFirstLetter(selectedIsland);
    setPlace(sIsland);
  };

  const handleCloseFilter = (isSearch) => {
    if (isSearch) {
      dispatch(updateFilterModalLocation(searchPlace));
      dispatch(updateFilterModalMerchant(searchMerchant));
      dispatch(updateFilterModalDate(searchDate));
      dispatch(updateFilterModalIsland(selectedIsland));
      const currentURL = window.location.pathname; // Get the current URL
      const newSearchParams = new URLSearchParams(window.location.search); // Extract current query parameters

      newSearchParams.delete("island");
      newSearchParams.delete("location");
      newSearchParams.delete("date");
      newSearchParams.delete("merchant");

      // Add or update query parameters
      if (selectedIsland !== "") {
        newSearchParams.set("island", selectedIsland);
      }
      if (searchPlace !== "") {
        newSearchParams.set("location", searchPlace);
      }
      if (searchDate !== "") {
        newSearchParams.set("date", searchDate);
      }
      if (searchMerchant !== "") {
        newSearchParams.set("merchant", searchMerchant);
      }
      const newURL = `${currentURL}?${newSearchParams.toString()}`;

      dispatch(updateListingLoading(false));
      dispatch(updateListingLastPage(false));
      dispatch(updateListings([]));
      dispatch(updateListingPage("1"));

      navigate(newURL);
    } else {
      setIsland("");
      setPlace("");
      setRegion([]);
      selectProvince([]);
      selectAll(false);
      setMerchant("");
    }
    dispatch(updateSearchModal({ status: false, type: "" }));
    setWherePosition({ top: 0, left: 0 });
    setDatePosition({ top: 0, left: 0 });
  };

  useEffect(() => {
    reUseFilterLocation();
  }, []);

  const reUseFilterLocation = async () => {
    switch (filterModalLocation) {
      case "Luzon":
        setIsland("luzon");
        setPlace("Luzon");
        break;
      case "Visayas":
        setIsland("visayas");
        setPlace("Visayas");
        break;
      case "Mindanao":
        setIsland("mindanao");
        setPlace("Mindanao");
        break;
      case "I'm flexible":
        setIsland("flexible");
        setPlace("I'm flexible");
        break;
      default:
        if (filterModalLocation.includes(",")) {
          let array = filterModalLocation.split(",");
          setIsland(filterModalIsland);
          selectProvince(array);
          setRegion(await locationFilter(selectedIsland));
          setPlace(filterModalLocation);
        }
        break;
    }
  };
  return (
    <div className="hidden lg:block px-10 lg:px-40 xl:px-56">
      <div className="mt-5 inset-x-0 top-0 justify-center z-10">
        <div className="flex">
          <div className="min-w-screen-xl flex flex-wrap justify-center lg:justify-between">
            <div
              ref={dateContainerRef}
              id="search"
              className={`cursor-pointer text-xs select-none rounded-full bg-primary-100 border flex flex-wrap shadow-lg`}
            >
              <div
                onClick={() =>
                  dispatch(updateSearchModal({ status: true, type: "1" }))
                }
                ref={whereContainerRef}
                className={`py-4 relative ${
                  filterModalType === "1" && "bg-white border w-80"
                } rounded-full  cursor-pointer`}
              >
                <div className="cursor-pointer">
                  <div
                    className={`px-4 lg:px-6 self-center font-semibold  ${
                      filterModalType === "1" ? "text-black" : "text-white"
                    }`}
                  >
                    Where
                  </div>
                  <input
                    readOnly
                    disabled={filterModalType !== "1"}
                    value={
                      filterModalType === "1"
                        ? searchPlace
                        : searchPlace.substring(0, 20) +
                          (searchPlace.length > 20 ? "..." : "")
                    }
                    className={`cursor-pointer resize-none border-none  outline-none bg-transparent px-4 lg:px-6 self-center  ${
                      filterModalType === "1"
                        ? "text-gray-400 pr-16 w-full"
                        : "text-white placeholder-white"
                    }`}
                    placeholder="Search your place"
                  />
                </div>
                {searchPlace && filterModalType === "1" && (
                  <button
                    onClick={() => {
                      setIsland("");
                      setRegion([]);
                      setPlace("");
                      selectProvince([]);
                    }}
                    className="absolute top-4 right-0 text-gray-400 mx-4 bg-gray-50 rounded-full p-2 hover:text-gray-600"
                  >
                    <svg
                      className="w-4 h-4 text-black"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                )}
              </div>

              <div
                onClick={() =>
                  dispatch(updateSearchModal({ status: true, type: "2" }))
                }
                className={`py-4 relative self-center ${
                  filterModalType === "2" && " bg-white border w-60"
                } rounded-full `}
              >
                <div
                  className={`text-center px-4 lg:px-6 self-center font-semibold  ${
                    filterModalType === "2" ? "text-black" : "text-white"
                  }`}
                >
                  Event Date
                </div>
                <input
                  value={dateFormatter(searchDate)}
                  className={`text-center resize-none border-none  outline-none bg-transparent self-center  ${
                    filterModalType === "2"
                      ? "text-gray-400 w-full"
                      : "text-white placeholder-white"
                  }`}
                  readOnly
                  disabled={filterModalType !== "2"}
                  placeholder="Any Week"
                />
                {searchPlace && filterModalType === "2" && (
                  <button
                    onClick={() => {
                      setDate("");
                    }}
                    className="absolute top-4 right-0 text-gray-400 mx-4 bg-gray-50 rounded-full p-2 hover:text-gray-600"
                  >
                    <svg
                      className="w-4 h-4 text-black"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                )}
              </div>

              {/* <div
                onClick={() => dispatch(handleSearchModal(true, "3"))}
                className={`py-4 relative  ${
                  filterModalType === "3" && "bg-white border w-60"
                } rounded-full `}
              >
                <div>
                  <div
                    className={`px-4 lg:px-6 self-center font-semibold  ${
                      filterModalType === "3" ? "text-black" : "text-white"
                    }`}
                  >
                    Merchant
                  </div>
                  <input
                    onChange={(e) => {
                      setMerchant(e.target.value);
                    }}
                    value={searchMerchant}
                    className={`resize-none border-none  outline-none bg-transparent pl-4 lg:pl-6 self-center  ${
                      filterModalType === "3"
                        ? "text-gray-400 pr-16 w-full"
                        : "text-white placeholder-white"
                    }`}
                    disabled={filterModalType !== "3"}
                    placeholder="Search Event Listing"
                  />
                </div>
                {searchMerchant && filterModalType === "3" && (
                  <button
                    onClick={() => {
                      setMerchant("");
                    }}
                    className="absolute top-4 right-0 text-gray-400 mx-4 bg-gray-50 rounded-full p-2 hover:text-gray-600"
                  >
                    <svg
                      className="w-4 h-4 text-black"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                )}
              </div> */}

              <div
                className="px-4 py-2 self-center text-white"
                onClick={() => {
                  handleCloseFilter(true);
                }}
              >
                <div className="items-center px-2 py-2 rounded-3xl flex flex-row bg-primary-500 text-white">
                  <svg
                    style={{ padding: "4px" }}
                    xmlns="http://www.w3.org/2000/svg"
                    color="#FFF"
                    viewBox="0 0 31 32"
                    width="32px"
                    height="32px"
                  >
                    <path
                      fill="#ffffff"
                      d="M 19 3 C 13.488281 3 9 7.488281 9 13 C 9 15.394531 9.839844 17.589844 11.25 19.3125 L 3.28125 27.28125 L 4.71875 28.71875 L 12.6875 20.75 C 14.410156 22.160156 16.605469 23 19 23 C 24.511719 23 29 18.511719 29 13 C 29 7.488281 24.511719 3 19 3 Z M 19 5 C 23.429688 5 27 8.570313 27 13 C 27 17.429688 23.429688 21 19 21 C 14.570313 21 11 17.429688 11 13 C 11 8.570313 14.570313 5 19 5 Z"
                    />
                  </svg>
                  <div className="mr-2">Search</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {whereContainerRef.current &&
        isSearchModalOpen &&
        filterModalType === "1" &&
        (searchPlace === "" || selectedIsland !== "") && (
          <div className="absolute">
            <div
              onClick={() =>
                dispatch(updateSearchModal({ status: false, type: "" }))
              }
              className="bg-black opacity-50"
              style={{
                position: "fixed",
                top: wherePosition.top + 10,
                left: 0,
                width: "100%",
                height: "100%",
                zIndex: 1,
              }}
            ></div>
            <div
              className="mt-2"
              style={{
                position: "fixed",
                top: wherePosition.top,
                left: wherePosition.left,
                width:
                  regions.length === 0
                    ? whereContainerRef.current.offsetWidth
                    : "45rem",
                zIndex: 2,
              }}
            >
              <div className="bg-white text-sm p-6 rounded-3xl shadow-lg">
                {regions.length === 0 ? (
                  <>
                    <div className="">Search by Island</div>
                    <div className="items-center grid grid-cols-2 gap-2 mt-5">
                      {islands.map((data, key) => {
                        return (
                          <div
                            key={key}
                            className="justify-self-center group"
                            onClick={async () => {
                              setIsland(data.key);
                              setRegion(await locationFilter(data.key));
                              selectProvince(await provinceFilter(data.key));
                              setPlace(data.name);
                              if (data.key === "flexible") {
                                dispatch(
                                  updateSearchModal({ status: true, type: "2" })
                                );
                              }
                            }}
                          >
                            <div className="self-center relative w-28 h-28 border rounded-2xl border-primary-500 p-2  cursor-pointer">
                              <div
                                className={
                                  data.key +
                                  ` w-full h-full bg-no-repeat bg-cover ${
                                    selectedIsland === data.key
                                      ? "opacity-100"
                                      : "opacity-50"
                                  } group-hover:opacity-100`
                                }
                              ></div>
                              <div className="rounded-2xl absolute inset-0  opacity-0 group-hover:opacity-10 transition-opacity duration-300 bg-primary-500"></div>
                            </div>
                            <div
                              className={`text-xs text-center mt-1  ${
                                selectedIsland === data.key
                                  ? "opacity-100"
                                  : "opacity-50"
                              } group-hover:opacity-100`}
                            >
                              {data.name}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </>
                ) : (
                  <>
                    <div className="items-center flex flex-row justify-between">
                      <div
                        onClick={() => {
                          setIsland("");
                          setRegion([]);
                          setPlace("");
                          selectProvince([]);
                        }}
                      >
                        <Back className="w-4 h-4" />
                      </div>
                      <Checkbox
                        checkSize="h-4 w-4"
                        label={
                          selectAllProvince ? "Unselect All" : "Select All"
                        }
                        labelClass="text-xs"
                        checked={selectAllProvince}
                        onChange={() => {
                          handleSelectAll();
                        }}
                        leftSide={true}
                      />
                    </div>
                    <div className="max-h-96 overflow-y-scroll ml-4 mt-2 grid grid-cols-2 gap-2">
                      {regions.map((data, key) => {
                        return (
                          <div key={key}>
                            <div className="text-primary-500">
                              {data.region_name}
                            </div>
                            <div>
                              {data.provinces.map((prv, i) => {
                                return (
                                  <div key={i}>
                                    <Checkbox
                                      checkSize="h-4 w-4"
                                      label={prv.province_name}
                                      labelClass="text-xs"
                                      checked={selectedProvince.includes(
                                        prv.province_name
                                      )}
                                      onChange={() =>
                                        handleSelectProvince(prv.province_name)
                                      }
                                    />
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        )}

      {dateContainerRef.current &&
        isSearchModalOpen &&
        filterModalType === "2" && (
          <div className="absolute">
            <div
              onClick={() =>
                dispatch(updateSearchModal({ status: false, type: "" }))
              }
              className="bg-black opacity-50"
              style={{
                position: "fixed",
                top: datePosition.top + 10,
                left: 0,
                width: "100%",
                height: "100%",
                zIndex: 1,
              }}
            ></div>
            <div
              className="mt-2"
              style={{
                position: "fixed",
                top: datePosition.top,
                left: datePosition.left - 120,
                width: dateContainerRef.current.offsetWidth,
                zIndex: 2,
              }}
            >
              <div className="bg-white absolute text-sm p-6 rounded-3xl shadow-lg">
                <Calendar
                  className="purple"
                  range
                  value={searchDate.split(",")}
                  minDate={dayjs().toDate()}
                  numberOfMonths={2}
                  headerOrder={["MONTH_YEAR", "LEFT_BUTTON", "RIGHT_BUTTON"]}
                  onChange={(date) => {
                    if (date?.toString().includes(",")) {
                      setDate(date?.toString());
                    }
                  }}
                />
              </div>
            </div>
          </div>
        )}

      {dateContainerRef.current &&
        isSearchModalOpen &&
        filterModalType === "3" && (
          <div className="absolute">
            <div
              onClick={() =>
                dispatch(updateSearchModal({ status: false, type: "" }))
              }
              className="bg-black opacity-50"
              style={{
                position: "fixed",
                top: datePosition.top + 10,
                left: 0,
                width: "100%",
                height: "100%",
                zIndex: 1,
              }}
            ></div>
          </div>
        )}
    </div>
  );
}

export default SearchComponent;
