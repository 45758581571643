import { useState } from "react";
import Close from "../../../assets/svg/Close";
import { PrimaryButton } from "../../common/PrimaryButton";
import { clientEvents } from "../../../api/client/listing";
import Error from "../../../assets/svg/Error";
import { toast } from "react-hot-toast";
export const CreateEvent = ({ isOpen, onClose, rerendList }) => {
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [eventName, setEventName] = useState("");

  const [selectedFile, setSelectedFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);

  const handleOnClose = () => {
    onClose();
  };

  const handleFileChange = (e) => {
    const allowedFileTypes = ["image/jpeg", "image/jpg", "image/png"];

    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      if (allowedFileTypes.includes(file.type)) {
        setSelectedFile(file);

        // Display the selected image preview
        const reader = new FileReader();
        reader.onload = (e) => {
          if (e.target && typeof e.target.result === "string") {
            setImagePreview(e.target.result);
          }
        };
        reader.readAsDataURL(file);
      }
    }
  };

  const handleUpload = async () => {
    if (selectedFile) {
      setIsLoading(true);
      try {
        const _requestData = {
          flag: "add",
          list_name: eventName,
          list_img: selectedFile,
        };
        const response = await clientEvents(_requestData);
        if (response.error === 0) {
          toast(response.msg);
          setIsLoading(false);
          rerendList();
          handleOnClose();
        } else {
          setError(response.error_msg);
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
        setError("Failed. Please try again.");
      }
    } else {
      setError("Please upload a photo for your event.");
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 text-gray-500">
      <div className="fixed inset-0 bg-black opacity-50"></div>
      <div className="bg-white py-6 rounded-3xl relative mx-4 w-500">
        <button
          className="px-6 text-gray-400 hover:text-gray-600"
          onClick={handleOnClose}
        >
          <Close className="w-4 h-4" />
        </button>

        <div
          id="events"
          className="px-8 overflow-y-auto"
          style={{ maxHeight: "600px" }}
        >
          <div className="my-5">
            <div className="text-lg font-semibold">Create an event</div>
          </div>

          <div className="relative h-60 w-full cursor-pointer">
            {imagePreview ? (
              <img
                draggable="false"
                src={imagePreview}
                alt="Selected"
                className="mt-6 h-full w-full border rounded-3xl bg-no-repeat bg-center bg-contain"
              />
            ) : (
              <div className="mt-6 rounded-lg w-full bg-primary-500 h-full">
                <div className="w-28 h-full mx-auto mb-5">
                  <div
                    className="anyaya h-full w-full "
                    style={{ backgroundPositionY: "center" }}
                  />
                </div>
              </div>
            )}
          </div>

          <label
            className={
              "bg-white text-primary-500  mt-5 px-10 flex justify-center w-full text-sm  rounded-xl cursor-pointer"
            }
          >
            <input type="file" className="hidden" onChange={handleFileChange} />
            {selectedFile ? "Change Photo" : "Upload Photo"}
          </label>

          <div id="eventName" className="mt-5">
            <div
              className={`w-full  items-center p-2 border mr-2 ${
                error && "border-red-500"
              } rounded-xl text-sm`}
            >
              <label htmlFor="event" className="px-2 text-xs text-gray-400">
                Event Name
              </label>
              <div className="flex">
                <input
                  autoFocus={true}
                  className="resize-none border-none px-2 outline-none w-full bg-transparent"
                  id="event"
                  type="text"
                  placeholder="Event Name"
                  value={eventName}
                  onChange={(e) => setEventName(e.target.value)}
                />
              </div>
            </div>
          </div>

          <div className="mt-2">
            {error && (
              <span className="text-xs text-red-500 flex flex-row item-center">
                <Error className="w-3 mr-1 self-center" />
                {error}
              </span>
            )}
          </div>

          <PrimaryButton
            isLoading={isLoading}
            title={"Create an event"}
            onClick={() => {
              handleUpload();
            }}
          />
        </div>
      </div>
    </div>
  );
};
