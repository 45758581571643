import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import ContentLoader from "react-content-loader";
import { useNavigate } from "react-router-dom";
import Close from "../../../assets/svg/Close";
import Check from "../../../assets/svg/Check";
import { addToEventList } from "../../../api/client/listing";
import { toast } from "react-hot-toast";
import Loader from "../../common/Loader";
import Star from "../../../assets/svg/Star";
import { PrimaryButton } from "../../common/PrimaryButton";
export const EventModal = ({
  isOpen,
  onClose,
  code,
  rerendList,
  selectedCodes,
}) => {
  const sRowPerPage = 20;
  const [aListings, setListings] = useState([]);
  const [bLoading, setLoading] = useState(false);
  const [bLastPage, setLastPage] = useState(false);
  const [iPage, setPage] = useState(1);
  const [aBookingCode, setBookingCode] = useState(selectedCodes);
  const [isSaving, setSaving] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (isOpen && code) {
      handleListings();
    }
  }, [isOpen, code]);

  const handleListings = async () => {
    if (bLoading || bLastPage) {
      return;
    }

    setLoading(true);

    const _requestData = {
      page_no: iPage,
      row_per_page: sRowPerPage,
      flag: "init",
    };

    try {
      const response = await addToEventList(_requestData);
      if (response.error === 0) {
        if (
          response.data.pagination.current_page === 1 &&
          response.data.my_bookings.length === 0
        ) {
          setLastPage(true);
          setLoading(false);
          return;
        }

        setLoading(false);
        if (response.data.pagination.current_page === 1) {
          setListings(response.data.my_bookings);
          setPage(2);
        } else {
          const listings = aListings?.concat(response.data.my_bookings);
          setListings(listings);
          setPage(response.data.pagination.current_page + 1);
        }

        if (
          response.data.pagination.current_page ===
          response.data.pagination.total_page
        ) {
          setLastPage(true);
        }
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const handleOnClose = () => {
    setListings([]);
    setLoading(false);
    setLastPage(false);
    setPage(1);
    setSaving(false);
    setBookingCode([]);
    onClose();
  };
  const loaders = Array.from({ length: 10 }, (_, index) => (
    <ContentLoader
      key={index}
      speed={1}
      width="10rem"
      height="10rem"
      viewBox="0 0 195 205"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <rect rx="20" ry="20" width="100%" height="100%" />
    </ContentLoader>
  ));

  const handleClick = (data) => {
    if (aBookingCode.includes(data)) {
      setBookingCode((prevData) => prevData.filter((item) => item !== data));
    } else {
      setBookingCode((prevData) => [...prevData, data]);
    }
  };

  const addEvent = async () => {
    setSaving(true);
    try {
      const _requestData = {
        flag: "save",
        event_list_code: code,
        booking_code: aBookingCode,
      };
      const response = await addToEventList(_requestData);
      if (response.error === 0) {
        setSaving(false);
        toast(response.msg);
        rerendList();
        handleOnClose();
      } else {
        setSaving(false);
      }
    } catch (error) {
      setSaving(false);
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 text-gray-500">
      <div className="fixed inset-0 bg-black opacity-50"></div>
      <div className="bg-white py-6 rounded-3xl relative mx-4 w-600">
        <div className="flex flex-row justify-between">
          <button
            className="px-6 text-gray-400 hover:text-gray-600"
            onClick={handleOnClose}
          >
            <Close className="w-4 h-4" />
          </button>
          {!bLoading && aListings?.length === 0 ? (
            <div className="py-5"></div>
          ) : bLoading ?        <div className="py-5"></div> : (
            <div
              onClick={() => addEvent()}
              className="cursor-pointer px-10 py-5 text-sm text-primary-500 font-semibold"
            >
              {isSaving ? (
                <Loader borderColor="border-primary-500" />
              ) : (
                "Update Event"
              )}
            </div>
          )}
        </div>
        <div id="events" className="px-8 overflow-y-auto h-600 mh-600">
          <div className="mb-5 flex flex-row justify-between">
            <div className="text-lg font-semibold">Confirmed events</div>
          </div>
          <div id="events" className="">
            <InfiniteScroll
              dataLength={aListings?.length || 0}
              next={() => handleListings()}
              hasMore={!bLastPage}
              loader={bLoading ? loaders : null}
              scrollThreshold={0.5}
              className={`${
                !bLoading && aListings?.length === 0
                  ? ""
                  : "grid grid-cols-2 md:grid-cols-3 gap-8"
              }`}
            >
              {!bLoading && aListings?.length === 0 ? (
                <div className="w-full h-full flex flex-col justify-center items-center">
                  <div className="text-lg font-semibold mt-10">
                    Nothing is in your Reservations
                  </div>
                  <div className="text-sm">
                    Your Reservation is currently empty
                  </div>
                  <div className="w-60 mt-5">
                    <PrimaryButton
                      title={"Explore Listings"}
                      onClick={() => {
                        navigate("/category");
                      }}
                    />
                  </div>
                </div>
              ) : (
                aListings?.map((data, i) => (
                  <div
                    key={i}
                    onClick={() => handleClick(data.booking_code)}
                    className="cursor-pointer relative"
                  >
                    {aBookingCode.includes(data.booking_code) && ( // Check if booking code exists in array
                      <div className="bg-primary-500 rounded-full absolute top-2 right-2">
                        <Check className="w-4 h-4 text-white" />
                      </div>
                    )}

                    <img
                      draggable="false"
                      alt={data.title}
                      src={data.cover_img}
                      className="h-40 w-40 border w-full rounded-3xl bg-no-repeat bg-center bg-cover"
                    />
                    <div className="flex flex-row justify-between">
                      <div className="mt-2 text-xs">
                        <div className="font-primary-500 font-semibold">
                          {data.title}
                        </div>
                        <div className="text-xs">{data.merchant_name}</div>
                      </div>
                      {/* {data.star_rank && (
                        <div className="lg:p-1 text-xs font-semibold mt-2 flex lg:self-end lg:mt-0">
                          <Star className="mr-2" />
                          {data.star_rank}
                        </div>
                      )} */}
                    </div>
                  </div>
                ))
              )}
            </InfiniteScroll>
          </div>
        </div>
      </div>
    </div>
  );
};
