import React, { useState } from "react";
import Close from "../../../assets/svg/Close";
import { ModalHeader } from "../../common/ModalHeader";
import { chatMerchant } from "../../../api/merchant/chat";
import { chatClient } from "../../../api/client/chat";
import { toast } from "react-hot-toast";
import Loader from "../../common/Loader";
const InboxPhotos = ({
  handleOnClose,
  uploadedPhotos,
  location,
  id,
  resetMessages,
}) => {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploadedPreviews, setUploadedPreviews] = useState(
    uploadedPhotos ?? []
  );
  const [selectedImage, setSelectedImage] = useState(null);
  const [isUploadLoading, setIsUploadLoading] = useState(false);

  const handleUploadedFileChange = (e) => {
    const files = e.target.files;

    if (files) {
      const newFiles = Array.from(files);
      setUploadedFiles([...uploadedFiles, ...newFiles]);

      const newPreviews = Array.from(files).map((file) =>
        URL.createObjectURL(file)
      );
      setUploadedPreviews([...uploadedPreviews, ...newPreviews]);
    }
  };

  const removeUploadedPreview = (index, name) => {
    const updatedPreviews = uploadedPreviews.filter((_, i) => i !== index);
    const updatedFiles = uploadedFiles.filter((_, i) => i !== index);
    setUploadedFiles(updatedFiles);
    setUploadedPreviews(updatedPreviews);
    if (Boolean(name.includes("blob")) === false) {
      handleRemove(name);
    }
  };

  const renderPermitPreviews = () => {
    return uploadedPreviews?.map((preview, index) => {
      let file = preview?.file_url
      let name = preview?.img_name
      if (typeof preview === 'string') {
        file = preview
        name = preview
      }
      return (
        <div key={index} className="relative">
          <img
            src={file}
            alt={`Img ${index + 1}`}
            onClick={() => setSelectedImage(file)}
            className="w-full h-40 bg-no-repeat object-cover bg-center"
          />
          <button
            onClick={() =>
              removeUploadedPreview(index, name)
            }
            className="absolute top-0 right-0 mt-4 mr-1  bg-red-500 text-white rounded-full text-xs"
          >
            <Close className="w-4 h-4" />
          </button>
        </div>
      );
    });
  };

  const handleSubmit = async () => {
    setIsUploadLoading(true);
    const _requestData = {
      flag: "upload_img",
      id: id,
      img: uploadedFiles,
    };
    try {
      let response = null;
      if (location === "merchant") {
        response = await chatMerchant(_requestData);
      } else {
        response = await chatClient(_requestData);
      }
      if (response?.error === 0) {
        setIsUploadLoading(false);
        toast(response.msg);
        resetMessages();
        handleOnClose()
      } else {
        toast(response.error_msg);
        setIsUploadLoading(false);
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const handleRemove = async (name) => {
    const _requestData = {
      flag: "remove_img",
      id: id,
      img_name: name,
    };
    try {
      let response = null;
      if (location === "merchant") {
        response = await chatMerchant(_requestData);
      } else {
        response = await chatClient(_requestData);
      }
      if (response?.error === 0) {
        toast(response.msg);
        resetMessages();
      } else {
        toast(response.error_msg);
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 text-gray-500">
      <div className="fixed inset-0 bg-black opacity-50"></div>
      <div className="bg-white rounded-3xl relative py-6 mx-4 w-500">
        <ModalHeader
          title={"Uploaded Photo"}
          onClick={() => {
            handleOnClose();
          }}
          type={1}
        />
        <div className="overflow-auto h-500 px-5 gap-2 flex flex-col">
          <div className={`w-full items-center text-sm`}>
            <div className="text-xs mb-2">
              Please upload maximum of 3 photos
            </div>

            <div className="flex">
              <input
                id="permit"
                type="file"
                accept="image/*"
                className="mt-2 hidden"
                onChange={handleUploadedFileChange}
                multiple
              />
            </div>
          </div>

          <div className="w-full grid grid-cols-2 gap-3 h-full">
            {renderPermitPreviews()}
          </div>

          <div className="flex flex-row mt-4">
            {uploadedPreviews?.length < 3 && (
              <label
                htmlFor="permit"
                className="mr-2 cursor-pointer border border-sunrise-500 rounded-lg mr-2 p-2"
              >
                <p className="px-2 cursor-pointer text-xs text-sunrise-500">
                  Upload
                </p>
              </label>
            )}
            <div
              onClick={isUploadLoading ? () => {} : () => handleSubmit()}
              className="mr-2 cursor-pointer border border-primary-500 rounded-lg mr-2 p-2"
            >
              <p className="px-2 cursor-pointer text-xs text-primary-500">
                {isUploadLoading ? (
                  <Loader borderColor="border-primary-500" />
                ) : (
                  "Submit"
                )}
              </p>
            </div>
          </div>

          {selectedImage && (
            <div className="fixed inset-0 flex items-center justify-center z-50 text-gray-500">
              <div
                onClick={() => setSelectedImage(null)}
                className="fixed inset-0 bg-black opacity-50"
              ></div>
              <div className="bg-white rounded-3xl relative py-6 mx-4 w-500">
                <div className="modal-content">
                  <img src={selectedImage} alt="Selected Img" />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default InboxPhotos;
