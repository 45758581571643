import React, { useState } from "react";
import "react-slideshow-image/dist/styles.css";
import { Slideshow } from "./SlideShow";
import { useSelector } from "react-redux";
import AlbumModal from "./AlbumModal";

const Gallery = () => {
  const coverImage = useSelector(
    (state) => state.listing.serviceOffered.cover_img
  );
  const images = useSelector((state) => state.listing.servicePhotos);
  const [albumModalOpen, setAlbumModalOpen] = useState(false);
  const [hideModal, setHideModal] = useState(false);

  const openAlbumModal = () => {
    setAlbumModalOpen(true);
    setHideModal(false);
  };

  return (
    <>
      <Slideshow openAlbumModal={() => openAlbumModal()} />

      <div className="mx-auto mt-4 flex flex-row hidden md:flex h-80 xl:h-96">
        <div
          className="relative w-1/2 cursor-pointer mr-2 group"
          onClick={openAlbumModal}
        >
          <img
            draggable={false}
            className="block object-cover w-full bg-no-repeat bg-center h-full bg-cover rounded-tl-xl rounded-bl-xl"
            src={coverImage}
            alt="Cover"
          />
          <div className="rounded-tl-xl rounded-bl-xl absolute inset-0  opacity-0 group-hover:opacity-10 transition-opacity duration-300 bg-black"></div>
        </div>
        <div className="w-1/2 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2">
          {images.slice(0, 4).map((image, index) => (
            <div
              key={index}
              className="relative flex flex-wrap cursor-pointer group"
              onClick={openAlbumModal}
            >
              <div className="w-full">
                <img
                  draggable={false}
                  className={`${index + 1 === 2 ? "pl-1 rounded-tr-xl" : ""} ${
                    index + 1 === 4
                      ? "pt-1 pl-1 rounded-br-xl"
                      : index === 0
                      ? ""
                      : index === 2
                      ? "pt-1"
                      : ""
                  } object-cover block w-full bg-no-repeat bg-center h-40 xl:h-48 bg-cover`}
                  src={image}
                  alt={"Service Gallery " + index}
                />
                {index + 1 === images.length && (
                  <div className="bg-white absolute z-2 right-0 m-4 rounded-full text-xs px-4 py-2 bottom-0 hover:bg-gray-200">
                    Show All Photos
                  </div>
                )}
                <div
                  className={`${index + 1 === 2 ? "rounded-tr-xl" : ""} ${
                    index + 1 === 4 ? "rounded-br-xl" : ""
                  } absolute inset-0  opacity-0 hover:opacity-10 transition-opacity duration-300 bg-black`}
                ></div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <AlbumModal
        hideModal={hideModal}
        setHideModal={(status) => {
          setHideModal(status);
        }}
        albumModalOpen={albumModalOpen}
        setAlbumModalOpen={(status) => {
          setAlbumModalOpen(status);
        }}
      />
    </>
  );
};

export default Gallery;
