import { useDispatch, useSelector } from "react-redux";
import { updateLoginModal } from "../../store/slicers/authSlice";
import { toast } from "react-hot-toast";
import {
  updateListingPage,
  updateListings,
  updateMultipleFields,
  updateWishlistModal,
} from "../../store/slicers/listingSlice";
import { wishlist } from "../../api/client/listing";
export const ShareAndSave = () => {
  const isLoggedIn = useSelector((state) => state.auth.token);
  const serviceOffered = useSelector((state) => state.listing.serviceOffered);
  const listings = useSelector((state) => state.listing.listings);
  const listingPage = useSelector((state) => state.listing.listingPage);
  const serviceCalendar = useSelector((state) => state.listing.serviceCalendar);
  const servicePricing = useSelector((state) => state.listing.servicePricing);
  const servicePhotos = useSelector((state) => state.listing.servicePhotos);
  const serviceReviews = useSelector((state) => state.listing.serviceReviews);
  const eventHighlights = useSelector((state) => state.listing.eventHighlights);
  const dispatch = useDispatch();

  const removeToWishlist = async (id) => {
    try {
      const _requestData = {
        act: "remove_to_wishlist",
        service_code: id,
      };
      const response = await wishlist(_requestData);
      if (response.error === 0) {
        const index = listings.findIndex(
          (listing) => listing.service_code === id
        );

        if (index !== -1) {
          const updatedListings = [...listings]; // Creating a new array
          updatedListings[index] = {
            ...updatedListings[index],
            in_wishlist: "0",
          }; // Updating the specific item immutably
          dispatch(updateListings(updatedListings));
          dispatch(updateListingPage(listingPage));
          dispatch(
            updateMultipleFields({
              serviceCalendar,
              serviceOffered: { ...serviceOffered, in_wishlist: "0" },
              servicePricing,
              servicePhotos,
              serviceReviews,
              eventHighlights,
            })
          );
        }

        toast("Successfully removed from wishlist");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleShare = () => {
    navigator.clipboard.writeText(window.location.href);
    toast.dismiss();
    toast("Link Copied");
  };
  return (
    <div className="flex gap-4">
      <button
        onClick={() => {
          handleShare();
        }}
        className="flex items-center flex-row gap-2 hover:bg-gray-50  rounded-full"
      >
        <div className="w-5 h-5">
          <div className="save w-full h-full bg-no-repeat bg-cover"></div>
        </div>
        <p className="text-xs font-semibold underline">Share</p>
      </button>
      <button
        onClick={() => {
          if (isLoggedIn) {
            if (serviceOffered.in_wishlist === "1") {
              removeToWishlist(serviceOffered.service_code);
            } else {
              dispatch(
                updateWishlistModal({
                  status: true,
                  id: serviceOffered.service_code,
                })
              );
            }
          } else {
            dispatch(updateLoginModal({ status: true }));
          }
        }}
        className="flex items-center flex-row gap-2 hover:bg-gray-50 p-2 rounded-full"
      >
        <div className="w-5 h-5">
          <div className="heart  w-full h-full bg-no-repeat bg-cover"></div>
        </div>
        <p className="text-xs font-semibold underline">
          {isLoggedIn
            ? serviceOffered.in_wishlist === "0"
              ? "Save"
              : "Remove"
            : "Save"}
        </p>
      </button>
    </div>
  );
};
