import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ModalHeader } from "../../common/ModalHeader";
import Error from "../../../assets/svg/Error";
import Loader from "../../common/Loader";
import { wishlist } from "../../../api/client/listing";
import {
  updateListingPage,
  updateListings,
  updateMultipleFields,
  updateWishlistModal,
} from "../../../store/slicers/listingSlice";
import { toast } from "react-hot-toast";
export const WishlistModal = () => {
  const dispatch = useDispatch();
  const wishlistModal = useSelector((state) => state.listing.wishlistModal);
  const selectedListingId = useSelector(
    (state) => state.listing.selectedListingId
  );
  const listings = useSelector((state) => state.listing.listings);
  const listingPage = useSelector((state) => state.listing.listingPage);
  const serviceCalendar = useSelector((state) => state.listing.serviceCalendar);
  const serviceOffered = useSelector((state) => state.listing.serviceOffered);
  const servicePricing = useSelector((state) => state.listing.servicePricing);
  const servicePhotos = useSelector((state) => state.listing.servicePhotos);
  const serviceReviews = useSelector((state) => state.listing.serviceReviews);
  const eventHighlights = useSelector((state) => state.listing.eventHighlights);

  const [wishlistName, setName] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const handleInput = (event) => {
    setName(event.currentTarget.value);
  };
  useEffect(() => {
    if (wishlistModal) {
      setName("");
    }
  }, [wishlistModal]);

  const handleCreate = async () => {
    setError("");
    if (wishlistName === "") {
      setError("Name is required.");
      return;
    }
    setIsLoading(true);
    try {
      const _requestData = {
        act: "add_to_wishlist",
        flag: "save",
        list_name: wishlistName,
        service_code: selectedListingId,
      };
      const response = await wishlist(_requestData);
      if (response.error === 0) {
        const index = listings.findIndex(
          (listing) => listing.service_code === selectedListingId
        );

        if (index !== -1) {
          const updatedListings = [...listings]; // Creating a new array
          updatedListings[index] = {
            ...updatedListings[index],
            in_wishlist: "1",
          }; // Updating the specific item immutably
          dispatch(updateListings(updatedListings));
          dispatch(updateListingPage(listingPage));
          dispatch(
            updateMultipleFields({
              serviceCalendar,
              serviceOffered: { ...serviceOffered, in_wishlist: "1" },
              servicePricing,
              servicePhotos,
              serviceReviews,
              eventHighlights,
            })
          );
        }

        setIsLoading(false);
        toast("Successfully saved as " + wishlistName);
        handleClose();
      } else {
        setIsLoading(false);
        setError(response.error_msg);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      setError("Saving Failed. Please try again.");
    }
  };

  const handleClose = () => {
    dispatch(
      updateWishlistModal({
        status: false,
        id: "",
      })
    );
    setError("");
  };

  return (
    <>
      <div className="fixed inset-0 flex items-center justify-center z-50 text-gray-500">
        <div className="fixed inset-0 bg-black opacity-50"></div>
        <div className="bg-white rounded-3xl relative py-6 mx-4 w-500">
          <ModalHeader
            title={"Name this wishlist"}
            onClick={() => {
              handleClose();
            }}
            type={1}
          />

          <div id="name_wishlist" className="px-6">
            <div
              className={`flex items-center p-2 border ${
                error && "border-red-500"
              } rounded-xl text-sm`}
            >
              <input
                autoFocus={true}
                onChange={handleInput}
                value={wishlistName}
                maxLength={50}
                className="resize-none border-none  outline-none w-full p-2 bg-transparent"
                placeholder=""
              />
            </div>
            {error && (
              <div className="text-xs mt-2 text-red-500 flex flex-row item-center">
                <Error className="h-3 w-3 mr-1 self-center" />
                {error}
              </div>
            )}
            <div className="text-xs mt-5">50 characters maximum</div>
          </div>
          <div className="px-6">
            <button
              className="mt-4 bg-primary-500 px-10 w-full text-white font-semibold py-4 rounded-xl hover:opacity-90"
              onClick={isLoading ? () => {} : handleCreate}
            >
              {isLoading ? <Loader /> : <p className="text-sm">Create</p>}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
