import * as React from "react";

const ChevronDown = (props) => {
  const { className } = props;

  return (
    <svg
      className={className}
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
    >
      <g clipPath="url(#clip0_215_172)">
        <path
          d="M7.2925 8.37418L11.5 12.5725L15.7075 8.37418L17 9.66668L11.5 15.1667L6 9.66668L7.2925 8.37418Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_215_172">
          <rect
            width="22"
            height="22"
            fill="white"
            transform="translate(0.5 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ChevronDown;
