import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-multi-date-picker";
import dayjs from "dayjs";
import { useLocation, useNavigate } from "react-router-dom";
import { updateMultipleFields } from "../../../store/slicers/listingSlice";
import Loader from "../../common/Loader";
import {
  reserve,
  reviewMerchant,
  viewListing,
} from "../../../api/client/listing";
import { Breadcrumbs } from "../../common/Breadcrumbs";
import Star from "../../../assets/svg/Star";
import MetaTags from "../../common/MetaTags";
import Error from "../../../assets/svg/Error";
import { tConvert } from "../../../utils/common";
import Helmet from "react-helmet";
import { chatClient } from "../../../api/client/chat";
import { SuccessModal } from "./SuccessModal";

const ReviewMerchant = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitLoading, setSubmitLoading] = useState(false);
  const [error, setError] = useState("");
  const [serviceOffered, setServiceOffered] = useState({});
  const [communication, setCommunication] = useState("");
  const [accuracy, setAccuracy] = useState("");
  const [value, setValue] = useState("");
  const [outcome, setOutcome] = useState("");
  const [details, setDetails] = useState("");
  const [successModal, setShowSuccess] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const pathnameParts = location.pathname.split("/");
  const bookingId = pathnameParts[pathnameParts.length - 2];

  const handleInit = async () => {
    try {
      const _requestData = {
        flag: "chat_booking_detail",
        id: bookingId,
      };
      const response = await chatClient(_requestData);
      if (response.error === 0) {
        const data = response.data;
        setServiceOffered(data?.service_offered);
        setIsLoading(false);
      } else {
        window.close();
      }
    } catch (error) {
      window.close();
    }
  };

  useEffect(() => {
    handleInit();
  }, []);
  const submitReview = async () => {
    setSubmitLoading(true);
    setError("");
    const _requestData = {
      merchant_code: serviceOffered?.merchant_code,
      communication: communication,
      accuracy: accuracy,
      value: value,
      outcome: outcome,
      remarks: details,
      booking_code: bookingId,
    };
    try {
      const response = await reviewMerchant(_requestData);
      if (response.error === 0) {
        setSubmitLoading(false);
        setShowSuccess(true);
      } else {
        setError(response.error_msg);
        setSubmitLoading(false);
      }
    } catch (error) {
      setSubmitLoading(false);
      setError(error);
    }
  };

  const closeReview = () => {
    setShowSuccess(false);
    navigate("/category");
  };
  return (
    !isLoading && (
      <div className="px-5  align-center items-center mt-5">
        <Breadcrumbs
          listing={true}
          location={`${serviceOffered?.title}/review-merchant`}
        />
        <Helmet>
          <MetaTags
            title={"Review Merchant - " + serviceOffered.title}
            description={serviceOffered.about}
            keywords={
              serviceOffered.full_name +
              " " +
              serviceOffered.municipality +
              " " +
              serviceOffered.province
            }
            imageUrl={serviceOffered.cover_img}
          />
        </Helmet>

        <div className="text-2xl font-semibold text-black">Review Merchant</div>

        <div className="flex flex-col mt-5 gap-10">
          <div className="">
            <div className="bg-white">
              <div className="flex flex-wrap pb-5">
                <div className="relative cursor-pointer mr-5 group">
                  <img
                    alt="gallery"
                    draggable="false"
                    className="rounded-xl rounded-bl-xl block h-full w-40 object-cover object-center"
                    src={serviceOffered?.cover_img}
                  />
                  <div className="rounded-xl rounded-bl-xl absolute inset-0  opacity-0 group-hover:opacity-10 transition-opacity duration-300 bg-black"></div>
                </div>
                <div className="mt-2">
                  <div>
                    <div className="font-semibold">{serviceOffered?.title}</div>
                    <div className="font-semibold">
                      {serviceOffered?.merchant_name}
                    </div>

                    <div className="flex flex-wrap">
                      <div className="font-semibold w-40">Event Date</div>
                      <div className="font-semibold">
                        {dayjs(serviceOffered?.start_date).format(
                          "MMM DD, YYYY h:MM A"
                        )}{" "}
                        {" - "}
                        {serviceOffered?.start_date !==
                          serviceOffered?.end_date &&
                          dayjs(serviceOffered?.end_date).format(
                            "MMM DD, YYYY h:MM A"
                          )}
                      </div>
                    </div>

                    <div className="flex flex-wrap">
                      <div className="font-semibold w-40">Event Location</div>
                      <div className="font-semibold">
                        {serviceOffered?.location}
                      </div>
                    </div>

                    <div className="flex flex-wrap">
                      <div className="font-semibold w-40">Contract Price</div>
                      <div className="font-semibold">
                        {serviceOffered?.service_price} PHP
                      </div>
                    </div>
                  </div>
                  {serviceOffered.star_rank && (
                    <div className="mt-2 items-center text-xs flex flex-wrap">
                      <Star className="mr-2" height={12} width={12} />
                      {serviceOffered.star_rank} |
                      <div className="text-xs  ml-1 mr-2  text-sunrise-500">
                        {serviceOffered.review_count}
                        {Number(serviceOffered.review_count) > 1
                          ? " stars"
                          : " star"}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-wrap w-full">
            <div>
              <div className=" flex flex-row">
                <div className="self-center w-40 font-semibold">
                  Communication
                </div>
                {["1", "2", "3", "4", "5"].map((id) => {
                  return (
                    <div
                      onClick={() => setCommunication(id)}
                      className={`px-4 py-2 bg-primary-100 opacity-90 text-primary-500 rounded-lg mx-4 cursor-pointer ${
                        communication === id &&
                        "opacity-100 bg-primary-500 text-white"
                      }`}
                      key={id}
                    >
                      {id}
                    </div>
                  );
                })}
              </div>
              <div className=" flex flex-row mt-5">
                <div className="self-center  w-40 font-semibold">Accuracy</div>
                {["1", "2", "3", "4", "5"].map((id) => {
                  return (
                    <div
                      onClick={() => setAccuracy(id)}
                      className={`px-4 py-2 bg-primary-100 opacity-90 text-primary-500 rounded-lg mx-4 cursor-pointer ${
                        accuracy === id &&
                        "opacity-100 bg-primary-500 text-white"
                      }`}
                      key={id}
                    >
                      {id}
                    </div>
                  );
                })}
              </div>
              <div className=" flex flex-row mt-5">
                <div className="self-center  w-40 font-semibold">Value</div>
                {["1", "2", "3", "4", "5"].map((id) => {
                  return (
                    <div
                      onClick={() => setValue(id)}
                      className={`px-4 py-2 bg-primary-100 opacity-90 text-primary-500 rounded-lg mx-4 cursor-pointer ${
                        value === id && "opacity-100 bg-primary-500 text-white"
                      }`}
                      key={id}
                    >
                      {id}
                    </div>
                  );
                })}
              </div>
              <div className=" flex flex-row mt-5">
                <div className="self-center  w-40 font-semibold">Outcome</div>
                {["1", "2", "3", "4", "5"].map((id) => {
                  return (
                    <div
                      onClick={() => setOutcome(id)}
                      className={`px-4 py-2 bg-primary-100 opacity-90 text-primary-500 rounded-lg mx-4 cursor-pointer ${
                        outcome === id &&
                        "opacity-100 bg-primary-500 text-white"
                      }`}
                      key={id}
                    >
                      {id}
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="mt-5 lg:mt-0 ml-5">
              <div className="my-2">
                How did your event go with this merchant?
              </div>
              <textarea
                rows="5"
                className="resize-none p-2 outline-none w-full rounded-lg border border-primary-500"
                type="text"
                value={details}
                onChange={(e) => {
                  setDetails(e.target.value);
                }}
              />

              <div className="w-60">
                <button
                  className="mt-4 bg-primary-500 px-10 w-full text-white font-semibold py-4 rounded-xl hover:opacity-90"
                  onClick={isSubmitLoading ? () => {} : submitReview}
                >
                  {isSubmitLoading ? (
                    <Loader />
                  ) : (
                    <p className="text-sm">Submit Review</p>
                  )}
                </button>
              </div>
            </div>
            {error && (
              <div className="text-xs mt-2 text-red-500 flex flex-row item-center">
                <Error className="h-3 w-3 mr-1 self-center" />
                {error}
              </div>
            )}
          </div>
        </div>

        {successModal && <SuccessModal onClose={() => closeReview()} />}
      </div>
    )
  );
};
export default ReviewMerchant;
