import React, { RefObject, useEffect, useRef, useState } from "react";
import Details from "./Details";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import InfiniteScroll from "react-infinite-scroll-component";
import ContentLoader from "react-content-loader";
import { chatClient } from "../../../api/client/chat";
import Loader from "../../common/Loader";
import { useWebSocketMessage } from "../../common/WebSocket/WebSocketContext";
import Back from "../../../assets/svg/Back";
import { PrimaryButton } from "../../common/PrimaryButton";
import DetailsPage from "./DetailsMobile";
import { updateUnreads } from "../../../store/slicers/authSlice";
import { unreadData } from "../../../api/client";
import { statusHandler, statusHandlerColor } from "../../../utils/common";

const GuestInbox = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const conversationRef = useRef(null);
  const profileImage = useSelector(
    (state) => state.auth.account_info.profile_img
  );
  const [messages, setMessages] = useState([]);
  const [mPage, setMPage] = useState(1);
  const [mLast, setMLast] = useState(false);
  const [mLoading, setMLoading] = useState(false);

  const [conversation, setConversation] = useState([]);
  const [cPage, setCPage] = useState(1);
  const [cLast, setCLast] = useState(false);
  const [cLoading, setCLoading] = useState(false);
  const [selectedChat, selectChat] = useState();

  const [dLoading, setDLoading] = useState(false);
  const [availableHeight, setAvailableHeight] = useState("0px");
  const [availableHeightMessages, setAvailableHeightMessages] = useState("0px");
  const [isDetailsOpen, setDetailsOpen] = useState(false);
  const [chatDetails, setChatDetails] = useState();
  const [paymentSchedule, setPaymentSchedule] = useState();
  const [serviceOffered, setServiceOffered] = useState();
  const [bookingReceipt, setBookingReceipt] = useState();

  const [isSidebarVisible, setSidebarVisible] = useState(true);
  const [chatImages, setChatImages] = useState();
  const [clientChatImages, setClientChatImages] = useState();

  const [inputChat, setInputChat] = useState("");
  const [sLoading, setSLoading] = useState(false);

  const toggleSidebar = (status) => {
    setSidebarVisible(status);
  };

  useEffect(() => {
    // Scroll chat to the bottom when a new message arrives
    if (conversationRef.current) {
      conversationRef.current.scrollTop = conversationRef.current.scrollHeight;
    }
  }, [conversation]);
  useEffect(() => {
    // Scroll to the bottom after initial render
    if (conversationRef.current) {
      const scrollContainer = conversationRef.current;
      const lastElement = scrollContainer.lastElementChild;
      lastElement && lastElement.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  const handleMessages = async () => {
    if (mLoading || mLast) {
      return;
    }

    setMLoading(true);

    let _requestData = {
      flag: "init",
      page_no: mPage,
      row_per_page: 20,
    };

    try {
      const response = await chatClient(_requestData);
      if (response.error === 0) {
        setMLoading(false);
        if (
          response.data.pagination.current_page === 1 &&
          response.data.data_list.length === 0
        ) {
          setMLast(true);
          return;
        }
        if (response.data.pagination.current_page === 1) {
          setMessages(response.data.data_list);
          selectChat(response.data.data_list[0]);
          setMPage(2);
        } else {
          const msg = messages.concat(response.data.data_list);
          setMessages(msg);
          setMPage(response.data.pagination.current_page + 1);
        }

        if (
          response.data.pagination.current_page ===
          response.data.pagination.total_page
        ) {
          setMLast(true);
        }
      } else {
        navigate(-1);
      }
    } catch (error) {
      navigate(-1);
    }
  };

  useEffect(() => {
    handleChat();
    handleDetails();
  }, [selectedChat]);

  // Calculate and set the available height for the sections
  useEffect(() => {
    window.scrollTo(0, 0);
    handleMessages();

    const updateWindowSize = () => {
      const topbarHeight = document.getElementById("topbar")?.offsetHeight ?? 0;
      const conversationHeaderHeight =
        document.getElementById("conversationHeader")?.offsetHeight ?? 0;
      const inputChatHeight =
        document.getElementById("inputChat")?.offsetHeight ?? 0;
      const availableHeight =
        window.innerHeight -
        (topbarHeight + conversationHeaderHeight + inputChatHeight);

      setAvailableHeight(availableHeight - 190 + "px");
      setAvailableHeightMessages(
        window.innerHeight -
          (topbarHeight + conversationHeaderHeight + 130) +
          "px"
      );
    };

    // Attach the event listener to the resize event
    window.addEventListener("resize", updateWindowSize);

    // Initial setup
    updateWindowSize();

    document.getElementById("routes")?.classList.add("inbox");
    document.body.classList.add("no-scroll-body");

    // When the component unmounts, remove the no-scroll-body class from the body
    return () => {
      window.removeEventListener("resize", updateWindowSize);
      document.body.classList.remove("no-scroll-body");
      document.getElementById("routes")?.classList.remove("inbox");
    };
  }, []);

  useEffect(() => {
    if (!isDetailsOpen) {
      const updateWindowSize = () => {
        const topbarHeight =
          document.getElementById("topbar")?.offsetHeight ?? 0;
        const conversationHeaderHeight =
          document.getElementById("conversationHeader")?.offsetHeight ?? 0;
        const inputChatHeight =
          document.getElementById("inputChat")?.offsetHeight ?? 0;
        const availableHeight =
          window.innerHeight -
          (topbarHeight + conversationHeaderHeight + inputChatHeight);

        setAvailableHeight(availableHeight - 190 + "px");
        setAvailableHeightMessages(
          window.innerHeight -
            (topbarHeight + conversationHeaderHeight + 130) +
            "px"
        );
      };

      // Attach the event listener to the resize event
      window.addEventListener("resize", updateWindowSize);
      // Initial setup
      updateWindowSize();
      document.getElementById("routes")?.classList.add("inbox");
      document.body.classList.add("no-scroll-body");
    }
  }, [isDetailsOpen]);

  const handleChat = async () => {
    if (selectedChat !== undefined) {
      if (cLoading || cLast) {
        return;
      }

      setCLoading(true);

      let _requestData = {
        page_no: cPage,
        row_per_page: 10,
        id: "",
        flag: "",
      };
      if (selectedChat.type === "booking") {
        _requestData = {
          ..._requestData,
          id: selectedChat.id,
          flag: "view_booking",
        };
      }
      try {
        const response = await chatClient(_requestData);
        if (response.error === 0) {
          setCLoading(false);
          if (
            response.data.pagination.current_page === 1 &&
            response.data.data_list.length === 0
          ) {
            setCLast(true);
            return;
          }
          if (response.data.pagination.current_page === 1) {
            setConversation(response.data.data_list);
            setCPage(2);
                
          } else {
            const convo = conversation.concat(response.data.data_list);
            setConversation(convo);
            setCPage(response.data.pagination.current_page + 1);
          }

          if (
            response.data.pagination.current_page ===
            response.data.pagination.total_page
          ) {
            setCLast(true);
          }
        } else {
          setCLoading(false);
        }
      } catch (error) {
        console.log("Error:", error);
      }
    }
  };

  const handleDetails = async () => {
    if (selectedChat !== undefined) {
      setDLoading(true);
      let _requestData = {
        id: selectedChat.id,
        flag: "",
      };
      if (selectedChat.type === "booking") {
        _requestData = {
          ..._requestData,
          flag: "chat_booking_detail",
        };
      } else {
        _requestData = {
          ..._requestData,
          flag: "chat_inquiry_detail",
        };
      }
      try {
        const response = await chatClient(_requestData);
        if (response.error === 0) {
          setDLoading(false);
          setChatDetails(response.data.chat_detail);
          setPaymentSchedule(response.data.payment_schedule);
          setServiceOffered(response.data.service_offered);
          setChatImages(response.data.merchant_chat_images);
          setClientChatImages(response.data.client_chat_images);
          setBookingReceipt(response.data.booking_receipt);
        } else {
          setDLoading(false);
        }
      } catch (error) {
        console.log("Error:", error);
        setDLoading(false);
      }
    } else {
      setDLoading(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      if (inputChat !== "") {
        sendMessage();
      }
    }
  };

  const sendMessage = async () => {
    if (selectedChat !== undefined) {
      // Logic to send the message
      if (sLoading) {
        return;
      }

      setSLoading(true);

      let _requestData = {
        id: "",
        flag: "",
        content: inputChat,
      };
      // Additional logic to send the message to the server or perform other actions
      // Clear the input field after sending the message

      // conversation
      const newMsg = {
        type: "0",
        content: inputChat,
        is_me: "1",
        date_created: new Date(),
      };
      setConversation([newMsg, ...conversation]);

      const existingMessageIndex = messages.findIndex(
        (msg) => msg.id === selectedChat.id
      );
      let updatedMessages = [...messages]; // Make a copy of the original messages array

      if (existingMessageIndex === -1) {
        // If the message doesn't exist, create a new message object
        const newMessageObj = {
          id: selectedChat.id,
          last_updated: new Date(),
          last_msg: inputChat,
        };
        updatedMessages.unshift(newMessageObj); // Add the new message to the start of the array
      } else {
        // If the message already exists, update its properties and move it to the start of the array
        updatedMessages[existingMessageIndex].last_updated = new Date();
        updatedMessages[existingMessageIndex].last_msg = inputChat;
        const existingMessage = updatedMessages.splice(existingMessageIndex, 1); // Remove the existing message
        updatedMessages.unshift(...existingMessage); // Add the updated message to the start
      }

      setMessages(updatedMessages); // Set the state with the updated messages array

      setInputChat("");
      if (selectedChat.type === "booking") {
        _requestData = {
          ..._requestData,
          id: selectedChat.id,
          flag: "send_booking",
        };
      } else {
        _requestData = {
          ..._requestData,
          id: selectedChat.id,
          flag: "send_inquiry",
        };
      }

      try {
        const response = await chatClient(_requestData);
        if (response.error === 0) {
          setSLoading(false);
        } else {
          setSLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const loaders = Array.from({ length: 1 }, (_, index) => (
    <div className="mt-5">
      <Loader borderColor="border-primary-500" />
    </div>
  ));

  const openChat = (data) => {
    setConversation([]);
    setCPage(1);
    setCLast(false);
    setCLoading(false);
    navigate(`/client/inbox/${data.id}`);
    selectChat(data);
  };

  useWebSocketMessage((message) => {
    const jsonParse = JSON.parse(message);

    // conversation
    if (jsonParse?.data?.chat_msg?.is_me === "0") {
      const newMsg = jsonParse?.data?.chat_msg;
      setConversation([newMsg, ...conversation]);

      // sidebar messages
      const newMessage = jsonParse.data.chat_info;
      // Check if the message ID exists in the array
      const existingMessageIndex = messages.findIndex(
        (msg) => msg.id === newMessage.id
      );

      if (existingMessageIndex === -1) {
        // If the message doesn't exist, prepend it to the array
        setMessages([newMessage, ...messages]);
      } else {
        // If the message already exists, remove it and add it to the start of the array
        const updatedMessages = messages.filter(
          (_, index) => index !== existingMessageIndex
        );
        setMessages([newMessage, ...updatedMessages]);
      }
    }
  });
  const getUnreads = async () => {
    try {
      const response = await unreadData();
      if (response.error === 0) {
        dispatch(
          updateUnreads({
            notif: response.data?.unread_notif,
            msg: response.data?.unread_msg,
            merchantMsg: response?.data?.unread_msg_merchant || 0,
          })
        );
      }
    } catch (error) {}
  };

  return (
    <div
      className={`bg-white min-h-screen ${messages.length !== 0 && "border-t"}`}
    >
      {!isDetailsOpen ? (
        mLast && messages.length === 0 ? (
          <div className="w-full h-full flex flex-col justify-center items-center">
            <div className="text-lg font-semibold mt-10">
              You have no messages
            </div>
            <div className="text-sm ">Your messages is currently empty</div>
            <div className="w-60 mt-5">
              <PrimaryButton
                title={"Book now"}
                onClick={() => {
                  navigate("/category");
                }}
              />
            </div>
          </div>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-8">
            {/* Sidebar */}
            <div
              className={`${
                isSidebarVisible && "hidden lg:block lg:col-span-2"
              } col-span-8`}
            >
              {/* messages */}
              <div className="text-base font-semibold border-b py-4 lg:py-6 px-4">
                Messages
              </div>
              <div
                style={{ height: availableHeightMessages }}
                className="max-h-screen overflow-y-auto pb-10"
              >
                <div className="min-h-0 mb-10">
                  <InfiniteScroll
                    dataLength={messages.length}
                    next={() => handleMessages()}
                    hasMore={!mLast}
                    loader={mLoading ? loaders : null}
                    scrollThreshold={0.5}
                    style={{ overflow: mLoading ? "hidden" : "auto" }}
                  >
                    {messages.map((data, key) => {
                      return (
                        <div
                          onClick={() => {
                            data.unread = null;
                            getUnreads();
                            if (data.id !== selectedChat?.id) {
                              openChat(data);
                              toggleSidebar(true);
                            }
                          }}
                          className={`${
                            data["id"] === selectedChat?.id && "bg-primary-50"
                          } grid grid-cols-5 p-2 lg:p-4 cursor-pointer border-b hover:bg-primary-50 relative`}
                          key={key}
                        >
                          <div className="align-center justify-self-center col-span-0 md:col-span-1">
                            {data["profile_img"] !== "" ? (
                              <img
                                draggable="false"
                                className="mr-3 rounded-full bg-primary-500 w-12 lg:w-10 h-12 lg:h-10 bg-no-repeat bg-cover"
                                src={data["profile_img"]}
                                alt={"Profile_Chat" + data["chat_title"]}
                              />
                            ) : (
                              <div className="mr-3 rounded-full defaulticon w-12 lg:w-10 h-12 lg:h-10 bg-no-repeat bg-cover"></div>
                            )}
                          </div>

                          <div
                            className={`${
                              data.unread && "font-semibold"
                            } col-span-4 flex flex-col w-full`}
                          >
                            <div className="flex flex-wrap">
                              <div className="text-sm">{data["chat_desc"]}</div>
                              <div className="text-sm px-1"> · </div>
                              <div className="text-sm text-gray-400">
                                {data["chat_title"]}
                              </div>
                            </div>
                            <div className="flex flex-col">
                              <div className="text-xs  whitespace-nowrap text-ellipsis overflow-hidden">
                                {data["last_msg"]}
                              </div>
                              <div className="flex flex-col">
                                {/* <div
                                  className={`text-xs ${statusHandlerColor(
                                    data?.booking_status
                                  )}`}
                                >
                                  {statusHandler(data?.booking_status)}
                                </div> */}
                                <div className="text-xs text-gray-400 ">
                                  {dayjs(data["last_updated"]).format(
                                    "MMMM D, YY h:mm A"
                                  )}
                                </div>
                              </div>
                            </div>

                            {data.unread && ( // Render the unread indicator based on 'data.unread'
                              <span className="bg-primary-500 rounded-full p-1 absolute top-1 right-1"></span>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </InfiniteScroll>
                </div>
              </div>
            </div>

            <div
              className={`${
                !isSidebarVisible && "hidden"
              }  lg:block lg:col-span-4 col-span-8 boder-none lg:border-x  break-all`}
            >
              <div className=" border-b py-4 px-4" id="conversationHeader">
                <div className="flex flex-row justify-between">
                  <div className="flex flex-row">
                    <div
                      className="block lg:hidden mr-2 self-center"
                      onClick={() => {
                        toggleSidebar(false);
                        selectChat();
                      }}
                    >
                      <Back className="w-6 h-6" />
                    </div>
                    {selectedChat && !dLoading ? (
                      <div className="flex flex-col">
                        <div className="text-base font-semibold">
                          {selectedChat?.chat_title || "-"}
                        </div>
                        <div className="text-xs">
                          Response time: {chatDetails?.response_time || "-"}
                        </div>
                      </div>
                    ) : (
                      <ContentLoader
                        speed={2}
                        width={200}
                        height={40}
                        viewBox="0 0 200 40"
                        backgroundColor="#f3f3f3"
                        foregroundColor="#ecebeb"
                      >
                        <rect
                          x="3"
                          y="0"
                          rx="2"
                          ry="2"
                          width="237"
                          height="40"
                        />
                      </ContentLoader>
                    )}
                  </div>
                  <div
                    className="underline text-sm font-semibold text-primary-500 text-center block lg:hidden mr-2 self-center"
                    onClick={() => {
                      setDetailsOpen(true);
                    }}
                  >
                    View Details
                  </div>
                </div>
              </div>
              <div
                ref={conversationRef}
                id="conversation"
                style={{ height: availableHeight }}
                className="max-h-screen overflow-y-auto "
              >
                <div className="min-h-0 mb-10">
                  <InfiniteScroll
                    dataLength={conversation.length}
                    next={() => handleChat()}
                    hasMore={!cLast}
                    scrollableTarget="conversation"
                    loader={cLoading ? loaders : null}
                    scrollThreshold={1}
                    style={{ display: "flex", flexDirection: "column-reverse" }} //To put endMessage and loader to the top.
                    // inverse={true}
                    // lastScrollTop={() => handleChat()}
                  >
                    {conversation.map((chat, index) => {
                      return (
                        <div key={index}>
                          {chat.is_me === "0" && chat.type === "1" && (
                            <div className="flex flex-row px-4 py-4 cursor-pointer">
                              <div className="flex items-center justify-center w-12 h-12 mr-3">
                                <div className="icon-anyaya h-full w-full" />
                              </div>
                              <div className="flex flex-col w-full">
                                <div className="flex flex-row items-center">
                                  <p className="text-sm font-semibold">
                                    Anyaya
                                  </p>
                                  <p className="text-xs px-1"> · </p>
                                  <p className="text-xs text-gray-400">
                                    {dayjs(chat.date_created).format(
                                      "MMMM D, YY h:mm A"
                                    )}
                                  </p>
                                </div>
                                <p
                                  className="text-sm"
                                  style={{ wordBreak: "break-word" }}
                                >
                                  {chat.content}
                                </p>
                                <div className="hidden lg:block">
                                  <p className="my-2 text-gray-400 text-sm">
                                    Request Form Details
                                  </p>
                                  <div
                                    className="border rounded-xl text-sm"
                                    style={{ maxWidth: "400px" }}
                                  >
                                    {chat.booking_details.map((detail, idx) => {
                                      return (
                                        <div
                                          key={idx}
                                          className={`${
                                            idx === 0 ? "" : "border-t"
                                          } flex flex-row justify-between p-3`}
                                        >
                                          <p className="text-gray-400">
                                            {detail.title}
                                          </p>
                                          <p>{detail.value}</p>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                          {chat.type === "0" && (
                            <div className="flex flex-row px-4 py-4 cursor-pointer">
                              {chat.is_me === "1" ? (
                                profileImage !== "" ? (
                                  <img
                                    draggable="false"
                                    className="mr-3 rounded-full bg-primary-500 w-14 h-12 bg-no-repeat bg-cover"
                                    src={profileImage}
                                    alt="Profile"
                                  />
                                ) : (
                                  <div className="mr-3 rounded-full defaulticon w-14 h-12 bg-no-repeat bg-cover"></div>
                                )
                              ) : chatDetails?.profile_img !== "" ? (
                                <img
                                  draggable="false"
                                  className="mr-3 rounded-full bg-primary-500 w-14 h-12 bg-no-repeat bg-cover"
                                  src={chatDetails?.profile_img}
                                  alt="Profile"
                                />
                              ) : (
                                <div className="mr-3 rounded-full defaulticon w-14 h-12 bg-no-repeat bg-cover"></div>
                              )}

                              <div className="flex flex-col w-full">
                                <div className="flex flex-row items-center">
                                  <div className="text-sm font-semibold">
                                    {chat.is_me === "1"
                                      ? "Me"
                                      : chatDetails?.title}
                                  </div>
                                  <div className="text-xs px-1"> · </div>
                                  <p className="text-xs text-gray-400">
                                    {dayjs(chat.date_created).format(
                                      "MMMM D, YY h:mm A"
                                    )}
                                  </p>
                                </div>
                                <p
                                  className="text-sm"
                                  style={{ wordBreak: "break-word" }}
                                >
                                  {chat.content}
                                </p>
                              </div>
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </InfiniteScroll>
                </div>
              </div>
              {!["3", "4", "5", "6"].includes(
                serviceOffered?.booking_status
              ) && (
                <div
                  id="inputChat"
                  className="px-4 lg:px-10 py-4 text-center relative"
                >
                  <input
                    className="border rounded-full w-full lg:w-3/4 h-12 px-2 lg:px-4 pr-10 text-sm"
                    placeholder="Type a message"
                    value={inputChat}
                    onChange={(e) => {
                      setInputChat(e.target.value);
                    }}
                    onKeyDown={handleKeyPress}
                  />
                  <svg
                    className="absolute text-primary-500 right-8 lg:hidden  top-7 cursor-pointer text-gray-500"
                    width="24"
                    height="24"
                    fill={"currentColor"}
                    viewBox="0 0 24 24"
                    onClick={inputChat === "" ? () => {} : () => sendMessage()}
                  >
                    <path d="M2.01 21L23 12 2.01 3 2 10l15 2-15 2z" />
                  </svg>
                </div>
              )}
            </div>
            <div className="hidden md:block md:col-span-2 ">
              {/* details */}
              <div className="text-base font-semibold border-b py-6 px-4">
                Details
              </div>

              <div
                style={{ height: availableHeightMessages }}
                id="details"
                className="flex flex-col  max-h-screen overflow-y-auto h-full"
              >
                <Details
                  chatDetails={chatDetails}
                  bookingReceipt={dLoading ? null : bookingReceipt}
                  resetMessages={() => {
                    handleDetails();
                  }}
                  paymentSchedule={dLoading ? null : paymentSchedule}
                  serviceOffered={dLoading ? null : serviceOffered}
                  bookingCode={selectedChat?.id}
                  chatImages={dLoading ? null : chatImages}
                  clientChatImages={dLoading ? null : clientChatImages}
                />
              </div>
            </div>
          </div>
        )
      ) : null}

      {isDetailsOpen && (
        <DetailsPage
          chatDetails={chatDetails}
          bookingReceipt={dLoading ? null : bookingReceipt}
          resetMessages={() => {
            handleDetails();
          }}
          paymentSchedule={dLoading ? null : paymentSchedule}
          serviceOffered={dLoading ? null : serviceOffered}
          bookingCode={selectedChat?.id}
          setDetailsOpen={() => setDetailsOpen(false)}
          chatImages={dLoading ? null : chatImages}
          clientChatImages={dLoading ? null : clientChatImages}
        />
      )}
    </div>
  );
};

export default GuestInbox;
