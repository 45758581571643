import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { capitalizeAndReplace } from "../../../utils/common";
import { ListingScroll } from "./List";
import MetaTags from "../../common/MetaTags";
import {
  updateFilterModalDate,
  updateFilterModalIsland,
  updateFilterModalLocation,
  updateFilterModalMerchant,
  updateFilterModalSub,
  updateListingLastPage,
  updateListingLoading,
  updateListingPage,
  updateListings,
  updateMultipleFields,
} from "../../../store/slicers/listingSlice";
import Helmet from "react-helmet";
const Listing = () => {
  const location = useLocation();
  const pathnameParts = location.pathname.split("/");
  const serviceTypeName = pathnameParts[pathnameParts.length - 1];
  const dispatch = useDispatch();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const place = queryParams.get("location");
  const merchant = queryParams.get("merchant");
  const date = queryParams.get("date");
  const island = queryParams.get("island");

  const [sub, setSelectedSub] = useState("");

  const filters = [
    { icon: "allevent", name: "Any Event" },
    { icon: "anniversary", name: "Anniversary" },
    { icon: "bachelor", name: "Bachelor" },
    { icon: "bbq", name: "BBQ" },
    { icon: "birthday", name: "Birthday" },
    { icon: "christening", name: "Christening" },
    { icon: "corporate", name: "Corporate" },
    { icon: "pool", name: "Pool" },
    { icon: "reunion", name: "Reunion" },
    { icon: "wedding", name: "Wedding" },
  ];

  const handleSub = (str) => {
    if (str !== sub && str !== "allevent") {
      updateState(str);
    } else if (str === "allevent" && sub !== "") {
      updateState("");
    }
  };
  
  const updateState = (str) => {
    dispatch(updateListingLoading(false));
    dispatch(updateListingLastPage(false));
    dispatch(updateListings([]));
    dispatch(updateListingPage("1"));
    setSelectedSub(str);
    dispatch(updateFilterModalSub(str));
  };
  useEffect(() => {
    dispatch(
      updateMultipleFields({
        selectedDate: {
          startDate: "",
          endDate: "",
        },
        serviceCalendar: [],
        serviceOffered: [],
        servicePricing: {},
        servicePhotos: [],
        serviceReviews: [],
        eventHighlights: [],
      })
    );

    dispatch(updateFilterModalLocation(place || ""));
    dispatch(updateFilterModalMerchant(merchant || ""));
    dispatch(updateFilterModalDate(date || ""));
    dispatch(updateFilterModalIsland(island || ""));
    dispatch(updateFilterModalSub(sub || ""));
  }, []);

  return (
    <div className="px-10 lg:px-40 xl:px-56 pb-10 pt-6 align-center items-center">
      <div className="text-3xl text-center font-semibold text-primary-500">
        {capitalizeAndReplace(serviceTypeName)}
      </div>
      <Helmet>
        <MetaTags
          title={capitalizeAndReplace(serviceTypeName)}
          description={capitalizeAndReplace(serviceTypeName)}
          keywords={serviceTypeName + " " + place + " " + merchant}
        />
      </Helmet>
      {/* <div className="mt-6 flex flex-wrap gap-6 justify-center"> */}
      <div className="rounded-xl py-2 mt-6  justify-start xl:justify-center items-center flex flex-row w-full overflow-x-auto">
        {filters.map((data, key) => {
          return (
            <div
              key={key}
              onClick={() => {
                handleSub(data.icon);
              }}
              className={`${
                sub === data.icon
                  ? `bg-gray-50`
                  : sub === "" && key === 0
                  ? `bg-gray-50`
                  : ""
              } mx-1 py-2 cursor-pointer text-center-webkit hover:bg-gray-50 rounded-xl`}
            >
              <div className="w-8 h-8">
                <div
                  className={`${
                    sub === data.icon
                      ? `active-${data.icon}`
                      : sub === "" && key === 0
                      ? `active-${data.icon}`
                      : data.icon
                  } w-full h-full bg-no-repeat bg-contain`}
                ></div>
              </div>
              <div
                className={`${
                  sub === data.icon
                    ? `text-primary-500`
                    : sub === "" && key === 0
                    ? `text-primary-500`
                    : ""
                } mt-2 text-sm whitespace-nowrap w-24`}
              >
                {data.name}
              </div>
            </div>
          );
        })}
      </div>

      <div className="flex flex-col lg:flex-row my-10 w-full h-full">
        <ListingScroll />
      </div>
    </div>
  );
};

export default Listing;
