import React, { createContext, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
const WebSocketContext = createContext();
export const WebSocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);
  const accountCode = useSelector(
    (state) => state.auth.account_info?.account_code
  );

  useEffect(() => {
    let isSubscribed = false;
    if (accountCode) {
      const newSocket = new WebSocket("wss://ws.anyaya-events.com");

      newSocket.onopen = () => {
        if (!isSubscribed) {
          const messageJSON = {
            command: "subscribe",
            account_code: accountCode,
          };
          newSocket.send(JSON.stringify(messageJSON));
          isSubscribed = true;
        }
      };

      newSocket.onerror = (error) => {
        console.error("WebSocket error:", error);
      };

      newSocket.onclose = (event) => {
        console.log("WebSocket closed:", event);
        // You can handle reconnection or other logic here
      };

      setSocket(newSocket);

      return () => {
        newSocket.close();
      };
    }
  }, [accountCode]);

  if (!accountCode) {
    return children;
  }

  return (
    <WebSocketContext.Provider value={socket}>
      {children}
    </WebSocketContext.Provider>
  );
};

export const useWebSocket = () => {
  return useContext(WebSocketContext);
};

export const useWebSocketMessage = (callback) => {
  const socket = useWebSocket();
  const messageNotif = useSelector(
    (state) => state.auth.account_settings?.messages
  );
  const navigate = useNavigate();

  useEffect(() => {
    const chat = document.getElementById("inboxAudio");
    const notif = document.getElementById("notificationAudio");
    let playChatPromise = null;
    let playNotifPromise = null;

    const playChat = () => {
      chat.muted = false;
      playChatPromise = chat.play();

      if (playChatPromise !== undefined) {
        playChatPromise
          .then((_) => {})
          .catch((error) => {
            console.error("Failed to play chat:", error);
          });
      }
    };
    const playNotif = () => {
      notif.muted = false;
      playNotifPromise = notif.play();

      if (playNotifPromise !== undefined) {
        playNotifPromise
          .then((_) => {})
          .catch((error) => {
            console.error("Failed to play chat:", error);
          });
      }
    };

    if (socket) {
      socket.onmessage = (event) => {
        const jsonParse = JSON.parse(event.data);
        const data = jsonParse.data;
        callback(event.data);

        // play the sound and notification here
        if (messageNotif === "1") {
          if (jsonParse.type === 1) {
            // content: 'Hello world ulit',
            //  is_me: '1',
            if (data?.chat_msg?.is_me === "0") {
              toast.custom((t) => (
                <div
                  onClick={() => {
                    // go to the inbox
                    toast.dismiss(t.id);
                  }}
                  className={`${
                    t.visible ? "animate-enter" : "animate-leave"
                  } max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
                >
                  <div className="flex-1 w-0 p-4">
                    <div className="flex items-start">
                      <div className="ml-3 flex-1">
                        <p className="text-sm font-medium text-gray-900">
                          {data.chat_info.chat_title}
                        </p>
                        <p className="mt-1 text-sm text-gray-500">
                          {data.chat_info.last_msg.substring(0, 40) +
                            (data.chat_info.last_msg.length > 40 ? "..." : "")}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ));
              playChat();
            }
          }
        }

        if (jsonParse.type === 0 && data?.msg) {
          toast.custom((t) => (
            <div
              onClick={() => {
                navigate("/notifications");
                toast.dismiss(t.id);
              }}
              className={`${
                t.visible ? "animate-enter" : "animate-leave"
              } max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
            >
              <div className="flex-1 w-0 p-4">
                <div className="flex items-start">
                  <div className="ml-3 flex-1">
                    <p className="mt-1 text-sm text-gray-500">
                      {data?.msg.substring(0, 40) +
                        (data?.msg.length > 40 ? "..." : "")}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ));
          playNotif();
        }
      };
    }
  }, [socket, callback]);
};
