import Close from "../../assets/svg/Close";
import Back from "../../assets/svg/Back";

export const ModalHeader = ({ title, onClick, type }) => {
  return (
    <>
      <button
        className="absolute top-6 left-6 text-gray-400 hover:text-gray-600"
        onClick={() => {
          onClick();
        }}
      >
        {type === 1 && <Close className="w-4 h-4" />}
        {type === 2 && <Back className="w-4 h-4" />}
      </button>

      <div className="flex flex-row mb-5 text-center items-center border-b">
        <div className="mb-3 w-full">
          <div className="text-base font-semibold">{title}</div>
        </div>
      </div>
    </>
  );
};
