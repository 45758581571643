import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { validatePhoneNumber } from "../../../utils/common";
import {
  fbLogin,
  getUserDetails,
  googleLogin,
  mobileLogin,
} from "../../../api/client";
import Loader from "../Loader";
import {
  updateAccountInfo,
  updateLoginModal,
  updateNewAccount,
  updateOTPModal,
  updatePaymentModal,
  updatePhone,
  updateToken,
} from "../../../store/slicers/authSlice";
import { ModalHeader } from "../ModalHeader";
import Error from "../../../assets/svg/Error";
import Google from "../../../assets/svg/Google";
import Facebook from "../../../assets/svg/Facebook";
import { useGoogleLogin } from "@react-oauth/google";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import Checkbox from "../Checkbox";

export const Login = () => {
  const dispatch = useDispatch();
  const [phoneNumber, handlePhoneNumber] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const isRemember = useSelector((state) => state.auth.isRemember);
  const [rememberMe, setRemember] = useState(isRemember);

  const handlePhoneNumberChange = (event) => {
    handlePhoneNumber(validatePhoneNumber(event.target.value));
  };

  const handleProceed = async () => {
    setError("");
    if (phoneNumber === "") {
      setError("Phone number is required.");
      return;
    }
    setIsLoading(true);
    try {
      const _requestData = { flag: "init", mobile_no: phoneNumber };
      const response = await mobileLogin(_requestData);
      if (response.error === 0) {
        setIsLoading(false);
        dispatch(updatePhone(phoneNumber));
        dispatch(updateOTPModal(true));
        handleClose();
      } else {
        setIsLoading(false);
        setError(response.error_msg);
      }
    } catch (error) {
      setIsLoading(false);
      setError("Login failed. Please try again.");
    }
  };

  const handleClose = () => {
    setError("");
    handlePhoneNumber("");
    dispatch(updateLoginModal({ status: false }));
  };

  const handleProceedGoogle = async (email, id) => {
    setError("");
    setIsLoading(true);
    try {
      const _requestData = {
        googleid: id,
        email,
        keep_login: rememberMe ? "1" : "0",
      };
      const response = await googleLogin(_requestData);
      if (response.error === 0) {
        const accountInfo = response.data.account_info;
        setIsLoading(false);
        if (accountInfo.new_account) {
          dispatch(updateNewAccount(true));
        }
        dispatch(updateToken(response.data.token));
        dispatch(updateAccountInfo(accountInfo));
        handleClose();
      } else {
        setIsLoading(false);
        setError(response.error_msg);
      }
    } catch (error) {
      setIsLoading(false);
      setError("Login failed. Please try again.");
    }
  };
  const loginGoogle = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const userDetails = await getUserDetails(tokenResponse.access_token);
      if (typeof userDetails === "string") {
        setError(userDetails);
      } else {
        handleProceedGoogle(userDetails.email, userDetails.id);
      }
    },
  });

  const loginFacebook = async (data) => {
    setError("");
    setIsLoading(true);
    try {
      const _requestData = {
        fbid: data.id,
        email: data.email,
        keep_login: rememberMe ? "1" : "0",
      };
      const response = await fbLogin(_requestData);
      if (response.error === 0) {
        const accountInfo = response.data.account_info;
        setIsLoading(false);
        if (accountInfo.new_account) {
          dispatch(updateNewAccount(true));
        }
        dispatch(updateToken(response.data.token));
        dispatch(updateAccountInfo(accountInfo));
        handleClose();
      } else {
        setIsLoading(false);
        setError(response.error_msg);
      }
    } catch (error) {
      setIsLoading(false);
      setError("Login failed. Please try again.");
    }
  };
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 text-gray-500">
      <div className="fixed inset-0 bg-black opacity-50"></div>
      <div className="bg-white rounded-3xl relative py-6 mx-4 w-500">
        <ModalHeader
          title={"Login or sign up"}
          onClick={() => {
            handleClose();
          }}
          type={1}
        />

        <div id="login" className="px-6">
          <div className="mb-5 flex gap-x-2 text-center align-center">
            <div className="text-base font-semibold">Welcome to Anyaya</div>
            <div className="italic text-sm  self-center text-primary-500">
              Free to use!
            </div>
          </div>
          <div
            className={`flex items-center p-2 border ${
              error && "border-red-500"
            } rounded-xl text-sm`}
          >
            <span>+63</span>
            <input
              autoFocus={true}
              value={phoneNumber}
              onChange={handlePhoneNumberChange}
              className="resize-none border-none  outline-none w-full p-2 bg-transparent"
              placeholder="Enter your phone number"
              onKeyDown={(e) => {
                console.log(e)
                if (e.key === "Enter") {
                  handleProceed()
                }
              }}
            />
          </div>

          <div className="my-4">
            <Checkbox
              checkSize="h-4 w-4"
              label={"Remember Me"}
              labelClass="text-xs"
              checked={rememberMe}
              onChange={(val) => {
                setRemember(val);
                dispatch(updatePaymentModal(val));
              }}
            />
          </div>

          {error && (
            <span className="text-xs text-red-500 flex flex-row item-center">
              <Error className="w-3 mr-1 self-center" />
              {error}
            </span>
          )}

          <div className="text-xs mt-5">
            We will send a verification code that you need to enter and register
            your account.
          </div>
        </div>
        <div className="px-6">
          <button
            className="mt-4 bg-primary-500 px-10 w-full text-white font-semibold py-4 rounded-xl hover:opacity-90"
            onClick={isLoading ? () => {} : handleProceed}
          >
            {isLoading ? <Loader /> : <p className="text-sm">Proceed</p>}
          </button>
        </div>
        {Boolean(isMobile) === false && <div className="flex items-center mt-5">
          <div className="border-t flex-grow mr-5" />
          <p className="text-xs">or</p>
          <div className="border-t flex-grow ml-5" />
        </div>}
        {/* <div className="px-6">
          <FacebookLogin
            appId="1721447438328381"
            autoLoad={false}
            fields="name,email"
            isSdkLoaded={true}
            callback={loginFacebook}
            render={(renderProps) => {
              return (
                <button
                  onClick={() => {
                    console.log(">>>>>>>>>>>>test")
                    renderProps.onClick();
                  }}
                  className="mt-4 items-center flex flex-row bg-white border w-full  py-4 rounded-xl hover:bg-gray-50"
                >
                  <Facebook className="mx-4 absolute h-6 w-6" />
                  <p className="flex-grow text-sm text-center">
                    Continue with Facebook
                  </p>
                </button>
              );
            }}
          />
        </div> */}
        {Boolean(isMobile) === false &&
        <div className="px-6 flex">
          <button
            className="mt-4 items-center flex flex-row bg-white border w-full  py-4 rounded-xl hover:bg-gray-50"
            onClick={() => loginGoogle()}
          >
            <Google className="mx-4 absolute h-6 w-6" />
            <p className="flex-grow text-sm text-center">
              Continue with Google
            </p>
          </button>
        </div>}
      </div>
    </div>
  );
};
