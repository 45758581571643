import axiosInstance from "../../utils/axiosInstance";

export async function getServices() {
  const requestData = {
    ctl: "client",
    act: "listings",
  };
  try {
    const response = await axiosInstance.post("/", requestData);
    return response.data;
  } catch (error) {
    return error;
  }
}
export async function getListing(_requestData) {
  const requestData = {
    ctl: "client",
    act: "listings",
    flag: "filter",
    ..._requestData,
  };
  try {
    const response = await axiosInstance.post("/", requestData);
    return response.data;
  } catch (error) {
    return error;
  }
}
export async function viewListing(_requestData) {
  const requestData = {
    ctl: "client",
    act: "view_listing",
    ..._requestData,
  };
  try {
    const response = await axiosInstance.post("/", requestData);
    return response.data;
  } catch (error) {
    return error;
  }
}

export async function reportListing(_requestData) {
  const requestData = {
    ctl: "client",
    act: "report_listing",
    ..._requestData,
  };
  try {
    const response = await axiosInstance.post("/", requestData);
    return response.data;
  } catch (error) {
    return error;
  }
}
export async function wishlist(_requestData) {
  const requestData = {
    ctl: "client",
    ..._requestData,
  };
  try {
    const response = await axiosInstance.post("/", requestData);
    return response.data;
  } catch (error) {
    return error;
  }
}

export async function clientEvents(_requestData) {
  const requestData = {
    ctl: "client",
    act: "event_list",
    ..._requestData,
  };
  try {
    const response = await axiosInstance.post("/", requestData);
    return response.data;
  } catch (error) {
    return error;
  }
}

export async function addToEventList(_requestData) {
  const requestData = {
    ctl: "client",
    act: "add_to_event_list",
    ..._requestData,
  };
  try {
    const response = await axiosInstance.post("/", requestData);
    return response.data;
  } catch (error) {
    return error;
  }
}
export async function reservation(_requestData) {
  const requestData = {
    ctl: "reservation",
    act: "list",
    ..._requestData,
  };
  try {
    const response = await axiosInstance.post("/", requestData);
    return response.data;
  } catch (error) {
    return error;
  }
}


export async function reserve(_requestData) {
  const requestData = {
    ctl: "reservation",
    act: "initial_reserve",
    ..._requestData,
  };
  try {
    const response = await axiosInstance.post("/", requestData);
    return response.data;
  } catch (error) {
    return error;
  }
}

// booking_code: ?
export async function cancelReservation(_requestData) {
  const requestData = {
    ctl: "reservation",
    act: "cancel_reserve",
    ..._requestData,
  };
  try {
    const response = await axiosInstance.post("/", requestData);
    return response.data;
  } catch (error) {
    return error;
  }
}

// booking_code: ?
// start_date: ? <Y-m-d H:i:s>
// end_date: ? <Y-m-d H:i:s>
// location: ?
// pax: ?
// remarks: ?
export async function updateReservation(_requestData) {
  const requestData = {
    ctl: "reservation",
    act: "update_reserve",
    ..._requestData,
  };
  try {
    const response = await axiosInstance.post("/", requestData);
    return response.data;
  } catch (error) {
    return error;
  }
}

export async function paymentReservation(_requestData) {
  const requestData = {
    ctl: "reservation",
    act: "payment",
    ..._requestData,
  };
  try {
    const response = await axiosInstance.post("/", requestData);
    return response.data;
  } catch (error) {
    return error;
  }
}
export async function paymentHistory(_requestData) {
  const requestData = {
    ctl: "reservation",
    act: "payment_history",
    ..._requestData,
  };
  try {
    const response = await axiosInstance.post("/", requestData);
    return response.data;
  } catch (error) {
    return error;
  }
}

export async function merchantInfo(_requestData) {
  const requestData = {
    ctl: "client",
    act: "merchant_info",
    ..._requestData,
  };
  try {
    const response = await axiosInstance.post("/", requestData);
    return response.data;
  } catch (error) {
    return error;
  }
}

export async function reviewMerchant(_requestData) {
  const requestData = {
    ctl: "client",
    act: "review_merchant",
    ..._requestData,
  };
  try {
    const response = await axiosInstance.post("/", requestData);
    return response.data;
  } catch (error) {
    return error;
  }
}

export async function reviewService(_requestData) {
  const requestData = {
    ctl: "client",
    act: "review_service",
    ..._requestData,
  };
  try {
    const response = await axiosInstance.post("/", requestData);
    return response.data;
  } catch (error) {
    return error;
  }
}