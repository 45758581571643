import { useEffect } from "react";

const MetaTags = ({ title, description, keywords, imageUrl }) => {
  const defaultImageUrl =
    "https://www.anyaya-events.com/static/media/logo.c64cfee0ad951bd72b51.png";

  useEffect(() => {
    // Update document's title
    document.title = "Anyaya - " + title;

    // Create or update meta tags
    const metaDescription = document.createElement("meta");
    metaDescription.name = "description";
    metaDescription.content = description;
    document.head.appendChild(metaDescription);

    const metaKeywords = document.createElement("meta");
    metaKeywords.name = "keywords";
    metaKeywords.content = "anyaya events" + keywords;
    document.head.appendChild(metaKeywords);

    // Create og:image meta tag for Facebook and Twitter
    const ogImage = document.createElement("meta");
    ogImage.setAttribute("property", "og:image");
    ogImage.content = imageUrl || defaultImageUrl; // Use imageUrl if provided, otherwise use defaultImageUrl
    document.head.appendChild(ogImage);

    const twitterImage = document.createElement("meta");
    twitterImage.setAttribute("name", "twitter:image");
    twitterImage.content = imageUrl || defaultImageUrl; // Use imageUrl if provided, otherwise use defaultImageUrl
    document.head.appendChild(twitterImage);

    // Clean-up function to remove added meta tags when the component unmounts
    return () => {
      document.head.removeChild(metaDescription);
      document.head.removeChild(metaKeywords);
      document.head.removeChild(ogImage);
      document.head.removeChild(twitterImage);
    };
  }, [title, description, keywords, imageUrl, defaultImageUrl]);

  return null; // Meta tags are added directly to the document, so this component doesn't render anything
};

export default MetaTags;
