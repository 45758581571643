import React, { useCallback, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import "react-slideshow-image/dist/styles.css";
import { useSelector } from "react-redux";
import { ShareAndSave } from "../../../common/ShareAndSave";

const AlbumModal = (props) => {
  const { hideModal, setHideModal, albumModalOpen, setAlbumModalOpen } = props;
  const images = useSelector((state) => state.listing.servicePhotos);
  const imageModalRef = useRef(null); // Ref for tracking image modal state

  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [imageModalOpen, setImageModalOpen] = useState(false);

  const closeAlbumModal = () => {
    setHideModal(true);
    // setTimeout(() => {
      setAlbumModalOpen(false);
      setHideModal(false);
    // }, 400);
  };

  const openImageModal = (index) => {
    setSelectedImageIndex(index);
    setImageModalOpen(true);
  };

  const closeImageModal = () => {
    setImageModalOpen(false);
  };

  const navigateImage = useCallback(
    (direction) => {
      const newIndex =
        (selectedImageIndex + direction + images.length) % images.length;
      setSelectedImageIndex(newIndex);
    },
    [selectedImageIndex, images.length]
  );
  const handleKeyDown = useCallback(
    (event) => {
      if (event.key === "ArrowLeft") {
        navigateImage(-1);
      } else if (event.key === "ArrowRight") {
        navigateImage(1);
      } else if (event.key === "Escape") {
        closeImageModal();
      }
    },
    [navigateImage]
  );

  useEffect(() => {
    if (imageModalOpen) {
      window.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [imageModalOpen, handleKeyDown]);

  return (
    <>
      {albumModalOpen && (
        <div
          className={`fixed top-0 left-0 right-0 bottom-0 justify-center bg-white z-50 overflow-y-auto ${
            hideModal ? "animate-slide-down" : "animate-slide-up"
          }`}
        >
          <div className="py-4 animate-slide-up-image">
            <div className="bg-white sticky top-0  z-10 p-4 flex justify-between items-center mb-4">
              <div>
                <button
                  onClick={closeAlbumModal}
                  className="rounded-full p-2 hover:bg-gray-50"
                >
                  <svg
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="4"
                      d="M15 19l-7-7 7-7"
                    />
                  </svg>
                </button>
              </div>
              <ShareAndSave />
            </div>
            <div className="px-4 max-w-3xl mx-auto">
              <div className="grid grid-cols-2 gap-2">
                {images.map((image, index) => (
                  <img
                    draggable={false}
                    key={index}
                    className={
                      "cursor-pointer object-cover  w-full rounded-xl hover:opacity-90 bg-no-repeat bg-center h-60 bg-cover"
                    }
                    src={image}
                    alt={"Service Gallery " + index}
                    onClick={() => {
                      openImageModal(index);
                    }}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Image Modal */}
      {imageModalOpen && (
        <div
          className="fixed top-0 left-0 right-0 bottom-0 flex justify-center items-center bg-black z-50 animate-slide-up"
          ref={imageModalRef}
        >
          <div className="absolute top-0 left-0 my-4 xl:left-12 xl:top-5">
            <button
              onClick={closeImageModal}
              className="flex flex-row p-2 text-white rounded-full"
            >
              <svg
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
              <p className="text-xs text-center self-center ml-1">Close</p>
            </button>
          </div>
          <div className="max-w-4xl mx-auto relative">
            <img
              draggable={false}
              className="block w-full object-cover bg-no-repeat bg-center h-full bg-cover"
              src={images[selectedImageIndex]}
              alt={"Service Gallery " + selectedImageIndex}
            />
            <button
              onClick={() => navigateImage(-1)}
              className="absolute top-1/2 left-5 transform -translate-y-1/2 bg-gray-900 p-2 rounded-full text-white opacity-70 hover:opacity-100 transition-opacity duration-300 focus:outline-none"
            >
              <svg
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M15 19l-7-7 7-7"
                />
              </svg>
            </button>
            <button
              onClick={() => navigateImage(1)}
              className="absolute top-1/2 right-5 transform -translate-y-1/2 bg-gray-900 p-2 rounded-full text-white opacity-70 hover:opacity-100 transition-opacity duration-300 focus:outline-none"
            >
              <svg
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M9 5l7 7-7 7"
                />
              </svg>
            </button>
            <div className="absolute bottom-5 left-5 px-4 py-2 rounded text-xs bg-gray-900 text-white opacity-70">
              {`${selectedImageIndex + 1}/${images.length}`}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AlbumModal;
