import { useEffect, useState } from "react";
import { accountSettings, logged } from "../../api/client";
import { useDispatch } from "react-redux";
import {
  logout,
  updateAccountInfo,
  updateAccountSettings,
  updateUnreads,
} from "../../store/slicers/authSlice";
import Confetti from "react-confetti";
import BeatLoader from "react-spinners/BeatLoader";
const CLogged = ({ children }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const fetchData = async () => {
    try {
      const response = await logged();
      if (response.error === 0) {
        const accountInfo = response.data.account_info;
        if (accountInfo.new_account) {
          dispatch(logout());
        }
        dispatch(
          updateUnreads({
            notif: response.data.unread_notif,
            msg: response.data.unread_msg,
            merchantMsg: response?.data?.unread_msg_merchant || 0,
          })
        );
        dispatch(updateAccountInfo(accountInfo));
        getAccountSettings();
      } else {
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      // call logout
    }
  };

  const getAccountSettings = async () => {
    try {
      const _requestData = {
        flag: "init",
      };
      const response = await accountSettings(_requestData);
      if (response.error === 0) {
        dispatch(updateAccountSettings(response.data.account_settings));
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  return isLoading ? (
    <>
      <div className="bg-primary-500 h-screen w-full">
        <div className="fixed inset-0 flex items-center justify-center z-50 text-white">
          <div className=" rounded-3xl relative w-500">
            <div className="modal-content">
              <div className="flex justify-right h-16 ">
                <div
                  className="anyaya h-full w-full"
                  style={{ backgroundPositionX: "center" }}
                />
              </div>
              <div className="mt-10" style={{ textAlignLast: "center" }}>
                <BeatLoader color="#fff" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Confetti
        width={window.innerWidth - 20}
        height={window.innerHeight - 20}
        tweenDuration={1}
      />
    </>
  ) : (
    <>{children}</>
  );
};

export default CLogged;
