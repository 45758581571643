import React, { useState } from "react";
import { PrimaryButton } from "./PrimaryButton";

export const SoonMerchant = ({ handleClose }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 text-gray-500">
      <div
        className="fixed inset-0 bg-black opacity-50"
        onClick={() => handleClose()}
      ></div>
      <div className="px-6 bg-white rounded-3xl relative py-6 mx-4 w-500">
        <div className="w-40 h-12 mx-auto">
          <div
            className="logo h-full w-full "
            style={{ backgroundPositionY: "center" }}
          />
        </div>
        
        <div className="mt-4">Merchants are on their way!</div>
        <div className="mt-4">You can easily communicate to merchants sooner than you think! 🙂 </div>
        <div className="mt-2">Visit Anyaya Events Marketplace on facebook for more news and updates. </div>
        <div>
          <PrimaryButton
            title={"Ok"}
            isLoading={false}
            onClick={() => {
              handleClose();
            }}
          />
        </div>
      </div>
    </div>
  );
};
