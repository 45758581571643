import { useEffect, useState } from "react";
import Close from "../../../assets/svg/Close";
import Loader from "../../common/Loader";
import { paymentReservation } from "../../../api/client/listing";

export const MerchantBank = ({ onClose, bookingCode }) => {
  const [merchantBank, setMerchantBank] = useState({});
  const [initLoading, setInitLoading] = useState(true);

  useEffect(() => {
    handleMerchantBank();
  }, []);

  const handleOnClose = () => {
    setMerchantBank({});
    setInitLoading(false);
    onClose();
  };

  const handleMerchantBank = async () => {
    setInitLoading(true);
    try {
      const _requestData = {
        flag: "merchant_bank",
        booking_code: bookingCode,
      };

      const response = await paymentReservation(_requestData);
      if (response.error === 0) {
        setMerchantBank(response.data.merchant_bank);
        setInitLoading(false);
      } else {
        setInitLoading(false);
      }
    } catch (error) {
      setInitLoading(false);
    }
  };
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 text-gray-500">
      <div className="fixed inset-0 bg-black opacity-50"></div>
      <div className="bg-white p-6 mx-4 rounded-3xl relative w-600">
        <button
          className="absolute top-6 left-6 text-gray-400 hover:text-gray-600"
          onClick={() => handleOnClose()}
        >
          <Close className="w-4 h-4" />
        </button>
        <div id="payment_3" className="flex flex-col">
          <div className="mt-10 ">
            <div className="text-lg font-semibold">Basic Payment</div>
            <span className="text-sm">
              Pay via online banking or over the counter payments.
            </span>
          </div>
          <div className="py-2 text-sm">
            <span>Transfer Payment to</span>
            {initLoading ? (
              <div className="h-500">
                <Loader borderColor="border-primary-500" />
              </div>
            ) : (
              <div className="py-2">
                <div className="flex flex-row justify-between">
                  <span id="event-date">Bank</span>
                  <span id="event-date-value" className="text-gray-400">
                    {merchantBank.bank_name}
                  </span>
                </div>
                <div className="flex flex-row justify-between">
                  <span id="event-location">Account Name</span>
                  <span id="event-location-value" className="text-gray-400">
                    {merchantBank.account_name}
                  </span>
                </div>
                <div className="flex flex-row justify-between">
                  <span id="event-price">Account Number</span>
                  <span id="event-price-value" className="text-gray-400">
                    {merchantBank.account_no}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className={`mt-4 flex flex-row justify-between`}>
          <div className="flex flex-row justify-start gap-2"></div>

          <div className="items-center flex flex-col self-center">
            <div className="w-36 h-12 mt-3">
              <div
                className="logo h-full w-full "
                style={{ backgroundPositionY: "center" }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
