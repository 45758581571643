import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { merchantApplication, services } from "../../../api/merchant";
import { updateWelcome } from "../../../store/slicers/authSlice";
import Close from "../../../assets/svg/Close";
import Error from "../../../assets/svg/Error";
import { config } from "../../../utils/config";
import Loader from "../../common/Loader";
import { cities, provinces, regions } from "select-philippines-address";
import {
  islandFilter,
  validateMoney,
  validateNumberOfDays,
  validatePercentage,
} from "../../../utils/common";
import { Modal } from "../../common/Modal";

const ServiceDetails = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [coverPhotoPreview, setCoverPhotoPreview] = useState();

  const pathnameParts = location.pathname.split("/");
  const listingCode = pathnameParts[pathnameParts.length - 1];
  const [region, setRegion] = useState({});
  const [regionData, setRegions] = useState([]);
  const [province, setProvince] = useState("");
  const [provinceData, setProvinces] = useState([]);
  const [city, setCity] = useState("");
  const [cityData, setCities] = useState([]);
  const [address, setAddress] = useState("");

  const [reservationFee, setReservationFee] = useState("");

  const [category, setCategory] = useState({});
  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [price, setPrice] = useState("");

  const [numberOfDaysFull, setNumberOfDaysFull] = useState("");

  const [supplyCapacity, setSupplyCapacity] = useState("");
  const [theme, setTheme] = useState([]);
  const [timeLimit, setTimeLimit] = useState("");
  const [outOfTownFee, setOutOfTownFee] = useState("");
  const [eventTheme, setEventTheme] = useState("");
  const [describeListing, setDescribeListing] = useState("");
  const [specialInclusion, setSpecialInclusion] = useState("");
  const [removeServiceImage, setRemoveServiceImage] = useState([]);

  const [selectedImage, setSelectedImage] = useState(null);

  const [idFiles, setIdFiles] = useState([]);
  const [idPreviews, setIdPreviews] = useState([]);

  const [coverPreviewFile, setCoverPreviewFile] = useState({});
  const [coverPreview, setCoverPreview] = useState({});

  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [page, setPage] = useState(1);

  const filters = [
    { icon: "anniversary", name: "Anniversary" },
    { icon: "bachelor", name: "Bachelor" },
    { icon: "bbq", name: "BBQ" },
    { icon: "birthday", name: "Birthday" },
    { icon: "christening", name: "Christening" },
    { icon: "corporate", name: "Corporate" },
    { icon: "pool", name: "Pool" },
    { icon: "reunion", name: "Reunion" },
    { icon: "wedding", name: "Wedding" },
  ];

  const openModal = (preview) => {
    setSelectedImage(preview);
  };
  useEffect(() => {
    handleInit();
    handleInitView();
  }, []);

  const getProvinces = async (data) => {
    const provinceData = await provinces(data.region_code).then((response) => {
      return response;
    });
    setProvinces(provinceData);
  };

  const getCity = async (data) => {
    const cityData = await cities(data.province_code).then((response) => {
      return response;
    });
    setCities(cityData);
  };

  const handleInitView = async () => {
    setIsLoading(true);
    try {
      const _requestData = {
        act: "update",
        flag: "init",
        service_code: listingCode,
      };

      const response = await services(_requestData);
      if (response.error === 0) {
        const serviceOffered = response?.data?.service_offered;
        const paymentScheme = response?.data?.payment_scheme;
        setIsLoading(false);
        const category = {
          service_type_code: serviceOffered?.service_type_code,
          service_name: serviceOffered?.service_name,
        };
        setReservationFee(paymentScheme?.reservation_percentage);

        setTitle(serviceOffered?.title);
        setSubtitle(serviceOffered?.subtitle);
        setCategory(category);
        setTheme(serviceOffered?.theme.split(" & "));
        const regionData = await regions().then((response) => {
          return response;
        });
        const region = regionData.find(
          (region) => region.region_name === serviceOffered.region
        );
        const provinceData = await provinces(region.region_code).then(
          (response) => {
            return response;
          }
        );
        const province = provinceData.find(
          (province) => province.province_name === serviceOffered.province
        );

        const cityData = await cities(province.province_code).then(
          (response) => {
            return response;
          }
        );

        const city = cityData.find(
          (city) => city?.city_name === serviceOffered.municipality
        );

        setRegions(regionData);
        setRegion(region);

        setProvinces(provinceData);
        setProvince(province);

        setCities(cityData);
        setCity(city);

        setAddress(serviceOffered?.address);

        setPrice(serviceOffered?.service_price);
        setOutOfTownFee(serviceOffered?.outside_town_fee);
        setSpecialInclusion(serviceOffered?.special_inclusions);
        setTimeLimit(serviceOffered?.time_limit);
        setSupplyCapacity(serviceOffered?.pax);
        setDescribeListing(serviceOffered?.about);

        setNumberOfDaysFull(paymentScheme?.pay_full_days);

        setCoverPreview(response?.data?.service_offered?.cover_img);

        setIdPreviews(response?.data?.service_img);
      } else {
        navigate(-1);
      }
    } catch (error) {
      navigate(-1);
    }
  };

  const handleInit = async () => {
    setIsLoading(true);
    try {
      const _requestData = {
        act: "add",
        flag: "init",
      };

      const response = await services(_requestData);
      if (response.error === 0) {
        setIsLoading(false);
        setCategories(response.data.services_type);
      } else {
        navigate(-1);
      }
    } catch (error) {
      navigate(-1);
    }
  };

  const handleSave = async () => {
    // if (idFiles.length !== 0 && idPreviews.length !== 0) {
    setIsLoading(true);
    try {
      const _requestData = {
        act: "update",
        flag: "save",
        service_code: listingCode,
        service_type_code: category.service_type_code,
        title: title,
        about: describeListing,
        island: islandFilter(region.id),
        region: region.region_name,
        province: province.province_name,
        municipality: city.city_name,
        brgy: "",
        address: address,
        subtitle: subtitle,
        pax: supplyCapacity,
        service_price: price,
        reservation_percentage: reservationFee,
        full_days: numberOfDaysFull,
        special_inclusions: specialInclusion,
        is_cancellable: 0,
        cover_img: coverPreviewFile,
        service_img: idFiles,
        service_img_ids: removeServiceImage,
        time_limit: timeLimit,
        theme: theme.join(" & "),
        // outside_town_fee: outOfTownFee,
      };
      const response = await services(_requestData);
      if (response.error === 0) {
        setIsLoading(false);
        setSuccess('Service listing has been sent for approval. Give us 24 hours to review your listing.');
        // navigate("/merchant/home");
      } else {
        setError(response.error_msg);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      setError("Failed. Please try again.");
    }
    // } else {
    //   setError("Please upload images.");
    // }
  };

  const renderInputField = (
    id,
    state,
    setState,
    placeholder,
    disabled = false,
    autoFocus = false,
    noBorder = false,
    allowNumberOnly = false,
    isFull = false,
    textArea = false,
    isPercentage = false
  ) => (
    <div
      id={id}
      className={`w-full ${!isFull && "lg:w-1/2"} items-center p-2 ${
        noBorder ? "" : "border"
      } rounded-xl text-base`}
    >
      <label
        htmlFor={id}
        className="whitespace-nowrap px-2 text-sm text-gray-400"
      >
        {placeholder}
      </label>
      <div className="flex">
        {textArea ? (
          <textarea
            rows="10"
            autoFocus={autoFocus}
            disabled={disabled}
            className="resize-none border-none px-2 outline-none w-full bg-transparent"
            id={id}
            type="text"
            value={state}
            onChange={(e) => {
              if (allowNumberOnly) {
                if (isPercentage) {
                  setState(validatePercentage(e.target.value));
                } else {
                  setState(validateMoney(e.target.value));
                }
              } else {
                setState(e.target.value);
              }
            }}
          />
        ) : (
          <input
            autoFocus={autoFocus}
            disabled={disabled}
            className="resize-none border-none px-2 outline-none w-full bg-transparent"
            id={id}
            type="text"
            value={state}
            onBlur={(e) => {
              if (id === "numberOfDaysFull") {
                setState(validateNumberOfDays(e.target.value));
              }
            }}
            onChange={(e) => {
              if (allowNumberOnly) {
                if (id === "price") {
                  // Ensure the value is a number and not greater than 700,000
                  const enteredValue = parseFloat(e.target.value);
                  const validatedValue =
                    enteredValue > 700000 ? 700000 : enteredValue;
                  e.target.value = validatedValue;
                }
                if (isPercentage) {
                  setState(validatePercentage(e.target.value));
                } else {
                  setState(validateMoney(e.target.value));
                }
              } else {
                setState(e.target.value);
              }
            }}
          />
        )}
      </div>
    </div>
  );

  const handleCoverChange = (e) => {
    const allowedFileTypes = ["image/jpeg", "image/jpg", "image/png"];

    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      if (allowedFileTypes.includes(file.type)) {
        setCoverPreviewFile(file);

        // Display the selected image preview
        const reader = new FileReader();
        reader.onload = (e) => {
          if (e.target && typeof e.target.result === "string") {
            setCoverPreview(e.target.result);
          }
        };
        reader.readAsDataURL(file);
      }
    }
  };
  const handleIdFileChange = (e) => {
    const files = e.target.files;

    if (files) {
      const newFiles = Array.from(files);
      setIdFiles([...idFiles, ...newFiles]);

      const newPreviews = newFiles.map((file) => {
        const id = Math.random().toString(36).substr(2, 9); // Generate unique ID for each preview
        return { id, img: URL.createObjectURL(file) }; // Storing id along with URL for later reference
      });

      setIdPreviews([...idPreviews, ...newPreviews]);
    }
  };

  const removeIdPreview = (id) => {
    const updatedPreviews = idPreviews.filter((preview) => preview.id !== id);
    const updatedFiles = idFiles.filter((file) => file.id !== id);
    setIdFiles(updatedFiles);
    setIdPreviews(updatedPreviews);

    setRemoveServiceImage((prevRemoveServiceImage) => {
      if (Array.isArray(prevRemoveServiceImage)) {
        return [...prevRemoveServiceImage, id];
      } else {
        return [id];
      }
    });
  };

  const renderIdPreviews = () => {
    const setAsCover = (coverPhoto) => {
      // Implement setAsCover logic here
    };

    return idPreviews.map((preview) => {
      // No need to use index here
      return (
        <div key={preview.id} className="relative">
          <img
            src={preview.img}
            alt={`ID ${preview.id + 1}`}
            onClick={() => openModal(preview.img)}
            className="mt-3 h-40 w-40 border rounded-lg bg-no-repeat bg-center bg-contain"
          />
          <button
            onClick={() => removeIdPreview(preview.id)} // Pass id to removeIdPreview
            className="absolute top-0 right-0 mt-4 mr-1 bg-red-500 text-white rounded-full text-sm"
          >
            <Close className="w-4 h-4" />
          </button>
        </div>
      );
    });
  };
  const handleTheme = (data) => {
    // Create a copy of the theme array
    let updatedThemes = [...theme];

    // Check if the clicked theme is already in the array
    const index = updatedThemes.indexOf(data);

    if (index === -1) {
      // If not present and the number of themes is less than 2, add it to the array
      if (updatedThemes.length < 2) {
        updatedThemes.push(data);
      } else {
        // If already 2 themes selected, remove the first one and add the new one
        updatedThemes.shift(); // Remove the first theme
        updatedThemes.push(data); // Add the new theme
      }
    } else {
      // If present, remove it from the array
      updatedThemes.splice(index, 1);
    }

    // Set the state using the updated array
    setTheme(updatedThemes);
  };

  return (
    <div>
      <p className="text-2xl text-center mb-5">Event Package Listing</p>
      {(success || error) && (
        <Modal
          onClose={() => setError("")}
          title={error ? "Error" : ""}
          description={success || error}
        />
      )}
      <div className="flex  flex-col lg:flex-row gap-4">
        <div
          id="form"
          className={`${
            page === 1 ? "items-center" : "items-start"
          } flex flex-1 flex-col gap-y-4`}
        >
          {page === 1 && (
            <>
              <div className="p-2">
                <div className="mb-1 text-start whitespace-nowrap px-2 text-sm text-gray-400">
                  Event Theme (optional)
                </div>

                <div className="flex flex-wrap">
                  {filters.map((data, key) => {
                    return (
                      <div
                        key={key}
                        onClick={() => {
                          handleTheme(data.name);
                        }}
                        className="p-2 cursor-pointer text-center-webkit hover:bg-gray-50"
                      >
                        <div className="w-7 h-7">
                          <div
                            className={`${
                              theme.includes(data.name)
                                ? `active-${data.icon}`
                                : data.icon
                            } w-full h-full bg-no-repeat bg-contain`}
                          ></div>
                        </div>
                        <div
                          className={`${
                            theme.includes(data.name) && "text-primary-500"
                          } mt-2 text-sm whitespace-nowrap`}
                        >
                          {data.name}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <Dropdown
                array={categories}
                func={(data) => setCategory(data)}
                keyName="service_name"
                selected={category.service_name || ""}
                title="Supply Category"
                subtitle="Category"
              />
              {renderInputField("title", title, setTitle, "Listing Title")}
              {renderInputField("subtitle", subtitle, setSubtitle, "Subtitle")}

              <div className="w-full lg:w-1/2 text-base">Address Information</div>

              <Dropdown
                array={regionData}
                func={(data) => {
                  setRegion(data);
                  getProvinces(data);
                  setProvince({});
                }}
                keyName="region_name"
                selected={region.region_name || ""}
                title="Region"
                subtitle="Region"
              />
              <Dropdown
                array={provinceData}
                func={(data) => {
                  setProvince(data);
                  getCity(data);
                  setCity({});
                }}
                keyName="province_name"
                selected={province.province_name || ""}
                title="Province"
                subtitle="Province"
              />
              <Dropdown
                array={cityData}
                func={(data) => {
                  setCity(data);
                }}
                keyName="city_name"
                selected={city.city_name || ""}
                title="City"
                subtitle="City"
              />
              {renderInputField("address", address, setAddress, "Address")}

              {/* {error && (
                <span className="w-full lg:w-1/2 text-sm text-red-500 flex flex-row item-center">
                  <Error className="w-3 mr-1 self-center" />
                  {error}
                </span>
              )} */}
              <div
                onClick={() => {
                  if (
                    Object.keys(category).length > 0 &&
                    title &&
                    Object.keys(region).length > 0 &&
                    Object.keys(province).length > 0 &&
                    Object.keys(city).length > 0
                  ) {
                    setPage(2);
                    setError("");
                  } else {
                    setError("Please enter required fields.");
                  }
                }}
                className="w-full lg:w-1/2 text-right text-base text-primary-500 cursor-pointer"
              >
                Next {`>`}{" "}
              </div>
            </>
          )}
          {page === 2 && (
            <div className=" w-full ">
              <div className="w-full text-base my-2">Package Information</div>

              <div className="flex w-full flex-wrap gap-3">
                <div className="flex-1 flex flex-col gap-3">
                  <div className="flex flex-row w-full items-center text-base">
                    <div className="border rounded-xl w-full">
                      {renderInputField(
                        "price",
                        price,
                        setPrice,
                        "Contract Price",
                        false,
                        true,
                        true,
                        true,
                        true
                      )}
                    </div>
                    {/* <div className="text-gray-400 text-sm whitespace-nowrap ml-2">
                      We deduct 2% as Merchant Service Fee.
                    </div> */}
                  </div>

                  {/* <div className="flex flex-row w-full items-center text-base">
                    <div className="border rounded-xl w-full">
                      {renderInputField(
                        "reservationFee",
                        reservationFee,
                        setReservationFee,
                        "Reservation Fee",
                        false,
                        false,
                        true,
                        true,
                        true
                      )}
                    </div>
                    <div className="text-gray-400 text-sm whitespace-nowrap ml-2">
                      Paid instantly after a confirmed reservation
                    </div>
                  </div> */}
                  {renderInputField(
                    "reservationFee",
                    reservationFee,
                    setReservationFee,
                    "Set percentage of contract price as reservation fee",
                    false,
                    false,
                    false,
                    true,
                    true,
                    false,
                    true
                  )}
                  {renderInputField(
                    "numberOfDaysFull",
                    numberOfDaysFull,
                    setNumberOfDaysFull,
                    "Number of days for full payment before the event date",
                    false,
                    false,
                    false,
                    true,
                    true
                  )}

                  {renderInputField(
                    "supplyCapacity",
                    supplyCapacity,
                    setSupplyCapacity,
                    "Supply Capacity",
                    false,
                    false,
                    false,
                    true,
                    true
                  )}

                  <div className="flex flex-row w-full items-center text-base">
                    <div className="border rounded-xl w-full">
                      {renderInputField(
                        "timeLimit",
                        timeLimit,
                        setTimeLimit,
                        "Time limit of Supply",
                        false,
                        false,
                        true,
                        true,
                        true
                      )}
                    </div>
                    <div className="text-gray-400 text-sm whitespace-nowrap ml-2">
                      Hours of Service
                    </div>
                  </div>

                  <div className="text-gray-400 text-sm">
                  Please be advised that no payment will be handled by Anyaya. Customers will send payment directly to you upon your negotiations.
                  </div>

                  {/* {renderInputField(
                    "outOfTownFee",
                    outOfTownFee,
                    setOutOfTownFee,
                    "Out of Town Fee (if necessary)",
                    false,
                    false,
                    false,
                    true,
                    true
                  )} */}
                </div>
                <div className="flex-1 flex flex-col gap-3">
                  {renderInputField(
                    "describeListing",
                    describeListing,
                    setDescribeListing,
                    "Describe your Listing Package",
                    false,
                    false,
                    false,
                    false,
                    true,
                    true
                  )}
                  {renderInputField(
                    "specialInclusion",
                    specialInclusion,
                    setSpecialInclusion,
                    "Special Inclusions",
                    false,
                    false,
                    false,
                    false,
                    true,
                    true
                  )}

                  <div
                    className={`w-full items-center p-2 border rounded-xl text-base`}
                  >
                    <div className="flex flex-row">
                      <label className="px-2  w-full  self-center text-sm text-gray-400">
                        Cover Image
                      </label>
                      <label
                        htmlFor="filescover"
                        className="cursor-pointer border border-primary-500 rounded-lg p-2"
                      >
                        <p className="px-2 cursor-pointer text-sm text-primary-500">
                          Change
                        </p>
                      </label>
                    </div>
                    <div className="flex">
                      <input
                        id="filescover"
                        type="file"
                        accept="image/*"
                        className="mt-2 hidden"
                        onChange={handleCoverChange}
                      />
                    </div>

                    <div className="flex justify-start flex-wrap gap-4">
                      <img
                        draggable="false"
                        src={coverPreview}
                        alt="Cover"
                        className="mt-3 h-40 w-40 border rounded-lg bg-no-repeat bg-center bg-contain"
                      />
                    </div>
                  </div>

                  <div
                    className={`w-full items-center p-2 border rounded-xl text-base`}
                  >
                    <div className="flex flex-row">
                      <label className="px-2  w-full  self-center text-sm text-gray-400">
                        Photos
                      </label>
                      {/* {idPreviews < 4 && ( */}
                      <label
                        htmlFor="files"
                        className="cursor-pointer border border-primary-500 rounded-lg p-2"
                      >
                        {/* setRemoveServiceImage */}
                        <p className="px-2 cursor-pointer text-sm text-primary-500">
                          Upload
                        </p>
                      </label>
                      {/* // )} */}
                    </div>
                    <div className="flex">
                      <input
                        id="files"
                        type="file"
                        accept="image/*"
                        className="mt-2 hidden"
                        onChange={handleIdFileChange}
                        multiple
                      />
                    </div>

                    <div className="flex justify-start flex-wrap gap-4">
                      {renderIdPreviews()}
                    </div>
                  </div>
                  <div className="text-gray-400 text-sm">
                    Note: Please choose and upload LANDSCAPE photos for a better
                    listing appearance.
                  </div>
                </div>
              </div>

              {/* {error && (
                <span className="w-full lg:w-1/2 text-sm text-red-500 flex flex-row item-center">
                  <Error className="w-3 mr-1 self-center" />
                  {error}
                </span>
              )} */}

              <div className="w-full mt-10 flex flex-row justify-between text-base text-primary-500 cursor-pointer">
                <div onClick={() => setPage(1)}>{"<"} Back</div>
                <div
                  onClick={() => {
                    if (!isLoading) {
                      handleSave();
                    }
                  }}
                >
                  {isLoading ? (
                    <Loader borderColor="border-primary-500" />
                  ) : (
                    "Update Listing"
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {selectedImage && (
        <div className="fixed inset-0 flex items-center justify-center z-50 text-gray-500">
          <div
            onClick={() => setSelectedImage(null)}
            className="fixed inset-0 bg-black opacity-50"
          ></div>
          <div className="bg-white rounded-3xl relative py-6 mx-4 w-500">
            <div className="modal-content">
              <img src={selectedImage} alt="Selected ID" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ServiceDetails;

const Dropdown = ({ array, func, keyName, selected, title, subtitle }) => {
  const handleSelect = (data) => {
    func(data);
    setShowModal(false);
  };
  const [showModal, setShowModal] = useState(false);
  return (
    <div
      className={`w-full ${
        subtitle !== "Theme" && "lg:w-1/2"
      } items-center p-2 border rounded-xl`}
    >
      <button
        onClick={() => {
          setShowModal(true);
        }}
        type="button"
        className="border-none outline-none items-center bg-transparent flex flex-row px-2 justify-between text-start text-sm w-full "
      >
        <div>
          <div classNamme="text-sm text-gray-400">{title}</div>

          {selected === "" ? (
            <div className="text-base mt-1 text-gray-400">Select {subtitle}</div>
          ) : (
            <div className="text-base mt-1">{selected}</div>
          )}
        </div>

        <svg
          className="-mr-1 ml-2 h-5 w-5"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fillRule="evenodd"
            d="M10 12.707l-5.146-5.147a1 1 0 011.414-1.414L10 10.586l4.146-4.146a1 1 0 111.414 1.414L10 12.707z"
            clipRule="evenodd"
          />
        </svg>
      </button>

      {showModal &&
        (array.length === 0 ? (
          <></>
        ) : (
          <>
            <div
              onClick={() => setShowModal(false)}
              className="fixed inset-0"
            ></div>

            <div
              className="absolute max-h-96 overflow-scroll mt-3 w-80 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="options-menu"
            >
              <div className="py-1" role="none">
                {array?.map((data, key) => {
                  return (
                    <div
                      onClick={() => {
                        handleSelect(data);
                      }}
                      className="block p-4 text-base text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                      role="menuitem"
                      key={key}
                    >
                      {data[keyName]}
                    </div>
                  );
                })}
              </div>
            </div>
          </>
        ))}
    </div>
  );
};
