import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import Close from "../../../assets/svg/Close";
import Loader from "../../common/Loader";
import { reportListing, reviewService } from "../../../api/client/listing";
import Error from "../../../assets/svg/Error";
import toast from "react-hot-toast";
export const GiveStar = ({ onClose, id, reloadPage }) => {
  const [error, setError] = useState("");
  const [remarks, setRemarks] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleOnClose = () => {
    setRemarks("");
    setError("");
    onClose();
  };
  
  const handleSubmit = async () => {
    setError("");
    setIsLoading(true);
    try {
      const _requestData = {
        service_code: id,
        remarks: remarks,
      };

      const response = await reviewService(_requestData);
      if (response.error === 0) {
        setIsLoading(false);
        toast.success(response.msg);
        reloadPage()
        handleOnClose();
      } else {
        setError(response.error_msg);
        setIsLoading(false);
      }
    } catch (error) {
      handleOnClose();
      setIsLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 text-gray-500">
      <div className="fixed inset-0 bg-black opacity-50"></div>
      <div className="bg-white p-6 mx-4 rounded-3xl relative w-500">
        <button
          className="absolute top-6 left-6 text-gray-400 hover:text-gray-600"
          onClick={handleOnClose}
        >
          <Close className="w-4 h-4" />
        </button>

        <div id="report_2">
          <div className="mt-10 mb-5">
            <div className="text-lg font-semibold">
              Why do you want to give this listing a star?
            </div>
          </div>

          <textarea
            rows={13}
            autoFocus={true}
            onChange={(e) => setRemarks(e.target.value)}
            className="resize-none border rounded-xl w-full p-4 text-sm"
          />
        </div>

        <div className="absolute">
          {error && (
            <span className="text-xs text-red-500 flex flex-row item-center">
              <Error className="w-3 mr-1 self-center" />
              {error}
            </span>
          )}
        </div>

        <div className={`mt-4 flex flex-row justify-end`}>
          <button
            className="mt-4 bg-primary-500 px-10 text-white font-semibold py-3 rounded-full hover:opacity-90"
            onClick={() => {
              handleSubmit();
            }}
          >
            {isLoading ? <Loader /> : <p className="text-sm">Submit</p>}
          </button>
        </div>
      </div>
    </div>
  );
};
