import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { toast } from "react-hot-toast";
import {
  bookingCancel,
  bookingConfirm,
  getHome,
  payouts,
} from "../../api/merchant";
import Loader from "../common/Loader";
import Close from "../../assets/svg/Close";
import { ProfileDetails } from "./Inbox/ProfileDetails";
import dayjs from "dayjs";
import { UploadOR } from "./UploadOR";

export const Payouts = ({ isOpen, onClose, selectedListing }) => {
  const tabs = ["Upcoming", "Completed", "Cancelled"];
  const [selectedTab, selectTab] = useState(0);
  const dispatch = useDispatch();
  const [currentEvent, setCurrentEvent] = useState();
  const [completedPayouts, setCompletedPayouts] = useState();
  const [upcomingPayouts, setUpcomingPayouts] = useState();
  const [cancelledPayouts, setCancelledPayouts] = useState();
  const [bLoading, setLoading] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const [accountCode, setAccountCode] = useState("");
  const [acceptLoading, setAcceptLoading] = useState(false);
  const [paymentCode, setPaymentCode] = useState("");
  const [showOR, setOR] = useState(false);

  const filterEvent = async () => {
    if (bLoading) {
      return;
    }
    setLoading(true);
    try {
      const response = await payouts();
      if (response.error === 0) {
        setCompletedPayouts(response.data.completed_payouts);
        setUpcomingPayouts(response.data.upcoming_payouts);
        setCancelledPayouts(response.data.cancelled_payouts);
        setLoading(false);
      } else {
        setCompletedPayouts([]);
        setUpcomingPayouts([]);
        setCancelledPayouts([]);
        setLoading(false);
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };
  useEffect(() => {
    if (isOpen) {
      filterEvent();
    }
  }, [isOpen]);

  const handleOnClose = () => {
    onClose();
  };

  const approve = async (bookingCode) => {
    const _requestData = {
      booking_code: bookingCode,
    };
    try {
      const response = await bookingConfirm(_requestData);
      if (response.error === 0) {
        toast(response.msg);
        filterEvent();
      } else {
        toast(response.error_msg);
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };
  const decline = async (bookingCode) => {
    const _requestData = {
      booking_code: bookingCode,
    };
    try {
      const response = await bookingCancel(_requestData);
      if (response.error === 0) {
        toast(response.msg);
        filterEvent();
      } else {
        toast(response.error_msg);
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const returnTable = (data, i) => {
    return (
      <div key={i} className="py-2 mt-2 border-b cursor-pointer ">
        <div className="font-semibold text-gray-500">
          {data.payout_amount} PHP
        </div>
        <div className="text-xs text-gray-400">{data.client_name}</div>
        <div className="text-xs text-gray-400">{data?.title}</div>
        <div className="text-xs text-gray-400">
          Event Date: {dayjs(data?.start_date).format("MMM DD, YYYY h:MM A")}{" "}
          {data?.start_date !== data?.end_date &&
            "- " + dayjs(data?.end_date).format("MMM DD, YYYY h:MM A")}
        </div>
        <div className="text-xs select-all text-gray-400">
          Booking Code: {data?.booking_code}
        </div>
        {selectedTab === 0 ? (
          <div
            onClick={() => {
              setOR(true);
              setPaymentCode(data?.payment_code);
            }}
            className="text-xs cursor-pointer text-orange-500"
          >
            Upload invoice or OR
          </div>
        ) : null}
      </div>
    );
  };

  return (
    <div
      style={{ zIndex: "999" }}
      className="fixed inset-0 flex items-center justify-center text-gray-500"
    >
      <div className="fixed inset-0 bg-black opacity-50"></div>
      <div className="bg-white py-6 overflow-x-auto rounded-3xl relative mx-4 w-600">
        <button
          className="px-6 text-gray-400 hover:text-gray-600"
          onClick={handleOnClose}
        >
          <Close className="w-4 h-4" />
        </button>

        <div id="highlights" className="px-8 overflow-y-auto h-600">
          <div className="my-5">
            <div className="text-lg font-semibold">Merchant Payouts</div>
          </div>

          <div className="flex flex-row text-sm text-gray-500  border-b">
            {tabs.map((tab, idx) => {
              return (
                <div
                  onClick={() => selectTab(idx)}
                  className={`${
                    selectedTab === idx && "border-primary-500 border-b-2  "
                  } font-semibold px-2 whitespace-nowrap pb-2 cursor-pointer`}
                  key={idx}
                >
                  {tab}
                </div>
              );
            })}
          </div>
          <div>
            {selectedTab === 0 && (
              <p className="text-xs mt-2">
                Upcoming payouts will be deposited within 2 working days after
                each event. Upload invoice or receipt to each booking to secure
                your payout.
              </p>
            )}
            {selectedTab === 1 && (
              <p className="text-xs mt-2">
                Completed payouts are payments deposited to your provided bank
                account details.
              </p>
            )}
            {selectedTab === 2 && (
              <p className="text-xs mt-2">
                Cancelled payouts are cancelled event services which payments
                are either refunded back to clients or non-refundable payments
                deposited to your account.
              </p>
            )}

            {bLoading && (
              <div className="mt-10">
                <Loader borderColor="border-primary-500" size="h-10 w-10" />
              </div>
            )}

            {selectedTab === 0 &&
              upcomingPayouts?.map((data, i) => returnTable(data, i))}

            {selectedTab === 1 &&
              completedPayouts?.map((data, i) => returnTable(data, i))}
            {selectedTab === 2 &&
              cancelledPayouts?.map((data, i) => returnTable(data, i))}
          </div>
        </div>
      </div>
      {showProfile && (
        <ProfileDetails
          accountCode={accountCode}
          handleOnClose={() => setShowProfile(false)}
        />
      )}
      {showOR && (
        <UploadOR
          paymentCode={paymentCode}
          setOR={() => {
            setOR(false);
            setPaymentCode("");
          }}
        />
      )}
    </div>
  );
};
