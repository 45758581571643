import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import ContentLoader from "react-content-loader";
import Loader from "../../common/Loader";
import { useLocation } from "react-router-dom";
import { PrimaryButton } from "../../common/PrimaryButton";
import { addToEventList, clientEvents } from "../../../api/client/listing";
import { EventModal } from "./EventModal";
import { CreateEvent } from "./Create";
import { config } from "../../../utils/config";
import { convertToKebabCase } from "../../../utils/common";
import Star from "../../../assets/svg/Star";
import { Breadcrumbs } from "../../common/Breadcrumbs";

const EventDetails = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [eventBookingData, setEventBookingData] = useState([]);
  const [eventListData, setEventListData] = useState({});
  const [selectedCodes, setSelectedCodes] = useState([]);
  const location = useLocation();
  const pathnameParts = location.pathname.split("/");
  const code = pathnameParts[pathnameParts.length - 1];
  const [isEventModalOpen, setEventModal] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    viewEvent();
  }, []);

  const viewEvent = async () => {
    try {
      const _requestData = {
        flag: "view",
        event_list_code: code,
      };
      const response = await clientEvents(_requestData);
      if (response.error === 0) {
        setEventBookingData(response.data?.event_booking);
        setEventListData(response.data?.event_list);

        const codes =
          response.data?.event_booking?.map(
            (booking) => booking["booking_code"]
          ) || [];
        setSelectedCodes(codes);

        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <div className="pt-2 px-10 align-center items-center">
      <Breadcrumbs
        location={`events/${eventListData?.event_list_name || ""}`}
      />
      <div className="text-2xl font-semibold text-black">
        {eventListData?.event_list_name}
      </div>

      <div className="flex mt-5 flex-wrap gap-x-4">
        <div className="w-60 ">
          <PrimaryButton
            reverse={true}
            title={"Add more booked events"}
            onClick={() => {
              setEventModal(true);
            }}
          />
        </div>
      </div>

      {isLoading && (
        <div className="w-full h-full mt-10 flex flex-col justify-center items-center">
          <Loader borderColor="border-primary-500" size="h-10 w-10" />
        </div>
      )}

      <div className="mt-5 grid grid-cols-2 md:grid-cols-4 gap-x-10 gap-y-10">
        {!isLoading && eventBookingData !== undefined
          ? eventBookingData.length !== 0 &&
            eventBookingData.map((data, key) => {
              return (
                <div
                  className=""
                  key={key}
                  onClick={() => {
                    window.open(
                      config.ANYAYA_URL +
                        `/category/${
                          data.service_type_code
                        }/${convertToKebabCase(data.service_name)}/${
                          data.service_code
                        }`,
                      "_blank"
                    );
                  }}
                >
                  <div className="mx-auto flex flex-wrap justify-center">
                    <div className="relative h-40 w-full cursor-pointer">
                      <img
                        alt="gallery"
                        draggable="false"
                        className="h-full w-full border rounded-3xl bg-no-repeat bg-center bg-contain"
                        src={data?.cover_img}
                      />
                    </div>
                  </div>
                  <div className="flex flex-row justify-between">
                    <div className="mt-2 text-sm">
                      <div className="font-primary-500 font-semibold">
                        {data.title}
                      </div>
                      <div className="text-xs">{data.merchant_name}</div>
                    </div>
                    {data.star_rank && (
                      <div className="lg:p-1 text-xs font-semibold mt-2 flex lg:self-end lg:mt-0">
                        <Star className="mr-2" />
                        {data.star_rank}
                      </div>
                    )}
                  </div>
                </div>
              );
            })
          : null}
      </div>

      {isEventModalOpen && (
        <EventModal
          isOpen={isEventModalOpen}
          onClose={() => {
            setEventModal(false);
          }}
          selectedCodes={selectedCodes}
          code={code}
          rerendList={() => viewEvent()}
        />
      )}
    </div>
  );
};

export default EventDetails;
