import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  updateAccountInfo,
  updateAccountSettings,
  updateNewAccount,
} from "../../../store/slicers/authSlice";
import { validateEmail, validatePhoneNumber } from "../../../utils/common";
import { ModalHeader } from "../ModalHeader";
import Checkbox from "../Checkbox";
import { config } from "../../../utils/config";
import Loader from "../Loader";
import DatePicker from "react-multi-date-picker";
import dayjs from "dayjs";
import {
  accountSettings,
  updateMobileLogin,
  updateProfile,
} from "../../../api/client";
import Error from "../../../assets/svg/Error";
import { useNavigate } from "react-router-dom";
import { OTP } from "../../client/Account/LoginSecurity/OTP";
export const CreateProfile = () => {
  const account = useSelector((state) => state.auth.account_info);
  const phone = useSelector(
    (state) => state.auth.account_info?.mobile_no
  );
  const userEmail = useSelector(
    (state) => state.auth.account_info.email_address || ""
  );
  const accountInfo = useSelector((state) => state.auth.account_info);

  const loginNavigate = useSelector((state) => state.auth.loginNavigate);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [email, setEmail] = useState(userEmail);
  const [dob, setDOB] = useState("");

  const [phoneNumber, setPhoneNumber] = useState(accountInfo?.M);
  const [isLoading, setIsLoading] = useState(false);
  const [isVerified, setVerified] = useState(false);

  const accountSet = useSelector((state) => state.auth.account_settings);
  const [marketing, setMarketing] = useState(false);
  const [error, setError] = useState("");

  const [selectedFile, setSelectedFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [isOtpOpen, setOTPModal] = useState(false);
  const [isLoadingMobile, setIsLoadingMobile] = useState(false);
  const isMerchant = useSelector(
    (state) => state.auth.account_info.merchant_info
  );

  const handleClose = () => {
    dispatch(updateNewAccount(false));

    setStep(1);
    setFirstName("");
    setLastName("");
    setEmail("");
    setDOB("");
    setMarketing(false);
    setError("");
    setSelectedFile(null);
    setImagePreview(null);

    if (loginNavigate !== undefined) {
      if (loginNavigate === "merchant") {
        navigate("/merchant/signup");
      }
    }
  };

  const handleAgree = async () => {
    setError("");
    if (!validateEmail(email) && userEmail === "") {
      setError("Invalid Email Address.");
      return;
    }
    if(userEmail !== "" && !isVerified) {
      setError("Invalid Contact Number.");
      return;
    }
    if (phoneNumber === "") {
      setError("Invalid Contact Number.");
      return;
    }
    setIsLoading(true);
    try {
      const _requestData = {
        act: "update_profile",
        flag: "save",
        firstname: firstname,
        lastname: lastname,
        email: email,
        mobile_no: phone,
        birthdate: dob,
      };
      const response = await updateProfile(_requestData);
      if (response.error === 0) {
        setStep(2);
        setIsLoading(false);
        const accountInfo = response.data.account_info;
        dispatch(updateAccountInfo(accountInfo));
      } else {
        setIsLoading(false);
        setError(response.error_msg);
      }
    } catch (error) {
      setIsLoading(false);
      setError("Failed. Please try again.");
    }
  };

  const handleFileChange = (e) => {
    const allowedFileTypes = ["image/jpeg", "image/jpg", "image/png"];

    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      if (allowedFileTypes.includes(file.type)) {
        setSelectedFile(file);

        // Display the selected image preview
        const reader = new FileReader();
        reader.onload = (e) => {
          if (e.target && typeof e.target.result === "string") {
            setImagePreview(e.target.result);
          }
        };
        reader.readAsDataURL(file);
      }
    }
  };

  const handleUpload = async () => {
    if (selectedFile) {
      setIsLoading(true);
      try {
        const _requestData = {
          act: "update_profile",
          flag: "change_photo",
          profile_img: selectedFile,
        };
        const response = await updateProfile(_requestData);
        if (response.error === 0) {
          setStep(step + 1);
          const accountInfo = response.data.account_info;
          dispatch(updateAccountInfo(accountInfo));
          setIsLoading(false);
        } else {
          setError(response.error_msg);
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
        setError("Failed. Please try again.");
      }
    }
  };
  useEffect(() => {
    if (accountInfo?.M !== "") {
      setVerified(true);
    }
  }, [accountInfo]);

  const handleOTP = async () => {
    setIsLoadingMobile(true);
    setError("");
    const _requestData = {
      flag: "init",
      mobile_no: phoneNumber,
    };

    const response = await updateMobileLogin(_requestData);

    if (response.error === 0) {
      setIsLoadingMobile(false);
      setOTPModal(true);
    } else {
      setIsLoadingMobile(false);
      setError(response.error_msg);
    }
  };

  const updateMarketing = (val) => {
    updateSettings("unsubscribe_email", val ? "1" : "0");
  };
  const updateSettings = async (key, value) => {
    try {
      const _requestData = {
        flag: "save",
        setting_key: key,
        setting_value: value,
      };
      const response = await accountSettings(_requestData);
      console.log(response);
      if (response.error === 0) {
        const updatedSettings = { ...accountSet, [key]: value };
        dispatch(updateAccountSettings(updatedSettings));
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 text-gray-500">
      <div className="fixed inset-0 bg-black opacity-50"></div>
      <div className="bg-white rounded-3xl relative py-6 mx-4 w-500">
        {step === 1 && (
          <ModalHeader
            title={"Create your profile"}
            onClick={() => {
              handleClose();
            }}
            type={1}
          />
        )}
        {step !== 1 && (
          <ModalHeader
            title={"Create your profile"}
            onClick={() => {
              setStep(step - 1);
            }}
            type={2}
          />
        )}

        {step === 1 && (
          <div id="info">
            <div className="px-6 pb-6">
              <div id="fname" className="p-2 border rounded-xl text-sm">
                <input
                  value={firstname}
                  onChange={(e) => {
                    setFirstName(e.target.value);
                  }}
                  className="resize-none border-none  outline-none w-full p-2 bg-transparent"
                  placeholder="First Name"
                />
              </div>

              <div id="lname" className="mt-2 p-2 border rounded-xl text-sm">
                <input
                  value={lastname}
                  onChange={(e) => {
                    setLastName(e.target.value);
                  }}
                  className="resize-none border-none  outline-none w-full p-2 bg-transparent"
                  placeholder="Last Name"
                />
              </div>
              <div className="mt-2 px-1 text-2xs">
                Make sure it matches the name on your government ID.
              </div>
              <DatePicker
                className="purple"
                value={dob}
                format="MM/DD/YYYY"
                onChange={(date) => {
                  setDOB(date.toString());
                }}
                render={(value, openCalendar) => {
                  return (
                    <div
                      id="dob"
                      onClick={openCalendar}
                      className="mt-2 p-2 border rounded-xl text-sm"
                    >
                      {value === "" ? (
                        <p className="p-2 w-full text-gray-400">
                          Date of Birth
                        </p>
                      ) : (
                        <p className="p-2 w-full">
                          {dayjs(value).format("MMMM D, YYYY")}
                        </p>
                      )}
                    </div>
                  );
                }}
              />

              {userEmail && (
                <div
                  id="phone"
                  className={`mt-2 p-2 flex items-center border rounded-xl text-sm rounded-xl text-sm`}
                >
                  <span>+63</span>
                  <input
                    value={phoneNumber}
                    onChange={(e) => {
                      setPhoneNumber(validatePhoneNumber(e.target.value));
                      if (accountInfo?.M === e.target.value) {
                        setVerified(true);
                      } else {
                        setVerified(false);
                      }
                    }}
                    className="resize-none border-none  outline-none w-full p-2 bg-transparent"
                    placeholder="Contact Number"
                  />
                  {!isVerified && account.login_option !== "M" && (
                    <span
                      className="text-primary-500 cursor-pointer whitespace-nowrap"
                      onClick={isLoadingMobile ? () => {} : () => handleOTP()}
                    >
                      {isLoadingMobile ? (
                        <Loader borderColor="border-primary-500" />
                      ) : (
                        "Send OTP"
                      )}
                    </span>
                  )}
                </div>
              )}

              <div
                id="email"
                className={`mt-2 p-2 ${
                  userEmail !== "" && "bg-gray-50"
                } border rounded-xl text-sm rounded-xl text-sm`}
              >
                <input
                  disabled={userEmail !== ""}
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  className="resize-none border-none  outline-none w-full p-2 bg-transparent"
                  placeholder="Email"
                />
              </div>
              <div className="mt-2 px-1 text-2xs">
                We'll send you the booking confirmation and receipts.
              </div>
              <p className="mt-6 text-xs px-1">
                By selecting Agree & Continue, I agree to Anyaya's{" "}
                <span
                  className="underline cursor-pointer mx-1"
                  onClick={() => {
                    window.open(
                      config.ANYAYA_URL + "terms-and-conditions",
                      "_blank"
                    );
                  }}
                >
                  Terms and Conditions
                </span>
                and acknowledge the{" "}
                <span
                  className="underline cursor-pointer"
                  onClick={() => {
                    window.open(config.ANYAYA_URL + "privacy-policy", "_blank");
                  }}
                >
                  Privacy & Policy
                </span>
                .
              </p>

              <div className="h-3 mt-2">
                {error && (
                  <span className="text-xs text-red-500 flex flex-row item-center">
                    <Error className="w-3 mr-1 self-center" />
                    {error}
                  </span>
                )}
              </div>
            </div>

            <div className="px-6">
              <button
                className="bg-primary-500 px-10 w-full text-white font-semibold py-4 rounded-xl hover:opacity-90"
                onClick={isLoading ? () => {} : handleAgree}
              >
                {isLoading ? (
                  <Loader />
                ) : (
                  <p className="text-sm">Agree and Continue</p>
                )}
              </button>
            </div>
            <div className="w-full mt-5 border-b" />
            <div className="px-6 mt-5">
              <div className="text-xs">
                Anyaya will send you great event deals, experiences and
                marketing emails. You can opt out of receiving these at anytime
                in your account settings or directly from the marketing
                notification.
              </div>

              <div className="mt-2">
                <Checkbox
                  label={
                    "I don't want to receive marketing messages from Anyaya"
                  }
                  checked={accountSet?.unsubscribe_email === "1"}
                  onChange={(val) => updateMarketing(val)}
                />
              </div>
            </div>
          </div>
        )}

        {step === 2 && (
          <div id="profile_picture" className="px-6 w-full">
            <div className="pb-6 text-center-webkit align-center flex flex-col">
              {selectedFile ? (
                <>
                  <div className="text-xs">You look great!</div>
                  <div className="text-xs mt-2">
                    This photo will be added to your profile it will also be
                    seen by merchants and other users, so make sure it doesn't
                    contain any sensitive info.
                  </div>
                </>
              ) : (
                <>
                  <div className="text-xs">Add a profile photo</div>
                  <div className="text-xs mt-2">
                    Choose an image that shows your face
                  </div>
                </>
              )}

              <div className="w-full">
                {imagePreview ? (
                  <img
                    draggable="false"
                    src={imagePreview}
                    alt="Selected"
                    className="mt-6 rounded-full w-28 h-28"
                  />
                ) : (
                  <div className="mt-6 rounded-full w-28 h-28 defaulticon bg-no-repeat bg-cover"></div>
                )}
              </div>
            </div>
            {error && (
              <span className="text-xs text-red-500 flex flex-row item-center mb-5">
                <Error className="w-3 mr-1 self-center" />
                {error}
              </span>
            )}
            {selectedFile && (
              <div className="mb-2">
                <button
                  className="bg-primary-500 px-10 w-full text-white font-semibold py-4 rounded-xl hover:opacity-90"
                  onClick={
                    isLoading
                      ? () => {}
                      : () => {
                          handleUpload();
                        }
                  }
                >
                  {isLoading ? <Loader /> : <p className="text-sm">Done</p>}
                </button>
              </div>
            )}
            <label
              className={`${
                selectedFile
                  ? "bg-white text-primary-500 border border-primary-500"
                  : "font-semibold bg-primary-500 text-white hover:opacity-90"
              } px-10 flex justify-center w-full text-sm py-4 rounded-xl cursor-pointer`}
            >
              <input
                type="file"
                className="hidden"
                onChange={handleFileChange}
              />
              {selectedFile ? "Change Photo" : "Upload"}
            </label>

            <div
              className={`${
                selectedFile ? "hidden" : "block"
              }  cursor-pointer mt-5 text-center text-xs underline`}
              onClick={() => {
                setStep(step + 1);
              }}
            >
              I'll do this later
            </div>
          </div>
        )}
        {step === 3 && (
          <div id="welcome">
            <div className="w-full h-20">
              <div className="logo h-full w-full bg-center" />
            </div>
            <div id="create_profile" className="p-6 text-center">
              <div className="text-base font-semibold mb-5">
                Welcome to Anyaya!
              </div>
              <div className="text-xs mt-5">
                Book an event easier, safer and faster!
              </div>
              <div className="text-xs">Go beyond celebration.</div>
            </div>

            <div className="px-6">
              <button
                className="mt-4 bg-primary-500 px-10 w-full text-white font-semibold py-4 rounded-xl hover:opacity-90"
                onClick={handleClose}
              >
                <p className="text-sm">Continue</p>
              </button>
            </div>
          </div>
        )}
      </div>
      {isOtpOpen && (
        <OTP phone={phoneNumber} closeMobile={() => setOTPModal(false)} />
      )}
    </div>
  );
};
