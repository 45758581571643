import * as React from "react";

const ChevronRight = (props) => {
  const { className } = props;

  return (
    <svg
      className={className}
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_186_502)">
        <path
          d="M9.16666 5.5L7.87416 6.7925L12.0725 11L7.87416 15.2075L9.16666 16.5L14.6667 11L9.16666 5.5Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_186_502">
          <rect width="22" height="22" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ChevronRight;
