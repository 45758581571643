import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import ContentLoader from "react-content-loader";
import { useNavigate } from "react-router-dom";
import { config } from "../../../utils/config";
import { convertToKebabCase } from "../../../utils/common";
import Close from "../../../assets/svg/Close";
import { getListing } from "../../../api/client/listing";

export const MerchantEvents = ({ isOpen, onClose }) => {
  const sMerchantCode = useSelector(
    (state) => state.listing.serviceOffered.merchant_code
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const sRowPerPage = 20;
  const [aListings, setListings] = useState([]);
  const [bLoading, setLoading] = useState(false);
  const [bLastPage, setLastPage] = useState(false);
  const [iPage, setPage] = useState(1);

  useEffect(() => {
    if (isOpen && sMerchantCode !== "") {
      handleListings();
    }
  }, [isOpen, sMerchantCode]);

  const handleListings = async () => {
    if (bLoading || bLastPage) {
      return;
    }

    setLoading(true);

    const _requestData = {
      page_no: iPage,
      row_per_page: sRowPerPage,
      merchant_code: sMerchantCode,
    };

    try {
      const response = await getListing(_requestData);
      if (response.error === 0) {
        if (
          response.data.pagination.current_page === 1 &&
          response.data.services_list.length === 0
        ) {
          setLastPage(true);
          setLoading(false);
          return;
        }

        setLoading(false);
        if (response.data.pagination.current_page === 1) {
          setListings(response.data.services_list);
          setPage(2);
        } else {
          const listings = aListings?.concat(response.data.services_list);
          setListings(listings);
          setPage(response.data.pagination.current_page + 1);
        }

        if (
          response.data.pagination.current_page ===
          response.data.pagination.total_page
        ) {
          setLastPage(true);
        }
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const handleOnClose = () => {
    setListings([]);
    setLoading(false);
    setLastPage(false);
    setPage(1);
    onClose();
  };
  const loaders = Array.from({ length: 10 }, (_, index) => (
    <ContentLoader
      key={index}
      speed={1}
      width="10rem"
      height="10rem"
      viewBox="0 0 195 205"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <rect rx="20" ry="20" width="100%" height="100%" />
    </ContentLoader>
  ));

  const handleClick = (data) => {
    window.open(
      config.ANYAYA_URL +
        `/category/${data.service_type_code}/${convertToKebabCase(
          data.service_name
        )}/${data.service_code}`,
      "_blank"
    );
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 text-gray-500">
      <div className="fixed inset-0 bg-black opacity-50"></div>
      <div
        className="bg-white py-6 rounded-3xl relative mx-4"
        style={{ width: "600px" }}
      >
        <button
          className="px-6 text-gray-400 hover:text-gray-600"
          onClick={handleOnClose}
        >
          <Close className="w-4 h-4" />
        </button>

        <div
          id="highlights"
          className="px-8 overflow-y-auto"
          style={{ maxHeight: "600px", height: "600px" }}
        >
          <div className="my-5">
            <div className="text-lg font-semibold">Other Events</div>
          </div>
          <div id="merchant-highlights" className="">
            <InfiniteScroll
              dataLength={aListings?.length || 0}
              next={() => handleListings()}
              hasMore={!bLastPage}
              loader={bLoading ? loaders : null}
              scrollThreshold={0.5}
              className="grid grid-cols-2 md:grid-cols-3 gap-8"
            >
              {!bLoading && aListings?.length === 0 ? (
                <div>No listing found</div>
              ) : (
                aListings?.map((data, i) => (
                  <div key={i} className="cursor-pointer relative">
                    <img
                      draggable="false"
                      onClick={() => handleClick(data)}
                      alt={data.title}
                      src={data.cover_img}
                      className="h-40 w-40 border w-full rounded-3xl bg-no-repeat bg-center bg-cover"
                    />
                    <div
                      className="flex flex-row justify-between"
                      onClick={() => handleClick(data)}
                    >
                      <div>
                        <div className="text-xs text-primary-500 mt-2">
                          {data.title}
                        </div>
                        <div className="text-xs">{data.location}</div>
                        <div className="text-xs">
                          PHP {data.service_price} for {data.pax} pax
                        </div>
                        <div className="text-xs underline text-primary-400 cursor-pointer">
                          {data.review_count}{" "}
                          {Number(data.review_count) > 1 ? "stars" : "star"}
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              )}
            </InfiniteScroll>
          </div>
        </div>
      </div>
    </div>
  );
};
