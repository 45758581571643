import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ContentLoader from "react-content-loader";
import { useLocation, useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import Heart from "../../../assets/svg/Heart";
import { updateLoginModal } from "../../../store/slicers/authSlice";
import {
  updateListingLastPage,
  updateListingLoading,
  updateListingPage,
  updateListings,
  updateMultipleFields,
  updateWishlistModal,
} from "../../../store/slicers/listingSlice";
import { convertToKebabCase, islandFormatter } from "../../../utils/common";
import { getListing, wishlist } from "../../../api/client/listing";
import { toast } from "react-hot-toast";
import { PrimaryButton } from "../../common/PrimaryButton";
export const ListingScroll = () => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.auth.token);
  const listingLoading = useSelector((state) => state.listing.listingLoading);
  const listings = useSelector((state) => state.listing.listings);
  const listingPage = useSelector((state) => state.listing.listingPage);
  const listingLastPage = useSelector((state) => state.listing.listingLastPage);
  const location = useLocation();
  const navigate = useNavigate();
  const rowPerPage = 8;
  const searchNameInHome = useSelector(
    (state) => state.listing.searchNameInHome
  );
  const pathnameParts = location.pathname.split("/");
  const serviceTypeId = pathnameParts[pathnameParts.length - 2];

  const filterModalDate = useSelector((state) => state.listing.filterModalDate);
  const filterModalLocation = useSelector(
    (state) => state.listing.filterModalLocation
  );
  const filterModalMerchant = useSelector(
    (state) => state.listing.filterModalMerchant
  );
  const filterModalIsland = useSelector(
    (state) => state.listing.filterModalIsland
  );
  const filterModalSub = useSelector((state) => state.listing.filterModalSub);

  const serviceOffered = useSelector((state) => state.listing.serviceOffered);
  const serviceCalendar = useSelector((state) => state.listing.serviceCalendar);
  const servicePricing = useSelector((state) => state.listing.servicePricing);
  const servicePhotos = useSelector((state) => state.listing.servicePhotos);
  const serviceReviews = useSelector((state) => state.listing.serviceReviews);
  const eventHighlights = useSelector((state) => state.listing.eventHighlights);

  const removeToWishlist = async (id) => {
    try {
      const _requestData = {
        act: "remove_to_wishlist",
        service_code: id,
      };
      const response = await wishlist(_requestData);
      if (response.error === 0) {
        const index = listings.findIndex(
          (listing) => listing.service_code === id
        );

        if (index !== -1) {
          const updatedListings = [...listings]; // Creating a new array
          updatedListings[index] = {
            ...updatedListings[index],
            in_wishlist: "0",
          }; // Updating the specific item immutably
          dispatch(updateListings(updatedListings));
          dispatch(updateListingPage(listingPage));
          dispatch(
            updateMultipleFields({
              serviceCalendar,
              serviceOffered: { ...serviceOffered, in_wishlist: "0" },
              servicePricing,
              servicePhotos,
              serviceReviews,
              eventHighlights,
            })
          );
        }

        toast("Successfully removed from wishlist");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const dynamicRegex = /^\/category\/[\w-]+\/[\w-]+$/;
    const isInListing = dynamicRegex.test(location.pathname);
    if (isInListing) {
      handleListings();
    }
  }, [
    filterModalIsland,
    filterModalLocation,
    filterModalMerchant,
    filterModalDate,
  ]);

  const loaders = Array.from({ length: 1 }, (_, index) => (
    <ContentLoader
      key={index}
      speed={1}
      width="18rem"
      height="19rem"
      viewBox="0 0 195 205"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <rect rx="20" ry="20" width="100%" height="100%" />
    </ContentLoader>
  ));

  const handleListings = async () => {
    if (listingLoading || listingLastPage) {
      return;
    }

    dispatch(updateListingLoading(true));
    const _requestData = {
      page_no: listingPage,
      row_per_page: rowPerPage,
      title: searchNameInHome || filterModalMerchant,
      service_type_code: serviceTypeId,
      island: filterModalIsland === "flexible" ? "" : filterModalIsland,
      event_type: filterModalSub,
      province: islandFormatter(filterModalLocation),
      start_date: filterModalDate !== "" ? filterModalDate.split(",")[0] : "",
      end_date: filterModalDate !== "" ? filterModalDate.split(",")[1] : "",
    };

    try {
      const response = await getListing(_requestData);
      if (response.error === 0) {
        if (
          response.data.pagination.current_page === 1 &&
          response.data.services_list.length === 0
        ) {
          dispatch(updateListingLastPage(true));
          dispatch(updateListingLoading(false));
          return;
        }

        dispatch(updateListingLoading(false));
        if (response.data.pagination.current_page === 1) {
          dispatch(updateListings(response.data.services_list));
          dispatch(updateListingPage("2"));
        } else {
          dispatch(
            updateListings([...listings, ...response.data.services_list])
          );
          dispatch(
            updateListingPage(response.data.pagination.current_page + 1)
          );
        }

        if (
          response.data.pagination.current_page ===
          response.data.pagination.total_page
        ) {
          dispatch(updateListingLastPage(true));
        }
      } else {
        dispatch(updateListingLoading(false));
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const handleClick = (data) => {
    // if(isLoggedIn){
    navigate(
      `/category/${data.service_type_code}/${convertToKebabCase(
        data.service_name
      )}/${data.service_code}`
    );
    // } else {
    //   dispatch(updateLoginModal({ status: true }));
    // }
  };

  useEffect(() => {
    const dynamicRegex = /^\/category\/[\w-]+\/[\w-]+$/;
    const isInListing = dynamicRegex.test(location.pathname);
    if (isInListing) {
      handleListings();
    }
  }, []);
  useEffect(() => {
    const dynamicRegex = /^\/category\/[\w-]+\/[\w-]+$/;
    const isInListing = dynamicRegex.test(location.pathname);
    if (isInListing) {
      handleListings();
    }
  }, [filterModalSub]);

  useEffect(() => {
    if (location.pathname === "/category" && searchNameInHome !== "") {
      handleListings();
    }
  }, [searchNameInHome, dispatch]);

  return !listingLoading && listings.length === 0 ? (
    <div className="w-full h-full text-center flex flex-col justify-center items-center">
      <div className="text-lg text-primary-600 font-semibold mt-10">
        No exact matches
      </div>
      <div className="text-sm text-primary-600">
        Try changing or removing some of your filters.
      </div>
      <div className="w-60 mt-5">
        <PrimaryButton
          title={"Explore Listings"}
          onClick={() => {
            navigate("/category");
          }}
          full={true}
        />
      </div>
    </div>
  ) : (
    <InfiniteScroll
      dataLength={listings.length}
      next={() => handleListings()}
      hasMore={!listingLastPage}
      loader={listingLoading ? loaders : null}
      scrollThreshold={0.5}
      style={{ width: "100%" }}
      className="justify-center md:justify-start flex flex-wrap gap-x-12 gap-y-8  w-full"
    >
      {listings.map((data, i) => (
        <div key={i} className="cursor-pointer relative">
          <div className="w-72 h-72 lg:w-60 lg:h-60">
            <img
              draggable="false"
              onClick={() => handleClick(data)}
              alt={data.title}
              src={data.cover_img}
              className="w-full h-full border border-primary-500 rounded-3xl bg-no-repeat object-cover bg-center"
            />
          </div>
          <div
            className="absolute top-4 right-6"
            onClick={() => {
              if (isLoggedIn) {
                if (data.in_wishlist === "1") {
                  removeToWishlist(data.service_code);
                } else {
                  dispatch(
                    updateWishlistModal({
                      status: true,
                      id: data.service_code,
                    })
                  );
                }
              } else {
                dispatch(
                  updateLoginModal({
                    status: true,
                  })
                );
              }
            }}
          >
            <Heart
              className={`h-6 w-6 text-primary-100 ${
                data.in_wishlist === "1" && "text-primary-500"
              } hover:text-primary-500`}
            />
          </div>
          <div
            className="flex flex-row justify-between"
            onClick={() => handleClick(data)}
          >
            <div>
              <div className="text-base font-semiboldd text-primary-500 mt-2">
                {data.title.length > 20
                  ? data.title.substring(0, 20) + "..."
                  : data.title}
              </div>
              <div className="text-sm text-primary-500">
                {data.theme !== "" ? data.theme : "Any Event"}
              </div>
              <div className="text-sm">{data.location}</div>
              <div className="text-sm">
                PHP {data.service_price} for {data.pax} pax
              </div>
              <div className="text-xs underline text-primary-400 cursor-pointer">
                {data.review_count}{" "}
                {Number(data.review_count) > 1 ? "stars" : "star"}
              </div>
            </div>
            {data.star_rank && (
              <div className="absolute right-0 text-xs mt-2 flex flex-row">
                ★ {data.star_rank}{" "}
              </div>
            )}
          </div>
        </div>
      ))}
    </InfiniteScroll>
  );
};
