import React, { useEffect } from "react";
import MetaTags from "./MetaTags";
import { Breadcrumbs } from "./Breadcrumbs";
import Helmet from "react-helmet";
const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(10, 0);
  }, []);
  return (
    <div className="px-8 lg:px-40 xl:px-56 pb-10 pt-6 mt-5 align-center items-center">
      <Helmet>
        <MetaTags
          title="Privacy Policy"
          description={`At Anyaya Booking Services, ("we", "us" or "our") we commit to
        the protection of data of the users ("you", "your" or "yours")
        obtained in the course of its business. We take the privacy of
        our users very seriously and urge all users to read our Privacy
        Policy carefully. By using our Website, using our Services or
        purchasing our Products, you agree and acknowledge this Privacy
        Policy. This also means you consent to the processing of your
        data or information obtained from the usage of the Website. If
        you do not agree to or understand this Privacy Policy, you may
        simply cease or refrain from using the Website.`}
          keywords="genuine event merchants"
        />
      </Helmet>
      <Breadcrumbs location="category/privacy-policy" />
      <div className="text-lg lg:text-2xl font-semibold text-black">Privacy Policy</div>
      <div className="text-xs text-gray-400">
        Last updated on October 6, 2023
      </div>

      <div>
        <div className="mt-5 text-justify">
          <div>
            <div className="mt-5 text-xs lg:text-sm text-black">
              <div className="mb-2 text-sm lg:text-base font-semibold text-black ">
                I. INTRODUCTION
              </div>
              <div>
                At Anyaya Booking Services, ("we", "us" or "our") we commit to
                the protection of data of the users ("you", "your" or "yours")
                obtained in the course of its business. We take the privacy of
                our users very seriously and urge all users to read our Privacy
                Policy carefully. By using our Website, using our Services or
                purchasing our Products, you agree and acknowledge this Privacy
                Policy. This also means you consent to the processing of your
                data or information obtained from the usage of the Website. If
                you do not agree to or understand this Privacy Policy, you may
                simply cease or refrain from using the Website.
              </div>
              <div className="my-5">
                This privacy policy (hereinafter known as "Privacy Policy")
                provides crucial information about
              </div>
              <div className="pl-5 my-5">
                2. The manner of collection, storage, and usage of data or
                information
              </div>
              <div className="pl-5 my-5">
                3. The purposes for which we collect and use data
              </div>
              <div className="pl-5 my-5">
                4. How we share your data or information
              </div>
              <div className="pl-5 my-5">
                5. How long we retain your data or information
              </div>
              <div className="pl-5 my-5">
                6. Your rights concerning your data or information
              </div>
              <div className="pl-5 my-5">
                7. Management of any modifications to this Privacy Policy
              </div>
              <div className="pl-5 my-5">
                8. How to contact us in case you have any suggestions, concerns,
                or complaints
              </div>
              <div className="mb-5">
                The foregoing information will be stated by this "Privacy
                Policy" incorporating the minimum standards provided by Republic
                Act No. 10173, also known as the "Data Privacy Act of 2012" (An
                Act Protecting Individual Personal Information In Information
                And Communications Systems In The Government And The Private
                Sector, Creating For This Purpose A National Privacy Commission
                And For Other Purposes) which took effect on September 8, 2012
                (the "Act").
              </div>
              <div>
                This Privacy Policy excludes personal information about the user
                obtained from other sources or sources outside of the use of
                this Website.
              </div>
            </div>

            <div className="mt-5 text-xs lg:text-sm text-black">
              <div className="mb-2 text-sm lg:text-base font-semibold text-black ">
                II. EFFECTIVE DATE
              </div>
              <div className="mb-5">
                This Privacy Policy comes into force and effect on 10 January
                2023.
              </div>
            </div>

            <div className="mt-5 text-xs lg:text-sm text-black">
              <div className="mb-2 text-sm lg:text-base font-semibold text-black ">
                III. DEFINITIONS
              </div>
              <div className="mb-5">
                Website: This Privacy Policy will be applicable to Anyaya
                Booking Services, which shall hereinafter be known and referred
                to as "Website".
              </div>
              <div className="mb-5">
                Who we are: Anyaya Booking Services, ("we", "us" or "our")
                collect, store, and use your data or information for the
                Website’s operations and services.
              </div>
              <div className="mb-5">
                User: The user ("you", "your" or "yours") using this Website,
                which means you agree to the provisions of this Privacy Policy.
              </div>
              <div className="mb-5">
                Parties: The parties to this Privacy Policy are the Personal
                Information Controller or "Anyaya Booking Services" and the
                user.
              </div>
              <div className="mb-5">
                Personal Information Controller: Refers to Anyaya Booking
                Services or the one who is responsible for the control of the
                processing of your data or information.
              </div>
              <div className="mb-5">
                Personal Information: Refers to and may be used interchangeably
                with your "data or information" that we obtain from your use of
                the Website, which can directly or reasonably ascertain your
                identity.
              </div>
              <div className="mb-5">
                Products: Refer to any goods or products that are offered for
                sale on the Website.
              </div>
              <div className="mb-5">
                Services: Refer to any services that are offered on the Website.
              </div>
            </div>

            <div className="mt-5 text-xs lg:text-sm text-black">
              <div className="mb-2 text-sm lg:text-base font-semibold text-black ">
                IV. PERSONAL INFORMATION
              </div>
              <div className="mb-5">
                When you choose to connect your account with an external third
                party service or application, such as Facebook or Google
              </div>
              <div className="pl-5 mb-5">
                Use the email address in your Facebook or Google account as your
                email address for your Account when you use our Service
              </div>
              <div className="text-gray-60 text-justify text-xs lg:text-sm">
                You are under no obligation to provide the information to us.
                However, if you choose to withhold the information or to revoke
                permission for us to receive the information, we may be unable
                to provide certain aspects of our Services to you.
              </div>
              <div className="text-gray-60 text-justify text-xs lg:text-sm mt-3">
                We may collect and receive the following information. Please
                note that not all of our Services collect all of the information
                listed below:
              </div>
              <p>
                profile information such as, name, email address, profile photo,
                mobile number, date of birth, bank details.{" "}
              </p>
            </div>

            <div className="mt-5 text-xs lg:text-sm text-black">
              <div className="mb-2 text-sm lg:text-base font-semibold text-black ">
                V. LEGITIMATE PURPOSE OF PROCESSING PERSONAL INFORMATION
              </div>
              <div className="mb-5">
                Your personal information will be processed pursuant to the
                following purposes:
              </div>
              <div className="pl-5 mb-5">
                To provide a booking service with even merchants which includes
                online payments for booked events; To collect service fee for
                the usage of our booking system, payment method, and other
                services that our platform provides.
              </div>
              <div className="mb-5">
                Our main purpose for using your personal information is to
                improve your experience on our Website and provide you with the
                services and/or information you have requested. Additionally, we
                may use information that is not considered personal information
                to gain broad insights into our customer base, which may be used
                for market research or marketing efforts. This information might
                include interests based on your cookies, among other things.
              </div>

              <div className="mb-5">
                Personal Information may also be processed for the following
                purposes:
              </div>

              <div className="pl-5 mb-5">
                1. Registration of your account for the use of various features
                of our Website
              </div>
              <div className="pl-5 mb-5">
                2. Communication of user account, and marketing information and
                if any
              </div>
              <div className="pl-5 mb-5">3. Enhancement of user experience</div>
              <div className="pl-5 mb-5">
                4. Billing or credit information or fulfilling transactions if
                any
              </div>
              <div className="pl-5 mb-5">5. Providing customer service</div>
              <div className="pl-5 mb-5">
                6. Aggregation or combination of Data (Analytics)
              </div>
            </div>

            <div className="mt-5 text-xs lg:text-sm text-black">
              <div className="mb-2 text-sm lg:text-base font-semibold text-black ">
                VI. COLLECTION OF DATA OR INFORMATION
              </div>
              <div className="mb-5">
                Depending on your usage of our Website, your personal
                information may be collected from you. Collection may be done in
                any of the following manners:
              </div>
              <div className="pl-5 mb-5">
                A. Registration of users: You, as a user of the Website, may
                register or create an account to access more features of the
                Website. By registering, we will collect or obtain the following
                personal information from you:
              </div>
              <div className="pl-10 mb-5">
                Full name, email address, social media account, contact number,
                valid government ID, birthday, age.
              </div>

              <div className="mb-5">
                Personal information may be collected from you after
                registration in order to:
              </div>

              <div className="pl-5 mb-5">
                a. Allow our officers or representatives to interact with you in
                any manner such as by messaging or email.
              </div>
              <div className="pl-5 mb-5">
                b. Notify you via email or SMS about general information about
                your use of our Website.
              </div>
              <div className="pl-5 mb-5">
                c. Allow you to leave comments on our Website's content
                including but not limited to photos, videos, text content, and
                forms.
              </div>
              <div className="pl-5 mb-5">
                d. Enable the following manner of user interaction:
              </div>
              <div className="pl-10 mb-5">
                Users can upload media assets as their profile photo; Users can
                interact with event merchants through our chat system; Users can
                interact with our agent using our Anyaya help center; Users can
                interact with our Anyaya event organizer using our chat system.
              </div>
              <div className="mb-5">
                The completion of your registration means you provide your
                consent to allow us to collect, store, use, or disclose your
                personal information according to the provisions of this Privacy
                Policy.
              </div>

              <div className="mb-5">
                B. Passive usage: Data may be collected from you passively
                through "Cookies", even if you do not undergo the registration
                process. Passive data collection may include location
                information, IP address information, or browser data such as
                session information.
              </div>

              <div className="mb-5">
                C. Billing Information: For purposes of purchasing products or
                services offered on our Website, we may ask you to provide
                certain credit information, billing address information, and
                additional specific information for us to be able to charge you
                accordingly. This information may be stored for the following
                period of time: 1 month. The billing information you provide
                shall only be used for facilitating your transactions with us.
              </div>
              <div className="mb-5">
                D. User Experience: We may ask for your suggestions on how we
                can improve our Website. This may be done by means of surveys,
                questions, or ratings asked, which may be conducted from time to
                time.
              </div>

              <div className="mb-5">
                E. Combination of Data or Information: We may aggregate or
                combine some of your personal information in order to provide
                you with the best user experienceand offer you the products and
                services that are best suited to your needs or usage patterns.
              </div>
            </div>

            <div className="mt-5 text-xs lg:text-sm text-black">
              <div className="mb-2 text-sm lg:text-base font-semibold text-black ">
                VII. PERSONAL INFORMATION WE RECEIVE AUTOMATICALLY (COOKIES)
              </div>
              <div className="mb-5">
                We may gather or collect information from you using automated
                technology that captures data about your browsing behavior and
                history, and the data or information that you willingly provide
                to us, such as details shared during the registration process or
                at other points when using the Website. For instance, we may use
                cookies to improve or enhance your browsing experience by
                storing information that may pertain to your preferences, the
                device type you used, or previous choices you made on the site.
                Cookies, which are small text files, allow us to modify the
                Website according to your expectations and provide a more
                customized browsing experience. The information in a cookie is
                limited and can only be read by the web server that issued it.
                It is not an executable code and is virus-free. Cookies do not
                save or transmit any personal information. If cookies are
                disabled, the functions of our Website may be limited. To
                proceed without changing your cookie settings, simply continue
                to use our Website. The following are the type of cookies that
                we may receive from you:
              </div>
              <div className="pl-5 mb-5">
                A. HTML cookies or technical cookies are utilized to allow you
                to access or go through different pages of the Website. They are
                needed to transmit communications using the network and provide
                the services you request. The utilization of these cookies also
                allows for the safe and efficient use of our Website.
              </div>
              <div className="pl-5 mb-5">
                B. Cookies may also be utilized for purposes of analytics or
                statistics, or to collect aggregated or combined data or
                information.
              </div>
              <div className="pl-5 mb-5">
                C. Temporary session cookies are those which are destroyed or
                deleted after a browsing session and are used to identify you
                and allow you to not log in repeatedly after each browsing
                session so long as your browser is not closed.
              </div>
              <div className="pl-5 mb-5">
                D. Permanent cookies are those which remain for a longer period,
                while these function the same as temporary cookies in that they
                are used to identify you and allow you to not log in repeatedly
                after each browsing session, they remain on your device even
                after your browser is closed.
              </div>

              <div className="mb-5">
                You provide your specific consent when these types of cookies
                are used to enable you to have the best experience on our
                Website.
              </div>
            </div>

            <div className="mt-5 text-xs lg:text-sm text-black">
              <div className="mb-2 text-sm lg:text-base font-semibold text-black ">
                VIII. CRITERIA FOR LAWFUL PROCESSING OF PERSONAL INFORMATION
              </div>
              <div className="mb-5">
                In accordance with the specified purposes of using your data or
                information, the following are the criteria or legal basis for
                processing your information:
              </div>
              <div className="pl-5 mb-5">
                A. You give us your consent before we collect and process your
                personal information.
              </div>
              <div className="pl-5 mb-5">
                B. Processing your personal information involves the performance
                of an obligation under a contract to which you are a party,
                including the act of entering into a contractual obligation.
              </div>
              <div className="pl-5 mb-5">
                C. Processing your personal information is required for us to
                comply with a legal obligation.
              </div>
              <div className="pl-5 mb-5">
                D. Processing your personal information is necessary to protect
                vitally important interests, including your life and health, or
                those of another.
              </div>

              <div className="pl-5 mb-5">
                E. Processing your personal information is necessary to respond
                to a national emergency, or to comply with the requirements of
                public order and safety, as prescribed by law.
              </div>
              <div className="pl-5 mb-5">
                F. Processing your personal information is necessary to fulfill
                the constitutional or statutory mandate of a public authority
              </div>

              <div className="pl-5 mb-5">
                G. Processing your personal information is necessary to ensure
                our legitimate interests or the legitimate interests of a third
                party (except when such interests are overridden by your
                fundamental and constitutional rights.) Details of the
                legitimate interests referred to herein will be provided if this
                is the basis or criterion by which your personal information is
                processed.
              </div>

              <div className="mb-5">
                Generally, we will process your personal information based on
                the specified purposes in this Privacy Policy mainly where:
              </div>

              <div className="pl-5 mb-5">
                A. We obtain your consent explicitly each time we process your
                personal information based on the purposes specified in this
                Privacy Policy.
              </div>
              <div className="pl-5 mb-5">
                B. It is required in order to perform a contractual obligation
                to which you are a party, including the act of entering into
                such contractual obligation with you.
              </div>

              <div className="pl-5 mb-5">
                C. We are required to comply with a legal obligation.
              </div>

              <div className="pl-5 mb-5">
                D. It is necessary to pursue our legitimate interests or those
                of another after we ensure that your fundamental and
                constitutional rights are protected.
              </div>
            </div>

            <div className="mt-5 text-xs lg:text-sm text-black">
              <div className="mb-2 text-sm lg:text-base font-semibold text-black ">
                IX. THIRD-PARTY SERVICE PROVIDERS
              </div>
              <div className="mb-5">
                We may share your information with third-party service
                providers, and vice versa, to help improve your experience on
                our Website. This includes the following:
              </div>

              <div className="pl-5 mb-5">
                A. Non-sale of Data: Your personal information will not be sold
                to third parties without first obtaining your specific consent.
              </div>

              <div className="pl-5 mb-5">
                B. Hosting: We may use the services of third-party service
                providers to host our Website which will also allow such
                providers to access your personal information.
              </div>

              <div className="pl-5 mb-5">
                C. Storage: We may use the services of third-party service
                providers for web or cloud storage to assist us with storing
                your information.
              </div>

              <div className="pl-5 mb-5">
                D. Tracking: We may use the services of third-party service
                providers to track usage data and determine user behavior and
                patterns of usage to improve the Website and your experience
                within it.
              </div>

              <div className="pl-5 mb-5">
                E. Advertising: We may allow third-party service providers to
                advertise on our Website and use cookies for marketing and
                advertising purposes.
              </div>

              <div className="pl-5 mb-5">
                F. Social Media API: Our Website uses the API (Application
                Programming Interface) of social networks, in order to allow
                integration of the functionalities of such social networks into
                your use of the Website. This integration allows for easy
                sharing of your data or information from our Website to such
                social networks and vice versa. Cookies are set if you
                voluntarily use the services of such social networks within our
                Website, thus it means that you consent to the use of cookies.
                The handling or processing of your data or information is
                governed by the respective privacy policies of such social
                networks. You may read the privacy policies in their respective
                websites.
              </div>
            </div>
            <div className="mt-5 text-xs lg:text-sm text-black">
              <div className="mb-2 text-sm lg:text-base font-semibold text-black ">
                X. LENGTH OF RETENTION OF PERSONAL INFORMATION
              </div>
              <div className="mb-5">
                Your data or information will be retained by us for a period of
                time, not more than what is required to fulfill the purposes as
                specified in this privacy policy.
              </div>
              <div className="mb-5">
                The manner by which we will retain your data or information is
                described as follows:
              </div>

              <div className="pl-5 mb-5">
                The names, addresses, email, contact details, and banking
                details shall be retained by us from the moment of registration
                up to cancellation and for up to 1 year after account
                termination.
              </div>

              <div className="mb-5">
                After the lapse of the period as stated in the previous
                paragraph, your information shall be destroyed, deleted, or
                anonymized.
              </div>
            </div>

            <div className="mt-5 text-xs lg:text-sm text-black">
              <div className="mb-2 text-sm lg:text-base font-semibold text-black ">
                XI. LOCATION OR SCOPE
              </div>
              <div className="mb-5">
                Your data or information shall be processed in the Philippines
                only and may also be shared with companies within the
                Philippines, and will be done in a manner that is compliant with
                the requirements of the Data Privacy Act of 2012.
              </div>
            </div>

            <div className="mt-5 text-xs lg:text-sm text-black">
              <div className="mb-2 text-sm lg:text-base font-semibold text-black ">
                XII. RIGHTS OF THE USER
              </div>
              <div className="mb-5">
                The following are your rights in relation to your data or
                information handled by us. These rights are those laid down by
                the Data Privacy Act of 2012:
              </div>
              <div className="pl-5 mb-5">
                A. Right to be informed: You have the right to be informed
                whether data or information pertaining to you shall be, are
                being, or have been processed, including the existence of
                automated decision-making and profiling.
              </div>
              <div className="pl-5 mb-5">
                B. Right to object: You have the right to object to the
                processing of your data or information, including processing for
                direct marketing, automated processing, or profiling.
              </div>
              <div className="pl-5 mb-5">
                When you object or withhold your consent, we shall no longer
                process the personal data, unless:
              </div>

              <div className="pl-10 mb-5">
                a. The data or information is needed pursuant to a subpoena;
              </div>
              <div className="pl-10 mb-5">
                b. The collection and processing are for obvious purposes,
                including, when it is necessary for the performance of or in
                relation to a contract or service to which you are a party, or
                when necessary or desirable in the context of an
                employer-employee relationship between the collector and the
                data subject; or
              </div>
              <div className="pl-10 mb-5">
                c. The information is being collected and processed as a result
                of a legal obligation.
              </div>

              <div className="pl-5 mb-5">
                C. Right to access: You have the right to reasonable access to,
                upon demand, the following:
              </div>

              <div className="pl-10 mb-5">
                a. Contents of your data or information that were processed;
              </div>

              <div className="pl-10 mb-5">
                b. Sources from which your data or information were obtained;
              </div>

              <div className="pl-10 mb-5">
                c. Names and addresses of recipients of your data or
                information;
              </div>
              <div className="pl-10 mb-5">
                d. Manner by which such data were processed;
              </div>

              <div className="pl-10 mb-5">
                e. Reasons for the disclosure of your data or information to
                recipients, if any;
              </div>

              <div className="pl-10 mb-5">
                f. Information on automated processes where the data will, or is
                likely to, be made as the sole basis for any decision that
                significantly affects or will affect you;
              </div>
              <div className="pl-10 mb-5">
                g. Date when your personal information concerning you, was last
                accessed and modified; and
              </div>
              <div className="pl-10 mb-5">
                h. Our designation, name or identity, and address.
              </div>

              <div className="pl-5 mb-5">
                D. Right to rectification: You have the right to dispute the
                inaccuracy or error in your data or information and have us
                correct it immediately and accordingly unless the request is
                vexatious or otherwise unreasonable.
              </div>

              <div className="pl-5 mb-5">
                E. Right to erasure or blocking: You have the right to suspend,
                withdraw or order the blocking, removal, or destruction of your
                data or information from our systems.
              </div>

              <div className="pl-5 mb-5">
                F. Right to data portability: You have the right to
                electronically move, copy or transfer your data or information
                without cost for whatever use you require.
              </div>

              <div className="pl-5 mb-5">
                G. Right to damages and to file a complaint: You have the right
                to file a case and/or be indemnified for any damages sustained
                due to such inaccurate, incomplete, outdated, false, unlawfully
                obtained or unauthorized use of your data or information, taking
                into account any violation of your rights as a data subject.
              </div>
            </div>

            <div className="mt-5 text-xs lg:text-sm text-black">
              <div className="mb-2 text-sm lg:text-base font-semibold text-black ">
                XIII. REVISION AND UPDATE OF PRIVACY POLICY
              </div>
              <div className="mb-5">
                We may update and revise this Privacy Policy from time to time
                and will notify you of any changes. Our Website was last
                published and updated on 10 January 2023.
              </div>
              <div className="mb-5">
                The notice of revision may be made in the following manners:
              </div>

              <div className="pl-5 mb-5">
                By email or sms if you have consented to providing information
                relative to such modes of messaging; By the notification in the
                home page of the website.
              </div>
            </div>

            <div className="mt-5 text-xs lg:text-sm text-black">
              <div className="mb-2 text-sm lg:text-base font-semibold text-black ">
                XIV. CONTACT
              </div>
              <div className="mb-5">
                The Party responsible for the processing of your Data or
                Information is: Anyaya Booking Services. The Personal
                lnformation Controller ("Us") may be contacted using the
                following information:
              </div>
              <div className="mb-5">Anyaya Booking Services</div>
              <div className="mb-5">Quezon City, Metro Manila</div>
              <div>0956-7048271</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
