import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getListing, report, services } from "../../api/merchant";
import { formatDateToMonthYear } from "../../utils/common";
import { MerchantListings } from "./Listings";
import { updateSelectedListing } from "../../store/slicers/listingSlice";
import Star from "../../assets/svg/Star";
export function MerchantInsights() {
  const merchant = useSelector(
    (state) => state.auth.account_info.merchant_info
  );
  const dispatch = useDispatch();
  const selectedListing = useSelector((state) => state.listing.selectedListing);
  const [activeLocation, setActive] = useState("reviews");
  const [selectedYear, setSelectedYear] = useState(""); // State to track selected year
  const [summaryReport, setSummaryReport] = useState({});
  const [totalIncome, setTotalIncome] = useState("0.00");
  const [reviews, setReviews] = useState([]);
  const [showListing, setShowlisting] = useState(false);
  const [listingDetails, setListingDetails] = useState({});

  const [bLoading, setLoading] = useState(false);

  useEffect(() => {
    handleListings();
  }, []);

  const handleListings = async () => {
    const requestData = {
      page_no: 1,
      row_per_page: 1,
    };

    try {
      const response = await getListing(requestData);
      if (response.error === 0) {
        if (response.data.pagination.current_page === 1) {
          if (response.data.services_list.length !== 0 && !selectedListing) {
            dispatch(updateSelectedListing(response.data.services_list[0]));
          }
        }
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  useEffect(() => {
    generateReviews();
  }, [selectedListing]);

  // Function to handle year selection
  const handleYearChange = (data) => {
    setSelectedYear(data);
    generateReport(data);
  };

  // Function to get the current year
  const getCurrentYear = () => {
    return new Date().getFullYear();
  };

  // Generate years based on current year and the number of years to display
  const generateYears = (startYear, numberOfYears) => {
    const years = [];
    for (let i = 0; i < numberOfYears; i++) {
      years.push(startYear - i);
    }
    return years;
  };

  const years = generateYears(getCurrentYear(), 10); // Displaying 10 years

  const generateReport = async (year) => {
    if (year !== "") {
      try {
        const response = await report({
          flag: "summary",
          year: year,
          service_code: selectedListing?.service_code,
        });
        if (response.error === 0) {
          setSummaryReport(response.data.summary_report);
          setTotalIncome(response.data.total_income);
        }
      } catch (error) {
        console.log("Error:", error);
      }
    }
  };
  const generateReviews = async () => {
    if (selectedListing !== null) {
      try {
        const response = await services({
          act: "reviews",
          service_code: selectedListing?.service_code,
        });
        if (response.error === 0) {
          setReviews(response.data.service_reviews);
          setListingDetails(response.data.service_offered);
        }
      } catch (error) {
        console.log("Error:", error);
      }
    }
  };

  return (
    <div className="h-screen text-sm align-center items-center">
      {merchant?.status !== "1" && (
        <div className="w-80 mb-4">
          <div className="self-center w-full">
            <div className="w-full cursor-pointer items-center p-4 border rounded-lg text-sm">
              <div className="flex">
                <div
                  className="w-full px-1 border-none outline-none bg-transparent"
                  onClick={() => setShowlisting(true)}
                >
                  <p>
                    {selectedListing?.title +
                      ` 
                  ${selectedListing?.status === "1" ? "(Inactive)" : ""} 
                  ${selectedListing?.status === "9" ? "(For Approval)" : ""}` ||
                      "-"}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="flex gap-x-4 border-b">
        <p
          onClick={() => setActive("reviews")}
          className={`${
            activeLocation === "reviews" && "border-b border-primary-500"
          } cursor-pointer pb-4 `}
        >
          Reviews
        </p>
        <p
          onClick={() => setActive("income")}
          className={`${
            activeLocation === "income" && "border-b border-primary-500"
          } cursor-pointer pb-4 `}
        >
          Income
        </p>
      </div>

      {activeLocation === "reviews" && (
        <div className="mt-4 w-80">
          {listingDetails?.star_rank && (
            <div className="flex flex-row mt-2 mb-5 gap-x-2">
              <div className="">Ratings</div>
              <div className="text-sm self-center font-semibold flex">
                <Star className="mr-2" />
                {listingDetails?.star_rank}
              </div>
            </div>
          )}

          <div className="mb-2">
            {reviews.length} Review{reviews.length > 1 && "s"}
          </div>
          {reviews.map((review, idx) => {
            return (
              <div className={`border-b py-4`} id={`review_${idx}`} key={idx}>
                <div className={`flex flex-row align-center self-center`}>
                  {review.profile_img !== "" ? (
                    <img
                      draggable="false"
                      className="rounded-full bg-primary-500 w-10 h-10 bg-no-repeat bg-cover"
                      src={review.profile_img}
                      alt={review.firstname}
                    />
                  ) : (
                    <div className="rounded-full defaulticon w-10 h-10 bg-no-repeat bg-cover"></div>
                  )}

                  <div className="flex-col mt-1 pl-3">
                    <div className="text-black">{review.firstname ?? "-"}</div>
                    <div className="text-xs" style={{ lineHeight: 0.5 }}>
                      {formatDateToMonthYear(review.date_created)}
                    </div>
                  </div>
                </div>
                <div className="text-sm mt-5">{review.review}</div>
              </div>
            );
          })}
        </div>
      )}

      {activeLocation === "income" && (
        <div className="mt-4">
          <div>
            <Dropdown
              array={years}
              func={(data) => handleYearChange(data)}
              keyName=""
              selected={selectedYear}
              title="Select a year"
              subtitle="----"
            />
            {/* <select
              id="yearSelect"
              className="border w-40 mt-2 border-primary-500 rounded-md p-2"
              value={selectedYear}
              onChange={handleYearChange}
            >
              <option value="">Select Year</option>
              {years.map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </select> */}
          </div>

          {selectedYear !== "" && (
            <div className="mt-5">
              <div>Php {totalIncome}</div>
              <div className="mt-2"> Events Income for {selectedYear}</div>
              <div className="mt-2 border-t w-80 py-2">
                <div className="font-semibold">{selectedYear} details</div>
              </div>
              <div className="border-t w-80 py-2">
                <div className="flex flex-row justify-between">
                  <div>Celebration days</div>
                  <div>{summaryReport?.celebration_days}</div>
                </div>
              </div>
              <div className="border-t w-80 py-2">
                <div className="flex flex-row justify-between">
                  <div>Off days</div>
                  <div>{summaryReport?.off_days}</div>
                </div>
              </div>
              <div className="border-t w-80 py-2">
                <div className="flex flex-row justify-between">
                  <div>Event Celebration rate</div>
                  <div>{summaryReport?.event_rate}</div>
                </div>
              </div>
              <div className="border-y w-80 py-2">
                <div className="flex flex-row justify-between">
                  <div>Contract Fare</div>
                  <div>{summaryReport?.contract_fee}</div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      {showListing && (
        <MerchantListings
          isOpen={showListing}
          selectedListing={selectedListing}
          setSelectedListing={(val) => dispatch(updateSelectedListing(val))}
          onClose={() => {
            setShowlisting(false);
            generateReport(selectedYear);
            generateReviews();
          }}
        />
      )}

      {/* Other content */}
    </div>
  );
}
const Dropdown = ({ array, func, selected, title, subtitle }) => {
  const handleSelect = (data) => {
    func(data);
    setShowModal(false);
  };
  const [showModal, setShowModal] = useState(false);
  return (
    <div
      className={`w-full ${
        subtitle !== "Theme" && "lg:w-1/2"
      } items-center p-2 border rounded-xl`}
    >
      <button
        onClick={() => {
          setShowModal(true);
        }}
        type="button"
        className="border-none outline-none items-center bg-transparent flex flex-row px-2 justify-between text-start text-xs w-full "
      >
        <div>
          <div classNamme="text-xs text-gray-400">{title}</div>

          {selected === "" ? (
            <div className="text-sm mt-1 text-gray-400">{subtitle}</div>
          ) : (
            <div className="text-sm mt-1">{selected}</div>
          )}
        </div>

        <svg
          className="-mr-1 ml-2 h-5 w-5"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fillRule="evenodd"
            d="M10 12.707l-5.146-5.147a1 1 0 011.414-1.414L10 10.586l4.146-4.146a1 1 0 111.414 1.414L10 12.707z"
            clipRule="evenodd"
          />
        </svg>
      </button>

      {showModal &&
        (array.length === 0 ? (
          <></>
        ) : (
          <>
            <div
              onClick={() => setShowModal(false)}
              className="fixed inset-0"
            ></div>

            <div
              className="absolute max-h-96 overflow-scroll mt-3 w-80 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="options-menu"
            >
              <div className="py-1" role="none">
                {array?.map((data, key) => {
                  return (
                    <div
                      onClick={() => {
                        handleSelect(data);
                      }}
                      className="block p-4 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                      role="menuitem"
                      key={key}
                    >
                      {data}
                    </div>
                  );
                })}
              </div>
            </div>
          </>
        ))}
    </div>
  );
};
