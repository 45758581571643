import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import ContentLoader from "react-content-loader";
import Loader from "../../common/Loader";
import { PrimaryButton } from "../../common/PrimaryButton";
import { addToEventList, clientEvents } from "../../../api/client/listing";
import { EventModal } from "./EventModal";
import { CreateEvent } from "./Create";

const Event = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isEventModalOpen, setEventModal] = useState(false);
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState({});

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleEvents = async () => {
    setIsLoading(true);
    try {
      const _requestData = {
        flag: "init",
      };
      const response = await clientEvents(_requestData);
      if (response.error === 0) {
        setEvents(response.data.event_list);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    handleEvents();
  }, []);

  const eventClick = async (code) => {
    navigate("/events/" + code);
  };

  return (
    <div className="px-5 align-center items-center ">
      <div className="text-2xl font-semibold text-black">My Events</div>

      <div className="flex flex-wrap gap-x-4">
        <div className="w-60 ">
          <PrimaryButton
            reverse={true}
            title={"Create an event"}
            onClick={() => {
              setEventModal(true);
            }}
          />
        </div>
        <div className="w-60">
          <PrimaryButton
            title={"Reservation list"}
            onClick={() => {
              navigate("/events/reservations");
            }}
          />
        </div>
      </div>

      {isLoading && (
        <div className="w-full h-full mt-10 flex flex-col justify-center items-center">
          <Loader borderColor="border-primary-500" size="h-10 w-10" />
        </div>
      )}

      {!isLoading && (
        <div className="mt-5 grid grid-cols-2 md:grid-cols-4 gap-x-10 gap-y-10">
          {events !== undefined && events.length !== 0
            ? events.map((data, key) => {
                return (
                  <div
                    className=""
                    key={key}
                    onClick={() => {
                      eventClick(data?.event_list_code);
                    }}
                  >
                    <div className="mx-auto flex flex-wrap justify-center">
                      <div className="relative h-40 w-full cursor-pointer">
                        <img
                          alt="gallery"
                          draggable="false"
                          className="h-full w-full border rounded-3xl bg-no-repeat bg-center bg-contain"
                          src={data?.list_img}
                        />
                      </div>
                    </div>
                    <div className="text-center mt-2 font-semibold">
                      {data.event_list_name}
                    </div>
                  </div>
                );
              })
            : null}
        </div>
      )}

      {isEventModalOpen && (
        <CreateEvent
          isOpen={isEventModalOpen}
          rerendList={() => {
            handleEvents();
          }}
          onClose={() => {
            setEventModal(false);
          }}
        />
      )}
    </div>
  );
};

export default Event;
