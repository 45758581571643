import React, { useEffect, useState } from "react";
import MetaTags from "./MetaTags";
import { Breadcrumbs } from "./Breadcrumbs";
import Helmet from "react-helmet";
import { ClientTerms } from "./ClientTerms";
import MerchantTerms from "./MerchantTerms";
const TermsAndConditions = () => {
  const [isClient, setIsClient] = useState(true);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="px-8 lg:px-40 xl:px-56 pb-10 pt-6 mt-5 align-center items-center">
      <Helmet>
        <MetaTags
          title="Terms and Conditions"
          description="By accessing and using the Website (www.anyaya-events.com), owned
        and operated by Anyaya Booking Services (the 'Company')"
          keywords="genuine event merchants"
        />
      </Helmet>
      <Breadcrumbs location="category/terms-&-conditions" />
      <div className="text-lg lg:text-2xl font-semibold text-black">
        Terms and Conditions
      </div>
      <div className="mt-5 text-justify">
        <div className="flex flex-row  border-b-2">
          <div
            onClick={() => setIsClient(true)}
            className={`font-semibold cursor-pointer text-xs lg:text-base text-left pb-2 mr-4 ${
              isClient && "border-b-2 border-primary-500"
            }`}
          >
            Client
          </div>
          <div
            onClick={() => setIsClient(false)}
            className={`font-semibold cursor-pointer text-xs lg:text-base text-left pb-2 mx-4 ${
              !isClient && "border-b-2 border-primary-500"
            }`}
          >
            Merchant
          </div>
        </div>
      </div>

      {/* <div className="text-xs text-gray-400">
        Last updated on October 6, 2023
      </div> */}

      <div className="mt-5 text-justify">
        <div className="text-xs lg:text-sm text-black">
          By accessing and using the Website (www.anyaya-events.com), owned and
          operated by Anyaya Booking Services (the "Company"), You agree to be
          bound by the terms and conditions set forth below. If You do not agree
          to be bound by this Agreement, do not understand the Agreement, or if
          You need more time to review and consider this Agreement, please leave
          the Website immediately. The Company only agrees to provide use of the
          Website and Services to You if You assent to this Agreement.
        </div>

        {isClient ? <ClientTerms /> : <MerchantTerms />}
      </div>
    </div>
  );
};

export default TermsAndConditions;
