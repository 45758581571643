import axiosInstance from "../../utils/axiosInstance";
import axios from "axios";

export const getUserDetails = async (accessToken) => {
  try {
    const response = await axios.get(
      "https://www.googleapis.com/oauth2/v1/userinfo",
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    if (response.status === 200) {
      return response.data;
    } else {
      return "Failed to fetch user details";
    }
  } catch (error) {
    return error;
  }
};

export async function logged() {
  const requestData = {
    ctl: "index",
    act: "logged",
  };
  try {
    const response = await axiosInstance.post("/", requestData);
    return response.data;
  } catch (error) {
    return error;
  }
}
export async function deactivate() {
  const requestData = {
    ctl: "index",
    act: "deactivate",
  };
  try {
    const response = await axiosInstance.post("/", requestData);
    return response.data;
  } catch (error) {
    return error;
  }
}



export async function mobileLogin(_requestData) {
  const requestData = {
    ctl: "index",
    act: "mobile_login",
    ..._requestData,
  };
  try {
    const response = await axiosInstance.post("/", requestData);
    return response.data;
  } catch (error) {
    return error;
  }
}
export async function googleLogin(_requestData) {
  const requestData = {
    ctl: "index",
    act: "google_login",
    ..._requestData,
  };
  try {
    const response = await axiosInstance.post("/", requestData);
    return response.data;
  } catch (error) {
    return error;
  }
}
export async function fbLogin(_requestData) {
  const requestData = {
    ctl: "index",
    act: "facebook_login",
    ..._requestData,
  };
  try {
    const response = await axiosInstance.post("/", requestData);
    return response.data;
  } catch (error) {
    return error;
  }
}
export async function updateProfile(_requestData) {
  const requestData = {
    ctl: "account",
    ..._requestData,
  };
  try {
    const response = await axiosInstance.post("/", requestData);
    return response.data;
  } catch (error) {
    return error;
  }
}
export async function accountVerification(_requestData) {
  const requestData = {
    ctl: "account",
    act: "verification",
    ..._requestData,
  };
  try {
    const response = await axiosInstance.post("/", requestData);
    return response.data;
  } catch (error) {
    return error;
  }
}
export async function logoutUser() {
  const requestData = {
    ctl: "index",
    act: "logout",
  };
  try {
    const response = await axiosInstance.post("/", requestData);
    return response.data;
  } catch (error) {
    return error;
  }
}
export async function accountSettings(_requestData) {
  const requestData = {
    ctl: "account",
    act: "settings",
    ..._requestData,
  };
  try {
    const response = await axiosInstance.post("/", requestData);
    return response.data;
  } catch (error) {
    return error;
  }
}


export async function updateMobileLogin(_requestData) {
  const requestData = {
    ctl: "login_option",
    act: "change_mobile_login",
    ..._requestData,
  };
  try {
    const response = await axiosInstance.post("/", requestData);
    return response.data;
  } catch (error) {
    return error;
  }
}

export async function updateGoogleLogin(_requestData) {
  const requestData = {
    ctl: "login_option",
    act: "change_google_login",
    ..._requestData,
  };
  try {
    const response = await axiosInstance.post("/", requestData);
    return response.data;
  } catch (error) {
    return error;
  }
}

export async function notificationList(_requestData) {
  const requestData = {
    ctl: "account",
    act: "notification",
    ..._requestData,
  };
  try {
    const response = await axiosInstance.post("/", requestData);
    return response.data;
  } catch (error) {
    return error;
  }
}

export async function unreadData(_requestData) {
  const requestData = {
    ctl: "account",
    act: "unread",
    ..._requestData,
  };
  try {
    const response = await axiosInstance.post("/", requestData);
    return response.data;
  } catch (error) {
    return error;
  }
}