import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import Close from "../../../assets/svg/Close";
import Loader from "../../common/Loader";
import { reportListing } from "../../../api/client/listing";
import Error from "../../../assets/svg/Error";
export const ReportListing = ({ onClose, type, id }) => {
  const reportDetails = [
    { key: "scam", label: "It's a scam", status: false },
    { key: "not_real", label: "It's not a real business", status: false },
    { key: "offensive", label: "It's offensive", status: false },
    { key: "something_else", label: "It's something else", status: false },
  ];
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const [selectedOption, setSelectedOption] = useState("scam");
  const [remarks, setRemarks] = useState("");
  const [step, setStep] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const handleOptionChange = (key) => {
    setSelectedOption(key);
  };
  const handleOnClose = () => {
    setSelectedOption("");
    setStep(1);
    setRemarks("");
    setError("");
    onClose();
  };
  const handleNext = () => {
    setStep(2);
  };
  const handleSubmit = async () => {
    setError("");
    setIsLoading(true);
    try {
      const _requestData = {
        service_code: id,
        title: selectedOption,
        remarks: remarks,
      };

      const response = await reportListing(_requestData);
      if (response.error === 0) {
        setIsLoading(false);
        setStep(3);
      } else {
        setError(response.error_msg);
        setIsLoading(false);
      }
    } catch (error) {
      handleOnClose();
      setIsLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 text-gray-500">
      <div className="fixed inset-0 bg-black opacity-50"></div>
      <div className="bg-white p-6 mx-4 rounded-3xl relative w-500">
        <button
          className="absolute top-6 left-6 text-gray-400 hover:text-gray-600"
          onClick={handleOnClose}
        >
          <Close className="w-4 h-4" />
        </button>

        {step === 1 && (
          <div id="report_1">
            <div className="mt-10 mb-5">
              <div className="text-lg font-semibold">
                Why are you reporting this {type}?
              </div>
              <div className="text-xs text-gray-400">
                This won't share with the merchant
              </div>
            </div>
            <div className="flex flex-col">
              {reportDetails.map((data, key) => {
                return (
                  <div
                    key={key}
                    className="flex flex-row justify-between border-b py-5 cursor-pointer"
                    onClick={() => handleOptionChange(data["key"])}
                  >
                    <span>{data["label"]}</span>
                    <label htmlFor={data["key"]} className="cursor-pointer">
                      <span
                        className={`${
                          selectedOption === data["key"]
                            ? "opacity-100"
                            : "opacity-20"
                        } h-5 w-5 bg-primary-500  rounded-full inline-block`}
                      ></span>
                    </label>
                  </div>
                );
              })}
            </div>
          </div>
        )}

        {step === 2 && (
          <div id="report_2">
            <div className="mt-10 mb-5">
              <div className="text-lg font-semibold">
                Please describe your report
              </div>
            </div>

            <textarea
              rows={13}
              autoFocus={true}
              onChange={(e) => setRemarks(e.target.value)}
              className="resize-none border rounded-xl w-full p-4 text-sm"
            />
          </div>
        )}

        {step === 3 && (
          <div id="report_3" className="h-96 flex items-center">
            <div className="mt-10 w-full mb-5 text-center">
              <div className="w-28 h-12 mx-auto mb-5">
                <div
                  className="logo h-full w-full "
                  style={{ backgroundPositionY: "center" }}
                />
              </div>
              <div className="text-xl text-primary-500 font-semibold">
                We've received your report.
              </div>
              <div className="text-base mt-10">
                We appreciate the time to let us know what you've discovered.
              </div>
              <div className="text-base ">
                Report like this help us improve the quality of the {type}s in
                Anyaya.
              </div>
            </div>
          </div>
        )}

        <div className="absolute">
          {error && (
            <span className="text-xs text-red-500 flex flex-row item-center">
              <Error className="w-3 mr-1 self-center" />
              {error}
            </span>
          )}
        </div>

        <div
          className={`mt-4 flex flex-row ${
            step === 2 ? "justify-between" : "justify-end"
          }`}
        >
          {step === 2 && (
            <button
              className="mt-4 font-semibold py-3 "
              onClick={() => {
                setRemarks("");
                setError("");
                setStep(1);
              }}
            >
              <p className="text-sm underline">Back</p>
            </button>
          )}

          <button
            className="mt-4 bg-primary-500 px-10 text-white font-semibold py-3 rounded-full hover:opacity-90"
            onClick={() => {
              if (step === 1) {
                handleNext();
              } else if (step === 2) {
                handleSubmit();
              } else if (step === 3) {
                handleOnClose();
              }
            }}
          >
            {step === 1 && <p className="text-sm">Next</p>}
            {step === 2 ? (
              isLoading ? (
                <Loader />
              ) : (
                <p className="text-sm">Submit</p>
              )
            ) : null}
            {step === 3 && <p className="text-sm">Ok</p>}
          </button>
        </div>
      </div>
    </div>
  );
};
