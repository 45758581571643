import React, { useState } from "react";

const Checkbox = ({
  label,
  checked,
  onChange,
  labelClass = "text-2xs",
  checkSize = "h-2 w-2",
  leftSide = false,
}) => {
  const handleChange = () => {
    onChange(!checked);
  };

  return (
    <label
      className="mt-2  flex items-center space-x-2 cursor-pointer"
      onClick={handleChange}
    >
      {leftSide && <span className={`ml-auto ${labelClass}`}>{label}</span>}
      <div
        className={`p-2 border rounded text-sm ${
          checked ? "relative bg-primary-500 " : "relative group"
        }`}
      >
        {checked && (
          <span className="absolute text-white inset-0 flex items-center justify-center">
            <svg viewBox="0 0 24 24" className={checkSize} fill="currentColor">
              <path d="M 19.980469 5.9902344 A 1.0001 1.0001 0 0 0 19.292969 6.2929688 L 9 16.585938 L 5.7070312 13.292969 A 1.0001 1.0001 0 1 0 4.2929688 14.707031 L 8.2929688 18.707031 A 1.0001 1.0001 0 0 0 9.7070312 18.707031 L 20.707031 7.7070312 A 1.0001 1.0001 0 0 0 19.980469 5.9902344 z" />
            </svg>
          </span>
        )}
      </div>
      {!leftSide && <span className={`ml-auto ${labelClass}`}>{label}</span>}
    </label>
  );
};

export default Checkbox;
