import * as React from "react";

const Back = (props) => {
  const { className, strokeWidth = "8" } = props;

  return (
    <svg className={className} viewBox="0 0 256 256">
      <rect width="256" height="256" fill="none" />
      <polyline
        points="160 208 80 128 160 48"
        fill="none"
        stroke="#333"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
    </svg>
  );
};

export default Back;
