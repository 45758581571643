import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import Loader from "./Loader";
import Close from "../../assets/svg/Close";
export const CancelBooking = ({ onClose, handleCancel, isLoading }) => {
  const dispatch = useDispatch();
  const [remarks, setRemarks] = useState("");

  useEffect(() => {
    setRemarks("");
  }, []);

  const handleOnClose = () => {
    setRemarks("");
    onClose();
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 text-gray-500">
      <div className="fixed inset-0 bg-black opacity-50"></div>
      <div className="bg-white p-6 mx-4 rounded-3xl relative w-500">
        <button
          className="absolute top-6 left-6 text-gray-400 hover:text-gray-600"
          onClick={handleOnClose}
        >
          <Close className="w-4 h-4" />
        </button>

        <div id="report_2">
          <div className="mt-10 mb-5">
            <div className="text-primary-600 font-semibold">
              Cancelled contracts may be subject to non-refundable payments, and
              account suspension or termination.
            </div>
          </div>
          <p className="text-xs mb-2 text-gray-400">Reason</p>
          <textarea
            rows={13}
            autoFocus={true}
            onChange={(e) => setRemarks(e.target.value)}
            className="resize-none border rounded-xl w-full p-4 text-sm"
          />
        </div>

        <div className={`mt-4 flex flex-row justify-between`}>
          <button
            className="mt-4 font-semibold py-3 "
            onClick={() => {
              handleOnClose();
            }}
          >
            <p className="text-sm underline">Back</p>
          </button>

          <button
            className="mt-4 bg-primary-500 px-10 text-white font-semibold py-3 rounded-full hover:opacity-90"
            onClick={() => {
              handleCancel(remarks);
            }}
          >
            {isLoading ? <Loader /> : <p className="text-sm">Send</p>}
          </button>
        </div>
      </div>
    </div>
  );
};
