import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Calendar } from "react-multi-date-picker";
import dayjs from "dayjs";
import {
  updateFilterModalDate,
  updateFilterModalIsland,
  updateFilterModalLocation,
  updateFilterModalMerchant,
  updateListingLastPage,
  updateListingLoading,
  updateListingPage,
  updateListings,
  updateSearchModal,
} from "../../store/slicers/listingSlice";
import {
  capitalizeFirstLetter,
  dateFormatter,
  locationFilter,
  provinceFilter,
} from "../../utils/common";
import { toast } from "react-hot-toast";
import Back from "../../assets/svg/Back";
import Checkbox from "./Checkbox";

export const SearchMobile = ({ isOpen, onClose }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const filterModalDate = useSelector((state) => state.listing.filterModalDate);
  const filterModalLocation = useSelector(
    (state) => state.listing.filterModalLocation
  );
  const filterModalMerchant = useSelector(
    (state) => state.listing.filterModalMerchant
  );

  const filterModalIsland = useSelector(
    (state) => state.listing.filterModalIsland
  );
  const [searchIsland, setIslandModal] = useState(false);
  const [searchDate, setDateModal] = useState(false);
  const [selectedIsland, setIsland] = useState(filterModalIsland);
  const [searchPlace, setPlace] = useState("");
  const [searchEventListing, setEventListingModal] = useState(false);
  const [selectedEventListing, setMerchant] = useState(filterModalMerchant);
  const [selectedDate, setDate] = useState(filterModalDate);
  const islands = [
    { key: "flexible", name: "I'm Flexible" },
    { key: "luzon", name: "Luzon" },
    { key: "visayas", name: "Visayas" },
    { key: "mindanao", name: "Mindanao" },
  ];
  const [regions, setRegion] = useState([]);
  const [selectedProvince, selectProvince] = useState([]);
  const [selectAllProvince, selectAll] = useState(true);

  const handleOnClose = (isSearch, isClear) => {
    if (isSearch) {
      dispatch(updateFilterModalLocation(searchPlace));
      dispatch(updateFilterModalMerchant(selectedEventListing));
      dispatch(updateFilterModalDate(selectedDate));
      dispatch(updateFilterModalIsland(selectedIsland));
      const currentURL = window.location.pathname; // Get the current URL
      const newSearchParams = new URLSearchParams(window.location.search); // Extract current query parameters

      // Add or update query parameters

      newSearchParams.delete("island");
      newSearchParams.delete("location");
      newSearchParams.delete("date");
      newSearchParams.delete("merchant");
      if (selectedIsland !== "") {
        newSearchParams.set("island", selectedIsland);
      }
      if (searchPlace !== "") {
        newSearchParams.set("location", searchPlace);
      }
      if (selectedDate !== "") {
        newSearchParams.set("date", selectedDate);
      }
      if (selectedEventListing !== "") {
        newSearchParams.set("merchant", selectedEventListing);
      }
      const newURL = `${currentURL}?${newSearchParams.toString()}`;

      dispatch(updateListingLoading(false));
      dispatch(updateListingLastPage(false));
      dispatch(updateListings([]));
      dispatch(updateListingPage("1"));

      navigate(newURL);
    } else {
      setIsland("");
      setPlace("");
      setDate("");
      setRegion([]);
      selectProvince([]);
      selectAll(false);
      setMerchant("");
    }
    if (!isClear) {
      onClose();
      dispatch(updateSearchModal({ status: false, id: "" }));
    }
  };

  const handleSelectProvince = (province) => {
    const index = selectedProvince.indexOf(province);
    if (index > -1) {
      selectAll(false);
      selectedProvince.splice(index, 1);
    } else {
      selectedProvince.push(province);
    }
    selectProvince([...selectedProvince]);
    setPlace(selectedProvince.toString());
  };
  const handleSelectAll = () => {
    if (selectAllProvince) {
      // Deselect all provinces
      selectAll(false);
      selectProvince([]);
    } else {
      // Select all provinces
      let allProvinces = [];
      regions.forEach((data) => {
        // Collect all provinces from all regions
        allProvinces = allProvinces.concat(
          data.provinces.map((prv) => prv.province_name)
        );
      });
      selectProvince(allProvinces);
      selectAll(true);
    }
    const sIsland = capitalizeFirstLetter(selectedIsland);
    setPlace(sIsland);
  };

  useEffect(() => {
    reUseFilterLocation();
  }, []);
  const reUseFilterLocation = async () => {
    switch (filterModalLocation) {
      case "Luzon":
        setIsland("luzon");
        setPlace("Luzon");
        break;
      case "Visayas":
        setIsland("visayas");
        setPlace("Visayas");
        break;
      case "Mindanao":
        setIsland("mindanao");
        setPlace("Mindanao");
        break;
      case "I'm flexible":
        setIsland("flexible");
        setPlace("I'm flexible");
        break;
      default:
        if (filterModalLocation.includes(",")) {
          let array = filterModalLocation.split(",");
          setIsland(filterModalIsland);
          selectProvince(array);
          setRegion(await locationFilter(selectedIsland));
          setPlace(filterModalLocation);
        }
        break;
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 text-gray-500">
      <div className="bg-gray-50 relative w-full h-screen mb-0">
        <button
          className="absolute top-6 left-6 text-gray-400 hover:text-gray-600"
          onClick={() => handleOnClose(false, false)}
        >
          <svg
            className="w-4 h-4"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>

        <div className="flex flex-col justify-between h-full">
          <div id="search_mobile">
            <div className="mt-10 px-6 flex flex-wrap justify-center">
              <div className="underline mr-5 text-black cursor-pointer">
                Celebrate
              </div>
              <div
                className="cursor-pointer"
                onClick={() => {
                  toast("Anyaya is still under construction. Coming soon!");
                }}
              >
                Online Experience
              </div>
            </div>
            <div className="flex flex-col p-6">
              {searchIsland ? (
                <div id="search_island">
                  <div className="bg-white text-sm p-6 rounded-3xl shadow-lg ">
                    <div className="flex flex-row">
                      {regions.length !== 0 && (
                        <div
                          className="mr-2"
                          onClick={() => {
                            setIsland("flexible");
                            setRegion([]);
                            setPlace("I'm Flexible");
                            selectProvince([]);
                          }}
                        >
                          <Back className="w-6 h-6" />
                        </div>
                      )}

                      <div className="text-lg font-semibold">Where to?</div>
                    </div>
                    {regions.length === 0 ? (
                      <>
                        <div className="">Search by Island</div>
                        <div className="items-center grid grid-cols-2 gap-4 py-5 w-full overflow-y-auto">
                          {islands.map((data, key) => {
                            return (
                              <div
                                key={key}
                                className="justify-self-center group px-2"
                                onClick={async () => {
                                  setIsland(data.key);
                                  setPlace(data.name);
                                  setRegion(await locationFilter(data.key));
                                  selectProvince(
                                    await provinceFilter(data.key)
                                  );
                                  setPlace(data.name);
                                }}
                              >
                                <div className="self-center relative w-28 h-28 border rounded-2xl border-primary-500 p-2  cursor-pointer">
                                  <div
                                    className={
                                      data.key +
                                      ` w-full h-full bg-no-repeat bg-cover ${
                                        selectedIsland === data.key
                                          ? "opacity-100"
                                          : "opacity-50"
                                      } group-hover:opacity-100`
                                    }
                                  ></div>
                                  <div className="rounded-2xl absolute inset-0  opacity-0 group-hover:opacity-10 transition-opacity duration-300 bg-primary-500"></div>
                                </div>
                                <div
                                  className={`text-xs text-center mt-1  ${
                                    selectedIsland === data.key
                                      ? "opacity-100"
                                      : "opacity-50"
                                  } group-hover:opacity-100`}
                                >
                                  {data.name}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="items-center flex flex-row justify-end">
                          <Checkbox
                            checkSize="h-8 w-8"
                            label={
                              selectAllProvince ? "Unselect All" : "Select All"
                            }
                            labelClass="text-base"
                            checked={selectAllProvince}
                            onChange={() => {
                              handleSelectAll();
                            }}
                            leftSide={true}
                          />
                        </div>
                        <div className="max-h-96 overflow-y-scroll ml-4 mt-2">
                          {regions.map((data, key) => {
                            return (
                              <div key={key}>
                                <div className="text-primary-500 mt-4 text-base">
                                  {data.region_name}
                                </div>
                                <div>
                                  {data.provinces.map((prv, i) => {
                                    return (
                                      <div key={i}>
                                        <Checkbox
                                          checkSize="h-8 w-8"
                                          label={prv.province_name}
                                          labelClass="text-base"
                                          checked={selectedProvince.includes(
                                            prv.province_name
                                          )}
                                          onChange={() =>
                                            handleSelectProvince(
                                              prv.province_name
                                            )
                                          }
                                        />
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              ) : (
                <div
                  id="search_island_value"
                  onClick={() => {
                    setIslandModal(true);
                    setDateModal(false);
                  }}
                >
                  <div className="bg-white text-sm p-6 rounded-xl shadow-lg flex flex-row justify-between">
                    <div className="text-sm font-semibold">Where</div>
                    <div className="text-sm whitespace-nowrap">
                      {searchPlace === "" ? "-" : searchPlace.substring(0, 25)}
                      {searchPlace.length > 25 ? "..." : ""}
                    </div>
                  </div>
                </div>
              )}

              {searchDate ? (
                <div id="search_event_listing" className="mt-4">
                  <div className="bg-white text-sm p-6 rounded-xl shadow-lg  justify-between">
                    <div className="flex flex-row justify-between text-center align-center">
                      <div className="text-lg font-semibold">
                        When's your event?
                      </div>
                      <button
                        onClick={() => {
                          setDate("");
                          setDateModal(false);
                          setIslandModal(false);
                          setEventListingModal(false);
                        }}
                        className="text-gray-400 rounded-full p-2 hover:text-gray-600"
                      >
                        <svg
                          className="w-4 h-4 text-black"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </button>
                    </div>

                    <div className="flex">
                      <Calendar
                        className="purple mobile"
                        range
                        value={selectedDate.split(",")}
                        minDate={dayjs().toDate()}
                        numberOfMonths={1}
                        headerOrder={[
                          "MONTH_YEAR",
                          "LEFT_BUTTON",
                          "RIGHT_BUTTON",
                        ]}
                        onChange={(date) => {
                          // 2023/10/28,2023/11/30
                          if (date?.toString().includes(",")) {
                            setDate(date?.toString());
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  className="mt-4"
                  id="search_date_value"
                  onClick={() => {
                    setDateModal(true);
                    setIslandModal(false);
                  }}
                >
                  <div className="bg-white text-sm p-6 rounded-xl shadow-lg flex flex-row justify-between">
                    <div className="text-sm font-semibold">When</div>
                    <div className="text-sm">
                      {selectedDate === "" ? "-" : dateFormatter(selectedDate)}
                    </div>
                  </div>
                </div>
              )}
              {/* {searchEventListing ? (
                      <div id="search_event_listing" className="mt-4">
                        <div className="bg-white text-sm p-6 rounded-xl shadow-lg flex flex-row justify-between">
                          <div className="text-lg font-semibold">
                            Event Listing
                          </div>
                          <input
                            onChange={(e) => {
                              setMerchant(e.target.value);
                            }}
                            onMouseEnter={() => {
                              setDateModal(false);
                              setIslandModal(false);
                              setEventListingModal(true);
                            }}
                            onMouseLeave={() => {
                              setDateModal(false);
                              setIslandModal(false);
                              setEventListingModal(false);
                            }}
                            value={selectedEventListing}
                            className={`resize-none border-none  outline-none bg-transparent text-gray-400 self-center `}
                            // disabled={filterModalType !== "3"}
                            placeholder="Search Event Listing"
                          />
                        </div>
                      </div>
                    ) : (
                      <div
                        className="mt-4"
                        id="search_merchant_value"
                        onClick={() => {
                          setEventListingModal(true);
                          setDateModal(false);
                          setIslandModal(false);
                        }}
                      >
                        <div className="bg-white text-sm p-6 rounded-xl shadow-lg flex flex-row justify-between">
                          <div className="text-sm font-semibold">
                            Event Listing
                          </div>
                          <div className="text-sm whitespace-nowrap">
                            {selectedEventListing.substring(0, 25)}
                            {selectedEventListing.length > 25 ? "..." : ""}
                          </div>
                        </div>
                      </div>
                    )} */}
            </div>
          </div>

          <div className="bg-white px-6 py-4 flex flex-row justify-between">
            <button
              className="font-semibold py-3 "
              onClick={() => handleOnClose(false, true)}
            >
              <p className="text-sm underline">Clear</p>
            </button>

            <button
              className="bg-primary-500 px-10 text-white font-semibold py-3 rounded-full hover:opacity-90"
              onClick={() => handleOnClose(true, false)}
            >
              <p className="text-sm">Search</p>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
