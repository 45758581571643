import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateLoginModal } from "../../../store/slicers/authSlice";
import { useLocation } from "react-router-dom";
const Protected = ({ children }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const isLoggedIn = useSelector((state) => state.auth.token);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true); // Loading state

  useEffect(() => {
    if (isLoggedIn === "") {
      dispatch(updateLoginModal({ status: true }));
      navigate("/category");
    }else{
      setLoading(false)
    }
  }, [isLoggedIn, navigate, dispatch]);
  const isInbox = location.pathname.includes("inbox");
  // Render loading state until authentication check is completed
  if (loading) {
    return <div></div>;
  }
  return (
    <div className={`${isInbox ? "" : "px-10"} lg:px-40 xl:px-56 pb-10 pt-4`}>
      {children}
    </div>
  );
};

export default Protected;
